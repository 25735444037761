import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

function ProtectedRoute({ authorized= false, redirectPath = '/' }) {
  const location = useLocation();

    if (!authorized) {
        return <Navigate to={redirectPath} state={{ from: location }} replace />;
      }
    
      return <Outlet />;
}

export default ProtectedRoute;
