import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// layout
import CabinetLayout from "../layout/cabinet/CabinetLayout";
import FullLayout from "../layout/full-layout/FullLayout";

// auth
import Login from "../module/auth/login/Login";

// agent page
import AgentDashboard from "../module/agent/dashboard/Dashboard";
import Finance from "../module/agent/finance/Finance";
import Archive from "../module/agent/archive/Archive";
import Client from "../module/agent/client/Client";
import ClientPageById from "../module/agent/client/ClientPageById";
// import Referal from "../module/agent/referal/Referal";
import Catalog from "../module/agent/catalog/Catalog";
import Comparsion from "../module/agent/comparsion/Comparsion";
import ReqCreateV2 from "../module/agent/req-create-v2/ReqCreateV2";

//
import { useAppContext } from "../store/Context";
import BookingById from "../module/booking/booking-by-id/BookingById";
import BookingPage from "../module/booking/BookingPage";
import ProtectedRoute from "./ProtectedRoute";

export default function Router() {
  const {
    state: { isAuth },
  } = useAppContext();

  // if (!isAuth)
  //   return (
  //     <Routes>
  //       <Route path="/" element={<Login />} />
  //       <Route path="*" element={<Navigate to="/" />} />
  //     </Routes>
  //   );

  return (
    <Routes>
      <Route path="/login" element={<Login />} />

      <Route
        element={<ProtectedRoute authorized={isAuth} redirectPath="/login" />}
      >
        <Route path="/" element={<FullLayout />}>
          <Route index element={<Navigate to="dashboard" />} />
          <Route path="dashboard" element={<AgentDashboard />} />
          <Route path="req-create" element={<ReqCreateV2 />} />
          <Route path="req-create/:reqId" element={<ReqCreateV2 />} />
          <Route path="catalog" element={<Catalog />} />
          <Route path="comparsion" element={<Comparsion />} />
          <Route path="booking" element={<BookingPage />} />
          <Route path="booking/:year/:id" element={<BookingById />} />
        </Route>
        <Route path="/" element={<CabinetLayout />}>
          <Route path="finance" element={<Finance />} />
          <Route path="archive" element={<Archive />} />
          <Route path="client" element={<Client />} />
          <Route path="client/:id" element={<ClientPageById />} />
        </Route>
        <Route path="*" element={<Navigate to="dashboard" />} />
      </Route>
    </Routes>
  );

  // return (
  //   <Routes>
  //     <Route path="/" element={<CabinetLayout />}>
  //       <Route index element={<Navigate to="dashboard" />} />
  //       <Route path="finance" element={<Finance />} />
  //       <Route path="archive" element={<Archive />} />
  //       <Route path="client" element={<Client />} />
  //       <Route path="client/:id" element={<ClientPageById />} />
  //       {/* <Route path="referal" element={<Referal />} /> */}
  //       <Route path="rc" element={<RequestCreate />} />
  //     </Route>
  //     <Route path="/" element={<FullLayout />}>
  //       <Route index element={<Navigate to="dashboard" />} />
  //       <Route path="dashboard" element={<AgentDashboard />} />
  //       <Route path="req-create" element={<ReqCreateV2 />} />
  //       <Route path="req-create/:reqId" element={<ReqCreateV2 />} />
  //       <Route path="catalog" element={<Catalog />} />
  //       <Route path="comparsion" element={<Comparsion />} />
  //       <Route path="booking" element={<BookingPage />} />
  //       <Route path="booking/:year/:id" element={<BookingById />} />
  //     </Route>
  //     <Route path="*" element={<Navigate to="dashboard" />} />
  //   </Routes>
  // );
}
