import React from "react";
//
import NumberFormat from "../number-format/NumberFormat";
import "./CatalogCarTerm.styles.scss";
import { useAppContext } from "../../store/Context";

export default function CatalogCarTerm({ term }) {

  const {
    state: { isMobile },
  } = useAppContext();

  return (
      <div className="car__term">
        {!isMobile &&
        <div className="car__term__top">
           <h1>Условия аренды</h1>
          <a
            href="https://avto-prokat.spb.ru/voprosy"
            target="_blank"
            rel="noreferrer"
          >
            Подробнее
          </a>
        </div>
        }
        <ul className="car__term__list">
          <li>
            <h1>Депозит</h1>
            <p>
              <NumberFormat value={term?.deposit} />
            </p>
          </li>
          <li>
            <h1>Стаж водителя</h1>
            <p>От {term?.exp} лет</p>
          </li>
          <li>
            <h1>Возраст водителя</h1>
            <p>От {term?.age} лет</p>
          </li>
          <li>
            <h1>Франшиза</h1>
            <p>
              <NumberFormat value={term?.franchise} />
            </p>
          </li>
          <li>
            <h1>Перепробег</h1>
            <p>
              <NumberFormat value={term?.overrun} /> за км
            </p>
          </li>
        </ul>
        {isMobile &&
          <a
            href="https://avto-prokat.spb.ru/voprosy"
            target="_blank"
            rel="noreferrer"
            className="car__term__link-mobile"
          >
            Подробнее
          </a>
        }
      </div>
  );
}
