import React, { useMemo, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

//
import PopUp from "../popup/PopUp";
import FilterCarMark from "../filter-car-mark/FilterCarMark";
import CarFilterCollapse from "../car-filter-collapse/CarFilterCollapse";
import CarBodyFilter from "../car-body-filter/CarBodyFilter";
import CatalogCarRow from "../catalog-car-row/CatalogCarRow";
import IconWrapper from "../icon-wrapper/IconWrapper";
import Select from "../select/Select";
import ReqListEmpty from "../req-list-empty/ReqListEmpty";
import Loader from "../loader/Loader";
import Switch from "../switch/Switch";
import { useAppContext } from "../../store/Context";
import { useGetCarList } from "../../store/catalog/hook";
import { countMarkModel, genMarkList } from "../../util/formatters";
import {
  staticCarMarkList,
  staticCarBodyList,
  staticDriveList,
  staticEngineTypeList,
  staticSeatList,
  staticCategoryList,
  staticStatusList,
} from "../../module/agent/catalog/sections/static";
import Button from "../button/Button";
import CarFilterItem from "../car-filter-item/CarFilterItem";
import CatalogCarRowMobile from "../catalog-car-row-mobile/CatalogCarRowMobile";
import "./ChooseCarModal.styles.scss";

export default function ChooseCarModal({ isOpen, onClose, car, onChangeCar }) {
  // helper
  const { pathname } = useLocation();
  const isComparsion = pathname.includes("comparsion");
  // context
  const {
    state: { cityId, priceId, priceLoading, isMobile },
  } = useAppContext();

  // state
  const [status, setStatus] = useState("");
  const [isAuto, setIsAuto] = useState(false);
  const [drive, setDrive] = useState([]);
  const [engine, setEngine] = useState([]);
  const [capacity, setCapacity] = useState([]);
  const [carBody, setCarBody] = useState([]);
  const [marks, setMarks] = useState([...staticCarMarkList]);
  const [category, setCategory] = useState([]);
  const [filterCounter, setFilterCounter] = useState(0);
  const [selectedCar, setSelectedCar] = useState(null);
  const [staticLoading, setStaticLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setStaticLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    setSelectedCar(car);
  }, [car]);

  // query
  const { data, isLoading: getCarListLoading } = useGetCarList(
    cityId,
    priceId,
    {
      enabled: !!cityId && !!priceId,
    }
  );

  useEffect(() => {
    if (!data) return;
    setMarks([...genMarkList(data.data.data)]);
  }, [data]);

  function changeStatus(s) {
    setStatus(s);
  }

  function changeIsAuto(ia) {
    setIsAuto(ia);
  }

  function changeDrive(v) {
    if (v === 0) {
      setDrive([]);
      return;
    }
    if (drive.includes(v)) {
      setDrive((p) => p.filter((i) => i !== v));
      return;
    }
    setDrive((p) => [...p, v]);
  }

  function changeEngine(v) {
    if (v === 0) {
      setEngine([]);
      return;
    }
    if (engine.includes(v)) {
      setEngine((p) => p.filter((i) => i !== v));
      return;
    }
    setEngine((p) => [...p, v]);
  }

  function changeCapacity(v) {
    if (v === 0) {
      setCapacity([]);
      return;
    }
    if (capacity.includes(v)) {
      setCapacity((p) => p.filter((i) => i !== v));
      return;
    }
    setCapacity((p) => [...p, v]);
  }

  function changeCarBody(v) {
    if (v === 0) {
      setCarBody([]);
      return;
    }
    if (carBody.includes(v)) {
      setCarBody((p) => p.filter((i) => i !== v));
      return;
    }
    setCarBody((p) => [...p, v]);
  }

  function changeMarkModel(id, mark) {
    setMarks((p) =>
      p.map((i) => {
        return {
          ...i,
          checked: i.mark === mark ? false : i.checked,
          models: i.models.map((j) =>
            j.id === id ? { ...j, checked: !j.checked } : j
          ),
        };
      })
    );
  }

  function resetMarks() {
    setMarks((p) =>
      p.map((i) => ({
        ...i,
        checked: false,
        models: i.models.map((j) => ({ ...j, checked: false })),
      }))
    );
  }

  function toggleAllMark(id) {
    setMarks((p) =>
      p.map((i) =>
        i.id === id
          ? {
              ...i,
              checked: !i.checked,
              models: i.models.map((j) => ({ ...j, checked: false })),
            }
          : i
      )
    );
  }

  function changeCategory(c) {
    if (c === "") {
      setCategory([]);
      return;
    }
    if (category.includes(c)) {
      setCategory((p) => p.filter((i) => i !== c));
      return;
    }
    setCategory((p) => [c, ...p]);
  }

  function resetFilters() {
    setDrive([]);
    setEngine([]);
    setCapacity([]);
    setCarBody([]);
    setCategory([]);
    setFilterCounter(0);
    setMarks((p) =>
      p.map((i) => ({
        ...i,
        checked: false,
        models: i.models.map((j) => ({ ...j, checked: false })),
      }))
    );
  }

  function changeSelectedCar(sc) {
    setSelectedCar(sc);
  }

  const resList = useMemo(() => {
    if (!data) return [];
    let tempList = [...data.data.data];
    let filterCount = 0;

    if (status) {
      tempList = tempList
        .map((i) => ({
          ...i,
          car_list: i.car_list
            .map((j) => (j.status === status ? j : null))
            .filter((k) => k),
        }))
        .filter((i) => i.car_list.length);
    }

    if (isAuto) {
      tempList = tempList.filter((i) => i.is_auto);
    }

    if (drive.length) {
      tempList = tempList
        .map((i) => ({
          ...i,
          car_list: i.car_list
            .map((j) => (drive.includes(j.parameters.drive_id) ? j : null))
            .filter((k) => k),
        }))
        .filter((i) => i.car_list.length);
      filterCount += 1;
    }

    if (engine.length) {
      tempList = tempList
        .map((i) => ({
          ...i,
          car_list: i.car_list
            .map((j) => (engine.includes(j.parameters.engine_id) ? j : null))
            .filter((k) => k),
        }))
        .filter((i) => i.car_list.length);
      filterCount += 1;
    }

    if (capacity.length) {
      tempList = tempList
        .map((i) => ({
          ...i,
          car_list: i.car_list
            .map((j) => (capacity.includes(j.parameters.capacity) ? j : null))
            .filter((k) => k),
        }))
        .filter((i) => i.car_list.length);
      filterCount += 1;
    }

    if (carBody.length) {
      tempList = tempList.filter((i) => carBody.includes(i.body_id));
      filterCount += 1;
    }

    const { markList, modelList } = countMarkModel(marks);
    if (modelList.length || markList.length) {
      tempList = tempList.filter(
        (i) =>
          modelList.includes(i.brand + " " + i.model) ||
          markList.includes(i.brand)
      );
      filterCount += 1;
    }

    if (category.length) {
      tempList = tempList.filter((i) => category.includes(i.class));
      filterCount += 1;
    }

    setFilterCounter(filterCount);

    return tempList;
  }, [data, status, isAuto, drive, engine, capacity, carBody, marks, category]);

  return (
    <PopUp
      isOpen={isOpen}
      onClose={onClose}
      full={1}
      onReady={() => {
        if (!isComparsion) {
          onChangeCar(selectedCar);
        }
        onClose();
      }}
    >
      <div className="choose__car__modal">
        <section className="choose__car__modal__left">
          <nav className="choose__car__modal__left__top">
            <h1>Выбор автомобиля</h1>
            <IconWrapper
              icon="close"
              w={48}
              h={48}
              onClick={onClose}
              border={1}
            />
          </nav>

          <ul className="choose__car__modal__left__selects">
            <li>
              <Select
                title="Категория"
                options={[...staticCategoryList]}
                multiple={1}
                value={category}
                onChange={changeCategory}
              />
            </li>
            <li>
              <Select
                title="Статус"
                options={[...staticStatusList]}
                value={status}
                onChange={changeStatus}
              />
            </li>
            <li className="auto__switch">
              <p>Только автомат</p>
              <Switch value={isAuto} onChange={changeIsAuto} />
            </li>
          </ul>

          {filterCounter > 0 && (
            <div className="choose__car__modal__left__filter">
              <Button
                p={[9, 24]}
                color="primary"
                radius={24}
                onClick={resetFilters}
              >
                Сбросить всё
              </Button>
              <CarFilterItem
                title="Категория"
                value={category}
                onReset={() => changeCategory("")}
              />
              <CarFilterItem
                title="Привод"
                list={staticDriveList}
                value={drive}
                onReset={() => changeDrive(0)}
              />
              <CarFilterItem
                title="Двигатель"
                list={staticEngineTypeList}
                value={engine}
                onReset={() => changeEngine(0)}
              />
              <CarFilterItem
                title="Кузов"
                list={staticCarBodyList}
                value={carBody}
                onReset={() => changeCarBody(0)}
              />
              <CarFilterItem
                title="Мест"
                value={capacity}
                onReset={() => changeCapacity(0)}
              />
              <CarFilterItem
                title="Марка"
                mark={1}
                value={marks}
                onReset={resetMarks}
              />
            </div>
          )}

          {!(getCarListLoading || priceLoading || staticLoading) &&
          resList.length === 0 ? (
            <ReqListEmpty notFound={1} title="Ничего не найдено" catalog={1} />
          ) : (
            <ul className="choose__car__modal__left__list">
              {getCarListLoading || priceLoading || staticLoading ? (
                <Loader />
              ) : (
                <>
                  {resList.map((i) => (
                    <li key={i.price_unit_id}>
                      {
                        isMobile ?
                        <CatalogCarRowMobile
                          car={i}
                          inModal={1}
                          selectedCar={selectedCar}
                          onChangeSelectedCar={changeSelectedCar}
                        />
                        :
                        <CatalogCarRow
                          car={i}
                          inModal={1}
                          selectedCar={selectedCar}
                          onChangeSelectedCar={changeSelectedCar}
                        />
                      }
                    </li>
                  ))}
                </>
              )}
            </ul>
          )}
        </section>
        {
        !isMobile &&
        <section className="choose__car__modal__right">
          <FilterCarMark
            options={marks}
            onChangeMarkModel={changeMarkModel}
            onToggleAllMark={toggleAllMark}
            onResetCarMarkList={resetMarks}
          />
          <CarFilterCollapse
            title="Привод"
            options={staticDriveList}
            value={drive}
            onChange={changeDrive}
          />
          <CarFilterCollapse
            title="Типу двигателя"
            options={staticEngineTypeList}
            value={engine}
            onChange={changeEngine}
          />
          <CarFilterCollapse
            title="Количество мест"
            options={staticSeatList}
            value={capacity}
            onChange={changeCapacity}
          />
          <CarBodyFilter
            title="Тип кузов"
            options={staticCarBodyList}
            value={carBody}
            onChange={changeCarBody}
          />
        </section>
        }
      </div>
    </PopUp>
  );
}

