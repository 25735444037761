import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useMutation } from "react-query";

//
import Button from "../../../../components/button/Button";
import SmsCodeInput from "../../../../components/sms-code-input/SmsCodeInput";
import AuthApi from "../../../../store/auth/api";
import Loader from "../../../../components/loader/Loader";
import { hidePhoneNumberGen } from "../../../../util/formatters";

export default function ResetSmsCode({ onBack, onNext, phone }) {
  // state
  const [code, setCode] = useState(["", "", "", ""]);
  const [time, setTime] = useState(60);
  const [codeErr, setCodeErr] = useState(false);

  // watcher
  useEffect(() => {
    let alfa = setTimeout(() => {
      setTime((p) => p - 1);
    }, 1000);
    if (time < 1) clearTimeout(alfa);
  }, [time]);

  // query
  const mutation = useMutation(
    (code) => AuthApi.checkCode({ login: phone, pass : code }),
    {
      onSuccess,
      onError,
    }
  );
  const resendCodeMutation = useMutation(() =>
    AuthApi.newPassReq({ login: phone })
  );

  // method
  function handleSubmit() {
    let tempCode = code.join("");
    if (tempCode.length < 4) {
      setCodeErr(true);
      return;
    }
    mutation.mutate(tempCode);
  }

  function onError() {
    setCodeErr(true);
    setTime(0);
  }

  function onSuccess() {
    onNext("reset_code", code.join(""));
  }

  function resendSmsCode() {
    setTime(60);
    resendCodeMutation.mutate();
  }

  return (
    <StyledResetSmsCode>
      <h1>Сбросить пароль</h1>
      <h4>
        На указанный номер <span>{hidePhoneNumberGen(phone)}</span> <br /> был
        отправлен одноразовый код
      </h4>
      <div className="sms__code__input__wrap">
        <SmsCodeInput
          value={code}
          setValue={setCode}
          err={codeErr}
          onResetErr={() => setCodeErr(false)}
        />
      </div>
      {time ? (
        <p>Повторно отправить код можно через {time} сек</p>
      ) : (
        <p className="refresh__code" onClick={resendSmsCode}>
          Отправить код повторно
        </p>
      )}

      <footer>
        <i className="icon icon-lg icon-arrow-left" onClick={onBack}></i>
        <Button
          type="submit"
          color="primary"
          p={[16, 30]}
          textUpperCase={1}
          fW={500}
          onClick={handleSubmit}
        >
          {mutation.isLoading && (
            <Loader bg="primary" color="light" w={24} h={24} size={3} />
          )}
          Далее
        </Button>
      </footer>
    </StyledResetSmsCode>
  );
}

const StyledResetSmsCode = styled.div`
  h1 {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    color: var(--dark);
    text-align: center;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  h4 {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--dark-70);
    margin-bottom: 50px;

    span {
      font-weight: 500;
    }
  }

  .sms__code__input__wrap {
    width: 320px;
    margin-bottom: 35px;
  }

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--dark-50);
    margin-bottom: 35px;
  }

  .refresh__code {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    color: var(--primary);
    cursor: pointer;
  }

  footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    i {
      background-color: var(--dark);
    }
  }
`;
