import React from "react";
import styled from "styled-components";

//
import logo from "../../asset/img/almak-logo.svg";

export default function AuthLeft() {

  return (
    <StyledAuthLeft>
      <img src={logo} alt="logo" />
      <p>Кабинет агента</p>
    </StyledAuthLeft>
  );
}

const StyledAuthLeft = styled.section`
  background-color: var(--primary);
  padding: 60px 40px 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 310px;

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: var(--light);
  }

  @media (max-width: 768px) {
    padding: 20px;
    width: 100%;

    p {
      display: none;
    }
  }
`;
