import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import cl from "classnames";
import ROCH from "react-outside-click-handler";
import { useParams } from "react-router-dom";

import IconWrapper from "../icon-wrapper/IconWrapper";
import Select from "../select/Select";
import Input from "../input/Input";
import InputPrice from "../input-price/InputPrice";
import NumberFormat from "../number-format/NumberFormat";
import { formatPrice } from "../../util/formatters";
import { useMemo } from "react";
import { useAppContext } from "../../store/Context";
import { vidachaList, vozvratList } from "./static";

export default function AddressPicker({
  type,
  obj,
  onChange,
  priceType,
  tariffIndex,
  options,
  changeOptions,
  points,
  isEditBooking,
}) {
  const { reqId } = useParams();
  // context
  const {
    state: {
      addOptions: { delivery },
    },
  } = useAppContext();

  const staticOptions = type === "vidacha" ? vidachaList : vozvratList;
  // state
  const [isOpen, setIsOpen] = useState(false);
  const [optionType, setOptionType] = useState(staticOptions[0]);

  useEffect(() => {
    if (!reqId) return;
    const pointType = obj.point_type || "office";
    const statOpt = staticOptions.find((i) => i.type === pointType);
    const indexDelivery = options.findIndex((i) => i.name === "Доставка в область")
    const indexReturn = options.findIndex((i) => i.name === "Возврат из области")

    setOptionType({ ...statOpt });

    if (type === "vidacha") {
      if ( options.find((opt) => opt.name === "Подача по адресу")) {
        setOptionType(staticOptions[1]);
      }
      if ( options.find((opt) => opt.name === "Доставка в область")) {
        setOptionType(staticOptions[4]);
        obj.sum = options[indexDelivery].sum;
      }
    } else {
      if ( options.find((opt) => opt.name === "Возврат по адресу")) {
        setOptionType(staticOptions[1])
      }
      if ( options.find((opt) => opt.name === "Возврат из области")) {
        setOptionType(staticOptions[4])
        obj.sum = options[indexReturn].sum;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [reqId, obj]);

  // methods
  function changeIsOpen(io) {
    if (!isEditBooking) return;
    setIsOpen(io);
  }
  
  function changeType(ot) {
    changeOptions(0, delivery[optionType.index]);
    setOptionType(ot);
    changeOptions(1, delivery[ot.index], type);
    changeIsOpen(false);
    onChange({
      address: "",
      point_id: null,
      point_name: null,
      point_type: ot.type,
    });

  }

  const addressList = useMemo(() => {
    const list = [];

    if (!points || !optionType.type) return list;

    points[optionType.type]?.forEach((i) => {
      list.push({ id: i.point_id, title: i.point_name, value: i.point_id });
    });
    if (list.length === 1 && !obj.address) {
      handleChangeSelect(points[optionType.type][0].point_id);
      return list;
    }

    return list;
    
  }, [points, optionType.type]);

  const optionsList = useMemo(() => {
    setOptionType((p) => {
      return {
        ...p,
        price:
          delivery[p.index]?.price_matrix[tariffIndex][priceType] || p.price,
      };
    });
    return staticOptions.map((i) =>
      i.index !== null
        ? {
            ...i,
            price:
              delivery[i.index]?.price_matrix[tariffIndex][priceType] ||
              i.price,
          }
        : i
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delivery, priceType, tariffIndex]);

  function handleChangeSelect(v) {
    if (!v) {
      onChange({
        address: "",
        point_id: null,
        point_name: "",
      });
      return;
    }

    const tempObj = points[optionType.type].find((i) => i.point_id === v);

    onChange({
      address: tempObj.address,
      point_id: tempObj.point_id,
      point_name: tempObj.point_name,
      point_type: tempObj.type,
    });
  }

  function handleChangeInput(e) {
    const address = e.target.value;
    onChange({ 
      address: address
     });

    if (!optionType.type) return;
 
    onChange({
      address: address,
      price: obj.comment,
      sum: obj.comment,
      point_id: null,
      point_name: optionType.name,
      point_type: optionType.type,
    });
  }

  function handleChangePrice(e) {
    const value = e.target.value;

    onChange({ 
      comment: value,
      price: value,
      sum: value,
    }); 
  }

  return (
    <StyledAddressPicker isDblInput={optionType.id === 4}>
      <nav className="address_picker__top">
        <h1>{optionType.value}</h1>
        {optionType.id === 4 ? (
          <div style={{ height: "23px" }} />
        ) : (
          <div className="price__wrap">
            <p>{formatPrice(optionType.price)}</p>₽
          </div>
        )}
      </nav>
      <main className="address_picker__main">
        <div className="left__icon">
          <IconWrapper
            icon={`${optionType.icon} icon-sm`}
            rounded={12}
            border={1}
            onClick={() => changeIsOpen(!isOpen)}
            bg={isOpen ? "primary" : "light"}
            color={isOpen ? "light" : "dark"}
          />
          {isOpen && (
            <ROCH onOutsideClick={() => changeIsOpen(false)}>
              <ul>
                {optionsList.map((i) => (
                  <li
                    key={i.id}
                    onClick={() => changeType(i)}
                    className={cl({
                      active: optionType.id === i.id,
                    })}
                  >
                    <div className="type__left">
                      <i className="icon icon-sm icon-check" />
                      <p>{i.title}</p>
                    </div>
                    {i.price ? (
                      <div className="type__right">
                        <NumberFormat value={i.price} />
                      </div>
                    ) : null}
                  </li>
                ))}
              </ul>
            </ROCH>
          )}
        </div>
        {optionType.id === 4 || optionType.id === 1 ? (
          <>
            <Input
              fill={1}
              size="sm"
              placeholder={"Введите адрес"}
              value={obj.address}
              onChange={handleChangeInput}
            />
            {optionType.id === 4 && (
              <InputPrice
                fill={1}
                size="sm"
                placeholder="Цена"
                value={obj.sum ? obj.sum : ""}
                onChange={handleChangePrice}
              />
            )}
          </>
        ) : (
          <>
          <Select
            title="Выберите пункт"
            size="sm"
            disabled={!isEditBooking}
            options={addressList}
            value={obj.point_id || null}
            onChange={handleChangeSelect}
          />
          </>
        )}
      </main>
    </StyledAddressPicker>
  );
}

const StyledAddressPicker = styled.div`
  .address_picker {
    &__top {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      margin-bottom: 10px;

      h1 {
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0em;
        color: var(--dark);
        padding: 0 10px;
      }

      .price__wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 5px;
        background-color: var(--primary-10);
        color: var(--primary);
        padding: 4px 8px 4px 10px;
        border-radius: 25px;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: 0em;

        p {
          &:focus {
            outline: none;
          }
        }
      }
    }

    &__main {
      display: -ms-grid;
      display: grid;
      grid-template-columns: 40px 1fr;
      gap: 5px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      .left__icon {
        position: relative;

        ul {
          position: absolute;
          background-color: var(--light);
          z-index: 5;
          border: 1px solid var(--primary-dark-15);
          box-shadow: 0px 0px 20px var(--secondary-dark-10);
          border-radius: 16px;
          white-space: nowrap;
          bottom: -5px;
          left: -7px;
          transform: translateY(100%);
          padding: 10px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          gap: 5px;

          li {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            gap: 20px;
            padding: 10px;
            border-radius: 12px;
            cursor: pointer;

            .type__left {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              gap: 10px;

              i {
                background-color: var(--dark);
                opacity: 0.1;
                transition: none;
              }

              p {
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0em;
                color: var(--dark);
              }
            }

            .type__right {
              background: var(--bg);
              border-radius: 6px;
              padding: 2px 6px;
              font-size: 12px;
              font-weight: 400;
              line-height: 15px;
              letter-spacing: 0em;
              color: var(--dark-50);
            }

            &.active {
              background-color: var(--primary-10);

              .type__left {
                i {
                  background-color: var(--primary);
                  opacity: 1;
                }

                p {
                  color: var(--primary);
                }
              }

              .type__right {
                background: var(--light);
                color: var(--primary);
              }
            }
          }

          &::before {
            content: "";
            position: absolute;
            width: 8px;
            height: 8px;
            background: var(--light);
            border: 1px solid var(--primary-dark-15);
            top: -4px;
            left: 22px;
            transform: rotate(45deg);
            border-right-color: transparent;
            border-bottom-color: transparent;
          }
        }
      }
    }

    ${({ isDblInput }) =>
      isDblInput &&
      css`
        &__main {
          grid-template-columns: 40px 1fr 85px;
        }
      `}
  }
`;
