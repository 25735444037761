import api from "../../service/axios";

class ReqCreateApi {
  static getPoints(cityId) {
    return api.get(`/getPoints/${cityId}`);
  }

  static getStaticData() {
    return api.get('/getStaticData')
  }

  static getRequestById(id) {
    return api.get('/getRequests/all/' + id + '/all/all/' )
  }
  
  static getReportRequestById(id) {
    return api.get('/getRequests/all/all/all/' + id )
  }
}

export default ReqCreateApi;
