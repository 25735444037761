import React from "react";
import styled, { css } from "styled-components";

//
import docAddImg from "../../asset/img/doc-add.png";
import docNotFoundImg from "../../asset/img/doc-notfound.png";
import alertClientBlackImg from "../../asset/img/alert-client-black.png";
import reqArrowImg from "../../asset/img/req-arrow.png";
import catalogEmptyImg from "../../asset/img/catalog__empty.png";

export default function ReqListEmpty({
  notFound,
  title,
  text,
  client,
  isBlack,
  catalog,
}) {
  // method
  function getImg() {
    if (catalog) return catalogEmptyImg;
    if (isBlack) return alertClientBlackImg;
    if (notFound) return docNotFoundImg;
    return docAddImg;
  }

  return (
    <StyledReqListEmpty notFound={notFound}>
      <img src={getImg()} alt="docAddImg" />
      <h1>{title}</h1>
      {text && <p>{text}</p>}
      {!notFound && !client && (
        <img
          src={reqArrowImg}
          alt="reqArrowImg"
          className="req__list__empty__arrow"
        />
      )}
    </StyledReqListEmpty>
  );
}

const StyledReqListEmpty = styled.div`
  padding: 80px 0 160px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;

  h1 {
    font-size: 22px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    margin: 60px 0 10px;
    color: var(--dark);
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: var(--dark);
    opacity: 0.6;
  }

  .req__list__empty__arrow {
    position: absolute;
    top: 27px;
    left: 60px;
  }

  ${({ notFound }) =>
    notFound &&
    css`
      padding-top: 110px;
    `}
`;
