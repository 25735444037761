import React, { useState, useMemo } from "react";
import cl from "classnames";
import ReactOutsideClickHandler from "react-outside-click-handler";
import "./HourPicker.styles.scss";

//

export default function HourPicker({ title, hour, value, onChange }) {
  const [isOpen, setIsOpen] = useState(false);

  function changeIsOpen(o) {
    setIsOpen(o);
  }

  function addZero(n) {
    if (String(n).length === 1) {
      n = "0" + n;
    }
    return n;
  }

  const hourList = useMemo(() => {
    const today = new Date();
    if (
      today.getFullYear() === value[2] &&
      today.getMonth() === value[1] &&
      today.getDate() === value[0]
    ) {
      const hour = today.getHours() + 1;
      const list = [];
      for (let i = hour; i < 24; i++) {
        list.push(i);
      }

      return list;
    }

    return new Array(24).fill("").map((i, idx) => idx);
  }, [value]);

  return (
    <div isOpen={isOpen} className="hour__picker">
      <nav className={`hour__picker__nav ${isOpen ? "hour__picker__nav--open" : ""}`} onClick={() => changeIsOpen(true)}>
        <h1>{title}</h1>
        <div className="time__display">
          {addZero(hour[0])}:{addZero(hour[1])}
        </div>
      </nav>
      {isOpen && (
        <ReactOutsideClickHandler onOutsideClick={() => changeIsOpen(false)}>
          <main className="hour__picker__main">
            <section className="hour__picker__main__left">
              <ul className="hour__picker__hour">
                {hourList.map((i) => (
                  <li
                    key={i}
                    onClick={() => onChange([addZero(i), hour[1]])}
                    className={cl({
                      active: addZero(i) === hour[0],
                    })}
                  >
                    {addZero(i)}
                  </li>
                ))}
              </ul>
            </section>
            <section className="hour__picker__main__right">
              <ul className="hour__picker__min">
                {[0, 15, 30, 45].map((i, idx) => (
                  <li
                    key={i}
                    onClick={() => onChange([hour[0], addZero(i)])}
                    className={cl({
                      active: addZero(i) === hour[1],
                    })}
                  >
                    {addZero(i)}
                  </li>
                ))}
              </ul>
            </section>
          </main>
        </ReactOutsideClickHandler>
      )}
    </div>
  );
}
