import React from "react";

//
import ReqCollapseLayout from "../../req-collapse-layout/ReqCollapseLayout";
import ReqProgress from "../../req-progress/ReqProgress";
import ClientContactCard from "../../client-contact-card/ClientContactCard";
import ReqInfoCard from "../../req-info-card/ReqInfoCard";
import { useAppContext } from "../../../store/Context";

export default function InWork({ archived, request, client }) {
  const {
    state: { isMobile },
  } = useAppContext();

  return (
    <ReqCollapseLayout
      left={
        <>
          <ClientContactCard request={request} id={request?.client_id} phone={request?.phone} email={client?.email} />
          {!isMobile && <ReqProgress list={request.status_history} archived={archived} />}
        </>
      }
      right={
        <>
          <ReqInfoCard request={request} />
          {isMobile && <ReqProgress list={request.status_history} archived={archived} />}
        </>
      }
    />
  );
}
