import React, { useMemo, useState, useEffect } from "react";
//
import Card from "../../../../../components/card/Card";
import SortBtn from "../../../../../components/sort-btn/SortBtn";
import Select from "../../../../../components/select/Select";
import Search from "../../../../../components/search/Search";
import Input from "../../../../../components/input/Input";
import ReqListEmpty from "../../../../../components/req-list-empty/ReqListEmpty";
import ReqListItem from "../../../../../components/req-list-item/ReqListItem";
import Loader from "../../../../../components/loader/Loader";
import { statusSelectList } from "../static";
import { useAppContext } from "../../../../../store/Context";
import "./ReqList.styles.scss";

export default function ReqList({ list, isBlack, isLoading, client, finance }) {

  const {
    state: { isMobile }
  } = useAppContext();

  // state
  const [isSearch, setIsSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(0);
  const [statusSort, setStatusSort] = useState(0);
  const [timeSort, setTimeSort] = useState(0);

  //   method
  function changeIsSearch(is) {
    if (!is) {
      setSearch("");
    }
    setIsSearch(is);
  }

  function handleChangeSearch(e) {
    setSearch(e.target.value);
  }

  function changeStatus(s) {
    setStatus(s);
  }

  function changeStatusSort(ss) {
    setStatusSort(ss);
  }

  function changeTimeSort(ts) {
    setTimeSort(ts);
  }

  function getReqListEmptyTitle() {
    if (isBlack) return "Клиент заблокирован";
    if (finance) return "";
    if (client) return "У клиента пока нет заявок";
    return "Всё готово к работе!";
  }

  function getReqListEmptyText() {
    if (isBlack) return "И без единой заявки :(";
    if (finance) return "У вас ещё нет сделок за этот месяц ";
    if (client) return "Время предложить автомобиль клиенту";
    return "Создайте свою первую заявку";
  }

  const reqList = useMemo(() => {
    if (!list) return [];
    let tempList = [...list].map((i) => ({
      ...i,
      ...i.status_history[i.status_history.length - 1],
    }));
    if (timeSort !== 0) {
      tempList = tempList.sort((a, b) =>
        timeSort === -1
          ? a.date_created - b.date_created
          : b.date_created - a.date_created
      );
    }
    if (statusSort !== 0) {
      tempList = tempList.sort((a, b) =>
        statusSort === 1 ? a.status_id - b.status_id : b.status_id - a.status_id
      );
    }
    if (status !== 0) {
      tempList = tempList.filter((i) => i.status_id === status);
    }

    if (search) {
      const tempStr = normalizeStr(search);
      tempList = tempList.filter(
        (i) =>
          normalizeStr(i.request_id).includes(tempStr) ||
          normalizeStr(i.client_name).includes(tempStr) ||
          normalizeStr(i.phone).includes(tempStr)
      );
    }

    return tempList;
  }, [list, timeSort, statusSort, status, search]);

  function normalizeStr(str) {
    return String(str).trim().toLowerCase();
  }

  return (
    <>
      <Card>
        {isLoading && <Loader />}
        <div className="req__list__body">
          {!finance && <nav className="req__list__nav">
            <section className="req__list__title">
              <h1>{reqList.length}</h1>
              <p>
                {isSearch && search ? (
                  <>
                    Найдено <br /> заявок
                  </>
                ) : finance ? (
                  <>
                    Успешных <br />
                    заявок
                  </>
                ) : (
                  <>
                    Всего <br /> заявок
                  </>
                )}
              </p>
              {
                isMobile && (
                  <>
                    <SortBtn
                      label="Дата"
                      value={timeSort}
                      onChange={changeTimeSort}
                    />
                    <SortBtn
                      label="Статус"
                      value={statusSort}
                      onChange={changeStatusSort}
                    />
                  </>
                )
              }
            </section>
            <section className={`req__list__filter ${isSearch && 'req__list__filter--search'}`}>
              {isSearch ? (
                <div className="req__list__filter__search">
                  <Input
                    icon="search icon-sm"
                    withValueEffect={1}
                    value={search}
                    onChange={handleChangeSearch}
                    fill={1}
                    placeholder="Поиск по ФИО, Телефон или номер заявки"
                  />
                </div>
              ) : (
                <div className="req__list__filter__status">
                {
                  !isMobile && (
                    <>
                    <SortBtn
                      label="Дата"
                      value={timeSort}
                      onChange={changeTimeSort}
                    />
                    <SortBtn
                      label="Статус"
                      value={statusSort}
                      onChange={changeStatusSort}
                    /></>
                  )
                }
                  <Select
                    title="Любой статус"
                    options={[...statusSelectList]}
                    value={status}
                    onChange={changeStatus}
                  />
                </div>
              )}
              <Search isOpen={isSearch} changeSearchIsOpen={changeIsSearch} />
            </section>
          </nav>}
          {((isSearch && search) || status) && reqList.length === 0 ? (
            <ReqListEmpty
              notFound={1}
              title="Ничего не найдено"
              text="Возможно то, что вы ищите, уже в архиве"
            />
          ) : reqList.length === 0 ? (
            <ReqListEmpty
              title={getReqListEmptyTitle()}
              text={getReqListEmptyText()}
              client={client}
              isBlack={isBlack}
            />
          ) : (
            <ul className="req__list__ul">
              {reqList.map((i, idx) => (
                <li key={i?.request_id}>
                  
                  <ReqListItem idx={idx} request={i} finance={finance} archived={i.is_archived} />
                </li>
              ))}
            </ul>
          )}
        </div>
      </Card>
    </>
  );
}

