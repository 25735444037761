import api from "../../service/axios";

class BookingApi {
  static getBookingList() {
    return api.get("/getBookingList");
  }

  static getBookingData() {
    return api.get("/getData");
  }

  static searchParty(body) {
    return api.post("/searchCounterparty", body);
  }

  static getPartyInfo(id) {
    return api.get(`/getCounterParty/${id}`);
  }

  static getBookingById(year, id) {
    return api.get(`/getBooking/${id}/${year}`);
  }

  static updateBooking(body) {
    return api.put("/editBooking", body);
  }

  static updatePrice(body) {
    return api.post("/updatePrice", body);
  }
}

export default BookingApi;
