import api from "../../service/axios";

class FinanceApi {
  static getFinanceBrief() {
    return api.get(`/getFinances`);
  }

  static getFinance(agentId) {
    return api.get(`/getFinances/${agentId}`);
  }
}

export default FinanceApi;
