import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import ReactOutsideClickHandler from 'react-outside-click-handler';
import cl from "classnames";
import "./SelectWithReset.styles.scss";
import { useWindowDimensions } from '../../hooks/useWindowDimensions';

function SelectWithReset({
	title,
  options,
  value,
  onChange,
  disabled,
  defaultValue,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
	const inputRef = useRef(null);
  const heightPage = useWindowDimensions().height;

  const elemPositionY = inputRef.current?.getBoundingClientRect();
  const directionList = useMemo(() => {
    if (elemPositionY?.top > heightPage*0.8) {
      return "top";
    } else {
      return "bottom";
    }
  },[elemPositionY?.top, heightPage]);
  
  useEffect(() => {
    if (isOpen) {
      if (options?.length * 52 > 300) {
        setIsScroll(true);
      }
    } else {
      setIsScroll(false);
    }
  }, [elemPositionY?.top, heightPage, isOpen, options]);

  function changeIsOpen(o) {
    if (disabled) return;
    setIsOpen(o);
  }

  function handleChange(v) {
		if (v === "") {
			inputRef.current.value = "";
		}
    onChange(v);
    changeIsOpen(false);
  }

  let navTitle = useMemo(() => {
      if (defaultValue) {
        return defaultValue;
      }
      if (value) return options?.find((i) => i.value === value)?.title || title;
      return title;
  }, [value, options, title, defaultValue]);

    return (
			<ReactOutsideClickHandler onOutsideClick={() => changeIsOpen(false)}>
      <div 
				className={`select-wrap ${cl({ openSelect: isOpen, isScroll: isScroll, disabled: disabled })}`}
				ref={inputRef}
      >
        <nav className={defaultValue ? "select__nav defaultName" : "select__nav"} 
          onClick={() => changeIsOpen(!isOpen)}
        >
          <h1
            className={cl({active: value})
            }
            title={navTitle}
          >
            {navTitle}
          </h1>
					{
						((value || defaultValue !== "") && !disabled) ? (
							<i className="icon icon-md icon-close" onClick={() => handleChange("")}></i>
						)
						:
          	<i className="icon icon-sm icon-chevron-selector"></i>
					}
        </nav>
        {isOpen && (
          <div className={directionList === "bottom" ? "select__list__wrap" : "select__list__wrap top"}>
            <ul className="select__list">
              {options?.map((i) => (
                <li
                  key={i.id}
                  className={cl({active: value === i.value})}
                  onClick={() => handleChange(i.value)}
                >
                  <p title={i.title}>{i.title}</p>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </ReactOutsideClickHandler>
    );
}

export default SelectWithReset;
