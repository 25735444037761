import api from "../../service/axios";

class RequestApi {
  static getCarStatus(gosnomer) {
    return api.get(`/getCarStatus/${gosnomer}`);
  }

  static postRequest(body) {
    return api.post("/postRequest", body);
  }

  static putRequest(body) {
    return api.put("/putRequest", body);
  }

  static deleteRequest(id) {
    return api.delete('/deleteRequest/' + id)
  }

  
}

export default RequestApi;
