import React, { useState, useEffect } from "react";
//
import Modal from "../modal/Modal";
import CarEquipment from "../car-equipment/CarEquipment";
import UserInfoModal from "../user-info-modal/UserInfoModal";
import { useAppContext } from "../../store/Context";
import { MainMenuMobile } from "../main-menu-mobile/MainMenuMobile";

export default function ModalProvider() {

  const {
    state: { carEquipment, userInfoModal, isOpenMenu, isMobile },
    fn: { changeCarEquipment, changeUserInfoModal, changeIsOpenMenu },
  } = useAppContext();

  return (
    <>
      <Modal isOpen={carEquipment} onClose={() => changeCarEquipment(null)}>
        <CarEquipment
          isOpen={carEquipment}
          car={carEquipment?.car}
          modalTab={carEquipment?.tab}
          onClose={() => changeCarEquipment(null)}
        />
      </Modal>
      <Modal
        isOpen={userInfoModal}
        title={"Мои данные"}
        onClose={() => changeUserInfoModal(false)}
      >
        <UserInfoModal />
      </Modal>
      {isMobile && (
          <Modal
            isOpen={isOpenMenu}
            onClose={() => changeIsOpenMenu(false)}
            direction="Left"
            colorTitle="Primary"
          >
            <MainMenuMobile />
          </Modal>
      )}

    </>
  );
}
