import React from "react";

//
import Input from "../input/Input";
import { filterForOnlyNumbers, formatPrice } from "../../util/formatters";

export default function InputPrice({ value, onChange, ...props }) {
  function changeValue(e) {
    e.target.value = filterForOnlyNumbers(e.target.value);
    onChange(e);
  }

  return <Input value={formatPrice(value)} onChange={changeValue} {...props} />;
}
