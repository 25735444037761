export const vidachaList = [
  {
    id: 0,
    title: "Офисы",
    value: "Выдача в офисе",
    price: 0,
    icon: "home",
    index: null,
    type: "office",
  },
  {
    id: 1,
    title: "Доставка по городу",
    value: "Выдача по городу",
    price: 1000,
    icon: "marker",
    index: 5,
    type: "delivery",
  },
  {
    id: 2,
    title: "В Аэропорт",
    value: "Выдача в аэропоту",
    price: 1200,
    icon: "luggage",
    index: 4,
    type: "airport",
  },
  {
    id: 3,
    title: "К станции метро",
    value: "Выдача у метро",
    price: 800,
    icon: "tram",
    index: 0,
    type: "metro",
  },
  {
    id: 4,
    title: "Доставка в область",
    value: "Доставка в область",
    price: null,
    icon: "marker",
    index: 6,
    type: "delivery_outside",
  },
];

export const vozvratList = [
  {
    id: 0,
    title: "Офисы",
    value: "Возврат в офис",
    price: 0,
    icon: "home",
    index: null,
    type: "office",
  },
  {
    id: 1,
    title: "Возврат по городу",
    value: "Возврат по городу",
    price: 1000,
    icon: "marker",
    index: 3,
    type: "delivery",
  },
  {
    id: 2,
    title: "Из Аэропорт",
    value: "Возврат из аэропорта",
    price: 1200,
    icon: "luggage",
    index: 1,
    type: "airport",
  },
  {
    id: 3,
    title: "От станции метро",
    value: "Возврат из метро",
    price: 800,
    icon: "tram",
    index: 2,
    type: "metro",
  },
  {
    id: 4,
    title: "Возврат из области",
    value: "Возврат из области",
    price: null,
    icon: "marker",
    index: 7,
    type: "delivery_outside",
  },
];
