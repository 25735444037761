import React from "react";

//
import ReqCollapseLayout from "../../req-collapse-layout/ReqCollapseLayout";
import ReqInfoCard from "../../req-info-card/ReqInfoCard";
import ReqSuccessCard from "../../req-success-card/ReqSuccessCard";

export default function PaidOut({ request }) {

  return (
    <ReqCollapseLayout
      left={
        <ReqSuccessCard
          type="paidOut"
          title="Выплачено"
          text="Заработок по этой сделки уже выплачен"
        />
      }
      right={<ReqInfoCard request={request} />}
    />
  );
}
