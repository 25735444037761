import React from "react";
import styled from "styled-components";
import ROCH from "react-outside-click-handler";
import { Navigate, useNavigate } from "react-router-dom";
//
import IconWrapper from "../../../../components/icon-wrapper/IconWrapper";
import ClientContactCard from "../../../../components/client-contact-card/ClientContactCard";
import ReqProgress from "../../../../components/req-progress/ReqProgress";
import ReqInfoCard from "../../../../components/req-info-card/ReqInfoCard";
import Loader from "../../../../components/loader/Loader";
import { differentDates, msToNormTime } from "../../../../util/date";
import "./DraftReqModal.styles.scss";
import { useAppContext } from "../../../../store/Context";

export default function DraftReqModal({
  request,
  onClose,
  isLoading,
  handleDeleteRequest,
}) {
  const navigate = useNavigate();

  const {
    state: { isMobile },
  } = useAppContext();

  function goToUrl(url) {
    navigate(url, {state : {isEdit: 1}});
  }

  return (
    <div className="draft-req-modal">
      <ROCH onOutsideClick={onClose}>
        <div className="draft_req_modal__main">
          {isLoading && <Loader />}
            {
              isMobile ?
              <>
              <div className="draft_req_modal__top-mobile">
                  <div >
                    <h1>Заявка № {request.request_id}</h1>
                    <p>{msToNormTime(request.date_created, 7)}</p>
                  </div>
                  <IconWrapper
                    icon="close"
                    bg="transparent"
                    w={48}
                    h={48}
                    rounded={0}
                    onClick={onClose}
                  />
                </div>
              </>
              :
              <>
              <div className="draft_req_modal__top">
                <div className="draft_req_modal__top__left">
                  <i className="icon icon-file icon-lg"></i>
                  <div>
                    <h1>{request.client_name}</h1>
                    <p>
                      <i className="icon icon-announcement icon-xs"></i>{" "}
                      Администраторы СТО
                    </p>
                  </div>
                </div>
                <div className="draft_req_modal__top__right">
                  <div className="date_num_info">
                    <h1>{msToNormTime(request.date_created, 7)}</h1>
                    <p>{request.request_id}</p>
                  </div>
                  <div className="status_info">
                    <h1>{request.status}</h1>
                    <p>
                      {differentDates(request.status_set_date, Date.now() / 1000)}
                    </p>
                  </div>
                  <IconWrapper
                    icon="close"
                    w={48}
                    h={48}
                    rounded={0}
                    onClick={onClose}
                  />
                </div>
                </div>
              </>
            }
          <div className="draft_req_modal__body">
            <ClientContactCard
              request={request}
              id={request?.client_id}
              phone={request?.phone}
              email={request?.email}
            />
            {/*  */}
            <div className="save_info__card">
              <div className="save_info__card__left">
                <h1>Сохранено в черновике</h1>
                <p>
                  Последнее изменение{" "}
                  {differentDates(request.status_set_date, Date.now() / 1000)}{" "}
                  назад
                </p>
              </div>
              <div className="save_info__card__right">
                <IconWrapper
                  icon="trash"
                  w={48}
                  h={48}
                  border={1}
                  rounded={14}
                  onClick={(e) => handleDeleteRequest(e, request.request_id)}
                />
                  <IconWrapper
                    icon="pencil"
                    w={48}
                    h={48}
                    border={1}
                    rounded={14}
                    onClick={() => goToUrl("/req-create/" + request.request_id)}
                  />
              </div>
            </div>
            {!isMobile && 
              <ReqProgress
                list={request.status_history}
                archived={request.is_archived}
                isDrafted={1}
              />
            }
            <ReqInfoCard request={request} />
            {isMobile && 
              <ReqProgress
                list={request.status_history}
                archived={request.is_archived}
                isDrafted={1}
              />
            }
          </div>
        </div>
      </ROCH>
    </div>
  );
}
