import api from "../../service/axios";

class ClientApi {
  static getClientList() {
    return api.get("/getClients");
  }

  static postClient(body) {
    return api.post("/postClient", body);
  }

  static putClient(body) {
    return api.put("/putClient", body);
  }

  static deleteClient(id) {
    return api.delete("/deleteClient/" + id);
  }

  static getRequestByClientId(clientId) {
    return api.get(`/getRequests/` + clientId + '/all/all/all');
  }
}

export default ClientApi;
