import React, { useState } from "react";
import cl from "classnames";

//
import IconWrapper from "../../icon-wrapper/IconWrapper";
import Tooltip from "../../tooltip/Tooltip";
import { months } from "../../../util/lists";
import "./DatePicker.styles.scss";

export default function DatePicker({
  year,
  month,
  date,
  curDate,
  type,
  selectedFrom,
  selectedTo,
  onChange,
  onSelect,
  typePicker,
}) {
  const [hoverDate, setHoverDate] = useState(null);
  const [rentPeriod, setRentPeriod] = useState(0);

  function hoverInDate(date) {
    setHoverDate(date);

    const hoverTime = new Date(year, month, date).getTime();
    const fromTime = new Date(
      selectedFrom[2],
      selectedFrom[1],
      selectedFrom[0]
    ).getTime();
    const toTime = new Date(
      selectedTo[2],
      selectedTo[1],
      selectedTo[0]
    ).getTime();

    if (fromTime < hoverTime && hoverTime <= toTime) {
      setRentPeriod(() => Math.floor((hoverTime - fromTime) / 86400000));
    }
  }

  function hoverOutDate() {
    setHoverDate(null);
    setRentPeriod(0);
  }

  function checkForDisabled(date) {
    return (
      new Date(curDate[2], curDate[1], curDate[0]).getTime() >
      new Date(year, month, date).getTime()
    );
  }

  function checkForToday(date) {
    return (
      new Date(curDate[2], curDate[1], curDate[0]).getTime() ===
      new Date(year, month, date).getTime()
    );
  }

  function checkForActive(date) {
    if (hoverDate === date) return true;
    date = new Date(year, month, date).getTime();
    const fromTime = new Date(
      selectedFrom[2],
      selectedFrom[1],
      selectedFrom[0]
    ).getTime();
    const toTime = new Date(
      selectedTo[2],
      selectedTo[1],
      selectedTo[0]
    ).getTime();
    if (date === fromTime || date === toTime) return true;
    return false;
  }

  function checkForFrom(date) {
    return (
      new Date(year, month, date).getTime() ===
      new Date(selectedFrom[2], selectedFrom[1], selectedFrom[0]).getTime()
    );
  }

  function checkForTo(date) {
    return (
      new Date(year, month, date).getTime() ===
      new Date(selectedTo[2], selectedTo[1], selectedTo[0]).getTime()
    );
  }

  function checkForInterval(date) {
    date = new Date(year, month, date).getTime();
    const fromTime = new Date(
      selectedFrom[2],
      selectedFrom[1],
      selectedFrom[0]
    ).getTime();
    const toTime = new Date(
      selectedTo[2],
      selectedTo[1],
      selectedTo[0]
    ).getTime();

    return date > fromTime && date < toTime;
  }

  function checkForLeftSide(idx) {
    return [0, 7, 14, 21, 28, 35].includes(idx);
  }

  function checkForRightSide(idx) {
    return [6, 13, 20, 27, 34, 41].includes(idx);
  }

  function checkForFromInterval(date) {
    if (!hoverDate) return false;
    date = new Date(year, month, date).getTime();
    const fromTime = new Date(
      selectedFrom[2],
      selectedFrom[1],
      selectedFrom[0]
    ).getTime();
    const hoverTime = new Date(year, month, hoverDate).getTime();

    return hoverTime < date && date <= fromTime;
  }

  function checkForToInterval(date) {
    if (!hoverDate) return false;
    if (typePicker === "from") return;
    date = new Date(year, month, date).getTime();
    const toTime = new Date(
      selectedTo[2],
      selectedTo[1],
      selectedTo[0]
    ).getTime();
    const hoverTime = new Date(year, month, hoverDate).getTime();

    return toTime <= date && date < hoverTime;
  }

  function checkForHoverBefore(date) {
    if (!hoverDate) return false;
    if (typePicker === "from") return;
    date = new Date(year, month, date).getTime();
    const hoverTime = new Date(year, month, hoverDate).getTime();

    if (date !== hoverTime) return;

    const toTime = new Date(
      selectedTo[2],
      selectedTo[1],
      selectedTo[0]
    ).getTime();

    return hoverTime > toTime;
  }

  function checkForHoverAfter(date) {
    if (!hoverDate) return false;
    date = new Date(year, month, date).getTime();
    const hoverTime = new Date(year, month, hoverDate).getTime();

    if (date !== hoverTime) return;

    const fromTime = new Date(
      selectedFrom[2],
      selectedFrom[1],
      selectedFrom[0]
    ).getTime();

    return hoverTime < fromTime;
  }

  return (
    <div>
      <nav className="date__picker__nav">
        {type === "from" ? (
          <IconWrapper
            icon="chevron-right"
            w={48}
            h={48}
            rounded={14}
            border={1}
            rotate={1}
            color="dark-30"
            onClick={() => onChange("-")}
          />
        ) : (
          <div style={{ width: "48px", height: "48px" }} />
        )}
        <div>
          <h1>{months[month]}</h1>
          <p>{year}</p>
        </div>
        {type === "to" ? (
          <IconWrapper
            icon="chevron-right"
            w={48}
            h={48}
            rounded={14}
            border={1}
            color="dark-30"
            onClick={() => onChange("+")}
          />
        ) : (
          <div style={{ width: "48px", height: "48px" }} />
        )}
      </nav>
      <ul className="date__picker__weekdays">
        {["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"].map((i) => (
          <li key={i}>{i}</li>
        ))}
      </ul>
      <ul className="date__picker__dates">
        {date.map((i, idx) => (
          <li
            key={idx}
            className={cl({
              hidden: !i,
              disabled: checkForDisabled(i),
              today: checkForToday(i),
              active: checkForActive(i),
              from: checkForFrom(i),
              to: checkForTo(i),
              interval: checkForInterval(i),
              leftSide: checkForLeftSide(idx),
              rightSide: checkForRightSide(idx),
              first: i === 1,
              last: idx === date.length - 1,
              fromInterval: checkForFromInterval(i),
              toInterval: checkForToInterval(i),
              hoverAfter: checkForHoverAfter(i),
              hoverBefore: checkForHoverBefore(i),
            })}
          >
            <div
              className={cl("date__circle", {})}
              onMouseOver={() => hoverInDate(i)}
              onMouseOut={hoverOutDate}
              onClick={() => onSelect(i, month, year)}
            >
              {i}
              <Tooltip
                title={rentPeriod + " дней"}
                text=""
                isOpen={!!rentPeriod && hoverDate === i}
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

