import React from "react";
import styled, { css } from "styled-components";
import ReactOutsideClickHandler from "react-outside-click-handler";

//
import Button from "../button/Button";

export default function PopUp({ isOpen, onClose, children, full, onReady }) {

  if (!isOpen) return null;

  return (
    <StyledPopUp full={full}>
      <ReactOutsideClickHandler onOutsideClick={onClose}>
        <div className="popup__children">
          {children}
          {onReady && (
            <div className="ready__btn">
              <Button
                p={[16, 65]}
                textUpperCase={1}
                color="primary"
                fW={500}
                onClick={onReady}
              >
                Готово
              </Button>
            </div>
          )}
        </div>
      </ReactOutsideClickHandler>
    </StyledPopUp>
  );
}

const StyledPopUp = styled.div`
  position: fixed;
  top: -16px;
  left: -16px;
  width: 100vw;
  height: 100vh;
  background-color: var(--dark-60);
  z-index: 10;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  .popup__children {
    max-height: 100vh;
    overflow-y: auto;
    scrollbar-width: none;
    border-radius: 20px;

    &::-webkit-scrollbar {
      display: none;
    }

    .ready__btn {
      position: fixed;
      z-index: 3;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      width: max-content;
      background: var(--light);
      box-shadow: 0px 0px 20px var(--secondary-dark-10);
      border-radius: 20px;
      padding: 10px;
      
    }
  }

  ${({ full }) =>
    full &&
    css`
      -webkit-box-align: end;
      -ms-flex-align: end;
      align-items: flex-end;

      .popup__children {
        max-height: unset;
        height: calc(100vh);
        background-color: var(--bg);
      }
    `}
`;
