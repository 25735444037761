import { useQuery } from "react-query";

//
import DashboardApi from "./api";
import { GET_REQUEST_LIST } from "./type";

export function useGetRequests(options) {
  return useQuery(GET_REQUEST_LIST, () => DashboardApi.getRequests(), {
    ...options,
  });
}
