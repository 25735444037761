import React, { useState, useMemo, useEffect } from "react";
import { formatPhoneNumber } from "../../util/formatters";

//
import CreateClinetModal from "../create-client-modal/CreateClientModal";
import IconWrapper from "../icon-wrapper/IconWrapper";
import Input from "../input/Input";
import "./AppealInput.styles.scss";

export default function AppealInput({
  clientList,
  value,
  changeClient,
  title,
  type,
}) {
  const [isFocused, setIsFocuesed] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [appeal, setAppeal] = useState("");

  useEffect(() => {
    setAppeal(value);
  }, [value]);

  useEffect(() => {
    function handler(e) {
      const list = ["appealList", "appealInput"];
      if (
        !(
          list.includes(e.target?.id) ||
          list.includes(e.target?.offsetParent?.id)
        )
      ) {
        changeIsFocused(false);
      }
    }
    window.addEventListener("click", handler);

    return () => {
      window.removeEventListener("click", handler);
    };
  }, []);

  const dropDownList = useMemo(() => {
    if (!clientList) return [];
    let list = [...clientList.data.client_list.filter((i) => !i.black_listed)];
    // if (appeal) {
    //   list = list.filter(
    //     (i) => i.name.toLowerCase().trim().includes(appeal.toLowerCase().trim())
    //     // ||
    //     // i.phone.toLowerCase().trim().includes(appeal.toLowerCase().trim())
    //   );
    // }

    return list;
  }, [clientList]);

  function changeIsFocused(isf) {
    setIsFocuesed(isf);
  }

  function changeIsOpenModal(iom) {
    setIsOpenModal(iom);
  }

  function handleChangeClient(clientInfo) {
    const { client_id, name, phone, source } = clientInfo;
    changeClient({ client_id, client_name: name, phone, source });
    changeAppeal(name);
    changeIsFocused(false);
  }

  function changeAppeal(a) {
    setAppeal(a);
  }

  return (
    <div className="appeal-input">
      <div className="input__wrap">
        <p>{title}</p>
        <Input
          size={type === "lead" ? "md" : "sm"}
          placeholder="Имя"
          value={appeal}
          onChange={(e) => changeAppeal(e.target.value)}
          onFocus={() => changeIsFocused(true)}
          id="appealInput"
          fill={1}
          readOnly
        />
      </div>
      <IconWrapper
        icon="plus icon-sm"
        w={type === "lead" ? 45 : 40}
        h={type === "lead" ? 45 : 40}
        border={1}
        rounded={12}
        onClick={() => changeIsOpenModal(true)}
      />
      {isFocused && (
        <ul className="appeal__input__dropdown" id="appealList">
          {dropDownList.length === 0 && appeal ? (
            <h4>
              Данного клиента в системе нет, сначала добавьте клиента в систему
            </h4>
          ) : (
            <>
              {dropDownList.map((i) => (
                <li key={i.client_id} onClick={() => handleChangeClient(i)}>
                  <h1 dangerouslySetInnerHTML={{ __html: i.name }} />
                  <p>{formatPhoneNumber(i.phone)}</p>
                </li>
              ))}
            </>
          )}
        </ul>
      )}
      {/* <Select
        size="sm"
        title="Выберите пункт"
        value={appeal}
        options={dropDownList}
        onChange={handleChangeClient}
      /> */}
      {isOpenModal && (
        <CreateClinetModal
          isOpen={isOpenModal}
          title="Новый клиент"
          onClose={() => changeIsOpenModal(false)}
          appeal={1}
        />
      )}
    </div>
  );
}

