import React from "react";
import styled from "styled-components";

//
import Card from "../../../../components/card/Card";
import AgentReqCard from "../../../../components/agent-req-card/AgentReqCard";

export default function ArchiveTop({ count }) {

  return (
    <StyledArchiveTop>
      <Card>
        <main className="archive__top">
          <AgentReqCard title="Успешных" count={count.success} />
          <AgentReqCard title="Отказанных" count={count.rejected} />
          <AgentReqCard title="Отмененено" count={count.canceled} />
        </main>
      </Card>
    </StyledArchiveTop>
  );
}

const StyledArchiveTop = styled.div`
  .archive__top {
    display: -ms-grid;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    padding: 20px;
  }
`;
