import React from "react";
import NumberFormatter from "react-number-format";

export default function NumberFormat({
  value = 0,
  suffix = "₽",
  prefix = "",
  decimalScale = undefined,
}) {
  return (
    <NumberFormatter
      value={value}
      thousandSeparator={" "}
      decimalScale={decimalScale}
      displayType="text"
      suffix={" " + suffix}
      prefix={prefix}
    />
  );
}
