import React from "react";
import styled, { css } from "styled-components";

export default function Switch({ value, onChange, disabled = false }) {

  const handleSwitchOn = () => {
    if (disabled) return;
    onChange(!value)
  }

  return (
    <StyledSwitch value={value} onClick={handleSwitchOn}>
      <div className="circle-indicator"></div>
    </StyledSwitch>
  );
}

const StyledSwitch = styled.div`
  display: inline-block;
  width: 60px;
  height: 34px;
  background: var(--dark-10);
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  transition: 0.2s linear;

  .circle-indicator {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: var(--light);
    transition: 0.2s linear;
  }

  ${({ value }) =>
    value &&
    css`
      background-color: var(--success);

      .circle-indicator {
        left: 31px;
      }
    `}
`;
