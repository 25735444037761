import { useMemo } from "react";

import Card from "../../../../../components/card/Card";
import TabV2 from "../../../../../components/tab-v2/TabV2";
import Select from "../../../../../components/select/Select";
import Checkbox from "../../../../../components/checkbox/Checkbox";
import NumberFormat from "../../../../../components/number-format/NumberFormat";
import AgentChatTemplate from "../../../../../components/agent-chat-template/AgentChatTemplate";
import "./AdditionalOptions.styles.scss";
import Input from "../../../../../components/input/Input";

export default function AdditionalOptions({
  isBooking,
  isEdit,
  travel,
  appeal,
  options,
  changeOptions,
  addOptions,
  carFranchise,
  priceMatrixIndex,
  isShowScripts,
  staticDataLoading,
  agentChatTemplate,
}) {
  const simple = addOptions.simple;
  function getTabOptions() {
    return [
      { id: 0, title: "Нет", value: 0 },
      { id: 1, title: "1", value: 1 },
      { id: 2, title: "2", value: 2 },
      { id: 3, title: "3", value: 3 },
    ];
  }

  function getAddOptionByName(name) {
    return addOptions.simple.find((op) =>
      op.name.toLocaleLowerCase().includes(name.toLocaleLowerCase())
    );
  }

  function getAmountOption(optionId) {
    return options.find((i) => i.option_id === optionId)?.amount || 0;
  }

  const insurance = useMemo(() => {
    if (!carFranchise) {
      let insuranceOptionIds = addOptions.insurance.map((i) => i.option_id);
      options.map((o) =>
        insuranceOptionIds.includes(o.option_id) ? changeOptions(0, o) : null
      );
      return [];
    }
    let obj = {
      12000: 1,
      18000: 2,
      24000: 3,
    };
    return addOptions.insurance.filter(
      (i) => i.class === "Ценовая группа №" + obj[carFranchise]
    );
  }, [carFranchise, addOptions.insurance, options, changeOptions]);

  function checkForChecked(list, id) {
    return !!list.find((i) => i.option_id === id);
  }

  function getIncuranceValue() {
    return (
      options.find((i) =>
        insurance.map((j) => j.option_id).includes(i.option_id)
      )?.option_id || 0
    );
  }

  function getIncuranceOptions() {
    return [
      ...insurance.map((i) => ({
        id: i.option_id,
        title: i.name,
        value: i.option_id,
      })),
    ];
  }

  function changeIncurance(optionId) {
    let exsistOption = options.find((i) =>
      insurance
        .map((i) => i.option_id)
        .join("")
        .includes(i.option_id)
    );
    if (exsistOption) {
      changeOptions(0, exsistOption);
    }
    optionId = insurance.find((i) => i.option_id === optionId);
    changeOptions(1, optionId);
  }

  const carWashIndex = useMemo(() => {
    if (carFranchise) {
      let optionIndex = addOptions.simple.findIndex((op) =>
        op.name.includes(String(carFranchise))
      );
      return optionIndex;
    } else
      return addOptions.simple.findIndex((op) => op.name.startsWith("Мойка"));
  }, [carFranchise, addOptions.simple]);

  function checkCarWash() {
    changeOptions(getAmountOption(simple[carWashIndex]?.option_id) === 1 ? 0 : 1, simple[carWashIndex]);
  }

  const chatListGenerator = useMemo(() => {
    if (!agentChatTemplate) return [];
    const list = [...agentChatTemplate.script_text];
    list[0] = (appeal || "Client") + list[0];
    return list;
  }, [agentChatTemplate, appeal]);

  const transponder = getAddOptionByName("Транспондер");
  const mobileHolder = getAddOptionByName("Держатель");
  const cableUSB = getAddOptionByName("Провод");

  return (
    <>
      <Card radius={12}>
        <main className="additional_options__main">
          {isShowScripts && (
            <AgentChatTemplate
              chat={chatListGenerator}
              radius="12px 12px 0 0"
              isLoading={staticDataLoading}
            />
          )}
          <div className="additional_options__form">
            <div className="add_driver__incurance_wrap">
              <div className="add_driver">
                <p>Доп. водитель</p>
                <TabV2
                  options={getTabOptions()}
                  value={getAmountOption(simple[6]?.option_id)}
                  onChange={(v) => changeOptions(v, simple[6])}
                />
              </div>
              <div className="incurance_wrap">
                <p>Страхование</p>
                <div>
                  {insurance.length > 0 ? (
                    <Select
                      size="sm"
                      title="Выберите пункт"
                      value={getIncuranceValue()}
                      options={getIncuranceOptions()}
                      onChange={changeIncurance}
                      disabled={!isEdit}
                    />
                  ) : (
                    <>
                      <Select size="sm" title="Выберите авто" />
                    </>
                  )}
                </div>
              </div>
            </div>
            <ul className="additional_options__list">
              <li>
                <Checkbox
                  checked={getAmountOption(simple[carWashIndex]?.option_id)}
                  onClick={checkCarWash}
                  disabled={!carFranchise || !isEdit}
                />
                <div>
                  <h1 style={!carFranchise ? { opacity: "0.5" } : undefined}>
                    Автомойка
                  </h1>
                </div>
              </li>
              <li>
                <Checkbox
                  checked={getAmountOption(transponder?.option_id)}
                  onClick={() =>
                    changeOptions(
                      getAmountOption(transponder?.option_id) === 1 ? 0 : 1,
                      transponder
                    )
                  }
                  disabled={!isEdit}
                />
                <div>
                  <h1>Транспондер</h1>
                  <p>
                    <NumberFormat
                      value={
                        transponder?.price_matrix[priceMatrixIndex]
                          ?.price_day || 0
                      }
                    />
                    <small>
                      {transponder?.per_day ? "в сутки" : "за услугу"}
                    </small>
                  </p>
                </div>
              </li>
              <li>
                <Checkbox
                  checked={getAmountOption(mobileHolder?.option_id)}
                  onClick={() =>
                    changeOptions(
                      getAmountOption(mobileHolder?.option_id) === 1 ? 0 : 1,
                      mobileHolder
                    )
                  }
                  disabled={!isEdit}
                />
                <div>
                  <h1>Держатель для телефона</h1>
                  <p>
                    <NumberFormat
                      value={
                        mobileHolder?.price_matrix[priceMatrixIndex]
                          ?.price_day || 0
                      }
                    />
                    <small>
                      {mobileHolder?.per_day ? "в сутки" : "за услугу"}
                    </small>
                  </p>
                </div>
              </li>
              <li>
                <Checkbox
                  checked={getAmountOption(cableUSB?.option_id)}
                  onClick={() =>
                    changeOptions(
                      getAmountOption(cableUSB?.option_id) === 1 ? 0 : 1,
                      cableUSB
                    )
                  }
                  disabled={!isEdit}
                />
                <div>
                  <h1>USB провод</h1>
                  <p>
                    <NumberFormat
                      value={
                        cableUSB?.price_matrix[priceMatrixIndex]?.price_day || 0
                      }
                    />
                    <small>{cableUSB?.per_day ? "в сутки" : "за услугу"}</small>
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </main>
      </Card>
    </>
  );
}
