import React from "react";
import ReactOutsideClickHandler from "react-outside-click-handler";

//
import IconWrapper from "../icon-wrapper/IconWrapper";
import "./Modal.styles.scss"

const DirectionModal = {
  Right:"direction-right",
  Left: "direction-left",
  Bottom: "direction-bottom",
};

const ColorModal = {
  Primary: "color-primary",
}
const HeightModal = {
  Sm: "height-small",
  Big: "height-big",
  Fill: "height-fill",
}

export default function Modal({ isOpen, title, onClose, children, direction, colorTitle, height }) {

  const classNameDirection = DirectionModal[direction];
  const classNameColor = ColorModal[colorTitle];
  const classNameHeight = HeightModal[height];
  
  if (isOpen) {window.document.body.style.overflowY = "hidden"}

  const handleClose = () => {
    window.document.body.style.overflowY = "auto";
    onClose();
  }

  return (
    <div className={`modal-wrap ${isOpen ? "modal-open" : ""}`} data-draggable='false'>
      <ReactOutsideClickHandler onOutsideClick={handleClose}>
        <div className={classNameHeight}>
        <main className={`modal__main ${classNameDirection} ${classNameHeight}`}>
          <nav className={`modal__nav ${classNameDirection} ${classNameColor}`}>
            <h1>{title}</h1>
            <IconWrapper
              icon="close icon-lg"
              w={52}
              h={52}
              rounded={0}
              onClick={handleClose}
            />
          </nav>
          <div className="modal__child">{children}</div>
        </main>
        </div>
      </ReactOutsideClickHandler>
    </div>
  );
}
