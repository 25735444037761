import React from "react";

//
import ReqCollapseLayout from "../../req-collapse-layout/ReqCollapseLayout";
import ClientContactCard from "../../client-contact-card/ClientContactCard";
import ReqCancelCard from "../../req-cancel-card/ReqCancelCard";
import ReqInfoCard from "../../req-info-card/ReqInfoCard";
import ReqProgress from "../../req-progress/ReqProgress";
import { useAppContext } from "../../../store/Context";

export default function Denied({ request, client, archived }) {
  const {
    state: { isMobile },
  } = useAppContext();

  return (
    <ReqCollapseLayout
      left={
        <>
          <ClientContactCard
            request={request}
            id={request?.client_id}
            phone={request?.phone}
            email={client?.email}
          />
          {!isMobile && (
            <ReqProgress list={request.status_history} archived={1} />
          )}
        </>
      }
      right={
        <>
          <ReqCancelCard
            title={request.status_id === 8 ? "Было отказано" : "Было отменено"}
            text="Клиент находится в черном списке компании"
            type={request.status_id}
            btnText={archived ? "Разархивировать" : "Скрыть в архив"}
            request={request}
            archived={archived}
          />
          <ReqInfoCard request={request} />
          {isMobile && (
            <ReqProgress list={request.status_history} archived={1} />
          )}
        </>
      }
    />
  );
}
