import React, { useMemo } from "react";
import styled from "styled-components";

//
import ArchiveTop from "./sections/ArchiveTop";
import ReqList from "../dashboard/sections/req-list/ReqList";
import { useAppContext } from "../../../store/Context";

export default function Archive() {
  const {
    state: {
      requestResult: { archived },
      isMobile,
    },
  } = useAppContext();

  const count = useMemo(() => {
    const obj = { success: 0, rejected: 0, canceled: 0 };
    archived.forEach((i) => {
      if (i.status_id === 10) obj.success++;
      if (i.status_id === 8) obj.rejected++;
      if (i.status_id === 7) obj.canceled++;
    });
    return obj;
  }, [archived]);

  return (
    <StyledArchive>
      {isMobile && <h1 className="archive-title">Архив</h1>}
      <ArchiveTop count={count} />
      <ReqList list={archived} archived={1} />
    </StyledArchive>
  );
}

const StyledArchive = styled.div`
.archive-title {
  margin-left: 20px;
  margin-bottom: 20px;
  }`;
