import React from "react";
//
import "./Auditor.styles.scss";

export default function Auditor({ check }) {
  const {
    standart: isCheckStandart,
    deep: isCheckDeep,
    check_status: status,
    standart_date: standartDate,
    standart_auditor: standartAuditor,
    deep_date: deepDate,
    deep_auditor: deepAuditor,
    counterparty_debt: counterpartyDebt,
  } = check;
  
  return (
    <>
      <main className="auditor-wrapper">
        <div className="auditor__title">
          <h2>Проверка</h2>
          {counterpartyDebt && (
            <div className="debt">
              <p>- {counterpartyDebt} ₽</p>
            </div>
          )}
        </div>

        <div className="auditor-block">
          <div className="auditor">
            <h3>
              Стандартная
              {isCheckStandart && (
                <span className="auditor__icon-check">
                  <i className="icon icon-check icon-xs" />
                </span>
              )}
            </h3>
            <div className="auditor__control-type">
              <i className="icon icon-auditor icon-xs icon-dark" />
              <p>{standartAuditor ? standartAuditor : "-"}</p>
            </div>
            <div className="auditor__control-type">
              <i className="icon icon-calendar icon-xms icon-dark" />
              <p>{standartDate ? standartDate : "-"}</p>
            </div>
          </div>
          <div className="auditor">
            <h3>
              Углубленная
              {isCheckDeep && (
                <span className="auditor__icon-check">
                  <i className="icon icon-check icon-xs" />
                </span>
              )}
            </h3>
            <div className="auditor__control-type">
              <i className="icon icon-auditor icon-xs icon-dark" />
              <p>{deepAuditor ? deepAuditor : "-"}</p>
            </div>
            <div className="auditor__control-type">
              <i className="icon icon-calendar icon-xms icon-dark" />
              <p>{deepDate ? deepDate : "-"}</p>
            </div>
          </div>
        </div>
        <div className="status">
          <p>
            <span>Статус:</span> {status}
          </p>
        </div>
      </main>
    </>
  );
}
