import { useQuery } from "react-query";

//
import ReqCreateApi from "./api";
import { GET_POINTS, GET_STATIC_DATA, GET_REQUEST_BY_ID } from "./type";

export function useGetPoints(cityId, options) {
  return useQuery([GET_POINTS, cityId], () => ReqCreateApi.getPoints(cityId), {
    ...options,
  });
}

export function useGetStaticData(options) {
  return useQuery(GET_STATIC_DATA, () => ReqCreateApi.getStaticData(), {
    ...options,
  });
}

export function useGetRequestById(id, options) {
  return useQuery(
    [GET_REQUEST_BY_ID, id],
    () => ReqCreateApi.getRequestById(id),
    { ...options }
  );
}
