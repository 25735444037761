import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";

//
import { countMarkModel } from "../../util/formatters";

export default function CarFilterItem({
  size,
  title,
  mark,
  value,
  list,
  onReset,
}) {
  const [str, setStr] = useState([]);
  const [count, setCount] = useState(0);
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    let tempArr = [];

    if (mark) {
      let { markList, modelList } = countMarkModel(value);
      tempArr = [...markList, ...modelList];
    } else {
      if (list) {
        list.forEach((i) => {
          if (value.includes(i.value)) {
            tempArr.push(i.title);
          }
        });
      } else {
        tempArr = [...value];
      }
    }

    setStr(tempArr);
    setCount(tempArr.length - 3);
  }, [list, value, mark]);

  function onScrollToFilter() {
    let item = document.getElementById(title);
    if (!item) return;
    window.scrollTo(0, item.getBoundingClientRect().y - 88);
  }

  function changeIsHover(ih) {
    setIsHover(ih);
  }

  if (!str.filter((i) => i).length || !value?.length) return null;

  return (
    <StyledCarFilterItem size={size}>
      <i className="icon icon-x-close icon-sm" onClick={onReset} />
      <p
        onClick={onScrollToFilter}
        onMouseOver={() => changeIsHover(true)}
        onMouseOut={() => changeIsHover(false)}
      >
        {title}: {str.slice(0, isHover ? str.length : 3).join(", ")}
        {count > 0 && !isHover && <span>+{count}</span>}
      </p>
    </StyledCarFilterItem>
  );
}

const StyledCarFilterItem = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
  background-color: var(--primary-10);
  padding: 8px;
  border-radius: 24px;
  transition: 0.2s linear;

  i {
    background-color: var(--primary);
    min-width: 20px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    color: var(--primary);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 5px;
  }

  span {
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    color: var(--primary-60);
    display: inline-block;
    background-color: var(--light);
    padding: 2px 6px;
    border-radius: 9px;
  }

  &:hover {
    background-color: var(--primary-15);
  }

  ${({ size }) =>
    size === "sm"
      ? css`
          gap: 5px;
          padding: 6px;
          border-radius: 20px;

          i {
            min-width: 16px;
            width: 16px;
            height: 16px;
          }

          p {
            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
          }

          span {
            font-size: 10px;
            font-weight: 400;
            line-height: 13px;
            padding: 1px 4px;
            border-radius: 6px;
          }
        `
      : null}
`;
