import React, { useState, useEffect } from "react";

//
import Card from "../../../../components/card/Card";
import TextArea from "../../../../components/textarea/TextArea";
import "./AgentInfo.styles.scss";
import Select from "../../../../components/select/Select";
import Checkbox from "../../../../components/checkbox/Checkbox";
import SelectWithReset from "../../../../components/select-with-reset/SelectWithReset";

export default function AgentInfo({
  isEdit,
  source,
  sourceList,
  agentList,
  agent,
  refAgentInfo,
  rejectComment,
  bookingStatus,
  onChangeLocalBooking,
  cancelReason,
  cancelReasonId,
  cancelReasonList,
  detailedCancelReason,
}) {
  const [isBookingCancel, setIsBookingCancel] = useState(false);
  
  const [localDetailedList, setLocalDetaildeList ] = useState([]);

  useEffect(() => {
    const currentReason = cancelReasonList.find((item) => 
      item.cancel_reason_id === cancelReasonId);
    if (currentReason) {
      setLocalDetaildeList(currentReason.detailed_cancel_reason_list);
    }

  }, [cancelReasonId, cancelReasonList])

  useEffect(() => {
    switch(bookingStatus) {
      case "canceled":  
        setIsBookingCancel(true);
      
        break;
      case "progress":  
        setIsBookingCancel(false);
        break;
      case "success":
        setIsBookingCancel(false);
        break;
      default:
        setIsBookingCancel(false);
      break;
    }
  }, [bookingStatus]);

  const handleChangeCancelStatus = () => {
    setIsBookingCancel(!isBookingCancel);
    onChangeLocalBooking("booking_status", "canceled");
  };

  function getAgentList() {
    return [
      ...agentList.map((i) => ({
        id: i.agent_id,
        title: i.agent,
        value: i.agent_id,
      })),
    ];
  }

  function getSourceList() {
    return [...sourceList?.map((i, index) => ({ 
      id: index,
      title: i, 
      value: index }))];
  }

  function handleChangeSource(indexSource) {
    const source = sourceList?.find((item, index) => index === indexSource);
    onChangeLocalBooking("booking_source", source ? source : "")
  }

  function handleChangeAgentList(ag) {
    const nameAgent = agentList.find((item) => item.agent_id === ag);
    onChangeLocalBooking("agent", nameAgent ? nameAgent.agent : "");
    onChangeLocalBooking("agent_id", nameAgent ? nameAgent.agent_id : "");
  }

  function getCancelReasonList() {
    return [
      ...cancelReasonList.map((i) => ({
        id: i.cancel_reason_id,
        title: i.cancel_reason,
        value: i.cancel_reason_id,
      })),
    ];
  }

  function handleChangeCancelReason(cr) {
    const nameCancelReason = cancelReasonList.find((item) => item.cancel_reason_id === cr);
    onChangeLocalBooking("cancel_reason", nameCancelReason ? nameCancelReason.cancel_reason : "");
    onChangeLocalBooking("cancel_reason_id", nameCancelReason ? nameCancelReason.cancel_reason_id : "");
    onChangeLocalBooking("detailed_cancel_reason", "");
    onChangeLocalBooking("detailed_cancel_reason_id", "");
  }


  function getDetailedCancelReasonList() {
    const currentReason = cancelReasonList.find((item) => 
      item.cancel_reason_id === cancelReasonId);

    if (currentReason) {
      return [
        ...localDetailedList.map((i) => ({
          id: i.detailed_cancel_reason_id,
          title: i.detailed_cancel_reason,
          value: i.detailed_cancel_reason_id,
        })),
      ];
    }
  }

  function handleChangeDetailedCancelReason(dcr) {
    const detailedCancelReason = localDetailedList.find((item) => item.detailed_cancel_reason_id === dcr);
    onChangeLocalBooking("detailed_cancel_reason", 
      detailedCancelReason ? detailedCancelReason.detailed_cancel_reason : "");
    onChangeLocalBooking("detailed_cancel_reason_id", 
      detailedCancelReason ? detailedCancelReason.detailed_cancel_reason_id : "");
  }

  return (
    <Card radius={20}>
      <main className="agent-info" ref={refAgentInfo}>
        <div className="agent-info__form">
          <h3>Агент</h3>
          <SelectWithReset
            fill={1}
            placeholder="ФИО"
            size="sm"
            disabled={!isEdit}
            options={getAgentList()}
            defaultValue={agent}
            onChange={handleChangeAgentList}
          />
        </div>
        <div className="agent-info__form">
          <h3>Источник брони</h3>
          <SelectWithReset
            fill={1}
            placeholder="источник брони"
            size="sm"
            disabled={!isEdit}
            options={getSourceList()}
            defaultValue={source}
            onChange={handleChangeSource}
          />
        </div>
        <div className="agent-info__form">
          <div className="agent-info__form__status">
            <h2>Заявка отменена</h2>
              <Checkbox
                checked={isBookingCancel}
                onClick={handleChangeCancelStatus}
                disabled={!isEdit || bookingStatus === "success"}
                size={"sm"}
              />
          </div>
        </div>
        <div className="agent-info__form">
          <h3>Причина</h3>
          <SelectWithReset
            fill={1}
            placeholder="Причина"
            size="sm"
            disabled={!isEdit || bookingStatus === "success"}
            options={getCancelReasonList()}
            defaultValue={cancelReason}
            onChange={handleChangeCancelReason}
          />
        </div>
        <div className="agent-info__form">
          <h3>Подробнее о причине</h3>
          <SelectWithReset
            fill={1}
            placeholder="Подробнее о причине"
            size="sm"
            disabled={!isEdit || bookingStatus === "success"}
            options={getDetailedCancelReasonList()}
            defaultValue={detailedCancelReason}
            onChange={handleChangeDetailedCancelReason}
          />
        </div>
        <div className="agent-info__form">
          <h3>Причина отказа СБ</h3>
          <TextArea
            placeholder="Комментарий отказа"
            rows={2}
            defaultValue={rejectComment}
            disabled={!isEdit}
            id="reject_comment"
          />
        </div>
      </main>
    </Card>
  );
}
