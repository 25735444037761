import React, { useMemo, useState, useEffect } from "react";
import { useQueryClient } from "react-query";
import { Collapse } from "react-collapse";

//
import CarFilterNav from "../../components/car-filter-nav/CarFilterNav";
import Loader from "../../../../../components/loader/Loader";
import IconWrapper from "../../../../../components/icon-wrapper/IconWrapper";
import CatalogCarRow from "../../../../../components/catalog-car-row/CatalogCarRow";
import SelectedCarCard from "../../components/selected-car-card/SelectedCarCard";
import CarFilterItem from "../../../../../components/car-filter-item/CarFilterItem";
import Button from "../../../../../components/button/Button";
import FilterCarMark from "../../../../../components/filter-car-mark/FilterCarMark";
import { GET_CAR_LIST } from "../../../../../store/catalog/type";
import {
  genMarkList,
  genLocationList,
  countMarkModel,
  getCarsCount,
} from "../../../../../util/formatters";
import {
  staticCarMarkList,
  staticDriveList,
  staticEngineTypeList,
  staticCarBodyList,
} from "../../../catalog/sections/static";
import CatalogFilter from "../../../catalog/sections/catalog-filter/CatalogFilter";
import SortBtn from "../../../../../components/sort-btn/SortBtn";
import "./ReqCreateMiddle.styles.scss";
import Modal from "../../../../../components/modal/Modal";
import ReqCreateFilterModal from "./ReqCreateFilterModal/ReqCreateFilterModal";

export default function ReqCreateMiddle({
  cityList,
  options = [],
  addOptions = [],
  travelRegion,
  ageExp,
  changeAgeExp,
  viewCarList,
  car,
  changeCar,
  priceMatrixIndex,
  tariffIndex,
  priceId,
  coef,
  perDayOptionsPrice,
  perDailyPrice,
  isFilterOpen,
  changeIsFilterOpen,
  carListRes,
  carLoading,
  isBookingPage,
}) {
  const [category, setCategory] = useState([]);
  const [status, setStatus] = useState("");
  const [isAuto, setIsAuto] = useState(false);
  const [markModel, setMarkModel] = useState([...staticCarMarkList]);
  const [models, setModels] = useState([]);
  const [priceFrom, setPriceFrom] = useState("");
  const [priceTo, setPriceTo] = useState("");
  const [priceSort, setPriceSort] = useState(0);
  const [drive, setDrive] = useState([]);
  const [engine, setEngine] = useState([]);
  const [capacity, setCapacity] = useState([]);
  const [carBody, setCarBody] = useState([]);
  const [carCount, setCarCount] = useState(0);
  const [lastUpdateTime, setLastUpdateTime] = useState("00:00");
  const [locationList, setLocationList] = useState([]);
  const [location, setLocation] = useState([]);
  const [isWorkFilter, setIsWorkFilter] = useState(false);

  useEffect(() => {
    if (!travelRegion) return;
    changeLastUpdateTime();
  }, [travelRegion]);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (!carListRes) return;
    setMarkModel([...genMarkList(carListRes.data.data)]);
    setLocationList([...genLocationList(carListRes.data.data)]);
  }, [carListRes]);

  function resetCarList() {
    queryClient.resetQueries([GET_CAR_LIST, travelRegion, priceId], {
      exact: true,
    });
    changeLastUpdateTime();
  }

  function changeLastUpdateTime() {
    const time = new Date();
    let hour = time.getHours();
    hour = hour < 10 ? "0" + hour : hour;
    let min = time.getMinutes();
    min = min < 10 ? "0" + min : min;
    setLastUpdateTime(hour + ":" + min);
  }

  function changeCategory(c) {
    if (c === "") {
      setCategory([]);
      return;
    }
    if (category.includes(c)) {
      setCategory((p) => p.filter((i) => i !== c));
      return;
    }
    setCategory((p) => [c, ...p]);
  }

  function changeStatus(s) {
    setStatus(s);
  }

  function changeIsAuto(ia) {
    setIsAuto(ia);
  }

  function changeOneModel(m) {
    if (models.includes(m)) {
      setModels((p) => p.filter((i) => i !== m));
      return;
    }
    setModels((p) => [...p, m]);
  }

  function changeAllMarkModels(m) {
    const tempMarkModels = markModel
      .find((i) => i.mark === m)
      .models.map((i) => i.mark + "-" + i.model);

    const countCheckedMarkModesl = models.filter((i) => i.includes(m)).length;
    if (countCheckedMarkModesl === 0) {
      setModels((p) => [...p, ...tempMarkModels]);
      return;
    }
    if (countCheckedMarkModesl === tempMarkModels.length) {
      setModels((p) => p.filter((i) => !i.includes(m)));
      return;
    }
    setModels((p) => [...p.filter((i) => !i.includes(m)), ...tempMarkModels]);
  }

  function changeAllAnyModel() {
    const allModels = [];
    markModel.forEach((i) => {
      allModels.push(...i.models.map((j) => j.mark + "-" + j.model));
    });

    if (allModels.length === models.length) {
      setModels([]);
      return;
    }
    setModels([...allModels]);
  }

  function resetModels() {
    setModels([]);
  }

  function changePriceFrom(pf) {
    setPriceFrom(pf);
  }

  function changePriceTo(pt) {
    setPriceTo(pt);
  }

  function changePriceSort(ps) {
    setPriceSort(ps);
  }

  function changeDrive(d) {
    if (d === 0) {
      setDrive([]);
      return;
    }
    if (drive.includes(d)) {
      setDrive((p) => p.filter((i) => i !== d));
      return;
    }
    setDrive((p) => [d, ...p]);
  }

  function changeEngine(e) {
    if (e === 0) {
      setEngine([]);
      return;
    }
    if (engine.includes(e)) {
      setEngine((p) => p.filter((i) => i !== e));
      return;
    }
    setEngine((p) => [e, ...p]);
  }

  function changeCapacity(c) {
    if (c === 0) {
      setCapacity([]);
      return;
    }
    if (capacity.includes(c)) {
      setCapacity((p) => p.filter((i) => i !== c));
      return;
    }
    setCapacity((p) => [c, ...p]);
  }

  function changeCarBody(cb) {
    if (cb === 0) {
      setCarBody([]);
      return;
    }
    if (carBody.includes(cb)) {
      setCarBody((p) => p.filter((i) => i !== cb));
      return;
    }
    setCarBody((p) => [cb, ...p]);
  }

  function resetFilter() {
    setCategory([]);
    setStatus("");
    setIsAuto(false);
    resetMarks();
    setPriceFrom("");
    setPriceTo("");
    setPriceSort(0);
    setDrive([]);
    setEngine([]);
    setCapacity([]);
    setCarBody([]);
    setLocation([]);
  }

  function changeLocation(l) {
    if (l === "") {
      setLocation([]);
      return;
    }
    if (location.includes(l)) {
      setLocation((p) => p.filter((i) => i !== l));
      return;
    }
    setLocation((p) => [l, ...p]);
  }

  const carList = useMemo(() => {
    if (!carListRes) return [];
    let tempList = [...carListRes.data.data];
    let isWkF = false;

    if (category.length) {
      tempList = tempList.filter((i) => category.includes(i.class));
      isWkF = true;
    }

    if (status) {
      tempList = tempList
        .map((i) => ({
          ...i,
          car_list: i.car_list
            .map((j) => (j.status === status ? j : null))
            .filter((k) => k),
        }))
        .filter((i) => i.car_list.length);
    }

    if (isAuto) {
      tempList = tempList.filter((i) => {
        if (isAuto === "auto") return i.is_auto;
        return !i.is_auto;
      });
    }
    // if (models.length) {
    //   tempList = tempList.filter((i) =>
    //     models.includes(i.brand + "-" + i.model)
    //   );
    //   isWkF = true;
    // }

    if (location.length) {
      tempList = tempList
        .map((i) => ({
          ...i,
          car_list: i.car_list
            .map((j) => (location.includes(j.point_address) ? j : null))
            .filter((k) => k),
        }))
        .filter((i) => i.car_list.length);
      isWkF = true;
    }

    if (!ageExp) {
      tempList = tempList.filter(
        (i) => !(i.rental_terms.age >= 25 && i.rental_terms.exp >= 5)
      );
    }

    if (drive.length) {
      tempList = tempList
        .map((i) => ({
          ...i,
          car_list: i.car_list
            .map((j) => (drive.includes(j.parameters.drive_id) ? j : null))
            .filter((k) => k),
        }))
        .filter((i) => i.car_list.length);
      isWkF = true;
    }

    if (engine.length) {
      tempList = tempList
        .map((i) => ({
          ...i,
          car_list: i.car_list
            .map((j) => (engine.includes(j.parameters.engine_id) ? j : null))
            .filter((k) => k),
        }))
        .filter((i) => i.car_list.length);
      isWkF = true;
    }

    if (capacity.length) {
      tempList = tempList
        .map((i) => ({
          ...i,
          car_list: i.car_list
            .map((j) => (capacity.includes(j.parameters.capacity) ? j : null))
            .filter((k) => k),
        }))
        .filter((i) => i.car_list.length);
    }

    if (carBody.length) {
      tempList = tempList.filter((i) => carBody.includes(i.body_id));
      isWkF = true;
    }

    let { markList, modelList } = countMarkModel(markModel);

    if (modelList.length || markList.length) {
      tempList = tempList.filter(
        (i) =>
          modelList.includes(i.brand + " " + i.model) ||
          markList.includes(i.brand)
      );
      isWkF += 1;
    }

    if (priceSort) {
      tempList = tempList.sort((a, b) =>
        priceSort === -1
          ? a.price_matrix[tariffIndex].prices[priceMatrixIndex].price -
            b.price_matrix[tariffIndex].prices[priceMatrixIndex].price
          : b.price_matrix[tariffIndex].prices[priceMatrixIndex].price -
            a.price_matrix[tariffIndex].prices[priceMatrixIndex].price
      );
    }

    setCarCount(getCarsCount(tempList));
    setIsWorkFilter(isWkF);
    return tempList;
  }, [
    carListRes,
    category,
    status,
    isAuto,
    location,
    ageExp,
    drive,
    engine,
    capacity,
    carBody,
    markModel,
    priceSort,
    tariffIndex,
    priceMatrixIndex,
  ]);

  const seatOptions = useMemo(() => {
    if (!carListRes) return [];
    let res = carListRes.data.data.map(
      (car) => car.car_list[0]?.parameters?.capacity
    );
    return [...new Set(res)];
  }, [carListRes]);

  const categoryOptions = useMemo(() => {
    if (!carListRes) return [];
    let res = carListRes.data.data.map((carModel) => carModel.class);
    let uniqueCategories = Array.from(new Set(res));
    let checkList = uniqueCategories.map((cat, index) => ({
      id: index,
      value: cat,
      title: cat,
    }));
    checkList.unshift({ id: 1000, value: "", title: "Любой" });
    return checkList;
  }, [carListRes]);

  function changeMarkModel(id, mark) {
    setMarkModel((p) =>
      p.map((i) => {
        return {
          ...i,
          checked: i.mark === mark ? false : i.checked,
          models: i.models.map((j) =>
            j.id === id ? { ...j, checked: !j.checked } : j
          ),
        };
      })
    );
  }

  function toggleAllMark(id) {
    setMarkModel((p) =>
      p.map((i) =>
        i.id === id
          ? {
              ...i,
              checked: !i.checked,
              models: i.models.map((j) => ({ ...j, checked: false })),
            }
          : i
      )
    );
  }

  function resetMarks() {
    setMarkModel((p) =>
      p.map((i) => ({
        ...i,
        checked: false,
        models: i.models.map((j) => ({ ...j, checked: false })),
      }))
    );
  }

  function getOneTimeOptionsPrice(car) {
    let onTimePaymentTotal = 0;
    let carWashSelected = options.find((op) => op.name.includes("Мойка"));
    if (carWashSelected) {
      onTimePaymentTotal += addOptions.simple.find((op) =>
        op.name.includes(String(car.rental_terms.franchise))
      ).option_max_price;
    }

    return onTimePaymentTotal;
  }

  return (
  <>
    <div className={`req-create-middle ${!!car && "exist-car" }`}>
      <CarFilterNav
          cityList={cityList}
          category={category}
          changeCategory={changeCategory}
          status={status}
          changeStatus={changeStatus}
          isAuto={isAuto}
          changeIsAuto={changeIsAuto}
          ageExp={ageExp}
          changeAgeExp={changeAgeExp}
          markModel={markModel}
          priceFrom={priceFrom}
          changePriceFrom={changePriceFrom}
          priceTo={priceTo}
          changePriceTo={changePriceTo}
          priceSort={priceSort}
          changePriceSort={changePriceSort}
          drive={drive}
          changeDrive={changeDrive}
          engine={engine}
          changeEngine={changeEngine}
          capacity={capacity}
          changeCapacity={changeCapacity}
          carBody={carBody}
          changeCarBody={changeCarBody}
          resetFilter={resetFilter}
          locationList={locationList}
          location={location}
          changeLocation={changeLocation}
          resetModels={resetModels}
          models={models}
          changeOneModel={changeOneModel}
          changeAllMarkModels={changeAllMarkModels}
          changeAllAnyModel={changeAllAnyModel}
          isFilterOpen={isFilterOpen}
          changeIsFilterOpen={changeIsFilterOpen}
          isBookingPage={isBookingPage}
      />
        <Collapse isOpened={car}>
          <SelectedCarCard 
            car={car} 
            changeCar={changeCar} 
            isEditBooking={true}
            isBookingPage={false}
          />
        </Collapse>
        <div className="car__list--with-filter">
          <div className="car__list">
            {carLoading && <Loader />}
            {!travelRegion ? (
              <div className="agent__template">
                <IconWrapper
                  icon="alert-circle icon-lg"
                  w={52}
                  h={52}
                  bg="primary-dark-15"
                  color="light"
                />
                <p>Для отображения каталога выберите город</p>
              </div>
            ) : (
              <>
                <div className="car__list__top">
                  <div className="car__list__top__left">
                    <h1>{carCount}</h1>
                    <p>
                      Всего <br /> автомобилей
                    </p>
                  </div>
                  <div className="sort__act__wrap">
                    <div className="sort__wrap">
                      <SortBtn
                        label="Цена"
                        value={priceSort}
                        onChange={changePriceSort}
                        size="sm"
                      />
                    </div>
                    <div className="car__list__top__right">
                      <p>
                        Последнее обновление <br /> каталога сегодня в{" "}
                        {lastUpdateTime}
                      </p>
                      <IconWrapper
                        icon="refresh icon-sm"
                        border={1}
                        rounded={12}
                        onClick={resetCarList}
                      />
                    </div>
                  </div>
                </div>
                {isWorkFilter && (
                  <section className="working_filters_display">
                    <Button
                      color="primary"
                      radius={16}
                      p={[5, 12]}
                      size="sm"
                      onClick={resetFilter}
                    >
                      Сбросить всё
                    </Button>
                    <CarFilterItem
                      size="sm"
                      title="Категория"
                      value={category}
                      onReset={() => changeCategory("")}
                    />
                    <CarFilterItem
                      size="sm"
                      title="Локация"
                      value={location}
                      onReset={() => changeLocation("")}
                    />
                    <CarFilterItem
                      size="sm"
                      title="Привод"
                      list={staticDriveList}
                      value={drive}
                      onReset={() => changeDrive(0)}
                    />
                    <CarFilterItem
                      size="sm"
                      title="Двигатель"
                      list={staticEngineTypeList}
                      value={engine}
                      onReset={() => changeEngine(0)}
                    />
                    <CarFilterItem
                      size="sm"
                      title="Мест"
                      value={capacity}
                      onReset={() => changeCapacity(0)}
                    />
                    <CarFilterItem
                      size="sm"
                      title="Кузов"
                      list={staticCarBodyList}
                      value={carBody}
                      onReset={() => changeCarBody(0)}
                    />
                    <CarFilterItem
                      size="sm"
                      title="Марка"
                      value={markModel}
                      onReset={resetMarks}
                      mark={1}
                    />
                  </section>
                )}
                {carList.length > 0 ? (
                  <ul>
                    {carList.map((i) => (
                      <li key={i.price_unit_id}>
                         
                        <CatalogCarRow
                          car={i}
                          reqCreate={1}
                          tariffIndex={tariffIndex}
                          viewCarList={viewCarList}
                          selectedCar={car}
                          onChangeSelectedCar={changeCar}
                          priceMatrixIndex={priceMatrixIndex}
                          coef={coef}
                          oneTimeOptionsPrice={getOneTimeOptionsPrice(i)}
                          perDayOptionsPrice={perDayOptionsPrice}
                          perDailyPrice={perDailyPrice}
                        />
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="agent__template">
                    <IconWrapper
                      icon="alert-circle icon-lg"
                      w={52}
                      h={52}
                      bg="primary-dark-15"
                      color="light"
                    />
                    <p>Нечего не найдено</p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
    </div>       
      <ReqCreateFilterModal
        isOpen={isFilterOpen}
        onClose={() => changeIsFilterOpen(false)}
        title={"Фильтры"}
      >
        <>
        <div className="right__filter">
          <CatalogFilter
            drive={drive}
            onChangeDrive={changeDrive}
            engine={engine}
            onChangeEngine={changeEngine}
            capacity={capacity}
            onChangeCapacity={changeCapacity}
            seatOptions={seatOptions}
            location={location}
            onChangeLocation={changeLocation}
            locationOptions={[
              { id: 1, title: "Любой", value: "" },
                ...locationList,
              ]}
            category={category}
            onChangeCategory={changeCategory}
            categoryOptions={categoryOptions}
            carBody={carBody}
            onChangeCarBody={changeCarBody}
            hideResetBtn={1}
          />
        </div>
        <div className="left__filter">
          <FilterCarMark
            options={markModel}
            onChangeMarkModel={changeMarkModel}
            onToggleAllMark={toggleAllMark}
            onResetCarMarkList={resetMarks}
          />
        </div>
        </>      
      </ReqCreateFilterModal>
  </>
  );
}

