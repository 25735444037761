import React from "react";
import Card from "../card/Card";

//
import IconWrapper from "../icon-wrapper/IconWrapper";
import { useAppContext } from "../../store/Context";
import "./BlackClientModal.styles.scss";

export default function BlackClientModal() {
  const {
    state: {isMobile},
  } = useAppContext();


  return (
    <div className="black__client__modal">
      <Card>
        <main className="black__client__modal__main">
          <IconWrapper
            w={isMobile ? 40 : 80}
            h={isMobile ? 40 : 80}
            icon={`close ${isMobile ? "icon-md" : "icon-xl"}`}
            color="danger"
            bg="danger-10"
          />
          <div>
            <h1>Клиент находится в черном списке</h1>
            <p>
              <i className="icon icon-sm icon-message-dots-circle" />
              Тут отображается причина блокировки.
            </p>
          </div>
        </main>
      </Card>
    </div>
  );
}
