import React from "react";
import styled, { css } from "styled-components";
import { v4 } from "uuid";

//

export default function InputDate({
  placeholder,
  icon,
  value,
  error,
  ...props
}) {
  const uuid = v4();

  function changeDate() {
    document.getElementById(uuid).showPicker();
  }

  function formatVal(val) {
    if (!val) return val;

    val = val.split("-");
    return val[2] + "." + val[1] + "." + val[0];
  }

  return (
    <StyledInputdate displayValue={value} error={error}>
      <div className="input-display" onClick={changeDate}>
        <p>{formatVal(value) || placeholder}</p>
        <i className={"icon icon-" + icon}></i>
      </div>
      {error && <p className="error-text">{error}</p>}
      <input type="date" id={uuid} value={value} {...props} />
    </StyledInputdate>
  );
}

const StyledInputdate = styled.div`
  position: relative;

  .input-display {
    cursor: pointer;
    min-width: 300px;
    background: var(--light);
    border: 1px solid var(--primary-dark-15);
    border-radius: 14px;
    padding: 14px 20px;
    transition: 0.2s linear;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0em;
      color: var(--dark);
      font-weight: 400;
    }

    i {
      background-color: var(--dark-40);
    }
  }

  input[type="date"] {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    bottom: 20px;
    height: 50px;
  }

  ${({ displayValue }) =>
    displayValue === "" &&
    css`
      .input-display {
        p {
          opacity: 0.4;
        }
      }
    `}

  ${({ error }) =>
    error &&
    css`
      .input-display {
        border-color: var(--danger);
      }

      .error-text {
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        padding-left: 10px;
        margin-top: 5px;
        color: var(--danger-dark);
      }
    `}
`;
