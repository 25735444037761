import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

//
import Button from "../button/Button";
import RequestApi from "../../store/request/api";
import IconWrapper from "../icon-wrapper/IconWrapper";
import ConfirmModal from "../confirm-modal/ConfirmModal";
import { GET_REQUEST_LIST } from "../../store/dashboard/type";
import { GET_REQUEST_BY_CLIENT_ID } from "../../store/client/type";
import "./ReqCancelCard.styles.scss"
import useModalKeyDown from "../../hooks/useModalKeyDown";

export default function ReqCancelCard({
  title,
  text,
  type,
  btnText,
  request,
  isDrafted,
  archived
}) {
  const navigate = useNavigate();
  const { id: clientId } = useParams();

  // state
  const [modal, setModal] = useState(false);

  // query
  const queryClient = useQueryClient();
  const putRequest = useMutation((body) => RequestApi.putRequest(body), {
    onSuccess,
  });
  const deleteRequest = useMutation((id) => RequestApi.deleteRequest(id), {
    onSuccess,
  });

  function onSuccess() {
    queryClient.resetQueries(GET_REQUEST_LIST, { exact: true });
    queryClient.resetQueries([GET_REQUEST_BY_CLIENT_ID, clientId], {
      exact: true,
    });
    handleChangeModal(false);
  }

  // method
  function getBtnColor() {
    if (type === 2 || type === 1) return "danger";
    if (type === 8 || type === 7 || type === 10) return "light";
  }

  function handleChangeModal(m) {
    setModal(m);
  }

  function handleSubmit() {
    const obj = {
      doc_photos : [],
      price_daily : 0,
      ...request,
    };
    if (type === 2) {
      obj.status_id = 7;
    }
    if (type === 8 || type === 7 || type === 10) {
      obj.is_archived = !request.is_archived
    }
    putRequest.mutate(obj);
  }

  function goToUrl(url) {
    navigate(url,  {state : {isEdit: 1}});
  }

  function handleDeleteReq() {
    deleteRequest.mutate(request.request_id);
  }

  useModalKeyDown({
    isOpen: modal,
    handleClose: () => handleChangeModal(false),
    handleSubmit: isDrafted ? () => handleDeleteReq() : () => handleSubmit(),
  });

  return (
    <>
    <div className="req-cancel-card" data-type={type}>
      <div>
        <h1>{title}</h1>
        <p>{text}</p>
      </div>
      <div className="action__wrap">
        {(type === 2 || type === 1) && (
          <IconWrapper
            icon="pencil"
            w={48}
            h={48}
            border={1}
            rounded={14}
            onClick={() => goToUrl("/req-create/" + request.request_id)}
          />
        )}
        <Button
          radius={14}
          color={getBtnColor()}
          p={[14, 26]}
          fW={500}
          border={type === 8 || type === 7}
          onClick={() => handleChangeModal(true)}
        >
          {btnText}
        </Button>
      </div>
      {isDrafted ? (
        <ConfirmModal
          isOpen={modal}
          onClose={() => handleChangeModal(false)}
          isLoading={deleteRequest.isLoading}
          onSubmit={handleDeleteReq}
          title="Удалить"
          text="Вы действительно хотите удалить заявку?"
        />
      ) : (
        archived ? 
          <ConfirmModal
            isOpen={modal}
            onClose={() => handleChangeModal(false)}
            isLoading={putRequest.isLoading}
            onSubmit={handleSubmit}
            title="Разархивировать"
            text={"Уверены, что хотите разархивировать заявку?"} 
          />
          :
        ((request.status_id === 7 || request.status_id === 8 || request.status_id === 10) ? (
          <ConfirmModal
            isOpen={modal}
            onClose={() => handleChangeModal(false)}
            isLoading={putRequest.isLoading}
            onSubmit={handleSubmit}
            title="В архив"
            text={"Уверены, что хотите cкрыть в архив?"} 
          />
          ) : (
          <ConfirmModal
            isOpen={modal}
            onClose={() => handleChangeModal(false)}
            isLoading={putRequest.isLoading}
            onSubmit={handleSubmit}
            title="Oтменить"
            text={"Уверены, что хотите отменить заявку?"} 
          />
        ))
      )}
    </div>
      </>
  );
}
