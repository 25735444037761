import axios from "axios";

import CONST from "../../util/const";

const $api = axios.create({
  baseURL: CONST.BASE_URL,
});

$api.interceptors.request.use(
  (config) => {
    let tempConfig = { ...config };
    let token = localStorage.getItem(CONST.APP_TOKEN);
    tempConfig.headers.token = token || "";

    return tempConfig;
  },
  (err) => {
    return Promise.reject(err);
  }
);

$api.interceptors.response.use(
  (res) => {
    return Promise.resolve(res);
  },
  (err) => {
    if (
      err?.response?.status === 401 &&
      window.location.pathname !== "/login"
    ) {
      localStorage.clear();
      window.location.replace("/");
    }
    return Promise.reject(err);
  }
);

export default $api;
