import React from "react";

//
import ClientContactCard from "../../client-contact-card/ClientContactCard";
import ReqCancelCard from "../../req-cancel-card/ReqCancelCard";
import ReqCollapseLayout from "../../req-collapse-layout/ReqCollapseLayout";
import ReqInfoCard from "../../req-info-card/ReqInfoCard";
import ReqProgress from "../../req-progress/ReqProgress";
import { useAppContext } from "../../../store/Context";

export default function Finalize({ archived, request, client, isDrafted }) {
  const {
    state: { isMobile },
  } = useAppContext();

  return (
    <ReqCollapseLayout
      left={
        <>
          <ClientContactCard request={request} id={request?.client_id} phone={request?.phone} email={client?.email} />
          {!isMobile && <ReqProgress list={request.status_history} archived={archived} isDrafted={isDrafted} />}
        </>
      }
      right={
        <>
          <ReqCancelCard
            title="Требуется доработка"
            text={request.reason || "Причина"}
            type={request.status_id}
            btnText={isDrafted ? "Удалить" : "Отменить"}
            request={request}
            isDrafted={isDrafted}
          />
          <ReqInfoCard request={request} />
          {isMobile && <ReqProgress list={request.status_history} archived={archived} isDrafted={isDrafted} />}
        </>
      }
    />
  );
}
