import React from "react";
import "./ReqCollapseLayout.styles.scss";

export default function ReqCollapseLayout({ left, right }) {
  return (
    <div className="req-collapse-layout">
      <section className="req__collapse__left">{left}</section>
      <section className="req__collapse__right">{right}</section>
    </div>
  );
}

