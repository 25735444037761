import React, { useMemo } from "react";

//
import IconWrapper from "../../../../../components/icon-wrapper/IconWrapper";
import NumberFormat from "../../../../../components/number-format/NumberFormat";
import Input from "../../../../../components/input/Input";
import "./ChildSeat.styles.scss";

export default function ChildSeat({
  options,
  changeOptions,
  childSeat,
  priceMatrixIndex,
  note,
  changeNote,
  isTariff,
  isEditBooking,
}) {
  
  function onDec() {
    if(!isEditBooking) return;
    if (amount <= 0) return;
    changeOptions(amount - 1, childSeat[0]);
  }
  
  function onInc() {
    if(!isEditBooking) return;
    if (amount >= +childSeat[0]?.option_max_count) return;
    changeOptions(amount + 1, childSeat[0]);
  }
  
    const amount = useMemo(() => {
      return (
        options.find((i) => i.option_id === childSeat[0]?.option_id)?.amount || 0
      );
    }, [options, childSeat]);
  
  return (
    <div className={`child-seat-block ${isTariff && "is-tariff"}`}>
      <li>
        <section>
          <i className="icon icon-xl icon-baby-seat" />
          <div>
            <h1>Детское кресло</h1>
            <p>
              <NumberFormat
                value={
                  childSeat[0]?.price_matrix[priceMatrixIndex]?.price_day || 0
                }
              />{" "}
              в сутки
            </p>
          </div>
        </section>
        <section>
          <IconWrapper icon="minus" w={35} h={35} border={1} onClick={onDec} />
          <h2>{amount}</h2>
          <IconWrapper icon="plus" w={35} h={35} border={1} onClick={onInc} />
        </section>
      </li>
      {
      isTariff ?
        null
      :
      <li>
        <Input
          fill={1}
          placeholder="Комментария"
          value={note}
          onChange={(e) => changeNote(e.target.value)}
        />
      </li>
      }
    </div>
  );
}
