import React, { useState, useEffect } from "react";
//
import FinalPriceCalculator from "../../components/final-price-calculator/FinalPriceCalculator";
import ClientInfo from "../../components/client-info/ClientInfo";
import TotalPriceRent from "../../components/total-price-rent/TotalPriceRent";
import { useAppContext } from "../../../../../store/Context";
import Modal from "../../../../../components/modal/Modal";
import { useWindowDimensions } from "../../../../../hooks/useWindowDimensions";
import "./ReqCreateRight.styles.scss";

export default function ReqCreateRight({
  appeal,
  isShowScripts,
  car,
  priceMatrixIndex,
  tariffIndex,
  tariff,
  rentPeriod,
  options,
  addOptions,
  carFranchise,
  client,
  changeClient,
  isLoading,
  onSubmitSend,
  sendBtnIsDisabled,
  totalPrice,
  carPrice,
  dailyPrice,
  priceVidachaType,
  priceVozvratType,
  doc_photos,
  changeDocPhotos,
  coef,
  staticDataLoading,
  agentChatTemplates,
  discount,
  discountSum,
  changeDiscount,
  isBooking,
  changeBooking,
  checkboxBookingDisabled,
  vidacha,
  vozvrat,
  handleSaveToDraft,
}) {

  const {
    state: { isOpenCalculator },
    fn: { changeIsOpenCalculator },
  } = useAppContext();
  // helpers
  const { width } = useWindowDimensions();

  const [isMobile, setIsMobile] = useState(false);
  
  useEffect(() => {
    width < 1280 ? setIsMobile(true) : setIsMobile(false)
  }, [width]);


  function getChatTemplate(list, id) {
    return list.find((i) => i.script_id === id);
  }

  return (
    <div className="req-create-right">
      {
        !isMobile && (
          <FinalPriceCalculator
            isShowScripts={isShowScripts}
            car={car}
            priceMatrixIndex={priceMatrixIndex}
            tariffIndex={tariffIndex}
            tariff={tariff}
            rentPeriod={rentPeriod}
            options={options}
            addOptions={addOptions}
            carFranchise={carFranchise}
            totalPrice={totalPrice}
            carPrice={carPrice}
            priceVidachaType={priceVidachaType}
            priceVozvratType={priceVozvratType}
            coef={coef}
            staticDataLoading={staticDataLoading}
            agentChatTemplate={getChatTemplate(agentChatTemplates, 7)}
            vidacha={vidacha}
            vozvrat={vozvrat}
          />
        )
      }
      
      <ClientInfo
        appeal={appeal}
        isShowScripts={isShowScripts}
        client={client}
        changeClient={changeClient}
        doc_photos={doc_photos}
        changeDocPhotos={changeDocPhotos}
        staticDataLoading={staticDataLoading}
        agentChatTemplate={getChatTemplate(agentChatTemplates, 8)}
      />
      <TotalPriceRent
        isLoading={isLoading}
        onSubmitSend={onSubmitSend}
        sendBtnIsDisabled={sendBtnIsDisabled}
        totalPrice={totalPrice}
        dailyPrice={dailyPrice}
        discount={discount}
        discountSum={discountSum}
        changeDiscount={changeDiscount}
        isBooking={isBooking}
        changeBooking={changeBooking}
        checkboxBookingDisabled={checkboxBookingDisabled}
        handleSaveToDraft={handleSaveToDraft}
        draftBtIsDisabled={sendBtnIsDisabled}
      />
      {isMobile && (
        <Modal
          isOpen={isOpenCalculator}
          onClose={() => changeIsOpenCalculator(false)}
          title={"Калькуляция"}
          direction="Bottom"
          height="Big"
        >
          <FinalPriceCalculator 
            isShowScripts={isShowScripts}
            car={car}
            priceMatrixIndex={priceMatrixIndex}
            tariffIndex={tariffIndex}
            tariff={tariff}
            rentPeriod={rentPeriod}
            options={options}
            addOptions={addOptions}
            carFranchise={carFranchise}
            totalPrice={totalPrice}
            carPrice={carPrice}
            priceVidachaType={priceVidachaType}
            priceVozvratType={priceVozvratType}
            coef={coef}
            staticDataLoading={staticDataLoading}
            agentChatTemplate={getChatTemplate(agentChatTemplates, 7)}
            vidacha={vidacha}
            vozvrat={vozvrat}/>
        </Modal>
      )}
    </div>
  );
}

