import React, { useMemo } from "react";
import cl from "classnames";

//
import Card from "../../../../../components/card/Card";
import AgentChatTemplate from "../../../../../components/agent-chat-template/AgentChatTemplate";
import "./ClientRentTariff.styles.scss";

export default function ClientRentTariff({
  tariff,
  changeTariff,
  isShowScripts,
  staticDataLoading,
  agentChatTemplate,
}) {
  const chatListGenerator = useMemo(() => {
    if (!agentChatTemplate) return [];
    const list = [...agentChatTemplate.script_text];
    return list;
  }, [agentChatTemplate]);

  return (
    <>
      <Card radius={12}>
        <main className="rent-tariff__main">
          {isShowScripts && (
            <AgentChatTemplate
              chat={chatListGenerator}
              radius="12px 12px 0 0"
              isLoading={staticDataLoading}
            />
          )}
          <ul className="rent-tariff__list">
            {["200", "300", "500"].map((i) => (
              <li
              key={i}
              className={cl({
                active: tariff === i,
              })}
              onClick={() => changeTariff(i)}
              >
                <div>
                  <h1>Тариф</h1>
                  <p>{i} "км/сутки"</p>
                </div>
                <i className="icon icon-check" />
              </li>
            ))}
          </ul>
        </main>
      </Card>
    </>
  );
}
