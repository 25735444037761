import { v4 } from "uuid";

export const staticDriveList = [
  {
    id: v4(),
    title: "Любой",
    value: 0,
  },
  {
    id: v4(),
    title: "Передний",
    value: 1,
  },
  {
    id: v4(),
    title: "Задний",
    value: 2,
  },
  {
    id: v4(),
    title: "Полный",
    value: 3,
  },
];

export const staticEngineTypeList = [
  {
    id: v4(),
    title: "Любой",
    value: 0,
  },
  {
    id: v4(),
    title: "Бензин",
    value: 1,
  },
  {
    id: v4(),
    title: "Дизель",
    value: 2,
  },
  {
    id: v4(),
    title: "Гибрид",
    value: 3,
  },
  {
    id: v4(),
    title: "Электро",
    value: 4,
  },
];

export const staticSeatList = [
  {
    id: v4(),
    title: "Любой",
    value: 0,
  },
  {
    id: v4(),
    title: "1 место",
    value: 1,
  },
  {
    id: v4(),
    title: "2 места",
    value: 2,
  },
  {
    id: v4(),
    title: "5 места",
    value: 5,
  },
  {
    id: v4(),
    title: "7 мест",
    value: 7,
  },
  {
    id: v4(),
    title: "8 мест",
    value: 8,
  },
  {
    id: v4(),
    title: "9 мест",
    value: 9,
  },
  {
    id: v4(),
    title: "19 мест",
    value: 19,
  },
];

export const staticCarBodyList = [
  {
    id: v4(),
    title: "Седан",
    icon: "sedan",
    value: 1,
  },
  {
    id: v4(),
    title: "Универсал",
    icon: "station-wagon",
    value: 2,
  },
  {
    id: v4(),
    title: "Хэтчбек",
    icon: "hatchback-5",
    value: 3,
  },

  {
    id: v4(),
    title: "Фургон",
    icon: "van",
    value: 4,
  },
  {
    id: v4(),
    title: "Кроссовер",
    icon: "jeep-5",
    value: 5,
  },
  {
    id: v4(),
    title: "Купе",
    icon: "compartment",
    value: 6,
  },
  {
    id: v4(),
    title: "Автобус",
    icon: "minibus",
    value: 7,
  },
  {
    id: v4(),
    title: "Минивэн",
    icon: "minivan",
    value: 8,
  },
  {
    id: v4(),
    title: "Лифтбэк",
    icon: "liftback",
    value: 9,
  },
];

export const staticCarMarkList = [
  {
    id: v4(),
    mark: "Марка 1",
    checked: false,
    models: [
      {
        id: v4(),
        model: "Модель 11",
        checked: false,
        mark: "Марка 1",
      },
      {
        id: v4(),
        model: "Модель 12",
        checked: false,
        mark: "Марка 1",
      },
      {
        id: v4(),
        model: "Модель 13",
        checked: false,
        mark: "Марка 1",
      },
    ],
  },
  {
    id: v4(),
    mark: "Марка 2",
    checked: false,
    models: [
      {
        id: v4(),
        model: "Модель 21",
        checked: false,
        mark: "Марка 2",
      },
      {
        id: v4(),
        model: "Модель 22",
        checked: false,
        mark: "Марка 2",
      },
      {
        id: v4(),
        model: "Модель 23",
        checked: false,
        mark: "Марка 2",
      },
    ],
  },
  {
    id: v4(),
    mark: "Марка 3",
    checked: false,
    models: [
      {
        id: v4(),
        model: "Модель 31",
        checked: false,
        mark: "Марка 3",
      },
      {
        id: v4(),
        model: "Модель 32",
        checked: false,
        mark: "Марка 3",
      },
      {
        id: v4(),
        model: "Модель 33",
        checked: false,
        mark: "Марка 3",
      },
    ],
  },
];

export const staticCategoryList = [
  { id: 1, title: "Любой", value: "" },
  { id: 2, title: "Класс промо", value: "Промо" },
  { id: 3, title: "Класс стандарт", value: "Стандарт" },
  { id: 4, title: "Класс комфорт", value: "Комфорт" },
  { id: 5, title: "Класс кроссоверы", value: "Кроссовер" },
  { id: 6, title: "Класс минивены", value: "Минивен" },
  { id: 7, title: "Класс бизнес", value: "Бизнес" },
  { id: 8, title: "Класс коммерческий", value: "Коммерческий" },
  {
    id: 9,
    title: "Класс электромобиль",
    value: "Электромобиль",
  },
];

export const staticStatusList = [
  { id: 1, title: "Любой", value: "" },
  { id: 2, title: "Свободные", value: "свободна" },
  { id: 3, title: "Занятые", value: "занята" },
  { id: 4, title: "Забронированные", value: "бронь" },
];
