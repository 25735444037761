import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

//
import ReqCancelModal from "../req-cancel-modal/ReqCancelModal";
import ConfirmModal from "../confirm-modal/ConfirmModal";
import ClientApi from "../../store/client/api";
import { useAppContext } from "../../store/Context";
import { GET_CLIENT_LIST } from "../../store/client/type";
import useModalKeyDown from "../../hooks/useModalKeyDown";

export default function ClientBlackModal({ isOpen, onClose, client }) {
  const {
    state: { userId },
  } = useAppContext();

  const [cause, setCause] = useState("");

  const queryClient = useQueryClient();
  const putClientMutation = useMutation((body) => ClientApi.putClient(body), {
    onSuccess: () => {
      onClose();
      queryClient.resetQueries(GET_CLIENT_LIST, { exact: true });
    },
  });

  function handlePutClient() {
    let { name, phone, email, birthdate, source, client_id, note } = client;
    let obj = {
      name,
      phone,
      email,
      birthdate: new Date(birthdate).getTime() / 1000,
      source,
      note,
      client_id,
      black_listed: !client.black_listed,
      agent_id: userId,
    };
    putClientMutation.mutate(obj);
  }

  useModalKeyDown({
    isOpen: isOpen,
    handleClose: onClose,
    handleSubmit: () => handlePutClient(),
  })

  if (isOpen === "revert")
    return (
      <ConfirmModal
        title="Удалить из черного списка"
        text={`Вы действительно хотите удалить ${client.name} из черного списка клиентов?`}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handlePutClient}
        isLoading={putClientMutation.isLoading}
      />
    );

  return (
    <ReqCancelModal
      isOpen={isOpen}
      onClose={onClose}
      title="В черный список"
      text="Просим указать причины, по которым вы отправляете клиента в черный список"
      onSubmit={handlePutClient}
      btnTitle="Отправить"
      isLoading={putClientMutation.isLoading}
      value={cause}
      onChange={(v) => setCause(v)}
    />
  );
}
