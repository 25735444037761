import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useMutation } from "react-query";

//
import Button from "../../../../components/button/Button";
import Input from "../../../../components/input/Input";
import IconWrapper from "../../../../components/icon-wrapper/IconWrapper";
import AuthApi from "../../../../store/auth/api";
import Consts from "../../../../util/const";
import Loader from "../../../../components/loader/Loader";

export default function ResetPassword({
  onBack,
  onClearSearchParam,
  reset_code,
  phone,
}) {
  // state
  const [password, setPassword] = useState("");
  const [prePassword, setPrePassword] = useState("");
  const [passwordErr, setPasswordErr] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);

  // query
  const mutation = useMutation(
    () =>
      AuthApi.setNewPassword({
        login: phone,
        pass: reset_code,
        new_pass: password,
      }),
    { onSuccess }
  );

  // method
  function handleSubmit() {
    if (password.length < 5) {
      setPasswordErr(1);
      return;
    }
    if (checkPasswordSymbols(password)) {
      setPasswordErr(2);
      return;
    }
    if (password !== prePassword) {
      setPasswordErr(3);
      return;
    }
    mutation.mutate();
  }

  function onSuccess() {
    setIsSuccess(true);
  }

  function checkPasswordSymbols(p) {
    let inValidSymbols = ["/", `\\`, "`", "+", "-", "&", '"', "?"];
    let res = false;
    p.split("").forEach((i) => {
      if (inValidSymbols.includes(i)) {
        res = true;
      }
    });

    return res;
  }

  if (isSuccess)
    return (
      <SuccessChangePasswordModal
        onClearSearchParam={onClearSearchParam}
        password={password}
        phone={phone}
      />
    );

  return (
    <StyledResetPassword>
      <h1>Новый пароль</h1>
      <form className="inputs__group" noValidate autoComplete="off">
        <Input
          type="password"
          fill={1}
          placeholder="Пароль"
          value={password}
          onChange={(e) => {
            if (passwordErr !== 3) {
              setPasswordErr(0);
            }
            setPassword(e.target.value);
          }}
          error={
            passwordErr === 1
              ? "Пароль должен состоять минимум из 5 символов"
              : passwordErr === 2
              ? 'Недопустимые символы, пароль не может содержать символы: /+-&"?`\\'
              : ""
          }
          style={{
            borderColor: `var(--${
              passwordErr === 3 ? "danger" : "primary-dark-15"
            })`,
          }}
        />
        <Input
          type="password"
          fill={1}
          placeholder="Пароль ещё раз"
          value={prePassword}
          onChange={(e) => {
            if (passwordErr === 3) {
              setPasswordErr(0);
            }
            setPrePassword(e.target.value);
          }}
          error={passwordErr === 3 ? "Пароли не совпадают" : ""}
        />
      </form>
      <footer>
        <i className="icon icon-lg icon-arrow-left" onClick={onBack}></i>
        <Button
          type="submit"
          color="primary"
          p={[16, 30]}
          textUpperCase={1}
          fW={500}
          onClick={handleSubmit}
        >
          {mutation.isLoading && (
            <Loader bg="primary" color="light" w={24} h={24} size={3} />
          )}
          Готово
        </Button>
      </footer>
    </StyledResetPassword>
  );
}

function SuccessChangePasswordModal({ onClearSearchParam, password, phone }) {
  const mutation = useMutation(
    () => AuthApi.getToken({ login: phone, pass: password }),
    {
      onSuccess,
    }
  );

  // watcher
  useEffect(() => {
    mutation.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // method
  function onSuccess(res) {
    onClearSearchParam();
    let token = res.data.data.token;
    localStorage.setItem(Consts.APP_TOKEN, token);
    localStorage.setItem(Consts.USER_PHONE, phone);
  }

  return (
    <StyledSucChanPasModal>
      <IconWrapper
        w={80}
        h={80}
        bg="success-20"
        color="success-dark"
        icon="check icon-big"
      />
      <p>Пароль успешно изменен!</p>
    </StyledSucChanPasModal>
  );
}

const StyledSucChanPasModal = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;

  p {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    color: var(--dark);
  }
`;

const StyledResetPassword = styled.section`
  h1 {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: uppercase;
    color: var(--dark);
    margin-bottom: 70px;
  }

  .inputs__group {
    width: 320px;
    margin-bottom: 43px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 20px;
  }

  footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    i {
      background-color: var(--dark);
    }
  }
`;
