import React from "react";
import styled, { css } from "styled-components";

export default function Tooltip({
  title = "Title",
  text = "text",
  p = [10, 15],
  radius = 14,
  position = "top",
  rectPosition = "b-c",
  isOpen,
  size,
  ...props
}) {
  p = p.map((i) => i + "px").join(" ");

  return (
    <StyledTooltip
      p={p}
      radius={radius}
      position={position}
      rectPosition={rectPosition}
      isOpen={isOpen}
      size={size}
      {...props}
    >
      {title && <h1 className="tooltip__title">{title}</h1>}
      {text && (
        <p
          className="tooltip__text"
          dangerouslySetInnerHTML={{ __html: text }}
        />
        )}
    </StyledTooltip>
  );
}

const StyledTooltip = styled.div`
  background-color: var(--gray);
  width: max-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 5px;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  max-width: 300px;
  z-index: 3;

  .tooltip {
    &__title {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      color: var(--light);
    }

    &__text {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      color: var(--light-70);
    }
  }

  &::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: inherit;

    ${({ rectPosition }) =>
      rectPosition === "b-c"
        ? css`
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 50%) rotate(45deg);
          `
        : rectPosition === "b-l"
        ? css`
            bottom: 0;
            left: 20px;
            transform: translate(0, 50%) rotate(45deg);
          `
        : null}
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      transition: 0.2s linear;
      transition-delay: 0.8s;
      opacity: 1;
      visibility: visible;
    `}

  ${({ p, radius }) => css`
    padding: ${p};
    border-radius: ${radius + "px"};
  `}

  ${({ position }) =>
    position === "top"
      ? css`
          top: -3px;
          left: 50%;
          transform: translate(-50%, -100%);
        `
      : position === "top-left"
      ? css`
          top: -12px;
          left: 0;
          transform: translate(0, -100%);
        `
      : position === "top-right"
      ? css`
          top: -12px;
          right: 0;
          transform: translate(0, -100%);
        `
      : null}

  ${({ size }) =>
    size === "sm"
      ? css`

          .tooltip {
            &__title {
              font-size: 14px;
              font-weight: 500;
              line-height: 17px;
            }

            &__text {
              font-size: 12px;
              line-height: 15px;
            }
          }
        `
      : null}
`;
