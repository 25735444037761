import React, { useMemo, useState } from "react";

//
import ClientTop from "./sections/client-top/ClientTop";
import ClientList from "./sections/client-list/ClientList";
import useDebounce from "../../../hooks/useDebounce";
import { useGetClientList } from "../../../store/client/hook";

export default function Client() {
  const [tab, setTab] = useState("client");
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);

  // query
  const { data, isLoading } = useGetClientList();

  // method
  function handleChangeTab(t) {
    setTab(t);
  }

  const clientList = useMemo(() => {
    if (!data) return [];
    let list = data?.data?.client_list;

    if (tab === "blackList") {
      list = list.filter((i) => i.black_listed);
    } else {
      list = list.filter((i) => !i.black_listed);
    }

    if (debouncedSearch) {
      list = list.filter((i) =>
        i.name.toLowerCase().includes(debouncedSearch.toLowerCase())
      );
    }
    return list;
  }, [data, tab, debouncedSearch]);

  function changeSearch(s) {
    setSearch(s);
  }

  return (
    <>
      <ClientTop tab={tab} onChangeTab={handleChangeTab} data={data} />
      <ClientList
        clientList={clientList}
        isLoading={isLoading}
        search={search}
        onChangeSearch={changeSearch}
      />
    </>
  );
}

