import {  useQuery } from "react-query";

//
import CatalogApi from "./api";
import { GET_CAR_LIST } from "./type";

export function useGetCarList(cityId, priceId, options) {
  return useQuery(
    [GET_CAR_LIST, cityId, priceId],
    () => CatalogApi.getCarList(cityId, priceId),
    { ...options,   }
  );
}

