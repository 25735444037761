import React, { useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import ReactOutsideClickHandler from "react-outside-click-handler";
import cl from "classnames";

//
import Checkbox from "../checkbox/Checkbox";


export default function Select({
  title,
  options,
  value,
  multiple,
  onChange,
  disabled,
  zIndex,
  header,
  size,
  defaultValue,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isScroll, setIsScroll] = useState(false);

  useEffect(() => {
    if (isOpen) {
      if (options?.length * 52 > 300) {
        setIsScroll(true);
      }
    } else {
      setIsScroll(false);
    }
  }, [isOpen, options]);

  function changeIsOpen(o) {
    if (disabled) return;
    setIsOpen(o);
  }

  function handleChange(v) {
    onChange(v);
    if (multiple) return;
    changeIsOpen(false);
  }

  const navTitle = useMemo(() => {
    if (!multiple) {
      if (defaultValue) {
        return defaultValue;
      }
      if (value) return options?.find((i) => i.value === value)?.title || title;
      return title;
    } else {
      return title;
    }
  }, [value, multiple, title, options, defaultValue]);

  return (
    <ReactOutsideClickHandler onOutsideClick={() => changeIsOpen(false)}>
      <StyledSelect
        isOpen={isOpen}
        isScroll={isScroll}
        multiple={multiple}
        zIndex={zIndex}
        header={header}
        size={size}
        disabled={disabled}
        {...props}
      >
        <nav className={defaultValue ? "select__nav defaultName" : "select__nav"} 
          onClick={() => changeIsOpen(!isOpen)}
        >
          <h1
            className={cl({
              active: multiple ? value?.length : value,
            })
            }
            title={navTitle}
          >
            {navTitle}
          </h1>
          <i className="icon icon-sm icon-chevron-selector"></i>
        </nav>
        {isOpen && (
          <div className="select__list__wrap">
            <ul className="select__list">
              {options?.map((i) => (
                <li
                  key={i.id}
                  className={cl(
                    multiple
                      ? {
                          active:
                            i.value === "" || i.value === 0
                              ? !value?.length
                              : value.includes(i.value),
                        }
                      : {
                          active: value === i.value,
                        }
                  )}
                  onClick={() => handleChange(i.value)}
                >
                  {multiple && (
                    <Checkbox
                      size={size}
                      outlined={i.value === "" || i.value === 0}
                      checked={
                        multiple && i.value === ""
                          ? !value.length
                          : value.includes(i.value)
                      }
                    />
                  )}
                  <p title={i.title}>{i.title}</p>
                </li>
              ))}
            </ul>
          </div>
        )}
      </StyledSelect>
    </ReactOutsideClickHandler>
  );
}

const StyledSelect = styled.div`
  position: relative;
  width: 100%;

  .select {
    &__nav {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      background: var(--light);
      border: 1px solid var(--primary-dark-15);
      border-radius: 12px;
      padding: 12px 14px 12px 20px;
      cursor: pointer;
      gap: 10px;
      width:${props=>props.width};

      h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark);
        opacity: 0.3;

        &.active {
          opacity: 1;
        }
      }

      &.defaultName h1 {
        opacity: 1;
      }

      i {
        background-color: var(--dark-30);
        transition: none;
      }

      ${({ header }) =>
        header &&
        css`
          border-color: transparent;
          background-color: transparent;
          border: none;

          h1 {
            color: var(--light);

            i {
              background-color: var(--light-30);
            }
          }

          i {
            background-color: var(--light-20);
          }
        `}
    }

    &__list__wrap {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      transform: translateY(99%);
      z-index: 3;
      background-color: var(--light);
      overflow: hidden;
      padding-right: ${({ isScroll }) => (isScroll ? "3px" : "0px")};
    }

    &__list {
      overflow-y: auto;
      min-height: 50px;
      max-height: 300px;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: var(--dark-15);
        border-radius: 2px;
      }

      li {
        padding: 16px 30px;
        cursor: pointer;
        transition: 0.2s linear;

        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          color: var(--dark);
          transition: 0.2s linear;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &:hover {
          background-color: var(--dark-5);
        }

        &.active {
          background-color: var(--primary-10);

          p {
            color: var(--primary);
          }
        }

        ${({ multiple }) =>
          multiple &&
          css`
            display: flex;
            align-items: center;
            gap: 15px;
            padding: 11px;
          `}
      }
    }

    ${({ isOpen }) =>
      isOpen &&
      css`
        &__nav {
          box-shadow: 0px 0px 20px rgba(62, 81, 112, 0.1);
          border-radius: 14px 14px 0 0;
          border-bottom-color: var(--light);

          ${({ header }) =>
            header &&
            css`
              border-radius: 14px 14px 0 0;
              background-color: transparent;
              border-color: var(--primary-dark-15);

              h1 {
                color: var(--dark);

                i {
                  background-color: var(--primary);
                }
              }

              i {
                background-color: var(--dark-20);
              }
            `}
        }

        &__list__wrap {
          border-radius: 0 0 14px 14px;
          border: 1px solid var(--primary-dark-15);
          border-top-color: transparent;
          box-shadow: 0px 16px 15px rgba(62, 81, 112, 0.1);
        }
      `}

    ${({ size, isOpen }) =>
      size === "sm"
        ? css`
            &__nav {
              padding: 8px 10px 8px 20px;
              border-radius: 12px;

              h1 {
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
              }

              i {
                width: 20px;
                height: 20px;
                min-width: 20px;
                min-height: 20px;
              }
            }

            &__list__wrap {
              padding-right: ${({ isScroll }) => (isScroll ? "2px" : "0px")};
            }

            &__list {
              min-height: 41px;

              &::-webkit-scrollbar {
                width: 3px;
              }

              li {
                padding: 12px 24px;
                gap: 8px;

                p {
                  font-size: 14px;
                  line-height: 17px;
                }

                ${({ multiple }) =>
                  multiple &&
                  css`
                    padding: 10px;
                  `}
              }
            }

            ${isOpen &&
            css`
              &__nav {
                border-radius: 12px 12px 0 0;
              }
            `}
          `
        : null}
  }

  ${({ zIndex }) =>
    zIndex &&
    css`
      transform: translateY(0.0000001%);
      z-index: 5;
    `}

    ${({ disabled }) =>
      disabled &&
    css`
    .select__nav {
      cursor: default;
      background-color: var(--dark-5);
    }
    `}
`;
