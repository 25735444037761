import React, { useState, useMemo } from "react";
import cl from "classnames";

//
import Card from "../../../../../components/card/Card";
import AgentChatTemplate from "../../../../../components/agent-chat-template/AgentChatTemplate";
import RentPeriodModal from "../../../../../components/rent-period-modal/RentPeriodModal";
import { msToNormTime } from "../../../../../util/date";
import "./ClientRentPeriod.styles.scss"

export default function ClientRentPeriod({
  appeal,
  vidacha,
  changeVidacha,
  vozvrat,
  changeVozvrat,
  isShowScripts,
  rentPeriod,
  staticDataLoading,
  agentChatTemplate,
  isEditBooking,
}) {
  const [isOpenModal, setIsOpenModal] = useState(false);

  function changeIsOpenModal(iom) {
    if (!isEditBooking) return;
    setIsOpenModal(iom);
  }

  function getFormmatedTime(time) {
    if (!time.length) return "-";
    time =
      new Date(time[2], time[1], time[0], time[3], time[4]).getTime() / 1000;
    return msToNormTime(time, 7);
  }

  const period = useMemo(() => {

    const { days, hours, mins } = rentPeriod;

    
    let res = days + "д ";
    
    if (isNaN(rentPeriod.days)) return 0 + "д ";

    if (hours) {
      res += hours + "ч ";
    }
    if (mins) {
      res += mins + "мин";
    }
    return res;
  }, [rentPeriod]);

  const chatListGenerator = useMemo(() => {
    if (!agentChatTemplate) return [];
    const list = [...agentChatTemplate.script_text];
    list[0] = list[0].replace("(ИМЯ)", appeal || "Client");
    return list;
  }, [agentChatTemplate, appeal]);

  return (
    <>
      <Card radius={12}>
        <main className="rent-period-main">
          {isShowScripts && (
            <AgentChatTemplate
              chat={chatListGenerator}
              radius="12px 12px 0 0"
              isLoading={staticDataLoading}
            />
          )}
        </main>
        <div className="rent-period-form">
          <div className="rent-period-form__container">
            <section className="rent-period-form__date">
              <h1>Дата выдача</h1>
              <div
                className={`rent-period-form__input ${!isEditBooking ? "disabled-input" : ""}`}
                onClick={() => changeIsOpenModal(true)}
              >
                <p
                  className={cl({
                    active: 0,
                  })}
                >
                  {getFormmatedTime(vidacha.date)}
                </p>
                <i className="icon icon-sm icon-calendar"></i>
              </div>
            </section>
            <section className="rent-period-form__date">
              <h1>Дата возврат</h1>
              <div
                className={`rent-period-form__input ${!isEditBooking ? "disabled-input" : ""}`}
                onClick={() => changeIsOpenModal(true)}
              >
                <p>{getFormmatedTime(vozvrat.date)}</p>
                <i className="icon icon-sm icon-calendar"></i>
              </div>
            </section>
          </div>
          <p className="rent-period-form__period">{period}</p>
        </div>
      </Card>
      {isOpenModal && (
        <RentPeriodModal
          isOpen={isOpenModal}
          onChangeIsOpenRent={changeIsOpenModal}
          fromTime={vidacha.date}
          onChangeVidacha={changeVidacha}
          toTime={vozvrat.date}
          onChangeVozvrat={changeVozvrat}
          onChangeError={() => null}
        />
      )}
    </>
  );
}
