import React, { useMemo, useState, useEffect, useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useBeforeunload } from "react-beforeunload";

//
import ReqCreateLeft from "./sections/req-create-left/ReqCreateLeft";
import ReqCreateMiddle from "./sections/req-create-middle/ReqCreateMiddle";
import ReqCreateRight from "./sections/req-create-right/ReqCreateRight";
import RequestApi from "../../../store/request/api";
import StatusCheckFailModal from "./components/StatusCheckFailModal";
import ConfirmModal from "../../../components/confirm-modal/ConfirmModal";
import { useAppContext } from "../../../store/Context";
import { useGetClientList } from "../../../store/client/hook";
import { GET_REQUEST_LIST } from "../../../store/dashboard/type";
import { GET_CLIENT_LIST } from "../../../store/client/type";
import { arrDateToUnix, unixDateToArr } from "../../../util/formatters";
import { useGetCarList } from "../../../store/catalog/hook";
import {
  useGetPoints,
  useGetStaticData,
  useGetRequestById,
} from "../../../store/req-create/hook";
import "./ReqCreateV2.styles.scss";
import Modal from "../../../components/modal/Modal";
import MobileReqCreateMiddle from "./sections/mobile-catalog-car/MobileReqCreateMiddle";
import useModalKeyDown from "../../../hooks/useModalKeyDown";

const defaultClient = {
  client_id: null,
  client_name: "",
  phone: "",
  source: "",
};

const today = new Date();
const tempTime = new Date(
  today.getFullYear(),
  today.getMonth(),
  today.getDate(),
  today.getHours() + 1
);
const defaultVidacha = {
  point_id: null,
  point_name: "",
  point_type: "office",
  address: "",
  date: [
    tempTime.getDate(),
    tempTime.getMonth(),
    tempTime.getFullYear(),
    tempTime.getHours(),
    "00",
  ],
  comment: "",
};

const tomorrow = new Date(
  tempTime.getFullYear(),
  tempTime.getMonth(),
  tempTime.getDate() + 1,
  tempTime.getHours()
);
const defaultVozvrat = {
  point_id: null,
  point_name: "",
  point_type: "office",
  address: "",
  date: [
    tomorrow.getDate(),
    tomorrow.getMonth(),
    tomorrow.getFullYear(),
    tomorrow.getHours(),
    "00",
  ],
  comment: "",
};

export default function ReqCreateV2() {
  // helpers
  const navigate = useNavigate();
  const { reqId } = useParams();
  const location = useLocation();

  // context
  const {
    state: {
      addOptions,
      cityList,
      cityId,
      reqCar,
      optionMatrix,
      reqClient,
      priceLoading,
      isOpenCatalogCar,
      isMobile,
    },
    fn: { changeReqCar, changeReqClient, changeIsOpenCatalogCar },
  } = useAppContext();

  const requestRef = useRef(null);
  const requestCacheCount = useRef(0);
  const [isShowScripts, setIsShowScripts] = useState(false);
  const [viewCarList, setViewCarList] = useState("grid");
  const [ageExp, setAgeExp] = useState(true);
  const [travelRegion, setTravelRegion] = useState("000000001");
  const [tariff, setTariff] = useState("200");
  const [options, setOptions] = useState([]);
  const [car, setCar] = useState(null);
  const [note, setNote] = useState("");
  const [client, setClient] = useState({ ...defaultClient });
  const [vidacha, setVidacha] = useState({ ...defaultVidacha });
  const [vozvrat, setVozvrat] = useState({ ...defaultVozvrat });
  const [doc_photos, setDocPhotos] = useState(new Array(6).fill(""));
  const [carStatusFailModal, setCarStatusFailModal] = useState(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isOpenResetCacheModal, setIsOpenResetCacheModal] = useState(false);
  const [staticLoading, setStaticLoading] = useState(true);
  const [discount, setDiscount] = useState(0);
  const [isBooking, setIsBooking] = useState(false);

  function changeIsOpenResetCacheModal(iorcm) {
    setIsOpenResetCacheModal(iorcm);
  }

  useBeforeunload(() => {
    cacheRequestObj();
    return;
  });

  useEffect(() => {
    const tempName = "REQUEST_CREATE_OBJ";
    const tempRequest = JSON.parse(localStorage.getItem(tempName) || "null");

    if (tempRequest && !location.state?.isEdit) {
      changeIsOpenResetCacheModal(true);
    }

    return () => {
      cacheRequestObj();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function cacheRequestObj() {
    if (requestCacheCount.current === 0) return;
    const tempName = "REQUEST_CREATE_OBJ";

    localStorage.setItem(tempName, JSON.stringify(requestRef.current));
  }

  useEffect(() => {
    requestRef.current = {
      ageExp,
      travelRegion,
      tariff,
      options,
      car,
      note,
      client,
      vidacha,
      vozvrat,
      doc_photos,
    };
  }, [
    ageExp,
    travelRegion,
    tariff,
    options,
    car,
    note,
    client,
    vidacha,
    vozvrat,
    doc_photos,
  ]);

  useEffect(() => {
    if (!reqCar) return;

    setTravelRegion(cityId);
    setCar(reqCar);
    changeReqCar(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqCar, cityId]);

  useEffect(() => {
    if (!reqClient) return;
    const { client_id, name, phone, source } = reqClient;
    setClient((p) => ({ ...p, client_id, client_name: name, phone, source }));
    changeReqClient(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqClient]);

  // query
  const queryClient = useQueryClient();
  const tempName = "REQUEST_CREATE_OBJ";
  const isCached = !!localStorage.getItem(tempName);

  const { data: requestById, isLoading: getReqLoading } = useGetRequestById(
    reqId,
    {
      enabled: !!reqId,
      onSuccess: (data) => {
        const findedRequest = data?.data?.request_list[0];

        setClient({
          client_id: findedRequest.client_id,
          client_name: findedRequest.client_name,
          phone: findedRequest.phone,
          source: findedRequest?.source,
        });
        setTravelRegion(findedRequest.city_id);
        setNote(findedRequest.wish);
        setTariff(findedRequest.tariff);
        setOptions(findedRequest.options);
        setVidacha({
          ...findedRequest.vidacha,
          date: unixDateToArr(+findedRequest.vidacha.date),
        });
        setVozvrat({
          ...findedRequest.vozvrat,
          date: unixDateToArr(+findedRequest.vozvrat.date),
        });
        setTimeout(() => {
          setStaticLoading(false);
        }, 500);
        setDiscount(findedRequest.discount_percent);

        // setIsBooking(???)
      },
    }
  );

  const priceDelivery = useMemo(() => {
    const newOpt = options.map((opt) => {
      if (opt.name === "Доставка в область") {
        let sum = Number(vidacha.sum);
        const maxSum = opt.option_max_price;
        if (sum > maxSum) sum = maxSum;
        return { ...opt, sum: sum, price: sum, max_price: maxSum };
      } else return opt;
    });
    setOptions([...newOpt]);

    return vidacha.sum;
  }, [vidacha.sum]);

  const priceReturn = useMemo(() => {
    const newOpt = options.map((opt) => {
      if (opt.name === "Возврат из области") {
        let sum = Number(vozvrat.sum);
        const maxSum = opt.option_max_price;
        if (sum > maxSum) sum = maxSum;
        return { ...opt, sum: sum, price: sum, max_price: maxSum };
      } else return opt;
    });
    setOptions([...newOpt]);

    return vozvrat.sum;
  }, [vozvrat.sum]);

  const priceId = useMemo(() => {
    if (!travelRegion) return null;
    return cityList.find((i) => i.id === travelRegion)?.price_id;
  }, [travelRegion, cityList]);

  const { data: pointList, isLoading: pointLoading } = useGetPoints(
    travelRegion,
    {
      enabled: !!travelRegion,
    }
  );
  const { data: clientList, isLoading: clientListLoading } = useGetClientList();
  const { data: staticData, isLoading: staticDataLoading } = useGetStaticData();
  const { data: carList, isLoading: carLoading } = useGetCarList(
    travelRegion,
    priceId,
    {
      enabled: !!travelRegion && !!priceId,
    }
  );

  const postReqMutation = useMutation((body) => RequestApi.postRequest(body));
  const putReqMutation = useMutation((body) => RequestApi.putRequest(body));
  const checkCarStatus = useMutation((gosnomer) =>
    RequestApi.getCarStatus(gosnomer)
  );

  useEffect(() => {
    if (!clientList || isCached) return;
    const findedRequest = requestById?.data?.request_list[0];

    if (!findedRequest) return;
    changeClientInfos(findedRequest.client_id, clientList);
  }, [clientList, isCached]);

  useEffect(() => {
    if (!requestById || !cityList || isCached) return;
    const findedRequest = requestById?.data?.request_list[0];

    if (!findedRequest) return;
    changeTravelRegionInfo(findedRequest.price_list_id, cityList);
  }, [cityList, isCached]);

  useEffect(() => {
    if (!requestById || !carList || isCached) return;
    const findedRequest = requestById?.data?.request_list[0];
    if (!findedRequest) return;
    changeCarInfos(
      findedRequest.price_unit_id,
      findedRequest.gosnomer,
      carList
    );
  }, [carList, isCached]);

  const priceVidachaType = useMemo(() => {
    const hour = Number(vidacha.date[3]);
    if (hour < 9 || hour > 21) return "price_night";
    return "price_day";
  }, [vidacha.date]);

  const priceVozvratType = useMemo(() => {
    const hour = Number(vozvrat.date[3]);
    if (hour < 9 || hour > 21) return "price_night";
    return "price_day";
  }, [vozvrat.date]);

  const rentPeriod = useMemo(() => {
    let dif =
      new Date(
        vozvrat.date[2],
        vozvrat.date[1],
        vozvrat.date[0],
        vozvrat.date[3],
        vozvrat.date[4]
      ) -
      new Date(
        vidacha.date[2],
        vidacha.date[1],
        vidacha.date[0],
        vidacha.date[3],
        vidacha.date[4]
      );
    const days = Math.floor(dif / 86400000);
    dif -= days * 86400000;
    const hours = Math.floor(dif / 3600000);
    dif -= hours * 3600000;
    const mins = Math.floor(dif / 60000);
    return {
      days,
      hours,
      mins,
    };
  }, [vidacha.date, vozvrat.date]);

  const coef = useMemo(() => {
    const { days, hours, mins } = rentPeriod;
    const minutes = hours * 60 + mins;
    if (days === 0 && minutes >= 0) return 1;
    if (minutes <= 75) return Number(days);
    if (minutes > 75 && minutes <= 135) return Number(days) + 0.2;
    if (minutes > 135 && minutes <= 195) return Number(days) + 0.3;
    if (minutes > 195 && minutes <= 255) return Number(days) + 0.4;
    if (minutes > 255 && minutes <= 315) return Number(days) + 0.5;
    if (minutes > 315 && minutes <= 370) return Number(days) + 0.6;
    if (minutes > 370) return Number(days) + 1;
  }, [rentPeriod]);

  const priceMatrixIndex = useMemo(() => {
    if (1 <= coef && coef <= 3) return 0;
    if (4 <= coef && coef <= 6) return 1;
    if (7 <= coef && coef < 15) return 2;
    if (coef >= 15) return 3;
  }, [coef]);

  useEffect(() => {
    if (!options.length || !optionMatrix.length) return;

    const result = options.map((i) => {
      const findedOption = optionMatrix.find(
        (j) => j.option_id === i.option_id
      );

      const priceType = getPriceKey(i.type);
      i.price =
        findedOption?.price_matrix[priceMatrixIndex || 0][priceType] || i.price;
      i.maxSum = findedOption?.option_max_price * i.amount;
      i.sum = findedOption?.per_day
        ? i.price * i.amount * coef
        : i.price * i.amount;
      if (i.sum > i.maxSum) i.sum = i.maxSum;

      return i;
    });
    setOptions([...result]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    priceVidachaType,
    priceVozvratType,
    priceMatrixIndex,
    optionMatrix,
    coef,
  ]);

  const editContentLoading = useMemo(() => {
    if (!reqId) return false;
    if (!getReqLoading && !clientListLoading && !pointLoading && !staticLoading)
      return false;
    return true;
  }, [getReqLoading, clientListLoading, pointLoading, reqId, staticLoading]);

  const points = useMemo(() => {
    if (!pointList) return null;
    const list = [...pointList.data.data];
    const obj = {
      Delivery: [],
      office: [],
      airport: [],
      metro: [],
    };
    list.forEach((i) => {
      obj[i.type] = obj[i.type] ? [...obj[i.type], i] : [i];
    });
    return obj;
  }, [pointList]);

  const agentChatTemplates = useMemo(() => {
    if (!staticData) return [];
    return staticData.data.scripts;
  }, [staticData]);

  const carFranchise = useMemo(() => {
    return car ? car?.rental_terms.franchise || 12000 : null;
  }, [car]);

  const tariffIndex = useMemo(() => {
    if (tariff === "200") return 0;
    if (tariff === "300") return 1;
    if (tariff === "500") return 2;
    return 0;
  }, [tariff]);

  const carPrice = useMemo(() => {
    if (!car) return 0;
    return (
      (car?.price_matrix[tariffIndex]?.prices[priceMatrixIndex]?.price || 0) *
      coef
    );
  }, [car, coef, tariffIndex, priceMatrixIndex]);

  const perDayOptionsPrice = useMemo(() => {
    if (!options.length) return 0;
    return options
      .filter((i) => i.per_day)
      .reduce((total, i) => {
        let sum = i.sum;
        let maxSum = i.option_max_price;
        if (sum > maxSum) {
          sum = maxSum;
        }
        total += sum;
        return total;
      }, 0);
  }, [options]);

  const onceOptionsPrice = useMemo(() => {
    if (!options.length) return 0;

    return options
      .filter((i) => !i.per_day)
      .reduce((total, i) => {
        let sum = i.sum;

        total += sum;
        return total;
      }, 0);
  }, [options]);

  const totalPrice = useMemo(() => {
    let sum = carPrice + perDayOptionsPrice + onceOptionsPrice;
    sum = Math.floor(sum * 100) / 100;
    // sum -= sum * (discount / 100);
    return sum;
  }, [carPrice, perDayOptionsPrice, onceOptionsPrice]);

  const discountSum = useMemo(() => {
    let sum = totalPrice * (discount / 100);
    sum = Math.floor(sum * 100) / 100;
    return sum;
  }, [totalPrice, discount]);

  const dailyPrice = useMemo(() => {
    return totalPrice / coef;
  }, [totalPrice, coef]);

  const perDailyPrice = useMemo(() => {
    return perDayOptionsPrice / coef;
  }, [perDayOptionsPrice, coef]);

  const requestBody = useMemo(() => {
    return {
      ...client,
      price_list_id: priceId,
      city_id: travelRegion,
      is_archived: false,
      status_id: 1,
      email: "",
      birthdate: Math.floor(new Date().getTime() / 1000),
      note: "",
      doc_photos: doc_photos.filter((i) => i),
      price_unit_id: car?.price_unit_id || null,
      gosnomer: car?.status === "свободна" ? car?.gosnomer : null,
      wish: note,
      tariff,
      rent_price_day: carPrice / coef,
      rent_price_total: carPrice,
      discount_percent: discount,
      discount_sum: discountSum,
      vidacha: { ...vidacha, date: arrDateToUnix(vidacha.date) },
      vozvrat: { ...vozvrat, date: arrDateToUnix(vozvrat.date) },
      options: [...options],
      create_booking: isBooking,
    };
  }, [
    client,
    priceId,
    travelRegion,
    doc_photos,
    car?.price_unit_id,
    car?.status,
    car?.gosnomer,
    note,
    tariff,
    carPrice,
    coef,
    discount,
    discountSum,
    vidacha,
    vozvrat,
    options,
    isBooking,
  ]);

  const sendBtnIsDisabled = useMemo(() => {
    return client.client_name.length < 3 || client.phone.length < 11;
  }, [client]);

  function changeClientInfos(id, list) {
    if (!id || !list) return;
    const tempClientInfo = list.data.client_list.find(
      (i) => i.client_id === id
    );
    if (!tempClientInfo) return;
    setClient({
      client_id: tempClientInfo.client_id,
      client_name: tempClientInfo.name,
      phone: tempClientInfo.phone,
      source: tempClientInfo.source,
    });
  }

  function changeTravelRegionInfo(pId, list) {
    if (!pId || !list.length) return;
    list.forEach((i) => {
      if (i.price_id === pId) {
        setTravelRegion(i.id);
      }
    });
  }

  function changeCarInfos(id, gosnomer, list) {
    if (!id || !list) return;
    list = list.data.data;
    let findedCar = list.find((i) => i.price_unit_id === id);
    if (!findedCar) return;
    if (gosnomer) {
      const carInfos = findedCar.car_list.find((i) => i.gosnomer === gosnomer);
      if (carInfos) {
        findedCar = { ...findedCar, ...carInfos };
      }
    }
    setCar(findedCar);
  }

  function changeIsShowScripts(iss) {
    setIsShowScripts(iss);
  }

  function changeViewCarList(vcl) {
    setViewCarList(vcl);
  }

  function changeAgeExp(ae) {
    setAgeExp(ae);
    requestCacheCount.current = 1;
  }

  function changeTravelRegion(tr) {
    setTravelRegion(tr);
    setCar(null);
    requestCacheCount.current = 1;
  }

  function changeVozvrat(obj) {
    setVozvrat((p) => {
      return { ...p, ...obj };
    });
    requestCacheCount.current = 1;
  }

  function changeVidacha(obj) {
    setVidacha((p) => {
      return { ...p, ...obj };
    });

    requestCacheCount.current = 1;
  }

  function changeTariff(t) {
    setTariff(t);
    requestCacheCount.current = 1;
  }

  function changeOptions(amount, option, type) {
    if (!option || (options.length === 0 && amount < 1)) return;
    requestCacheCount.current = 1;
    let isExsist = options.find((i) => i.option_id === option.option_id);

    if (!isExsist) {
      const priceKey = getPriceKey(type);
      setOptions((p) => {
        let price = option.price_matrix[priceMatrixIndex][priceKey];
        let maxSum = option.option_max_price * amount;
        let sum = option.per_day ? price * amount * coef : price * amount;

        if (sum > maxSum) sum = maxSum;

        const result = [
          ...p,
          {
            option_id: option.option_id,
            name: option.name,
            amount,
            price: price,
            sum: sum,
            per_day: option.per_day,
            type,
            is_custom: option.is_custom,
            max_price: maxSum,
          },
        ];
        return result;
      });
      return;
    }

    if (amount >= 1) {
      setOptions((p) => {
        const result = p.map((i) => {
          const maxSum = option.option_max_price * amount;
          let sum = i.price * amount * coef;
          if (sum > maxSum) sum = maxSum;
          return i.option_id === option.option_id
            ? { ...i, amount, sum: sum, max_price: maxSum }
            : i;
        });
        return result;
      });
      return;
    }

    setOptions((p) => {
      const result = p.filter((i) => i.option_id !== option.option_id);
      return result;
    });
  }

  function getPriceKey(t) {
    if (t === "vidacha") return priceVidachaType;
    if (t === "vozvrat") return priceVozvratType;
    return "price_day";
  }

  function changeCar(c) {
    requestCacheCount.current = 1;
    if (
      car &&
      c &&
      car.price_unit_id === c.price_unit_id &&
      car.gosnomer === c.gosnomer
    ) {
      setCar(null);
      return;
    }

    setCar(c);
  }

  function changeClient(obj) {
    setClient((p) => {
      return { ...p, ...obj };
    });
    requestCacheCount.current = 1;
  }

  function changeNote(n) {
    setNote(n);
    requestCacheCount.current = 1;
  }

  function clearLclStrg() {
    const tempName = "REQUEST_CREATE_OBJ";
    localStorage.removeItem(tempName);
    localStorage.removeItem("ADDRESS_PICKER_vidacha");
    localStorage.removeItem("ADDRESS_PICKER_vozvrat");
    requestCacheCount.current = 0;
  }

  function resetQueries() {
    queryClient.resetQueries(GET_REQUEST_LIST, { exact: true });
    queryClient.resetQueries(GET_CLIENT_LIST, { exact: true });
  }

  async function onSubmitSend() {
    const obj = { ...requestBody };
    let carStatusResult = null;
    if (obj.gosnomer) {
      const { data } = await checkCarStatus.mutateAsync(obj.gosnomer);
      carStatusResult = data.status;
      if (carStatusResult !== "свободна") {
        setCarStatusFailModal({
          title: "Эх, не успели",
          text: `Статус авто был изменен на "${
            carStatusResult[0].toUpperCase() + carStatusResult.slice(1)
          }"`,
          type: "fail",
        });
      }
      if (carStatusResult !== "свободна") return;
    }
    //status_id = 5 Бронь или  status_id = 3  На рассмотрение
    isBooking ? (obj.status_id = 5) : (obj.status_id = 3);

    if (reqId) {
      obj.request_id = reqId;
      putReqMutation.mutate(obj, {
        onSuccess: () => {
          changeCarStatusFailModal({
            title: "Заявка",
            text: "Заявка отправлена",
            type: "success",
          });
          resetStates();
          resetQueries();
          clearLclStrg();
        },
      });
    } else {
      postReqMutation.mutate(obj, {
        onSuccess: () => {
          changeCarStatusFailModal({
            title: "Заявка",
            text: "Заявка отправлена",
            type: "success",
          });
          resetStates();
          resetQueries();
          clearLclStrg();
        },
      });
    }
  }

  // status_id = 1
  async function handleSaveToDraft() {
    const obj = {
      ...requestBody,
    };

    obj.status_id = 1;

    if (reqId) {
      putReqMutation.mutate(
        { ...obj, request_id: reqId },
        {
          onSuccess: () => {
            changeCarStatusFailModal({
              title: "Заявка",
              text: "Заявка сохранена",
              type: "success",
            });
            resetStates();
            resetQueries();
            clearLclStrg();
          },
        }
      );
      return;
    }
    postReqMutation.mutate(
      { ...obj },
      {
        onSuccess: () => {
          resetQueries();
          changeCarStatusFailModal({
            title: "Заявка",
            text: "Заявка сохранена",
            type: "success",
          });
          resetStates();
          clearLclStrg();
        },
      }
    );
  }

  function changeCarStatusFailModal(csfm) {
    setCarStatusFailModal(csfm);
  }

  function changeDocPhotos(arr) {
    setDocPhotos(arr);
    requestCacheCount.current = 1;
  }

  function changeIsFilterOpen(ifo) {
    setIsFilterOpen(ifo);
  }

  function handleResetCacheData() {
    const tempName = "REQUEST_CREATE_OBJ";
    localStorage.removeItem(tempName);
    changeIsOpenResetCacheModal(false);
  }

  function handleRecoverCacheData() {
    const tempName = "REQUEST_CREATE_OBJ";
    const tempRequestObj = JSON.parse(localStorage.getItem(tempName) || "null");
    const {
      ageExp,
      travelRegion,
      tariff,
      options,
      car,
      note,
      client,
      vidacha,
      vozvrat,
      doc_photos,
    } = tempRequestObj;

    setAgeExp(ageExp);
    setTravelRegion(travelRegion);
    setTariff(tariff);
    setOptions(options);
    setCar(car);
    setNote(note);
    setClient(client);
    setVidacha(vidacha);
    setVozvrat(vozvrat);
    setDocPhotos(doc_photos);
    changeIsOpenResetCacheModal(false);
  }

  function resetStates() {
    setAgeExp(true);
    setClient({
      client_id: null,
      client_name: "",
      phone: "",
      source: "",
    });
    setTravelRegion("000000001");
    setOptions([]);
    const today = new Date();
    const tempTime = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      today.getHours() + 1
    );
    setVidacha({
      point_id: null,
      point_name: "",
      point_type: "office",
      address: "",
      date: [
        tempTime.getDate(),
        tempTime.getMonth(),
        tempTime.getFullYear(),
        tempTime.getHours(),
        "00",
      ],
      comment: "",
    });
    const tomorrow = new Date(
      tempTime.getFullYear(),
      tempTime.getMonth(),
      tempTime.getDate() + 1,
      tempTime.getHours()
    );
    setVozvrat({
      point_id: null,
      point_name: "",
      point_type: "office",
      address: "",
      date: [
        tomorrow.getDate(),
        tomorrow.getMonth(),
        tomorrow.getFullYear(),
        tomorrow.getHours(),
        "00",
      ],
      comment: "",
    });
    setTariff("200");
    setCar(null);
    setNote("");
    setDiscount(0);
    setIsBooking(false);
  }

  function changeDiscount(d) {
    const nums = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    d = Number(
      d
        .split("")
        .filter((i) => nums.includes(i))
        .join("")
    );
    if (d < 0) {
      d = 0;
    }
    if (d > 99) {
      d = 99;
    }
    setDiscount(d);
  }

  function changeBooking() {
    setIsBooking(!isBooking);
  }

  const checkboxBookingDisabled = useMemo(() => {
    const isDisabled =
      sendBtnIsDisabled ||
      !car ||
      vidacha.address.length < 5 ||
      vozvrat.address.length < 5;

    if (isDisabled) {
      setIsBooking(false);
      return true;
    }
  }, [sendBtnIsDisabled, car, vidacha, vozvrat]);

  useModalKeyDown({
    isOpen: isOpenResetCacheModal,
    handleClose: () => handleRecoverCacheData(),
    handleSubmit: () => handleResetCacheData(),
  });

  return (
    <>
      <div className="req-create-wrap">
        <ReqCreateLeft
          addOptions={addOptions}
          cityList={cityList}
          appeal={client.client_name}
          changeClient={changeClient}
          ageExp={ageExp}
          changeAgeExp={changeAgeExp}
          travelRegion={travelRegion}
          changeTravelRegion={changeTravelRegion}
          vidacha={vidacha}
          changeVidacha={changeVidacha}
          vozvrat={vozvrat}
          changeVozvrat={changeVozvrat}
          tariff={tariff}
          changeTariff={changeTariff}
          options={options}
          changeOptions={changeOptions}
          carFranchise={carFranchise}
          priceMatrixIndex={priceMatrixIndex}
          isShowScripts={isShowScripts}
          changeIsShowScripts={changeIsShowScripts}
          viewCarList={viewCarList}
          changeViewCarList={changeViewCarList}
          rentPeriod={rentPeriod}
          carAddress={car?.point_address}
          priceVidachaType={priceVidachaType}
          priceVozvratType={priceVozvratType}
          tariffIndex={tariffIndex}
          note={note}
          changeNote={changeNote}
          points={points}
          handleSaveToDraft={handleSaveToDraft}
          draftBtIsDisabled={sendBtnIsDisabled}
          staticDataLoading={staticDataLoading}
          agentChatTemplates={agentChatTemplates}
          clientList={clientList}
          editContentLoading={editContentLoading}
          reqId={reqId}
          isLoading={postReqMutation.isLoading || putReqMutation.isLoading}
          car={car}
          changeCar={changeCar}
        />
        {!isMobile && (
          <ReqCreateMiddle
            cityList={cityList}
            options={options}
            addOptions={addOptions}
            travelRegion={travelRegion}
            changeTravelRegion={changeTravelRegion}
            ageExp={ageExp}
            changeAgeExp={changeAgeExp}
            viewCarList={viewCarList}
            car={car}
            changeCar={changeCar}
            priceMatrixIndex={priceMatrixIndex}
            tariffIndex={tariffIndex}
            priceId={priceId}
            coef={coef}
            perDayOptionsPrice={perDayOptionsPrice}
            perDailyPrice={perDailyPrice}
            isFilterOpen={isFilterOpen}
            changeIsFilterOpen={changeIsFilterOpen}
            carListRes={carList}
            carLoading={carLoading || editContentLoading || priceLoading}
            isBookingPage={false}
          />
        )}
        <ReqCreateRight
          appeal={client.client_name}
          isShowScripts={isShowScripts}
          car={car}
          priceMatrixIndex={priceMatrixIndex}
          tariffIndex={tariffIndex}
          tariff={tariff}
          rentPeriod={rentPeriod}
          options={options}
          addOptions={addOptions}
          carFranchise={carFranchise}
          client={client}
          changeClient={changeClient}
          onSubmitSend={onSubmitSend}
          sendBtnIsDisabled={sendBtnIsDisabled}
          totalPrice={totalPrice}
          carPrice={carPrice}
          dailyPrice={dailyPrice}
          priceVidachaType={priceVidachaType}
          priceVozvratType={priceVozvratType}
          doc_photos={doc_photos}
          changeDocPhotos={changeDocPhotos}
          coef={coef}
          staticDataLoading={staticDataLoading}
          agentChatTemplates={agentChatTemplates}
          discount={discount}
          discountSum={discountSum}
          changeDiscount={changeDiscount}
          isLoading={
            putReqMutation.isLoading ||
            postReqMutation.isLoading ||
            checkCarStatus.isLoading ||
            getReqLoading
          }
          isBooking={isBooking}
          changeBooking={changeBooking}
          checkboxBookingDisabled={checkboxBookingDisabled}
          vidacha={vidacha}
          vozvrat={vozvrat}
          handleSaveToDraft={handleSaveToDraft}
        />
        <StatusCheckFailModal
          isOpen={!!carStatusFailModal}
          data={carStatusFailModal}
          onSubmit={() => navigate("/dashboard")}
          onClose={() => navigate("/dashboard")}
        />
        <ConfirmModal
          title="Важно !"
          text="В заявке есть данные. Хотите создать новую?"
          isOpen={isOpenResetCacheModal}
          onClose={handleRecoverCacheData}
          onSubmit={handleResetCacheData}
        />
      </div>
      {isMobile && (
        <Modal
          isOpen={isOpenCatalogCar}
          onClose={() => changeIsOpenCatalogCar(false)}
          title={"Каталог"}
          direction="Bottom"
          height="Fill"
          colorTitle="Primary"
        >
          <MobileReqCreateMiddle
            cityList={cityList}
            options={options}
            addOptions={addOptions}
            travelRegion={travelRegion}
            changeTravelRegion={changeTravelRegion}
            ageExp={ageExp}
            changeAgeExp={changeAgeExp}
            viewCarList={viewCarList}
            car={car}
            changeCar={changeCar}
            priceMatrixIndex={priceMatrixIndex}
            tariffIndex={tariffIndex}
            priceId={priceId}
            coef={coef}
            perDayOptionsPrice={perDayOptionsPrice}
            perDailyPrice={perDailyPrice}
            isFilterOpen={isFilterOpen}
            changeIsFilterOpen={changeIsFilterOpen}
            carListRes={carList}
            carLoading={carLoading || editContentLoading || priceLoading}
            changeIsOpenCatalogCar={changeIsOpenCatalogCar}
          />
        </Modal>
      )}
    </>
  );
}
