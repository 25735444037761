import React, { useMemo } from "react";
import styled, { css } from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import cl from "classnames";

//
import Card from "../card/Card";
import IconWrapper from "../icon-wrapper/IconWrapper";
import CarNumber from "../car-number/CarNumber";
import { msToNormTime } from "../../util/date";
import { useAppContext } from "../../store/Context";

export default function CarCard({
  idx,
  step,
  inModal,
  car,
  grid,
  selectedCar,
  onChangeSelectedCar,
  reqCreate,
}) {
  // helper
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isComparsion = pathname.includes("comparsion");

  // context
  const {
    state: { carComparsionList },
    fn: {
      changeReqCar,
      addCarToCarComparsionList,
      deleteCarFromCarComparsionList,
      changeCarEquipment,
    },
  } = useAppContext();


  function navigateReqCreate() {
    navigate(`/req-create`);
    changeReqCar(car);
  }

  const checkSelected = useMemo(() => {
    return (
      selectedCar?.price_unit_id === car.price_unit_id &&
      selectedCar?.gosnomer === car.gosnomer
    );
  }, [selectedCar, car]);

  const comparsionCheck = useMemo(() => {
    return carComparsionList
      .map((i) => i.price_unit_id + i.gosnomer)
      .includes(car.price_unit_id + car.gosnomer);
  }, [carComparsionList, car]);

  const carAddressInfos = useMemo(() => {
    if (car.status === "свободна") return car.point_address;
    if (car.status === "занята" && !car.rent_end_date) return "В сервисе";
    return `Освободится ${msToNormTime(car.rent_end_date, 3)}`;
  }, [car]);

  return (
    <StyledCarCard
      idx={idx}
      step={step}
      inModal={inModal}
      grid={grid}
      reqCreate={reqCreate}
    >
      <Card radius={20}>
        <main className="car__card__main">
          <nav className="car__card__nav">
              <>
            {reqCreate ? 
              <IconWrapper
                icon="check"
                border={1}
                bg={checkSelected ? "success" : "light"}
                color={checkSelected ? "light" : "dark-20"}
                disabled={car.status === "занята" || car.status === "бронь"}
                onClick={() => onChangeSelectedCar(car)}
              /> 
              : (
              <>
              {inModal && !isComparsion ? (
                <IconWrapper
                  icon="check"
                  w={48}
                  h={48}
                  rounded={14}
                  bg={checkSelected ? "success" : "dark-5"}
                  color={selectedCar ? "light" : "dark"}
                  onClick={() => onChangeSelectedCar(car)}
                />
              ) : isComparsion ? (
                  <IconWrapper
                    icon={comparsionCheck ? "check" : "plus"}
                    w={48}
                    h={48}
                    rounded={14}
                    bg={comparsionCheck ? "success" : ""}
                    color={comparsionCheck ? "light" : "dark"}
                    border={!comparsionCheck}
                    onClick={() =>
                    comparsionCheck
                        ? deleteCarFromCarComparsionList(car)
                        : addCarToCarComparsionList(car)
                    }
                    />
                  ) : (
                    <IconWrapper
                      icon="plus"
                      w={48}
                      h={48}
                      rounded={14}
                      bg="success"
                      color="light"
                      onClick={navigateReqCreate}
                      disabled={
                        car.status === "занята" || car.status === "бронь"
                      }
                    />
                  )}
                </>
                )}
                <p
                  className={cl("address__badge", {
                    danger: car.status === "занята",
                    warning: car.status === "бронь",
                  })}
                  title={carAddressInfos}
                >
                  {carAddressInfos}
                </p>
              </>
          </nav>
          <ul className="car__card__ul">
            <li>
              <IconWrapper
                icon="palette icon-xs"
                w={30}
                h={30}
                bg="primary-10"
                color="primary"
              />
              <p>{car.parameters.color}</p>
              <div
                className="rectangle"
                style={{ backgroundColor: car.parameters.color_code }}
              />
            </li>
            <li>
              <IconWrapper
                icon="state-number icon-xs"
                w={30}
                h={30}
                bg="primary-10"
                color="primary"
              />
              <CarNumber title={car.gosnomer} size="sm" />
            </li>
            <li>
              <IconWrapper
                icon="calendar icon-xs"
                w={30}
                h={30}
                bg="primary-10"
                color="primary"
              />
              <p>{car.parameters.year}</p>
            </li>
            <li>
              <IconWrapper
                icon="engine icon-xs"
                w={30}
                h={30}
                bg="primary-10"
                color="primary"
              />
              <p>{car.parameters.engine_type}</p>
            </li>
            <li>
              <IconWrapper
                icon="transmission icon-xs"
                w={30}
                h={30}
                bg="primary-10"
                color="primary"
              />
              <p>{car.is_auto ? "Автомат" : "Механика"}</p>
            </li>
            <li>
              <IconWrapper
                icon="drive icon-xs"
                w={30}
                h={30}
                bg="primary-10"
                color="primary"
              />
              <p>{car.parameters.drive}</p>
            </li>
          </ul>
          <footer className="car__card__footer">
            <div className="car__card__footer__left">
              <IconWrapper
                border={!comparsionCheck}
                bg={comparsionCheck ? "success" : ""}
                color={comparsionCheck ? "light" : "dark"}
                rounded={12}
                icon={
                  comparsionCheck
                    ? "scales-minus icon-sm"
                    : "scales-plus icon-sm"
                  }
                onClick={() =>
                  comparsionCheck
                    ? deleteCarFromCarComparsionList(car)
                    : addCarToCarComparsionList(car)
                }
              />
            </div>
            <div className="car__card__footer__right">
                  <IconWrapper
                    icon="file icon-sm"
                    border={1}
                    rounded={12}
                    onClick={() => changeCarEquipment({ car, tab: 1 })}
                  />
                  <IconWrapper
                    icon="settings icon-sm"
                    border={1}
                    rounded={12}
                    onClick={() => changeCarEquipment({ car, tab: 2 })}
                  />
            </div>
          </footer>
        </main>
      </Card>
    </StyledCarCard>
  );
}

const StyledCarCard = styled.div`
  width: 230px;
  position: absolute;
  transition: 0.4s ease-in-out;

  .car__card {
    &__main {
      border-radius: inherit;
      overflow: hidden;
    }

    &__nav {
      padding: 20px 20px 10px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      gap: 10px;

      .address__badge {
        width: 160px;
        padding: 7px 16px;
        background-color: var(--success-20);
        border-radius: 15px;
        color: var(--success-dark);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;

        &.warning {
          background-color: var(--warning-20);
          color: var(--warning-dark);
        }

        &.danger {
          background-color: var(--danger-10);
          color: var(--danger-dark);
        }
      }
    }

    &__ul {
      li {
        padding: 10px 20px;
        display: flex;
        align-items: center;
        gap: 20px;
        position: relative;

        &:nth-child(2n) {
          background-color: var(--dark-3);
        }

        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0em;
          color: var(--dark);
        }

        .rectangle {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: var(--danger);
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 20px;
        }
      }
    }

    &__footer {
      padding: 10px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 10px;

      &__left {
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 10px;
      }
    }
  }

  ${({ idx, step, grid }) => css`
    left: ${(idx - step) * 240 + (grid ? 30 : 20)}px;
  `}

  ${({ inModal }) =>
    inModal &&
    css`
      .car__card {
        &__footer {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          gap: 20px;

          &__left {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            gap: 10px;
          }
        }
      }
    `}

    .car__card {
      &__nav {
        padding: 10px;
      }

      &__ul {
        li {
          padding: 8px 10px;
        }
      }

      &__footer {
        &__right {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          gap: 5px;
        }
      }
    }
`;
