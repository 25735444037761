/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";

//
import Loader from "../../../../components/loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";

export default function SuccessModal({
  changeIsAuth,
  user,
  removeSearchParam,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (!user) return;
    setTimeout(() => {
      removeSearchParam("success");
      changeIsAuth(true);
      navigate(location.state?.from?.pathname || "/dashboard");
    }, 2000);
  }, [user]);

  return (
    <StyledSuccessModal>
      <h1>Добро пожаловать</h1>
      <p>В личный кабиент партнера Альмак!</p>

      <main>
        <img
          src={
            user?.avatar ||
            "https://cdn3d.iconscout.com/3d/premium/thumb/man-avatar-6299539-5187871.png"
          }
          alt="avatar"
        />
        <h4>{user?.name || "Пользователь"}</h4>
      </main>

      <footer>
        <Loader w={30} h={30} size={2} />
      </footer>
    </StyledSuccessModal>
  );
}

const StyledSuccessModal = styled.div`
  h1 {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--dark);
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--dark-70);
    margin-bottom: 60px;
  }

  main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 53px;

    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }

    h4 {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      color: var(--dark);
    }
  }

  footer {
    height: 30px;
    position: relative;
  }
`;
