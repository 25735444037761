import React, { useEffect, useState } from "react";

//
import SelectSearch from "../../../../components/select-search/SelectSearch";
import "./ControlInfo.styles.scss";
import Checkbox from "../../../../components/checkbox/Checkbox";
import { useMutation } from "react-query";
import BookingApi from "../../../../store/booking/api";

export default function ControlInfo({
  counterparty,
  signer,
  clientStatus,
  isLongTermRent,
  isLegalEntity,
  onChangeLocalBooking,
  isEdit,
}) {
  const [isLongTermRentLocal, setIsLongTermRentLocal] = useState(false);

  const getCounterpartyInfo = useMutation(
    ["GET_COUNTERPARTY"],
    (id) => BookingApi.getPartyInfo(id),
    {
      onSuccess: (res) => {
        onChangeLocalBooking("counterparty_all", res.data);
      },
    }
  );

  useEffect(() => {
    setIsLongTermRentLocal(isLongTermRent);
  }, [ isLegalEntity, isLongTermRent]);

  const handleChangeCounterparty = (counterparty) => {
    getCounterpartyInfo.mutate(counterparty.counterparty_id);
    // onChangeLocalBooking("counterparty_id", counterparty.counterparty_id);
    // onChangeLocalBooking("counterparty", counterparty.counterparty);
  };

  const handleChangeSigner = (counterparty) => {
    onChangeLocalBooking("signer_id", counterparty.counterparty_id);
    onChangeLocalBooking("signer", counterparty.counterparty);
  };

  const handleChangeLongTerm = () => {
    setIsLongTermRentLocal(!isLongTermRentLocal);
    onChangeLocalBooking("long_term_rent", !isLongTermRentLocal);
  };

  return (
    <>
      <main className="control-info">
        <div className="control-info__block">
          <div className="label-block">
            <h3>Контрагент</h3>
          </div>
          <div className="control-info__main-block">
            <div className="control-info__main-block__input">
              <SelectSearch
                value={counterparty}
                onChange={handleChangeCounterparty}
                disabled={!isEdit}
              />
            </div>
          </div>
            <div className="control-info__mark-block">
              {
                isLegalEntity && 
                <div className="control-info__mark">юр. лицо </div>
              }
              {clientStatus && (
              <div className="control-info__mark">{clientStatus} клиент </div>
              )}
            </div>
        </div>
        { isLegalEntity && 
          <div className="control-info__block">
            <h3>Имя подписанта</h3>
            <SelectSearch
              value={signer}
              onChange={handleChangeSigner}
              disabled={!isEdit}
            />
          </div>
        }
        <div className="control-info__block">
            <div className="block-checkbox">
              <div className="checkbox">
                <Checkbox
                  checked={isLongTermRentLocal}
                  onClick={handleChangeLongTerm}
                  disabled={!isEdit}
                />
                <p>Долгосрочная аренда</p>
              </div>
            </div>
        </div>
      </main>
    </>
  );
}
