import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import cl from "classnames";
import { Link, useLocation, useNavigate } from "react-router-dom";

//
import logo from "../../asset/img/logo-icon.svg";
import { navList } from "./static";
import { useAppContext } from "../../store/Context";
import { hidePhoneNumberGen } from "../../util/formatters";


export default function VerticalNavbar() {
  // helper
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(
    JSON.parse(localStorage.getItem("VERTICAL_NAV" || "false"))
  );

  // context
  const {
    state: { carComparsionListLength, user, draftsCount },
    fn: { changeUserInfoModal },
  } = useAppContext();
  navList[0].count = draftsCount;
  navList[2].count = carComparsionListLength;

  function checkForActive(to) {
    if (pathname.includes("req-create") && to === "/dashboard") return true;
    if (pathname.includes(to)) return true;
    return false;
  }

  function toggleIsOpen() {
    setIsOpen((p) => {
      localStorage.setItem("VERTICAL_NAV", !p);
      return !p;
    });
  }

  return (
    <StyledVerticalNavbar isOpen={isOpen} onClick={toggleIsOpen}>
      <section className="vertical__nav__top">
        <Link
          to="/"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <img src={logo} alt="logo" width={40} height={40}/>
        </Link>
        <button
          className="btn-create"
          onClick={(e) => {
            e.stopPropagation();
            navigate("/req-create");
          }}
        >
          <p>Создать</p>
          <i className="icon icon-sm icon-plus" />
        </button>
        <ul>
          {navList.map((i) => (
            <li key={i.id}>
              <Link
                to={i.to}
                className={cl("vertical__nav__link", {
                  active: checkForActive(i.to),
                })}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div className="icon__wrap">
                  <i className={"icon icon-sm icon-" + i.icon} />
                </div>
                <p>{i.title}</p>
                {i.count > 0 && <div className="count__circle">{i.count}</div>}
              </Link>
            </li>
          ))}
          {
          user?.has_extended_rights &&
          <li>
            <Link
              to={"/booking"}
              className={cl("vertical__nav__link", {
                active: checkForActive("/booking"),
              })}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
							<div className="icon__wrap">
								<i className={"icon icon-sm icon-booking"} />
							</div>
							<p>Бронирование</p>
            </Link>
          </li>
          }
        </ul>
      </section>
      <section className="vertical__nav__bottom">
        {/* <div className="msg__wrap">
          <i className="icon icon-sm icon-message-dots-circle" />
        </div> */}
        <div
          className="agent__info__wrap"
          onClick={(e) => {
            e.stopPropagation();
            changeUserInfoModal(true);
          }}
        >
          <img
            src={
              user?.avatar ||
              "https://cdn3d.iconscout.com/3d/premium/thumb/man-avatar-6299539-5187871.png"
            }
            alt="user-avatar"
            width={40}
          />
          <div className="agent__info">
            <h1>{user?.name}</h1>
            <p>{hidePhoneNumberGen(user?.phone)}</p>
          </div>
          <div className="more__wrap">
            <i className="icon icon-sm icon-dots-horizontal"></i>
          </div>
        </div>
      </section>
      <div className="vertical__nav__toggle__btn">
        <i
          className="icon icon-xs icon-chevron-right"
          style={{ transform: isOpen ? "rotate(180deg)" : "rotate(0deg)" }}
        ></i>
      </div>
    </StyledVerticalNavbar>
  );
}

const StyledVerticalNavbar = styled.div`



  position: relative;
  background-color: var(--primary);
  width: 68px;
  min-width: 68px;
  height: 100vh;
  

  .vertical__nav {
    &__top {
      position: fixed;
      z-index: 5;
      width: 70px;
      min-width: 70px;
      padding: 12px 11px;
      height: calc(100vh - 80px);
      background-color: var(--primary);
      transition: 0.4s;

      .btn-create {
        position: fixed;
        margin-top: 30px;
        margin-left: 5px;
        width: 40px;
        height: 40px;
        transition: 0.4s;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        gap: 10px;
        background-color: var(--success);
        border-radius: 50%;
        border: none;
        cursor: pointer;
        position: relative;
        margin-bottom: 5px;
  
        p {
          transition: 0.4s;
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0em;
          color: var(--light);
          position: absolute;
          top: 14px;
          left: 66px;
          visibility: hidden;
          opacity: 0;
        }
  
        i {
          position: absolute;
          top: 10px;
          left: 10px;
          transition: 0.4s;
        }
      }

      ul {
        margin-top: 30px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 10px;
        background-color: var(--light-5);
        padding: 4px;
        border-radius: 24px;
      }
    }

    &__link {
      display: flex;
      align-items: center;
      gap: 0px;
      padding: 0px;
      padding-left: 4px;
      position: relative;
      border-radius: 12px;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      transition: 0.4s;
      transform: translateX(0.00000001%);
      z-index: 3;

      .icon__wrap {
        background-color: var(--light-10);
        width: 32px;
        height: 32px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        border-radius: 50%;
      }

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        color: var(--light);
        position: absolute;
        left: 52px;
        opacity: 0;
        visibility: hidden;
        transition: 0.4s;
      }

      .count__circle {
        position: absolute;
        font-size: 10px;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 0em;
        padding: 2px 4px;
        background-color: var(--danger);
        color: var(--light);
        border-radius: 8px;
        top: -3px;
        right: -3px;
        transition: 0.4s;
      }

      &.active {
        background-color: var(--light);

        .icon__wrap {
          i {
            background-color: var(--primary);
          }
        }

        p {
          color: var(--primary);
        }
      }
    }

    &__toggle__btn {
      position: fixed;
      top: 500px;
      transform: translate(100%, 50%);
      left: 45px;
      background-color: var(--primary);
      width: 20px;
      height: 40px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      border-radius: 0 10px 10px 0;
      cursor: pointer;
      transition: 0.4s;
    }

    &__bottom {
      position: fixed;
      bottom: 0;
      z-index: 5;
      padding-bottom: 20px;
      width: 70px;
      min-width: 70px;
      background-color: var(--primary);
      transition: 0.4s;

      .msg__wrap {
        width: 40px;
        height: 40px;
        transition: 0.4s;
        overflow: hidden;
        margin-left: 10px;
        background-color: var(--light-10);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
      }

      .agent__info__wrap {
        width: 70px;
        height: 52px;
        margin-top: 10px;
        transition: 0.4s;
        padding: 0 0 12px;
        position: relative;
        background-color: transparent;
        cursor: pointer;

        img {
          position: absolute;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          border: 1px solid var(--primary-dark-15);
          transition: 0.4s;
          top: 0px;
          left: 10px;
        }

        .agent__info {
          position: absolute;
          top: 16px;
          left: 60px;
          opacity: 0;
          visibility: hidden;
          transition: 0.4s;

          h1 {
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 0em;
            color: var(--light);
            width: 120px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          p {
            font-size: 10px;
            font-weight: 400;
            line-height: 12px;
            letter-spacing: 0em;
            color: var(--light);
            opacity: 0.5;
          }
        }

        .more__wrap {
          position: absolute;
          top: 14px;
          right: 10px;
          width: 32px;
          height: 32px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          background-color: var(--light-10);
          border-radius: 50%;
          transition: 0.4s;
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }

  
  @media (max-width: 1280px) {
    height: auto;
    .vertical__nav {
      &__top {
        position: static;
        z-index: 5;
        width: 70px;
        min-width: 70px;
        padding: 12px 11px;
        height: auto;
        background-color: var(--primary);
        transition: 0.4s;
      }

      &__bottom {
        position: fixed;
        top: 0;
        height: 50px;
        z-index: 5;
        padding-bottom: 20px;
        width: 70px;
        min-width: 70px;
        background-color: var(--primary);
        transition: 0.4s;
      }
    }

  }

  ${({ isOpen }) =>
    isOpen
      ? css`
          width: 240px;
          min-width: 240px;
          transition: 0.4s;

          .vertical__nav {
            &__top {
              padding: 12px 11px;
              width: 240px;
              min-width: 240px;

              .btn-create {
                width: 220px;
                height: 48px;
                border-radius: 12px;
                margin-bottom: 5px;
                margin-left: 0;
  
                p {
                  visibility: visible;
                  opacity: 1;
                }
  
                i {
                  top: 15px;
                  left: 144px;
                }
              }

              ul {
                padding: 0;
                background-color: transparent;
              }
            }

            &__link {
              width: 220px;
              height: 44px;
              border-radius: 12px;

              p {
                opacity: 1;
                visibility: visible;
              }

              .count__circle {
                top: 14px;
                right: 14px;
              }

              &:hover {
                background-color: var(--light-10);
              }

              &.active {
                background-color: var(--light);
              }
            }

            &__toggle__btn {
              left: 215px;
            }

            &__bottom {
              transition: 0.4s;
              width: 240px;
              min-width: 240px;

              .msg__wrap {
                width: 0px;
                height: 0px;
              }

              .agent__info__wrap {
                width: 240px;
                height: 60px;
                background-color: var(--light-5);

                img {
                  top: 10px;
                  left: 10px;
                }

                .agent__info,
                .more__wrap {
                  opacity: 1;
                  visibility: visible;
                }
              }
            }
          }
        `
      : css`
          .vertical__nav {
            &__link {
              &:hover {
                width: 150px;
                background-color: var(--light);
                
                p {
                  opacity: 1;
                  visibility: visible;
                  left: 45px;
                  color: var(--primary);
                }

                .icon__wrap {
                  i {
                    background-color: var(--primary);
                  }
                }
              }
            }
          }
        `}
`;
