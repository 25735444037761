import { v4 } from "uuid";

export const statusSelectList = [
  {
    id: v4(),
    title: "Любой статус",
    value: 0,
  },
  {
    id: v4(),
    title: "Доработать",
    value: 2,
  },
  {
    id: v4(),
    title: "На рассмотрении",
    value: 3,
  },
  {
    id: v4(),
    title: "В работе",
    value: 4,
  },
  {
    id: v4(),
    title: "Предв. бронь",
    value: 5,
  },
  {
    id: v4(),
    title: "Бронь",
    value: 6,
  },
  {
    id: v4(),
    title: "Отмена",
    value: 7,
  },
  {
    id: v4(),
    title: "Отказ",
    value: 8,
  },
  {
    id: v4(),
    title: "На выезде",
    value: 9,
  },
  {
    id: v4(),
    title: "Реализация",
    value: 10,
  },
];
