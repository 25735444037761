import React, { useEffect, useState } from "react";
//
import "./ModalAddDriver.styles.scss";
import SelectSearch from "../../../../components/select-search/SelectSearch";
import Button from "../../../../components/button/Button";

export default function ModalAddDriver({
  driverList,
  onChangeLocalBooking = () => {},
  changeIsOpenAddDriverModal,
}) {
  const [localDriverList, setLocalDriverList] = useState([]);

  useEffect(() => {
    setLocalDriverList(driverList);
  }, [driverList]);

  const handleDeleteDriver = (index) => {
    let newDriverList = [...localDriverList];
    newDriverList.splice(index, 1);
    setLocalDriverList(newDriverList);
  };

  const handleChangeDriverList = (driver) => {
    let newDriverList = [...localDriverList];
    newDriverList.push(driver);
    setLocalDriverList(newDriverList);
  };

  const handleSaveDriverList = () => {
    onChangeLocalBooking("add_drivers_list", localDriverList);
    changeIsOpenAddDriverModal();
  };

  return (
    <>
      <main className="modal-driver-wrapper">
        {Array.from({ length: 5 }, (_, i) => i + 1).map((item, index) => {
          return (
            <div key={"driver" + index} className="modal-driver">
              <h2>Водитель {index + 1}</h2>
              <div className="modal-driver__select">
                <SelectSearch
                  placeholder="Выберите водителя"
                  value={localDriverList[index]}
                  onChange={handleChangeDriverList}
                />
              </div>
              <Button
                radius={12}
                color="light"
                textUpperCase={1}
                fW={500}
                p={[11, 13]}
                onClick={() => handleDeleteDriver(index)}
              >
                <i className="icon icon-close icon-sm" />
              </Button>
            </div>
          );
        })}
        <Button
          className="modal-driver__btn"
          radius={16}
          color="primary"
          border={"1"}
          textUpperCase={1}
          fW={500}
          p={[15, 30]}
          onClick={handleSaveDriverList}
        >
          Сохранить
        </Button>
      </main>
    </>
  );
}
