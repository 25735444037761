import React, { useMemo } from "react";

//
import Card from "../../../../../components/card/Card";
import Input from "../../../../../components/input/Input";
import InputPhone from "../../../../../components/input-phone/InputPhone";
import AgentChatTemplate from "../../../../../components/agent-chat-template/AgentChatTemplate";
import "./ClientInfo.styles.scss";

export default function ClientInfo({
  appeal,
  isShowScripts,
  client,
  doc_photos,
  changeDocPhotos,
  staticDataLoading,
  agentChatTemplate,
}) {
  function uploadImage(idx) {
    const input = document.createElement("input");
    input.type = "file";
    input.multiple = true;
    input.accept = "image/*";
    input.onchange = (e) => getBase64(e.target.files[0], idx);
    input.click();
  }

  function getBase64(file, idx) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      changeDocPhotos(
        doc_photos.map((i, index) => (index === idx ? reader.result : i))
      );
    };
  }

  function deleteImage(idx) {
    changeDocPhotos(doc_photos.map((i, index) => (index === idx ? "" : i)));
  }

  const chatListGenerator = useMemo(() => {
    if (!agentChatTemplate) return [];
    const list = [...agentChatTemplate.script_text];
    list[0] = (appeal || "Client") + list[0];
    return list;
  }, [agentChatTemplate, appeal]);

  return (
    <>
      <Card radius={20}>
        <main className="client-info__main">
          <h1>Данные клиента</h1>
          {isShowScripts && (
            <AgentChatTemplate
              chat={chatListGenerator}
              radius="16px"
              hideIcon={1}
              isLoading={staticDataLoading}
            />
          )}
          <div className="client-info__form">
            <Input
              fill={1}
              placeholder="ФИО"
              size="sm"
              value={client.client_name}
              onChange={(e) => null}
              readOnly
            />
            <InputPhone
              fill={1}
              placeholder="+7"
              size="sm"
              value={client.phone}
              onChange={(e) => null}
              readOnly
            />
            <Input
              fill={1}
              placeholder="Источник"
              size="sm"
              value={client.source}
              onChange={(e) => null}
              readOnly
            />
          </div>
          <div className="client-info__document">
            <div className="client-info__document__top">
              <h1>Документы</h1>
              <div className="icon__wrap">
                <i className="icon icon-sm icon-alert-circle" />
              </div>
            </div>
            <ul className="client-info__document__list">
              {doc_photos.map((i, idx) => (
                <li key={idx}>
                  {i === "" ? (
                    <div
                      className="upload__wrap"
                      onClick={() => uploadImage(idx)}
                    >
                      <i className="icon icon-sm icon-plus" />
                    </div>
                  ) : (
                    <div className="image__wrap">
                      <img src={i} alt="uploadedImage" />
                      <div className="trash__wrapper">
                        <i
                          className="icon icon-xs icon-trash"
                          onClick={() => deleteImage(idx)}
                        ></i>
                      </div>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </main>
      </Card>
    </>
  );
}