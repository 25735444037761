import React from "react";
import styled from "styled-components";

//
import CatalogRefreshCard from "../../../../../components/catalog-refresh-card/CatalogRefreshCard";
import CarFilterCollapse from "../../../../../components/car-filter-collapse/CarFilterCollapse";
import CarBodyFilter from "../../../../../components/car-body-filter/CarBodyFilter";
import {
  staticDriveList,
  staticEngineTypeList,
  staticSeatList,
  staticCarBodyList,
} from "../static";

export default function CatalogFilter({
  drive,
  onChangeDrive,
  engine,
  onChangeEngine,
  capacity,
  onChangeCapacity,
  seatOptions=[],
  location,
  onChangeLocation,
  locationOptions=[],
  category,
  onChangeCategory,
  categoryOptions,
  carBody,
  onChangeCarBody,
  onResetQueryGetCarList,
  hideResetBtn,
}) {
  let seatStaticsOptions = staticSeatList.filter((seatStatic) =>
    seatOptions.includes(seatStatic.value)
  );
  seatStaticsOptions.unshift(staticSeatList[0]);

  return (
    <StyledCatalogFilter>
      {!hideResetBtn && <CatalogRefreshCard onClick={onResetQueryGetCarList} />}
      <div id="Привод">
        <CarFilterCollapse
          title="Привод"
          options={staticDriveList}
          value={drive}
          onChange={onChangeDrive}
        />
      </div>

      <div id="Локация">
        <CarFilterCollapse
          title="Локация"
          options={locationOptions}
          value={location}
          onChange={onChangeLocation}
        />
      </div>

      <div id="Двигатель">
        <CarFilterCollapse
          title="Типу двигателя"
          options={staticEngineTypeList}
          value={engine}
          onChange={onChangeEngine}
        />
      </div>
      <div id="Мест">
        <CarFilterCollapse
          title="Количество мест"
          options={seatStaticsOptions}
          value={capacity}
          onChange={onChangeCapacity}
        />
      </div>
      <div id="Категория">
        <CarFilterCollapse
          title="Категория"
          options={categoryOptions}
          value={category}
          onChange={onChangeCategory}
        />
      </div>
      <div id="Кузов">
        <CarBodyFilter
          title="Тип кузов"
          options={staticCarBodyList}
          value={carBody}
          onChange={onChangeCarBody}
        />
      </div>
    </StyledCatalogFilter>
  );
}

const StyledCatalogFilter = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 10px;

  .age_exp_wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    gap: 10px;
    padding: 20px 10px;

    & > div {
      & > h1 {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 10px;
        color: var(--dark);
      }
    }
  }
`;
