import { useEffect, useState, useRef } from "react";
import ReactOutsideClickHandler from "react-outside-click-handler";
import BookingApi from "../../store/booking/api";
import { useMutation } from "react-query";
import useDebounce from "../../hooks/useDebounce";
import "./SelectSearch.styles.scss";
import cl from "classnames";
import { msToNormTime } from "../../util/date";

export default function SelectSearch({
  value,
  onChange,
  placeholder,
  disabled,
}) {
  const [searchValue, setSearchValue] = useState("");
  const [optionList, setOptionList] = useState([]);
  const [isOpenSelect, setIsOpenSelect] = useState(false);
  const debouncedSearch = useDebounce(searchValue, 300);
  const searchInputRef = useRef(null);
  const searchService = useMutation(
    ["SEARCH_COUNTERPARTY"],
    (body) => BookingApi.searchParty(body),
    {
      onSuccess: (res) => {
        setOptionList(res.data.counterparty_list);
      },
    }
  );

  useEffect(() => {
    if (value && "counterparty" in value && "counterparty_id" in value) {
      setSearchValue(value.counterparty);
      setOptionList(Array.from(value));
    } else {
      setSearchValue("");
    }
  }, [value]);

  useEffect(() => {
    if (debouncedSearch) {
      searchService.mutate({ search_param: debouncedSearch });
    }
  }, [debouncedSearch]);

  function handleClick(e) {
    let partyName = e.target.innerText;
    let partyId = e.target.dataset.partyId;
    setSearchValue(partyName);

    let party = optionList.find((op) => op.counterparty_id === partyId);
    // call change function
    if (onChange) onChange(party);
    setIsOpenSelect(false);
  }

  function handleChange(e) {
    const keyword = e.target.value;
    setSearchValue(keyword);
  }

  return (
    <ReactOutsideClickHandler onOutsideClick={() => setIsOpenSelect(false)}>
      <div className={`searching-select ${cl({ openSelect: isOpenSelect })}`}>
        <input
          className={`searching-select__search ${cl({ disabled: disabled })}`}
          value={searchValue}
          onChange={handleChange}
          placeholder={placeholder}
          onFocus={() => setIsOpenSelect(true)}
          disabled={disabled}
          ref={searchInputRef}
        />
        <i className="icon icon-sm icon-chevron-selector icon-dark"></i>
        <ul
          className={`searching-select__values ${cl({ open: isOpenSelect })}`}
        >
          {optionList.map((el) => (
            <li
              key={el.counterparty_id}
              onClick={handleClick}
              data-party-id={el.counterparty_id}
              className={cl({ active: searchValue === el.counterparty })}
            >
              {el.counterparty +
                " - " +
                msToNormTime(parseInt(el.birthdate), 3)}
            </li>
          ))}
        </ul>
      </div>
    </ReactOutsideClickHandler>
  );
}
