import React from "react";
import styled, { css } from "styled-components";

export default function Card({ children, radius = 30 }) {
  return (
    <StyledCard radius={radius} >
      {children}
    </StyledCard>
  );
}

const StyledCard = styled.div`
  background: var(--light);
  box-shadow: 0px 2px 10px var(--dark-5);
  // height: 100%;
  position: relative;

  ${({ radius }) => css`
    border-radius: ${radius}px;
  `}
`;
