import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

//
// import Requests from "./sections/Requests";
import ReqList from "./sections/req-list/ReqList";
import Drafts from "./sections/drafts/Drafts";
import DraftReqModal from "./components/DraftReqModal";
import RequestApi from "../../../store/request/api";
import { useAppContext } from "../../../store/Context";
import { GET_REQUEST_LIST } from "../../../store/dashboard/type";
import { useMemo } from "react";
import "./Dashboard.styles.scss";

export default function Dashboard() {
  const {
    state: { requestResult, getRequestsLoading },
  } = useAppContext();

  //
  const [selectedDraftRequest, setSelectedDraftRequest] = useState(null);

  function changeSelectedDraftRequest(sdr) {
    setSelectedDraftRequest(sdr);
  }

  const queryClient = useQueryClient();
  const deleteReqMutation = useMutation((id) => RequestApi.deleteRequest(id));
  function handleDeleteRequest(e, id) {
    e.stopPropagation();
    deleteReqMutation.mutate(id, {
      onSuccess: () => {
        queryClient.resetQueries(GET_REQUEST_LIST, { exact: true });
        changeSelectedDraftRequest(null);
      },
    });
  }

  const isLoading = useMemo(() => {
    return getRequestsLoading || deleteReqMutation.isLoading;
  }, [getRequestsLoading, deleteReqMutation.isLoading]);

  return (
    <div className={`dashboard ${(!requestResult.draft.length || isLoading) && "draft-count"}`}>
      {(isLoading || requestResult.draft.length > 0) && (
        <section>
          <Drafts
            list={requestResult.draft}
            changeSelectedDraftRequest={changeSelectedDraftRequest}
            handleDeleteRequest={handleDeleteRequest}
            isLoading={isLoading}
          />
        </section>
      )}
      <section>
        {/* code: Dashboard__Requests */}
        {/* <Requests countStatus={requestResult.statusCount} /> */}
        <ReqList list={requestResult.list} isLoading={isLoading} />
      </section>
        {selectedDraftRequest && (
          <DraftReqModal
            request={selectedDraftRequest}
            onClose={() => changeSelectedDraftRequest(null)}
            isLoading={deleteReqMutation.isLoading}
            handleDeleteRequest={handleDeleteRequest}
          />
        )}
    </div>
  );
}
