import React, { useEffect, useState } from "react";

//
import Input from "../../../../components/input/Input";
import "./TypeBooking.styles.scss";
import Select from "../../../../components/select/Select";

import { useQueryClient } from "react-query";
import { GET_CAR_LIST } from "../../../../store/catalog/type";
import { useAppContext } from "../../../../store/Context";

export default function TypeBooking({
  deposit,
  isEdit,
  bookingType,
  bookingTypeList,
  onChangeLocalBooking,
  priceListName,
  priceList,
  car,
  changeShowDeliveryman,
  defaultPriceList,
}) { 
  const {
    fn: { changeCityId },
  } = useAppContext();
  const queryClient = useQueryClient(); 
  const [depositLocal, setDepositLocal] = useState("");

  useEffect(() => {
    if (priceListName === "") {
      const price = priceList?.find((item) => item.price_list_id === defaultPriceList);
      if (price) {
        onChangeLocalBooking("price_list_id", defaultPriceList);
        onChangeLocalBooking("price_list_name", price.price_list);
      }
    }
  }, [priceListName, priceList])
  
  useEffect(() => {
    car ?
      setDepositLocal(car.rental_terms?.deposit)
    :
      setDepositLocal(deposit);
  }, [car, deposit])

  function getPriceList() {
    return [...priceList.map((i) => ({ 
      id: i.price_list_id, 
      title: i.price_list, 
      value: i.price_list_id }))];
  }

  function handleChangePrice(p) {
    const price = priceList.find((item) => item.price_list_id === p);
    onChangeLocalBooking("price_list_name", price.price_list);
    onChangeLocalBooking("price_list_id", price.price_list_id);
    onChangeLocalBooking("city_id", price.city_id);
    changeCityId(price.city_id);
    queryClient.invalidateQueries([GET_CAR_LIST])
  }

  function getBookingTypeList() {
    return [...bookingTypeList.map((i, index) => ({ 
		id: index, 
		title: i.booking_type, 
		value: index }))];
  }

  function handleChangeType(typeIndex) {
    const type = bookingTypeList.find((item, index) => index === typeIndex);
    onChangeLocalBooking("booking_type", type.booking_type);
    changeShowDeliveryman(type.opens_delivery)
  }

  return (
    <main className="type-booking-wrap">
      <div className="type-booking__type">
          <div className="type-booking__form">
            <h3>Прайс</h3>
            <Select
              title="Тип брони"
              size="sm"
              disabled={!isEdit}
              options={getPriceList()}
              defaultValue={priceListName}
              onChange={handleChangePrice}
            />
          </div>
          <div className="type-booking__form">
            <h3>Тип брони</h3>
            <Select
              title="Тип брони"
              size="sm"
              disabled={!isEdit}
              options={getBookingTypeList()}
              defaultValue={bookingType}
              onChange={handleChangeType}
            />
          </div>
      </div>
      <div className="type-booking__deposit">
        <div className="type-booking__form">
          <h3>Залог</h3>
          <Input
            fill={1}
            placeholder="депозит"
            size="sm"
            defaultValue={depositLocal}
            disabled={!isEdit}
            id="deposit"
          />
        </div>
      </div>
    </main>
  );
}