import React, { useMemo } from "react";

//
import AgentChatTemplate from "../../../../../components/agent-chat-template/AgentChatTemplate";
import Card from "../../../../../components/card/Card";
import Checkbox from "../../../../../components/checkbox/Checkbox";
import AppealInput from "../../../../../components/appeal-input/AppealInput";
import { useAppContext } from "../../../../../store/Context";
import "../client-base-info/ClientBaseInfo.styles.scss"

export default function ClientBaseInfo({
  appeal,
  changeClient,
  ageExp,
  changeAgeExp,
  isShowScripts,
  staticDataLoading,
  agentChatTemplate,
  clientList,
}) {
  const {
    state: { user: agent },
  } = useAppContext();

  const chatListGenerator = useMemo(() => {
    if (!agentChatTemplate) return [];
    const list = [...agentChatTemplate.script_text];
    list[0] = list[0].replace("<name>", agent?.name || "Agent");
    list[1] = list[1].replace("<client_name>", appeal || "Client");
    return list;
  }, [agentChatTemplate, agent, appeal]);

  return (
    <>
      <>
        <Card radius={12}>
          <main className="client-base-info">
            {isShowScripts && (
              <AgentChatTemplate
                chat={chatListGenerator}
                radius="12px 12px 0 0"
                isLoading={staticDataLoading}
              />
            )}
            <div className="client-base-info__form">
              <AppealInput
                className="appeal__input"
                clientList={clientList}
                value={appeal}
                changeClient={changeClient}
                title="Как обращаться"
              />
              <div className="wrapper-age">
                <div className="age__exp__tabs">
                  <Checkbox
                    size={"sm"}
                    checked={ageExp}
                    onClick={() => changeAgeExp(!ageExp)}
                  />
                  <p>Возраст 25+ и стаж 5+</p>
                </div>
                <div className="rent__terms">
                  <p>
                  <a
                    href="https://avto-prokat.spb.ru/voprosy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Условия аренды
                  </a>
                  </p>
                  <div className="icon__wrap">
                    <i className="icon icon-xs icon-alert-circle"></i>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </Card>
      </>
    </>
  );
}
