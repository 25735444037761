import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";

//
import CatalogCarGrid from "../../../../../components/catalog-car-grid/CatalogCarGrid";
import CatalogCarRow from "../../../../../components/catalog-car-row/CatalogCarRow";
import CatalogCarRowMobile from "../../../../../components/catalog-car-row-mobile/CatalogCarRowMobile";
import IconWrapper from "../../../../../components/icon-wrapper/IconWrapper";
import ReqListEmpty from "../../../../../components/req-list-empty/ReqListEmpty";
import Loader from "../../../../../components/loader/Loader";
import Button from "../../../../../components/button/Button";
import CarFilterItem from "../../../../../components/car-filter-item/CarFilterItem";
import {
  staticDriveList,
  staticEngineTypeList,
  staticCarBodyList,
} from "../static";
import SortBtn from "../../../../../components/sort-btn/SortBtn";
import { useWindowDimensions } from "../../../../../hooks/useWindowDimensions";

export default function CatalogList({
  isLoading,
  carList,
  carsCount,
  filterCounter,
  onResetFilters,
  drive,
  onChangeDrive,
  engine,
  onChangeEngine,
  capacity,
  onChangeCapacity,
  carBody,
  onChangeCarBody,
  marks,
  onResetMarks,
  category,
  onChangeCategory,
  location,
  changeLocation,
  search,
  changeSearch,
  priceSort,
  changePriceSort
}) {
  const { width } = useWindowDimensions();

  const [isMobile, setIsMobile] = useState(false);
  const [viewType, setViewType] = useState("row");
  const [gridIsOpen, setGridIsOpen] = useState(null);
  
  useEffect(() => {
    width < 1280 ? setIsMobile(true) : setIsMobile(false)
  }, [width]);
  

  function changeViewType(v) {
    setViewType(v);
  }

  function changeGridIsOpen(g) {
    if (gridIsOpen === g) {
      setGridIsOpen(null);
      return;
    }
    if (gridIsOpen && Math.abs(gridIsOpen - g) < 3) {
      setGridIsOpen(null);
      setTimeout(() => {
        setGridIsOpen(g);
      }, 250);
      return;
    }
    setGridIsOpen(g);
  }

  return (
    <StyledCatalogList viewType={viewType}>
      <main className="catalog__list__main">
        {isLoading ? (
          <Loader />
        ) : (
          <>
          {!isMobile &&
            <nav className="catalog__list__nav">
              <section className="catalog__list__count">
                <h1>{carsCount}</h1>
                <p>
                  Всего <br /> автомобилей
                </p>
              </section>
              {!!carsCount ? (
                <section className="catalog__list__viewType">
                  <SortBtn
                    label="Цена"
                    value={priceSort}
                    onChange={changePriceSort}
                  />
                  <IconWrapper
                    icon="rows"
                    w={48}
                    h={48}
                    rounded={14}
                    bg={viewType === "row" ? "dark-5" : "light"}
                    border={viewType !== "row"}
                    onClick={() => changeViewType("row")}
                  />
                  <IconWrapper
                    icon="layout-grid"
                    w={48}
                    h={48}
                    rounded={14}
                    bg={viewType === "grid" ? "dark-5" : "light"}
                    border={viewType !== "grid"}
                    onClick={() => changeViewType("grid")}
                  />
                </section>
              ) : (
                <div style={{ width: "106px" }} />
              )}
            </nav>
          }
            {filterCounter > 0 && (
              <section className="catalog__list__filter">
                <Button
                  color="primary"
                  radius={24}
                  p={[9, 24]}
                  size="sm"
                  onClick={onResetFilters}
                >
                  Сбросить всё
                </Button>
                <CarFilterItem
                  title="Категория"
                  value={category}
                  onReset={() => onChangeCategory("")}
                />
                <CarFilterItem
                  title="Локация"
                  value={location}
                  onReset={() => changeLocation("")}
                />
                <CarFilterItem
                  title="Поиск"
                  value={[search]}
                  onReset={() => changeSearch("")}
                />
                <CarFilterItem
                  title="Привод"
                  list={staticDriveList}
                  value={drive}
                  onReset={() => onChangeDrive(0)}
                />
                <CarFilterItem
                  title="Двигатель"
                  list={staticEngineTypeList}
                  value={engine}
                  onReset={() => onChangeEngine(0)}
                />
                <CarFilterItem
                  title="Кузов"
                  list={staticCarBodyList}
                  value={carBody}
                  onReset={() => onChangeCarBody(0)}
                />
                <CarFilterItem
                  title="Мест"
                  value={capacity}
                  onReset={() => onChangeCapacity(0)}
                />
                <CarFilterItem
                  title="Марка"
                  mark={1}
                  value={marks}
                  onReset={onResetMarks}
                />
              </section>
            )}
            {carsCount === 0 ? (
              <ReqListEmpty
                notFound={1}
                title="Ничего не найдено"
                catalog={1}
              />
            ) : (
              <>
                <ul className="catalog__list__ul">
                  {carList.map((i, idx) => (
                    <li key={i.price_unit_id}>
                      {viewType === "grid" ? (
                        <CatalogCarGrid
                          idx={idx + 1}
                          car={i}
                          gridIsOpen={gridIsOpen}
                          onChangeGridIsOpen={changeGridIsOpen}
                        />
                      ) : (
                          isMobile ?
                          <CatalogCarRowMobile
                            car={i}
                          />
                          :
                          <CatalogCarRow
                            car={i}
                          />
                      )}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </>
        )}
      </main>
    </StyledCatalogList>
  );
}

const StyledCatalogList = styled.div`
  height: 100%;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--dark-15);
    border-radius: 3px;
  }

  .catalog__list {
    &__main {
      padding: 20px;
      padding-bottom: 40px;
      border-radius: inherit;
      overflow: hidden;
      position: relative;
      height: calc(100vh - 70px);
      min-height: calc(1080px - 70px);
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background: var(--dark-15);
        border-radius: 4px;
      }
    }

    &__nav {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      gap: 30px;
      margin-bottom: 20px;
      padding-left: 20px;
    }

    &__count {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 10px;

      h1 {
        font-size: 38px;
        font-weight: 600;
        line-height: 46px;
        letter-spacing: 0em;
        color: var(--dark);
      }

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        color: var(--dark-70);
      }
    }

    &__viewType {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 20px;
    }

    &__filter {
      margin-bottom: 10px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      gap: 10px;
    }

    &__ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      gap: 10px;
      position: relative;

      ${({ viewType }) =>
        viewType === "grid" &&
        css`
          display: -ms-grid;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 20px;
        `}
    }

    &__btn {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin-top: 30px;
    }
  }
`;
