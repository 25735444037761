import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

//
import ConfirmModal from "../confirm-modal/ConfirmModal";
import PopUp from "../popup/PopUp";
import ClientApi from "../../store/client/api";
import { GET_CLIENT_LIST } from "../../store/client/type";
import IconWrapper from "../icon-wrapper/IconWrapper";
import useModalKeyDown from "../../hooks/useModalKeyDown";

export default function ClientDeleteModal({ isOpen, onClose, clientId }) {
  const navigate = useNavigate();

  // query
  const queryClient = useQueryClient();
  const deleteClientMutation = useMutation((id) => ClientApi.deleteClient(id), {
    onSuccess: () => {
      onClose();
      navigate("/client");
      queryClient.resetQueries(GET_CLIENT_LIST, { exact: true });
    },
  });

  
  useModalKeyDown({
    isOpen: isOpen,
    handleClose: onClose,
    handleSubmit: () => onSubmit(),
  });

  function onSubmit() {
    deleteClientMutation.mutate(clientId);
  }

  if (deleteClientMutation.error)
    return (
      <PopUp isOpen={isOpen} onClose={onClose}>
        <StyledClientDelModal>
          <IconWrapper
            icon={"alert-circle icon-xxl"}
            w={110}
            h={110}
            bg="danger-20"
            color="danger-dark"
          />
          <p>{deleteClientMutation.error.response.data.error}</p>
        </StyledClientDelModal>
      </PopUp>
    );


  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      title="Удалить"
      text="Вы действительно хотите удалить клиентов из своего списка клиентов?"
      onSubmit={onSubmit}
      isLoading={deleteClientMutation.isLoading}
    />
  );
}

const StyledClientDelModal = styled.div`
  background-color: var(--light);
  padding: 40px 30px;
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  p {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
  }
`;
