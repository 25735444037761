import React, { useMemo, useState } from "react";
import cl from "classnames";
//
import ComparsionOptions from "../../../components/comparsion-options/ComparsionOptions";
import IconWrapper from "../../../components/icon-wrapper/IconWrapper";
import ChooseCarModal from "../../../components/choose-car-modal/ChooseCarModal";
import ComparsionCarCard from "../../../components/comparsion-car-card/ComparsionCarCard";
import { useAppContext } from "../../../store/Context";
import "./Comparsion.styles.scss";

export default function Comparsion() {
  // context
  const {
    state: { carComparsionList },
    fn: {
      addCarToCarComparsionList,
      deleteCarFromCarComparsionList,
      resetCarComparsionList,
    },
  } = useAppContext();

  // state
  const [hoverOption, setHoverOption] = useState(null);
  const [equipmentIsOpen, setEquipmentIsOpen] = useState(true);
  const [featureIsOpen, setFeatureIsOpen] = useState(true);
  const [modal, setModal] = useState(false);
  const [dragIdx, setDragIdx] = useState(null);
  const [placeIdx, setPlaceIdx] = useState(null);
  const [isOnlyDiff, setIsOnlyDiff] = useState(false);

  function changeHoverOption(ho) {
    setHoverOption(ho);
  }

  function changeModal(m) {
    setModal(m);
  }

  function changeEquipmentIsOpen(eio) {
    setEquipmentIsOpen(eio);
  }

  function changeFeatureIsOpen(fio) {
    setFeatureIsOpen(fio);
  }

  function dragStartHandler(e, idx) {
    setDragIdx(idx);
  }

  function dragEndHandler(e) {
    changeIndexsCarComparsionList();
    setDragIdx(null);
    setPlaceIdx(null);
  }

  function changeIndexsCarComparsionList() {
    const tempList = [...carComparsionList];
    const tempCar = tempList[dragIdx];
    tempList[dragIdx] = tempList[placeIdx];
    tempList[placeIdx] = tempCar;
    resetCarComparsionList(tempList);
  }

  function dragOverHandler(e, idx) {
    e.preventDefault();
    setPlaceIdx(idx);
  }

  function changeIsOnlyDiff(iod) {
    if (carComparsionList.length > 1) {
      setIsOnlyDiff(iod);
    }
  }

  const filterOptions = useMemo(() => {
    const obj = {
      p_sensors: [],
      air_cond: [],
      climate: [],
      cruise: [],
      back_cam: [],
      armrest: [],
      seat_heat: [],
      windshield_heat: [],
      color: [],
      capacity: [],
      max_speed: [],
      engine_power: [],
      engine_vol: [],
      tank_vol: [],
      trunk_vol: [],
      drive: [],
    };

    carComparsionList.forEach((i) => {
      if (!obj.p_sensors.includes(i.setup.p_sensors)) {
        obj.p_sensors.push(i.setup.p_sensors);
      }
      if (!obj.air_cond.includes(i.setup.air_cond)) {
        obj.air_cond.push(i.setup.air_cond);
      }
      if (!obj.climate.includes(i.setup.climate)) {
        obj.climate.push(i.setup.climate);
      }
      if (!obj.cruise.includes(i.setup.cruise)) {
        obj.cruise.push(i.setup.cruise);
      }
      if (!obj.back_cam.includes(i.setup.back_cam)) {
        obj.back_cam.push(i.setup.back_cam);
      }
      if (!obj.armrest.includes(i.setup.armrest)) {
        obj.armrest.push(i.setup.armrest);
      }
      if (!obj.seat_heat.includes(i.setup.seat_heat)) {
        obj.seat_heat.push(i.setup.seat_heat);
      }
      if (!obj.windshield_heat.includes(i.setup.windshield_heat)) {
        obj.windshield_heat.push(i.setup.windshield_heat);
      }
      //
      if (!obj.color.includes(i.parameters.color)) {
        obj.color.push(i.parameters.color);
      }
      if (!obj.capacity.includes(i.capacity)) {
        obj.capacity.push(i.capacity);
      }
      if (!obj.max_speed.includes(i.parameters.max_speed)) {
        obj.max_speed.push(i.parameters.max_speed);
      }
      if (!obj.engine_power.includes(i.parameters.engine_power)) {
        obj.engine_power.push(i.parameters.engine_power);
      }
      if (!obj.engine_vol.includes(i.parameters.engine_vol)) {
        obj.engine_vol.push(i.parameters.engine_vol);
      }
      if (!obj.tank_vol.includes(i.parameters.tank_vol)) {
        obj.tank_vol.push(i.parameters.tank_vol);
      }
      if (!obj.trunk_vol.includes(i.parameters.trunk_vol)) {
        obj.trunk_vol.push(i.parameters.trunk_vol);
      }
      if (!obj.drive.includes(i.drive)) {
        obj.drive.push(i.drive);
      }
    });

    return obj;
  }, [carComparsionList]);

  return (
    <>
      <main className="comparsion__main">
        <ComparsionOptions
          hoverOption={hoverOption}
          onChangeHoverOption={changeHoverOption}
          count={carComparsionList.length}
          equipmentIsOpen={equipmentIsOpen}
          onChangeEquipmentIsOpen={changeEquipmentIsOpen}
          featureIsOpen={featureIsOpen}
          changeFeatureIsOpen={changeFeatureIsOpen}
          resetCarComparsionList={(v) => {
            resetCarComparsionList(v);
            changeIsOnlyDiff(false);
          }}
          isOnlyDiff={isOnlyDiff}
          changeIsOnlyDiff={changeIsOnlyDiff}
          filterOptions={filterOptions}
        />
        <div className="comparsion__main__right">
          <ul className="comparsion__main__list">
            {carComparsionList.map((i, idx) => (
              <li
                key={i.price_unit_id}
                draggable={true}
                onDragStart={(e) => dragStartHandler(e, idx)}
                onDragEnd={(e) => dragEndHandler(e)}
                onDragOver={(e) => dragOverHandler(e, idx)}
                className={cl({
                  isPlace: dragIdx !== null && dragIdx !== idx,
                  overPlace:
                    placeIdx !== null &&
                    placeIdx !== dragIdx &&
                    placeIdx === idx,
                })}
              >
                <ComparsionCarCard
                  car={i}
                  hoverOption={hoverOption}
                  equipmentIsOpen={equipmentIsOpen}
                  featureIsOpen={featureIsOpen}
                  lastElement={idx === carComparsionList.length - 1}
                  onDeleteCarFromCarList={deleteCarFromCarComparsionList}
                  isOnlyDiff={isOnlyDiff}
                  filterOptions={filterOptions}
                  idx={idx}
                />
              </li>
            ))}
            <li>
              <div className="add__car__btn" onClick={() => changeModal(true)}>
                <IconWrapper
                  icon="plus"
                  w={48}
                  h={48}
                  bg="primary"
                  color="light"
                />
                <p>Добавить к сравнению</p>
              </div>
            </li>
          </ul>
        </div>
      </main>
      {modal && (
        <ChooseCarModal
          isOpen={modal}
          onClose={() => changeModal(false)}
          car={carComparsionList}
          onChangeCar={addCarToCarComparsionList}
        />
      )}
    </>
  );
}

