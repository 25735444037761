import React from "react";

//
import Input from "../input/Input";
import { filterPhoneNumber } from "../../util/formatters";

export default function InputPhone({ value, onChange, ...props }) {
  function getFormattedNum(num) {
    if (!num) return num;
    // +7 (999) 087 18 89
    num = filterPhoneNumber(num);
    let str = "+";

    for (let i = 0; i < num.length; i++) {
      if (i === 1) {
        str += " (" + num[i];
        continue;
      }
      if (i === 4) {
        str += ") " + num[i];
        continue;
      }
      if (i === 7) {
        str += " " + num[i];
        continue;
      }
      if (i === 9) {
        str += " " + num[i];
        continue;
      }

      str += num[i];
    }

    return str;
  }

  function changePhone(e) {
    let { value } = e.target;
    value = filterPhoneNumber(value);
    if (value.length > 11) return;

    if (value && value[0] !== "7") {
      value = "7" + value;
    }

    e.target.value = value;
    onChange(e);
  }

  return (
    <Input value={getFormattedNum(value)} onChange={changePhone} {...props} />
  );
}
