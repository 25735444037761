import { useQuery } from "react-query";

//
import Api from "./api";
import { GET_CLIENT_LIST, GET_REQUEST_BY_CLIENT_ID } from "./type";

export function useGetClientList(options = {}) {
  return useQuery(GET_CLIENT_LIST, () => Api.getClientList(), { ...options });
}

export function useGetRequestByClientId(clientId, options = {}) {
  return useQuery(
    [GET_REQUEST_BY_CLIENT_ID, clientId],
    () => Api.getRequestByClientId(clientId),
    { ...options }
  );
}
