import React, { useState } from "react";
import cl from "classnames";
import { Collapse } from "react-collapse";

//
import Card from "../card/Card";
import Checkbox from "../checkbox/Checkbox";
import "./CarFilterCollapse.styles.scss";

export default function CarFilterCollapse({
  title,
  options,
  value = [],
  onChange,
}) {
  const [isOpen, setIsOpen] = useState(false);

  function toggleIsOpen() {
    setIsOpen((p) => !p);
  }

  return (
    <>
      <Card radius={isOpen ? 20 : 14}>
        <main className="car__filter__collapse__main">
          <nav className="car__filter__collapse__nav" onClick={toggleIsOpen}>
            <h1>{title}</h1>
            <i
              className="icon icon-sm icon-chevron-down"
              style={{ transform: isOpen ? "rotate(-180deg)" : "rotate(0deg)" }}
            />
          </nav>
          <div className="car__filter__collapse__list">
            <Collapse isOpened={isOpen}>
              <ul className="car__filter__collapse__ul">
                {options?.map((i) => {
                  let active =
                    (!i.value && !value.length) || value.includes(i.value);
                  return (
                    <li
                      key={i.id}
                      onClick={() => onChange(i.value)}
                      className={cl({
                        active,
                      })}
                    >
                      <Checkbox checked={active} />
                      <p>{i.title}</p>
                    </li>
                  );
                })}
              </ul>
            </Collapse>
          </div>
        </main>
      </Card>
    </>
  );
}

