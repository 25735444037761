import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { useLocation } from "react-router-dom";

//
import CarCard from "../catalog-car-info-card/CarCard";
import { useMemo } from "react";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import CarCardMobile from "../catalog-car-info-card/car-card-mobile/CarCardMobile";

export default function CatalogCarList({
  grid,
  car,
  inModal,
  selectedCar,
  onChangeSelectedCar,
  reqCreate,
  changeIsOpenModal,
  changeIsOpenCatalogCar
}) {
  const { pathname } = useLocation();
  const { width } = useWindowDimensions();

  const [isMobile, setIsMobile] = useState(false);
  
  useEffect(() => {
    width < 1280 ? setIsMobile(true) : setIsMobile(false)
  }, [width]);

  const [step, setStep] = useState(0);

  const limit = pathname === "/catalog" ? 5 : 3;

  function onPrevStep() {
    if (step < 1) return;
    setStep((p) => p - 1);
  }

  function onNextStep() {
    if (step + limit >= car.car_list.length) return;
    setStep((p) => p + 1);
  }

  const readyCarList = useMemo(() => {
    if (!car?.car_list) return [];
    const obj = {
      свободна: 1,
      бронь: 2,
      занята: 3,
    };
    return car.car_list.sort((a, b) => obj[a.status] - obj[b.status]);
  }, [car]);

  return (
    <>
      <StyledCatalogCarList grid={grid} inModal={inModal} reqCreate={reqCreate}>
        {!isMobile && step > 0 && (
          <li className="control__btn prev" onClick={onPrevStep}>
            <i className="icon icon-lg icon-chevron-right"></i>
          </li>
        )}
        {readyCarList.map((i, idx) => (
          <li key={idx}>
            {
              isMobile ?
              <CarCardMobile
                inModal={inModal}
                car={{ ...car, ...i }}
                selectedCar={selectedCar}
                onChangeSelectedCar={onChangeSelectedCar}
                reqCreate={reqCreate}
                changeIsOpenCatalogCar={changeIsOpenCatalogCar}
                changeIsOpenModal={changeIsOpenModal}
              />
              :
              <CarCard
                idx={idx}
                step={step}
                inModal={inModal}
                car={{ ...car, ...i }}
                grid={grid}
                selectedCar={selectedCar}
                onChangeSelectedCar={onChangeSelectedCar}
                reqCreate={reqCreate}
              />

            }
          </li>
        ))}
        {!isMobile && step + limit < car.car_list.length && (
          <li className="control__btn next" onClick={onNextStep}>
            <i className="icon icon-lg icon-chevron-right"></i>
          </li>
        )}
      </StyledCatalogCarList>
    </>
  );
}

const StyledCatalogCarList = styled.ul`

@media (min-width: 1280px) {
  height: 440px !important;
  position: relative;
  width: calc(100% + 40px);
  transform: translateX(-20px);

  .control__btn {
    position: absolute;
    background-color: var(--primary);
    width: 104px;
    box-shadow: 0px 0px 20px rgba(62, 81, 112, 0.1);
    border-radius: 26px;
    padding: 11px;
    line-height: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
    cursor: pointer;

    i {
      background-color: var(--light);
    }

    &.next {
      right: -52px;
    }

    &.prev {
      left: -52px;
      display: flex;
      justify-content: flex-end;

      i {
        transform: rotate(180deg);
      }
    }
  }

    ${({ grid }) =>
      grid &&
      css`
        width: calc(100% + 60px);
        transform: translateX(-30px);
      `}
  
    ${({ inModal }) =>
      inModal &&
      css`
        height: 485px;
      `}
  
      ${({ reqCreate }) =>
      reqCreate &&
      css`
        height: 395px !important;
      `}
  }

`;
