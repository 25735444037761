import React, { useState } from "react";
import styled from "styled-components";
import { useMutation, useQueryClient } from "react-query";

//
import PopUp from "../popup/PopUp";
import IconWrapper from "../icon-wrapper/IconWrapper";
import InputPhone from "../input-phone/InputPhone";
import TextArea from "../textarea/TextArea";
import Button from "../button/Button";
import RequestApi from "../../store/request/api";
import Loader from "../loader/Loader";
import { useGetClientList } from "../../store/client/hook";
import { GET_REQUEST_LIST } from "../../store/dashboard/type";
import { GET_CLIENT_LIST } from "../../store/client/type";
import AppealInput from "../appeal-input/AppealInput";

const defaultClientObj = {
  client_id: null,
  client_name: "",
  phone: "",
  source: "",
  wish: "",
};

export default function SendLeadModal({ isOpen, onClose }) {
  const [isSuccess, setIsSuccess] = useState("");
  const [client, setClient] = useState({
    ...defaultClientObj,
  });
  const [errorInfo, setErrorInfo] = useState({
    name: "",
    phone: "",
  });

  const queryClient = useQueryClient();
  const postLead = useMutation((body) => RequestApi.postRequest(body), {
    onSuccess: () => {
      changeIsSuccess("ok");
      setClient({ ...defaultClientObj });
      queryClient.resetQueries(GET_REQUEST_LIST, { exact: true });
      queryClient.resetQueries(GET_CLIENT_LIST, { exact: true });
    },
    onError: () => {
      changeIsSuccess("error");
    },
  });
  const { data: clientList } = useGetClientList();

  function changeIsSuccess(s) {
    setIsSuccess(s);
  }

  function changeInfo(e) {
    const { value, name } = e.target;
    setClient((p) => ({ ...p, [name]: value }));
    setErrorInfo((p) => ({ ...p, [name]: "" }));
  }

  function handleSend(e) {
    e.preventDefault();
    let isErr = false;
    if (client.client_name.length < 3) {
      setErrorInfo((p) => ({ ...p, name: "error" }));
      isErr = true;
    }
    if (client.phone.length < 11) {
      setErrorInfo((p) => ({ ...p, phone: "error" }));
      isErr = true;
    }
    if (!isErr) {
      const obj = {
        ...client,
        price_list_id: null,
        is_archived: false,
        status_id: 3,
        email: "",
        birthdate: 0,
        note: "",
        doc_photos: [],
        price_unit_id: null,
        gosnomer: null,
        tariff: "200",
        price_daily: 0,
        price_total: 0,
        vidacha: {
          point_id: null,
          point_name: null,
          point_type: null,
          address: "",
          date: 0,
        },
        vozvrat: {
          point_id: null,
          point_name: null,
          point_type: null,
          address: "",
          date: 0,
        },
        options: [],
      };

      postLead.mutate(obj);
    }
  }

  function handleClose() {
    onClose();
  }

  function changeClient(obj) {
    setClient((p) => ({ ...p, ...obj }));
  }

  return (
    <PopUp isOpen={isOpen} onClose={handleClose}>
      <StyledSendLeadModal>
        <nav className="send__lead__modal__top">
          <h1>Передать лида</h1>
          <IconWrapper
            icon="close icon-lg"
            w={52}
            h={52}
            rounded={0}
            onClick={handleClose}
          />
        </nav>
        {isSuccess !== "" ? (
          <div className="send__lead__modal__success">
            <IconWrapper
              icon={
                isSuccess === "ok"
                  ? "check-circle icon-xxl"
                  : "alert-circle icon-xxl"
              }
              w={110}
              h={110}
              bg={isSuccess === "ok" ? "success-20" : "danger-10"}
              color={isSuccess === "ok" ? "success-dark" : "danger-dark"}
            />
            <p
              dangerouslySetInnerHTML={{
                __html:
                  isSuccess === "ok"
                    ? "Лид отправлен"
                    : "Нет такого клиента в ситеме <br/> сначала создайте клиент",
              }}
            ></p>
            <div className="btns__grp">
              <Button
                p={[14, 34]}
                color="light"
                radius={12}
                fw={500}
                border={1}
                onClick={() => changeIsSuccess("")}
              >
                Назад
              </Button>
              <Button
                p={[14, 48]}
                color="primary"
                radius={12}
                fw={500}
                onClick={onClose}
              >
                Ок
              </Button>
            </div>
          </div>
        ) : (
          <form className="send__lead__modal__form">
            <ul className="send__lead__modal__form__list">
              <li>
                <AppealInput
                  title="ФИО"
                  type="lead"
                  value={client.client_name}
                  clientList={clientList}
                  changeClient={changeClient}
                />
              </li>
              <li>
                <p>Телефон</p>
                <InputPhone
                  placeholder="+7"
                  fill={1}
                  value={client.phone}
                  name="phone"
                  onChange={changeInfo}
                  error={errorInfo.phone}
                />
              </li>
              <li>
                <p>Комментария</p>
                <TextArea
                  value={client.wish}
                  name="wish"
                  onChange={changeInfo}
                />
              </li>
            </ul>
            <div className="send__lead__modal__form__btn">
              <Button
                type="submit"
                p={[14, 26]}
                radius={14}
                color="primary"
                fW={500}
                onClick={handleSend}
              >
                {postLead.isLoading && (
                  <Loader bg="primary" color="light" w={24} h={24} size={3} />
                )}
                Oтправить
              </Button>
            </div>
          </form>
        )}
      </StyledSendLeadModal>
    </PopUp>
  );
}

const StyledSendLeadModal = styled.div`
  border-radius: 30px;
  background-color: var(--light);
  padding: 30px;
  min-width: 530px;
  min-height: 400px;

  .send__lead__modal {
    &__top {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      gap: 30px;
      margin-bottom: 30px;
      padding-left: 30px;

      h1 {
        font-size: 26px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        color: var(--dark);
      }
    }

    &__form {
      padding: 0 15px;

      &__list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 30px;

        & > li {
          & > p {
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0em;
            color: var(--dark);
            margin-bottom: 10px;
            padding-left: 20px;
          }
        }
      }

      &__btn {
        width: max-content;
        margin: 0 auto;
      }
    }

    &__success {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 30px;
      padding-top: 26px;

      p {
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        color: var(--dark-70);
        text-align: center;
      }

      .btns__grp {
        margin-top: 25px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 10px;
      }
    }
  }
`;
