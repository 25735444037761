import React, { useEffect, useMemo, useState } from "react";
//
import Select from "../../../../../components/select/Select";
import Input from "../../../../../components/input/Input";
import Search from "../../../../../components/search/Search";
import Checkbox from "../../../../../components/checkbox/Checkbox";
import { staticStatusList } from "../static";
import { useAppContext } from "../../../../../store/Context";
import "./CatalogTop.styles.scss";
import IconWrapper from "../../../../../components/icon-wrapper/IconWrapper";
import Modal from "../../../../../components/modal/Modal";
import Button from "../../../../../components/button/Button";
import BlockFilterMobile from "../../../../../components/block-filter-mobile/BlockFilterMobile";

export default function CatalogTop({
  status,
  onChangeStatus,
  search,
  onChangeSearch,
  isAuto,
  onChangeIsAuto,
  ageExp,
  changeAgeExp,
  onResetQueryGetCarList,
  priceSort,
  changePriceSort,
  isFilterOpen,
  changeIsFilterOpen,
}) {
  // context
  const {
    state: { cityList, cityId, isMobile },
    fn: { changeCityId },
  } = useAppContext();


  //   state
  const [isSearch, setIsSearch] = useState(false);
  const [isOpenRefresh, setIsOpenRefresh] = useState(false);
  const [cacheTime, setCacheTime] = useState("00:00");

  useEffect(() => {
    changeCacheTime();
  }, []);

  function changeCacheTime() {
    const now = new Date();
    let hours = now.getHours();
    hours = hours < 10 ? "0" + hours : hours;
    let min = now.getMinutes();
    min = min < 10 ? "0" + min : min;
    setCacheTime(`${hours}:${min}`);
  }

  function handleClick() {
    onResetQueryGetCarList();
    changeCacheTime();
    setIsOpenRefresh(false)
  }

  //   method
  function changeIsSearch(v) {
    setIsSearch(v);
  }


  function getKppOptions() {
    return [
      { id: 1, title: "Любой", value: "" },
      { id: 2, title: "Aвтомат", value: "auto" },
      { id: 3, title: "Mеханика", value: "mechanic" },
    ];
  }

  const citySelectOptions = useMemo(() => {
    return cityList.map((i) => ({ id: i.id, title: i.name, value: i.id }));
  }, [cityList]);

  return (
    <div className='catalog__top__wrap' isSearch={isSearch}>
      {isMobile && 
        <div className="catalog__top__title">
        <h1>Каталог</h1>
        <IconWrapper
          icon="refresh icon-sm"
          border={1}
          rounded={12}
          onClick={() => setIsOpenRefresh(true)}
        />
      </div>}
      {
      !isMobile &&
      <main className={`catalog__top__main ${isSearch && "catalog__top__search"}`}>
        {isSearch ? (
          <Input
            fill={1}
            placeholder="Поиск автомобиля"
            icon="search"
            withValueEffect={1}
            value={search ?? ""}
            onChange={(e) => onChangeSearch(e.target.value)}
          />
        ) : (
          <>
            <div>
              <Select
                title="Город"
                options={citySelectOptions}
                value={cityId}
                onChange={changeCityId}
              />
            </div>
            <div>
              <Select
                title="Статус"
                options={[...staticStatusList]}
                value={status}
                onChange={onChangeStatus}
              />
            </div>
            <div>
              <Select
                title="Коробка передач"
                options={getKppOptions()}
                value={isAuto}
                onChange={onChangeIsAuto}
              />
            </div>
            <div className="age_exp_checkbox__wrap">
              <Checkbox
                checked={ageExp}
                onClick={() => changeAgeExp(!ageExp)}
              />
              <p>25/5+</p>
            </div>

          </>
        )}
        <Search isOpen={isSearch} changeSearchIsOpen={changeIsSearch} />
      </main>
      }
      {
        isMobile &&
        <>
        <div className="catalog__top__main-mobile">
          <div>
              <Select
                title="Город"
                options={citySelectOptions}
                value={cityId}
                onChange={changeCityId}
              />
            </div>
            <div>
              <Select
                title="Статус"
                options={[...staticStatusList]}
                value={status}
                onChange={onChangeStatus}
              />
            </div>
            <div>
              <Select
                title="Коробка передач"
                options={getKppOptions()}
                value={isAuto}
                onChange={onChangeIsAuto}
              />
            </div>
        </div>
        <div className="catalog__top__filter">
            <div className="age_exp_checkbox__wrap">
              <Checkbox
                checked={ageExp}
                onClick={() => changeAgeExp(!ageExp)}
              />
              <p>25/5+</p>
            </div>
            <BlockFilterMobile 
              priceSort={priceSort}
              changePriceSort={changePriceSort}
              isFilterOpen={isFilterOpen}
              changeIsFilterOpen={changeIsFilterOpen}
              search={search}
              onChangeSearch={onChangeSearch}
            />
        </div>
        </>
      }
      {isMobile && 
			<Modal
				isOpen={isOpenRefresh}
				onClose={() => setIsOpenRefresh(false)}
				title={"Обновить каталог"}
				height="Sm"
				direction="Bottom"
			>
				<div className="modal-refresh">
					<p>Последнее обновление <br /> каталога сегодня в{" "}
            {cacheTime}
					</p>
					<Button
						color={"primary"} 
						fill={"fill"}
						onClick={handleClick}>
						Обновить
					</Button>
					</div>
			</Modal>}
    </div>
  );
}
