import React, { useEffect, useState } from "react";
import styled from "styled-components";

//
import Card from "../../components/card/Card";
import IconWrapper from "../../components/icon-wrapper/IconWrapper";

export default function CatalogRefreshCard({ onClick }) {
  const [cacheTime, setCacheTime] = useState("00:00");

  useEffect(() => {
    changeCacheTime();
  }, []);

  function changeCacheTime() {
    const now = new Date();
    let hours = now.getHours();
    hours = hours < 10 ? "0" + hours : hours;
    let min = now.getMinutes();
    min = min < 10 ? "0" + min : min;
    setCacheTime(`${hours}:${min}`);
  }

  function handleClick() {
    onClick();
    changeCacheTime();
  }

  return (
    <StyledCatalogRefreshCard>
      <Card radius={16}>
        <main className="catalog__refresh__card__main">
          <p>Последнее обновление каталога сегодня в {cacheTime}</p>
          <IconWrapper
            w={48}
            h={48}
            icon="refresh"
            bg="primary"
            color="light"
            onClick={handleClick}
          />
        </main>
      </Card>
    </StyledCatalogRefreshCard>
  );
}

const StyledCatalogRefreshCard = styled.div`
  .catalog__refresh__card__main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 20px;
    padding: 20px;

    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0em;
      color: var(--dark);
      opacity: 0.5;
    }
  }
`;
