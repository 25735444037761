import React from "react";
import styled from "styled-components";
import cl from "classnames";
import { Collapse } from "react-collapse";

//
import { staticEquipmentList, staticFeatureList } from "./static";
import Button from "../button/Button";
import Switch from "../switch/Switch";
import { useAppContext } from "../../store/Context";

export default function ComparsionOptions({
  hoverOption,
  onChangeHoverOption,
  count,
  equipmentIsOpen,
  onChangeEquipmentIsOpen,
  featureIsOpen,
  changeFeatureIsOpen,
  resetCarComparsionList,
  isOnlyDiff,
  changeIsOnlyDiff,
  filterOptions,
}) {
    // context
    const {
      state: { isMobile }
    } = useAppContext();

  return (
    <StyledComparsionOptions>
      <div className="comparsion__options">
        <h1>Сравнение авто</h1>
        <div className="comparsion__options__actions">
          <div className="act__top">
            <h1>
              <span>{count}</span> Авто
            </h1>
            <Button
              p={[14, 16, 14, 26]}
              color="light"
              border={1}
              radius={12}
              fW={500}
              onClick={() => resetCarComparsionList([])}
            >
              Сбросить <i className="icon icon-sm icon-trash"></i>{" "}
            </Button>
          </div>
          <div className="act__bottom">
            <p>Только отличие</p>
            <Switch value={isOnlyDiff} onChange={changeIsOnlyDiff} />
          </div>
        </div>
        {
          !isMobile && 
          <div className="comparsion__options__lists">
            <ComparsionOptionsList
              title="Комплектация"
              list={staticEquipmentList}
              value={hoverOption}
              onChange={onChangeHoverOption}
              isOpen={equipmentIsOpen}
              onToggleOpen={onChangeEquipmentIsOpen}
              isOnlyDiff={isOnlyDiff}
              filterOptions={filterOptions}
            />
            <ComparsionOptionsList
              title="Характеристики"
              list={staticFeatureList}
              value={hoverOption}
              onChange={onChangeHoverOption}
              isOpen={featureIsOpen}
              onToggleOpen={changeFeatureIsOpen}
              isOnlyDiff={isOnlyDiff}
              filterOptions={filterOptions}
            />
          </div>
        }
      </div>
    </StyledComparsionOptions>
  );
}

const StyledComparsionOptions = styled.div`
  height: 100%;

  .comparsion__options {
    & > h1 {
      font-size: 26px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0em;
      color: var(--dark);
      margin: 20px;
    }

    &__actions {
      background: var(--light);
      box-shadow: 0px 2px 5px var(--dark-5);
      border-radius: 16px;
      margin-bottom: 12px;

      .act__top {
        padding: 10px 10px 20px 20px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        gap: 10px;

        h1 {
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0em;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          gap: 10px;
          color: var(--dark-50);

          span {
            font-size: 24px;
            font-weight: 600;
            line-height: 29px;
            color: var(--dark);
          }
        }
      }

      .act__bottom {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        gap: 10px;
        padding: 20px;
        padding-top: 0px;

        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0em;
          color: var(--dark);
        }
      }
    }

    &__lists {
      background-color: var(--light);
      box-shadow: 0px 2px 5px var(--dark-5);
      border-radius: 16px;
      padding: 10px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      gap: 20px;
    }
  }
`;

function ComparsionOptionsList({
  title,
  list,
  value,
  onChange,
  isOpen,
  onToggleOpen,
  isOnlyDiff,
  filterOptions,
  ...props
}) {
  return (
    <StyledComparsionOptionsList {...props}>
      <nav
        className="comparsion__option__list__nav"
        // onClick={() => onToggleOpen(!isOpen)}
      >
        <h1>{title}</h1>
        {/* <i
          className="icon icon-chevron-down"
          style={{ transform: `rotate(${isOpen ? "-180deg" : "0deg"})` }}
        ></i> */}
      </nav>
      <Collapse isOpened={isOpen}>
        <ul className="comparsion__option__list__ul">
          {list.map((i) => {
            if (isOnlyDiff && filterOptions[i.value].length <= 1) return null;
            return (
              <li
                key={i.id}
                className={cl({
                  hover: value === i.value,
                })}
                onMouseOver={() => onChange(i.value)}
                onMouseOut={() => onChange(null)}
              >
                {i.title}
              </li>
            );
          })}
        </ul>
      </Collapse>
    </StyledComparsionOptionsList>
  );
}

const StyledComparsionOptionsList = styled.div`
  .comparsion__option__list {
    &__nav {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 20px;
      padding-top: 10px;

      h1 {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark);
      }

      i {
        background-color: var(--dark);
      }
    }

    &__ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      gap: 5px;

      li {
        padding: 16px 20px;
        border-radius: 12px;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        color: var(--dark-70);
        cursor: pointer;
        transition: 0.2s linear;

        &:nth-child(2n) {
          background-color: var(--dark-3);
        }

        &.hover {
          background-color: var(--primary-5);
          color: var(--primary);

          &:hover {
            background-color: var(--primary-10);
          }
        }
      }
    }
  }
`;
