import React from "react";
import styled from "styled-components";

//
import PopUp from "../../../../components/popup/PopUp";
import IconWrapper from "../../../../components/icon-wrapper/IconWrapper";
import Button from "../../../../components/button/Button";

export default function StatusCheckFailModal({
  isOpen,
  onClose,
  data,
  onSubmit,
}) {
  return (
    <PopUp isOpen={isOpen} onClose={onClose}>
      <StyledStatusCheckFailModal>
        <nav>
          <h1>{data?.title}</h1>
          <IconWrapper icon="close icon-lg" w={52} h={52} onClick={onClose} />
        </nav>
        <main>
          <IconWrapper
            icon={
              data?.type === "success"
                ? "check icon-xxl"
                : "info-octagon icon-xxl"
            }
            w={110}
            h={110}
            bg={data?.type === "success" ? "success-20" : "danger-10"}
            color={data?.type === "success" ? "success-dark" : "danger-dark"}
          />
          <p>{data?.text}</p>
        </main>
        <footer>
          <Button
            p={[14, 62]}
            fW={500}
            color="primary"
            radius={12}
            onClick={data?.type === "success" ? onSubmit : onClose}
          >
            Ок
          </Button>
        </footer>
      </StyledStatusCheckFailModal>
    </PopUp>
  );
}

const StyledStatusCheckFailModal = styled.div`
  background-color: var(--light);
  padding: 20px;
  border-radius: 20px;
  min-width: 450px;

  @media (max-width: 768px) {
    min-width: 375px;
  }

  & > nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    h1 {
      font-size: 26px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0em;
      color: var(--dark);
      padding-left: 20px;
    }
  }

  & > main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 30px;
    margin: 60px;
  }

  & > footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
`;
