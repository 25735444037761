import React from "react";
import { useEffect, useCallback } from "react";

//
import PopUp from "../popup/PopUp";
import Card from "../card/Card";
import IconWrapper from "../icon-wrapper/IconWrapper";
import Button from "../button/Button";
import Loader from "../loader/Loader";
import "./ConfirmModal.styles.scss"

export default function ConfirmModal({
  isOpen,
  onClose,
  title,
  text,
  onSubmit,
  isLoading,
}) {
  
  return (
    <PopUp isOpen={isOpen} onClose={onClose}>
        <Card>
          <div className="confirm__modal__content">
            <nav className="confirm__modal__nav">
              <h1>{title}</h1>
              <IconWrapper
                icon="close icon-lg"
                w={52}
                h={52}
                rounded={0}
                onClick={onClose}
              />
            </nav>
            <main className="confirm__modal__main">
              <p>{text}</p>
            </main>
            <footer className="confirm__modal__footer">
              <Button
                textUpperCase={1}
                color="light"
                border={1}
                p={[16, 30]}
                fill={"fill"}
                fW={500}
                onClick={onClose}
                radius={14}
              >
                Нет
              </Button>
              <Button
                textUpperCase={1}
                color="danger"
                p={[16, 30]}
                fill={"fill"}
                fW={500}
                radius={14}
                onClick={onSubmit}
              >
                {isLoading && (
                  <Loader bg="danger" color="light" w={24} h={24} size={3} />
                )}
                Да
              </Button>
            </footer>
          </div>
        </Card>
    </PopUp>
  );
}
