import api from "../../service/axios";

class UserApi {
  static getUserInfo() {
    return api.get("/getUser");
  }

  static postAvatar(body) {
    return api.post("/postAvatar", body);
  }
}

export default UserApi;
