import React from "react";
import styled, { keyframes } from "styled-components";

export default function Loader({
  w = 35,
  h = 35,
  size = 4,
  bg = "light",
  color = "primary",
  radius = 30,
  zIndex = 2,
}) {
  return (
    <StyledLoader
      w={w}
      h={h}
      size={size}
      color={color}
      bg={bg}
      radius={radius}
      zIndex={zIndex}
    >
      <div className="anim__indicator"></div>
    </StyledLoader>
  );
}

const LoaderAnim = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const StyledLoader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: progress;
  background-color: var(--${({ bg }) => bg});
  border-radius: ${({ radius }) => radius + "px"};
  z-index: ${({ zIndex }) => zIndex};

  .anim__indicator {
    width: ${({ w }) => w + "px"};
    height: ${({ h }) => h + "px"};
    border-radius: 50%;
    border: ${({ size }) => size + "px"} solid var(--${({ color }) => color});
    border-top-color: transparent;
    animation-name: ${LoaderAnim};
    animation-iteration-count: infinite;
    animation-duration: 0.75s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }
`;
