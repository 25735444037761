import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

//
import contact from "../../asset/img/contact.svg";
import organization from "../../asset/img/organization.svg";
import location from "../../asset/img/location.svg";
import Avatar from "../avatar/Avatar";
import Button from "../button/Button";
import UserApi from "../../store/user/api";
import AuthApi from "../../store/auth/api";
import Loader from "../loader/Loader";
import { formatPhoneNumber } from "../../util/formatters";
import { GET_USER_INFO } from "../../store/user/type";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { useAppContext } from "../../store/Context";
import "./UserInfoModal.styles.scss";
import { useNavigate } from "react-router-dom";

export default function UserInfoModal() {
  // helper
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  // context
  const {
    state: { user },
    fn: { changeIsAuth, changeUserInfoModal },
  } = useAppContext();

  // state
  const [img_url, setImgUrl] = useState("");

  // query
  const queryClient = useQueryClient();
  const mutation = useMutation(
    () => UserApi.postAvatar({ avatar_url: img_url }),
    {
      onSuccess,
    }
  );
  const logOutMutation = useMutation((body) => AuthApi.logout(body), {
    onSuccess: () => {
      changeUserInfoModal(false);
      changeIsAuth(false);
      queryClient.clear();
      localStorage.clear();
    },
  });

  // watcher
  useEffect(() => {
    if (img_url) {
      mutation.mutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [img_url]);

  // method
  function onLogOut() {
    logOutMutation.mutate({ login: user.user_id });
  }

  function onSuccess() {
    queryClient.setQueryData(GET_USER_INFO, (user_info) => {
      let tempObj = { ...user_info.data.data, avatar: img_url };

      return {
        ...user_info,
        data: {
          data: tempObj,
        },
      };
    });
  }

  function changeImgUrl(url) {
    setImgUrl(url);
  }

  return (
    <div className="user-info-modal">
      <main>
        <section>
          <div className="user__server__infos">
            <Avatar avatar={user?.avatar} changeImgUrl={changeImgUrl} />
            <h1>{user?.name}</h1>
          </div>
          <div className="edit__logout__wrapper">
            {/* <IconWrapper icon='icon icon-lg icon-pencil' w={52} h={52} rounded={16} border={1} onClick={() => changeShowEditForm(true)} /> */}
            <Button color="danger" onClick={onLogOut} fW={500}>
              {logOutMutation.isLoading && (
                <Loader
                  radius={width > 768 ? 16 : 24}
                  bg="danger"
                  color="light"
                  w={24}
                  h={24}
                  size={3}
                />
              )}
              {width > 768 ? "Выйти" : ""}
              <i
                className="icon icon-sm icon-logout"
                style={{ background: "var(--light)" }}
              ></i>
            </Button>
          </div>
        </section>
        <section>
          <ul className="info__list">
            <li>
              <img src={contact} alt="contact" />
              <div>
                <h1>Контакты</h1>
                <p>
                  {formatPhoneNumber(user?.phone)} <br /> {user?.email}
                </p>
              </div>
            </li>
            <li>
              <img src={organization} alt="organization" />
              <div>
                <h1>Организация</h1>
                <ul className="organition__list">
                  {user?.org?.map((i) => (
                    <li key={i}>{i}</li>
                  ))}
                </ul>
              </div>
            </li>
            <li>
              <img src={location} alt="location" />
              <div>
                <h1>Контакты</h1>
                <p>{user?.address}</p>
              </div>
            </li>
          </ul>
        </section>
      </main>
    </div>
  );
}
