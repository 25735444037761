import { v4 } from "uuid";

export const staticEquipmentList = [
  {
    id: v4(),
    title: "Партроники",
    value: "p_sensors",
  },
  {
    id: v4(),
    title: "Кондиционер",
    value: "air_cond",
  },
  {
    id: v4(),
    title: "Климат контроль",
    value: "climate",
  },
  {
    id: v4(),
    title: "Круиз контроль",
    value: "cruise",
  },
  {
    id: v4(),
    title: "Камера заднего вида",
    value: "back_cam",
  },
  {
    id: v4(),
    title: "Подлокотник",
    value: "armrest",
  },
  {
    id: v4(),
    title: "Обогрев сидений/стекл",
    value: "seat_heat",
  },
  {
    id: v4(),
    title: "Обогрев лобового стекла",
    value: "windshield_heat",
  },
];

export const staticFeatureList = [
  {
    id: v4(),
    title: "Цвет",
    value: "color",
  },
  {
    id: v4(),
    title: "Вместимость",
    value: "capacity",
  },
  {
    id: v4(),
    title: "Максимальная скорость",
    value: "max_speed",
  },
  {
    id: v4(),
    title: "Мощность, л.с.",
    value: "engine_power",
  },
  {
    id: v4(),
    title: "Объем двигателя",
    value: "engine_vol",
  },
  {
    id: v4(),
    title: "Объем топливного бака",
    value: "tank_vol",
  },
  {
    id: v4(),
    title: "Объем багажника",
    value: "trunk_vol",
  },
  {
    id: v4(),
    title: "Тип привода",
    value: "drive",
  },
];
