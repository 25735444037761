import React, { useState, useMemo } from "react";

//
import Card from "../../../../../components/card/Card";
import Select from "../../../../../components/select/Select";
import Checkbox from "../../../../../components/checkbox/Checkbox";
import AgentChatTemplate from "../../../../../components/agent-chat-template/AgentChatTemplate";
import Tooltip from "../../../../../components/tooltip/Tooltip";
import "../client-travel-location/ClientTravelLocation.styles.scss"

export default function ClientTravelLocation({
  appeal,
  travelRegion,
  changeTravelRegion,
  cityList,
  travel,
  options,
  changeOptions,
  isShowScripts,
  staticDataLoading,
  agentChatTemplate,
}) {
  const [isOpenTooltip, setIsOpenTooltip] = useState(false);

  function getRegionList() {
    return [...cityList.map((i) => ({ id: i.id, title: i.name, value: i.id }))];
  }

  function changeIsOpenTooltip(iot) {
    setIsOpenTooltip(iot);
  }

  function checkForChecked(list, id) {
    return !!list.find((i) => i.option_id === id);
  }

  const chatListGenerator = useMemo(() => {
    if (!agentChatTemplate) return [];
    const list = [...agentChatTemplate.script_text];
    list[2] = list[2].replace("(ИМЯ)", appeal || "Client");
    list[3] = list[3].replace("(ИМЯ)", appeal || "Client");
    return list;
  }, [agentChatTemplate, appeal]);

  return (
    <>
      <Card radius={12}>
        <main className="travel-location__main">
          {isShowScripts && (
            <AgentChatTemplate
              chat={chatListGenerator}
              radius="12px 12px 0 0"
              isLoading={staticDataLoading}
            />
          )}
          <div className="travel-location-form">
            <Select
              className="select-city"
              title="Город"
              size="sm"
              options={getRegionList()}
              value={travelRegion}
              onChange={changeTravelRegion}
            />
            <div className="checkbox__wrap">
              <Checkbox
                checked={checkForChecked(options, travel[0]?.option_id)}
                onClick={() => {
                  changeOptions(
                    checkForChecked(options, travel[0]?.option_id) ? 0 : 1,
                    travel[0]
                  );
                }}
              />
              <p>Выезд в другие регионы</p>
            </div>
            <div className="checkbox__wrap">
              <Checkbox
                checked={checkForChecked(options, travel[1]?.option_id)}
                onClick={() => {
                  changeOptions(
                    checkForChecked(options, travel[1]?.option_id) ? 0 : 1,
                    travel[1]
                  );
                }}
              />
              <p>Выезд за границу</p>
              <div
                className="icon__wrap"
                onMouseOver={() => changeIsOpenTooltip(true)}
                onMouseOut={() => changeIsOpenTooltip(false)}
              >
                <Tooltip
                  isOpen={isOpenTooltip}
                  title={travel[1]?.name}
                  text={travel[1]?.description}
                  size="sm"
                  p={[8, 10]}
                  radius={10}
                />
                <i className="icon icon-sm icon-alert-circle"></i>
              </div>
            </div>
          </div>
        </main>
      </Card>
    </>
  );
}
