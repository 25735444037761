import React from "react";
import ReactOutsideClickHandler from "react-outside-click-handler";

//
import IconWrapper from "../icon-wrapper/IconWrapper";
import "./ModalFullMobile.styles.scss"

export default function ModalFullMobile({ isOpen, title, onClose, children }) {
 if (!isOpen) return null;

 if (isOpen) {window.document.body.style.overflowY = "hidden"}

 const handleClose = () => {
   window.document.body.style.overflowY = "auto";
   onClose();
 }

  return (
    <div className={`modal-full-wrap ${isOpen ? "modal-open" : ""}`} data-draggable='false'>
      <ReactOutsideClickHandler onOutsideClick={handleClose}>
        <main className="modal-full__main">
          <nav className="modal-full__nav">
            <h1>{title}</h1>
            <IconWrapper
              icon="close icon-lg"
              w={52}
              h={52}
              rounded={0}
              onClick={handleClose}
            />
          </nav>
          <div className="modal-full__child">{children}</div>
        </main>
      </ReactOutsideClickHandler>
    </div>
  );
}
