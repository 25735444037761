import { useMemo } from "react";

import Card from "../../../../components/card/Card";
import Checkbox from "../../../../components/checkbox/Checkbox";
import Input from "../../../../components/input/Input";
import "./OverrunBooking.styles.scss";

export default function OverrunBooking({
  isEdit,
  options,
  changeOptions,
  addOptions,
  carFranchise,
	order,
}) {

	
  const overrunOption = useMemo(() => {
    let obj = {
      12000: 1,
      18000: 2,
      24000: 3,
      48000: 4,
    };
    return addOptions.simple.find(
      (i) =>
        i.class === "Ценовая группа №" + obj[carFranchise] &&
        i.name === "Перепробег"
    );
  }, [carFranchise, addOptions.simple]);

  function handleChangeOverrun(e) {
    let km = e.target.value;
    changeOptions(km, overrunOption);
  }

  function handleSelectOverrun() {
    if (!overrunOption) return;
    let isSelected = checkForChecked(options, overrunOption?.option_id);
    changeOptions(isSelected ? 0 : 1, overrunOption);
  }

  function checkForChecked(list, id) {
    return !!list.find((i) => i.option_id === id);
  }

  return (
    <Card radius={12}>
      <div className="overrun-wrap">
        <div className="overrun">
          <div className="checkbox__wrap">
            <Checkbox
              disabled={!carFranchise}
              checked={checkForChecked(options, overrunOption?.option_id)}
              onClick={handleSelectOverrun}
            />
            <p>Перепробег</p>
          </div>
          <Input
            min="1"
            type="number"
            value={
              options.find(
                (op) => op.option_id === overrunOption?.option_id
              )?.amount || 0
            }
            disabled={!checkForChecked(options, overrunOption?.option_id)}
            onChange={handleChangeOverrun}
            placeholder="Введите кол-во км"
            size={"sm"}
          />
				</div>
        <div className="destination">
          <p>Куда едет клиент?</p>
            <Input
              defaultValue={order ? order.destination : ""}
              disabled={!isEdit}
              size={"xs"}
              fill={2}
              id="destination"
            />
        </div> 
      </div>
    </Card>
	);
}
