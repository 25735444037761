import React from "react";
//
import Card from "../../../../../components/card/Card";
import PayCard from "../../../../../components/pay-card/PayCard";
import "./FinanceTop.styles.scss";

export default function FinanceTop({ total, toPay, paid }) {
  return (
    <div className="finance__top">
      <Card>
        <main className="finance__top__content">
          <PayCard
            icon="currency-ruble icon-lg"
            summa={paid}
            title="Выплачено"
          />
          <PayCard
            icon="wallet icon-lg"
            summa={toPay}
            title="К выплате"
            color="warning"
          />
          <PayCard
            icon="check-circle icon-lg"
            summa={total}
            title="Сумма всех сделок"
            color="primary"
            outlined={1}
          />
        </main>
      </Card>
    </div>
  );
}

