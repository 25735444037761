import React, { useMemo } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";

//
import BlackClientModal from "../../../components/black-client-card/BlackClientModal";
import ClientInfoCard from "../../../components/client-info-card/ClientInfoCard";
import ReqList from "../dashboard/sections/req-list/ReqList";
import {
  useGetClientList,
  useGetRequestByClientId,
} from "../../../store/client/hook";

export default function ClientPageById() {
  // helper
  const { id } = useParams();

  // query
  const { data, isLoading } = useGetClientList();
  const { data: clientRequestList, isLoading: requestLoading } =
    useGetRequestByClientId(id);

  const client = useMemo(() => {
    return data?.data?.client_list.find((i) => i.client_id === id);
  }, [data, id]);

  const requestList = useMemo(() => {
    if (!clientRequestList) return [];
    return clientRequestList.data.request_list;
  }, [clientRequestList]);

  return (
    <StyledClientPageById>
      {client?.black_listed && <BlackClientModal />}
      <ClientInfoCard clientInfo={client} isLoading={isLoading} />
      <ReqList
        list={requestList}
        isBlack={client?.black_listed}
        isLoading={requestLoading}
        client={1}
      />
    </StyledClientPageById>
  );
}

const StyledClientPageById = styled.div``;
