import React from "react";
import cl from "classnames";

//
import Card from "../card/Card";
import IconWrapper from "../icon-wrapper/IconWrapper";
import NumberFormat from "../number-format/NumberFormat";
import "./ReqSuccessCard.styles.scss";

// type => toPayOff, paidOut
export default function ReqSuccessCard({ type, title, text, request }) {

  // method
  function getIcon() {
    if (type === "toPayOff") return "credit-card-upload icon-xl";
    return "check icon-xl";
  }

  function getIconBg() {
    if (type === "toPayOff") return "warning-10";
    return "success-20";
  }

  function getIconColor() {
    if (type === "toPayOff") return "warning-dark";
    return "success-dark";
  }

  return (
    <>
      <Card radius={20}>
        <main className="req__success__card__main">
          <nav className="req__success__card__top">
            <IconWrapper
              icon={getIcon()}
              bg={getIconBg()}
              color={getIconColor()}
              w={80}
              h={80}
            />
            <h1>{title}</h1>
            <p>{text}</p>
          </nav>
          <footer className="req__success__card__bottom">
            <div
              className={cl("price__wrap", { warning: type === "toPayOff" })}
            >
              <NumberFormat value={request?.agent_comission || 0} />
            </div>
            {type === "paidOut" && <p>2 апр 2022 г.</p>}
          </footer>
        </main>
      </Card>
    </>
  );
}

