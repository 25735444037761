import React, { useState } from "react";
import styled, { css } from "styled-components";
import cl from "classnames";
import { Collapse } from "react-collapse";

//
import Card from "../card/Card";
import Checkbox from "../checkbox/Checkbox";
import { useAppContext } from "../../store/Context";

export default function FilterCarMark({
  options,
  onChangeMarkModel,
  onToggleAllMark,
  onResetCarMarkList,
  inCatalog,
}) {
    // context
    const {
      state: { isMobile }
    } = useAppContext();

  const [isOpen, setIsOpen] = useState([]);

  function toggleIsOpen(id) {
    if (isOpen.includes(id)) {
      setIsOpen((p) => p.filter((i) => i !== id));
    } else {
      setIsOpen((p) => [...p, id]);
    }
  }

  function checkedModelsLength(car) {
    if (car.checked) return car.models.length;
    return car.models.filter((i) => i.checked).length;
  }

  return (
    <StyledFilterCarMark inCatalog={inCatalog} isMobile={isMobile}>
      <Card radius={16}>
        <main className="filter__car__mark__main">
          <nav className="filter__car__mark__nav">
            <h1>Марки</h1>
            <p onClick={onResetCarMarkList}>Сбросить</p>
          </nav>
          <div className="filter__car__mark__list">
            <ul className="filter__car__mark__ul">
              {options.map((i) => {
                let countCheckedModels = checkedModelsLength(i);
                return (
                  <li key={i.id}>
                    <div
                      className={cl("filter__car__mark__item", {
                        active: isOpen.includes(i.id),
                      })}
                    >
                      <section
                        className={cl("filter__car__mark__item__top", {
                          active: !!countCheckedModels,
                          isOpen: isOpen.includes(i.id),
                        })}
                        onClick={() => toggleIsOpen(i.id)}
                      >
                        <h1>{i.mark}</h1>
                        <i
                          className="icon icon-sm icon-chevron-down"
                          style={{
                            transform: isOpen.includes(i.id)
                              ? "rotate(-180deg)"
                              : "rotate(0deg)",
                          }}
                        />
                        {!!countCheckedModels && <p>{countCheckedModels}</p>}
                      </section>
                      <Collapse isOpened={isOpen.includes(i.id)}>
                        <ul className="filter__car__mark__item__body">
                          <li
                            className={cl("one__model", {
                              active: i.checked,
                            })}
                            onClick={() => onToggleAllMark(i.id)}
                          >
                            <Checkbox checked={i.checked} />
                            <p>Все модели</p>
                          </li>
                          {i.models.map((j) => (
                            <li
                              key={j.id}
                              className={cl("one__model", {
                                active: j.checked,
                              })}
                              onClick={() => onChangeMarkModel(j.id, i.mark)}
                            >
                              <Checkbox checked={j.checked} />
                              <p>{j.model}</p>
                            </li>
                          ))}
                        </ul>
                      </Collapse>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <footer className="filter__car__mark__footer"></footer>
        </main>
      </Card>
    </StyledFilterCarMark>
  );
}

const StyledFilterCarMark = styled.div`
  .filter__car__mark {
    &__main {
      padding: 0 3px 20px 10px;
    }

    &__nav {
      padding: 20px 27px 20px 20px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      gap: 20px;

      @media (max-width: 1280px) {
        margin-top: 20px;
      }

      h1 {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark);
      }

      p {
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        color: var(--primary);
        cursor: pointer;
      }
    }

    &__list {
      max-height: 365px;
      overflow-y: auto;
      scrollbar-color: var(--dark-15) var(--light);
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--dark-15);
        border-radius: 2px;
      }

      ${({ inCatalog, isMobile }) =>
        inCatalog && !isMobile &&
        css`
          max-height: unset;
          height: calc(100vh - 135px);
          min-height: calc(1080px - 100px);
        `}
    }

    &__ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      gap: 5px;

      & > li {
        padding-right: 3px;
      }
    }

    &__item {
      border-radius: 14px;
      border: 3px solid transparent;

      &.active {
        border-color: var(--primary);
      }

      &__top {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        gap: 20px;
        padding: 10px 14px 10px 20px;
        transition: 0.2s linear;
        border-radius: 14px;

        h1 {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          color: var(--dark);
        }

        i {
          background-color: var(--dark-50);
        }

        &:hover {
          background-color: var(--dark-3);

          i {
            background-color: var(--dark);
          }
        }

        &.isOpen {
          i {
            background-color: var(--dark);
          }
        }

        &.active {
          background-color: var(--primary-5);
          position: relative;

          h1 {
            color: var(--primary);
          }

          i {
            background-color: var(--primary-50);
          }

          p {
            font-size: 12px;
            font-weight: 600;
            line-height: 15px;
            letter-spacing: 0em;
            color: var(--primary-60);
            background-color: var(--light);
            padding: 4px 6px;
            border-radius: 6px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 40px;
          }

          &:hover {
            background-color: var(--primary-10);
          }
        }
      }

      &__body {
        margin-top: 5px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 5px;
        padding: 0 10px 10px;

        .one__model {
          padding: 5px;
          border-radius: 16px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          gap: 10px;
          transition: 0.2s linear;
          cursor: pointer;

          p {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            color: var(--dark);
          }

          &:hover {
            background-color: var(--dark-3);
          }

          &.active {
            background-color: var(--primary-10);

            p {
              color: var(--primary);
            }

            &:hover {
              background-color: var(--primary-20);
            }
          }
        }
      }
    }
  }
`;
