import React, { useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { Collapse } from "react-collapse";
import { useNavigate, useLocation } from "react-router-dom";
import cl from "classnames";

//
import defaultCarImg from "../../asset/img/sad.png";
import Button from "../button/Button";
import NumberFormat from "../number-format/NumberFormat";
import IconWrapper from "../icon-wrapper/IconWrapper";
import CatalogCarList from "../catalog-car-list/CatalogCarList";
import CatalogCarTerm from "../catalog-car-term/CatalogCarTerm";
import CatalogRentTerm from "../catalog-rent-term/CatalogRentTerm";
import { useAppContext } from "../../store/Context";

export default function CatalogCarRow({
  car,
  inModal,
  selectedCar,
  onChangeSelectedCar,
  reqCreate,
  tariffIndex = 0,
  viewCarList,
  priceMatrixIndex,
  coef,
  oneTimeOptionsPrice = 0,
  perDayOptionsPrice,
  perDailyPrice,
}) {
  
  // helper
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isComparsion = pathname.includes("comparsion");

  // context
  const {
    state: { carComparsionList },
    fn: {
      changeReqCar,
      addCarToCarComparsionList,
      deleteCarFromCarComparsionList,
    },
  } = useAppContext();

  // state
  const [isOpen, setIsOpen] = useState(false);

  function toggleIsOpen() {
    setIsOpen((p) => !p);
  }

  const checkSelected = useMemo(() => {
    if (!selectedCar) return;
    return selectedCar?.price_unit_id === car.price_unit_id;
  }, [selectedCar, car]);

  const checkForComparsion = useMemo(() => {
    return carComparsionList
      .map((i) => i.price_unit_id)
      .includes(car.price_unit_id);
  }, [carComparsionList, car]);

  const carCount = useMemo(() => {
    return car.car_list.length;
  }, [car]);

  const carInfo = useMemo(() => {
    return { ...car.car_list[0] };
  }, [car]);

  const carPrice = useMemo(() => {
    return Math.floor(
      car.price_matrix[tariffIndex ?? 0].prices[priceMatrixIndex ?? 3].price *
        coef +
        perDayOptionsPrice +
        oneTimeOptionsPrice
    );
  }, [
    car,
    tariffIndex,
    priceMatrixIndex,
    coef,
    perDayOptionsPrice,
    oneTimeOptionsPrice,
  ]);

  // price per day without
  const dailyCarPrice = useMemo(() => {
    return Math.floor(
      car.price_matrix[tariffIndex ?? 0].prices[priceMatrixIndex ?? 3].price +
      perDailyPrice
    );
  }, [car, tariffIndex, priceMatrixIndex, perDailyPrice]);

  function navigateReqCreate(e) {
    e.stopPropagation();
    navigate(`/req-create`);
    if (carCount > 1) {
      changeReqCar(car);
    } else {
      changeReqCar({ ...car, ...carInfo });
    }
  }

  const carStatus = useMemo(() => {
    const obj = {
      свободна: 0,
      бронь: 0,
      занята: 0,
    };
    car.car_list.forEach((i) => {
      obj[i.status] += 1;
    });
    if (obj.свободна > 0) return "свободна";
    if (obj.бронь > 0) return "бронь";
    return "занята";
  }, [car]);

  return (
    <StyledCatalogCarRow
      isOpen={isOpen}
      inModal={inModal}
      reqCreate={reqCreate}
    >
      <nav className="catalog__car__row__nav" onClick={toggleIsOpen}>
        <section className="catalog__car__row__nav__left">
          <div
            className={cl("status__frame", {
              warning: "бронь" === carStatus,
              danger: "занята" === carStatus,
            })}
          />
          <div className="car__image__wrap">
            <img
              src={car.image_url || defaultCarImg}
              alt="carImg"
              width={reqCreate ? "72px" : "108px"}
            />
            {carCount > 1 ? <p>{carCount}</p> : null}
          </div>
          <div className="car__info__warp">
            <h1>{car.brand + " " + car.model + " " + car.generation}</h1>
            <p>
              Класс {car.class}{" "}
              {carCount === 1 && carInfo?.point_address ? (
                <>
                  <span className="circle" /> {carInfo?.point_address}{" "}
                </>
              ) : null}
            </p>
          </div>
        </section>
        <section className="catalog__car__row__nav__right">
          <div
            className={cl("car__isauto__wrap", {
              success: car.is_auto,
            })}
          >
            {car.is_auto ? "A" : "M"}
          </div>
          {inModal || reqCreate ? (
            <>
              <div className="rent__price__frame">
                {
                  reqCreate ?
                  <>
                  <NumberFormat value={carPrice} />
                  &nbsp;
                  <p className="per_day_container">
                    <NumberFormat value={dailyCarPrice} />
                  </p>
                  </> 
                  :
                  <p>
                    От{" "}
                    <NumberFormat
                      value={car.price_matrix[tariffIndex].prices[3].price}
                    />
                  </p>
                }
              </div>
              {isComparsion ? (
                <Button
                  p={[14, 26]}
                  radius={14}
                  fW={500}
                  color={checkForComparsion ? "success" : ""}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (checkForComparsion) {
                      deleteCarFromCarComparsionList({ ...car, ...carInfo });
                    } else {
                      addCarToCarComparsionList({ ...car, ...carInfo });
                    }
                  }}
                >
                  {checkForComparsion ? "Добавлено" : "Добавить"}
                </Button>
              ) : (
                <Button
                  radius={14}
                  border={1}
                  p={reqCreate ? [12, 26] : [14, 26]}
                  fW={reqCreate ? 400 : 500}
                  color={checkSelected ? "success" : "light"}
                  size={reqCreate ? "sm" : ""}
                  onClick={(e) => {
                    e.stopPropagation();
                      onChangeSelectedCar({
                        ...car,
                      });
                  }}
                >
                  {checkSelected ? "Выбрано" : "Выбрать"}
                </Button>
              )}
            </>
          ) : (
            <>
              {isOpen ? (
                <Button
                  p={[14, 26]}
                  color="success"
                  fW={500}
                  radius={14}
                  onClick={navigateReqCreate}
                >
                  Создать заявку
                </Button>
              ) : (
                <div className="rent__sum__wrap">
                  <p>Аренда в сутки</p>
                  <h1>
                    От{" "}
                    <NumberFormat
                      value={car.price_matrix[tariffIndex].prices[3].price}
                    />
                  </h1>
                </div>
              )}
            </>
          )}
          <IconWrapper
            w={reqCreate ? 40 : 30}
            h={reqCreate ? 40 : 48}
            bg={reqCreate ? "dark-3" : "light"}
            rounded={reqCreate ? 12 : 0}
            icon="chevron-down"
            rotate={isOpen}
          />
        </section>
      </nav>
      <Collapse isOpened={isOpen}>
        <main className="catalog__car__row__main">
            <CatalogRentTerm
              priceMatrix={car.price_matrix[tariffIndex].prices}
              priceMatrixIndex={priceMatrixIndex}
            />
              <CatalogCarList
                car={car}
                inModal={inModal}
                selectedCar={selectedCar}
                onChangeSelectedCar={onChangeSelectedCar}
                reqCreate={reqCreate}
              />
          {!(inModal || reqCreate) && (
            <CatalogCarTerm term={car.rental_terms} />
          )}
        </main>
      </Collapse>
    </StyledCatalogCarRow>
  );
}

const StyledCatalogCarRow = styled.div`
  border: 3px solid transparent;
  border-radius: 20px;
  overflow: hidden;
  transition: 0.2s linear;

  .catalog__car__row {
    &__nav {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      gap: 10px;
      padding: 14px 20px;
      padding-right: 0;
      background-color: var(--light);

      &__left {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 20px;

        .status__frame {
          width: 10px;
          height: 30px;
          border-radius: 5px;
          background-color: var(--success);

          &.warning {
            background-color: var(--warning);
          }

          &.danger {
            background-color: var(--danger);
          }
        }

        .car__image__wrap {
          position: relative;
          line-height: 0;

          p {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: var(--light);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            border: 1px solid var(--primary-dark-15);
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 0em;
            color: var(--dark);
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }

        .car__info__warp {
          h1 {
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0em;
            color: var(--dark);
          }

          p {
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            white-space: nowrap;
            color: var(--dark-60);
            width: 185px;
            overflow:hidden;
            margin-right:-20px;
            position:relative;

            .circle {
              display: inline-block;
              position: relative;
              top: -3px;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background-color: var(--dark-60);
            }
          }

          p::after {
            content:"";
            display:block;
            width:20px;
            height: 20px;
            right:80px;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 2;
            box-shadow: inset -66px 0px 13px -50px #fff;
          }

          p:hover {
            width: 185px;
            overflow: visible;
            color: var(--dark);

            &::after {
              box-shadow: none;
            }
          }
        }
      }

      &__right {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: end;
        gap: 12px;
        width: 270px;
        min-width: 270px;

        .car__isauto__wrap {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: var(--primary-10);
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0em;
          color: var(--primary);

          &.success {
            background-color: var(--success-20);
            color: var(--success);
          }
        }

        .rent__sum__wrap {
          border: 1px solid var(--primary-dark-15);
          border-radius: 14px;
          padding: 9px 20px;

          p {
            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: 0em;
            color: var(--dark-70);
          }

          h1 {
            font-size: 12px;
            font-weight: 500;
            line-height: 15px;
            letter-spacing: 0em;
            color: var(--dark);
          }
        }

        .rent__price__frame {
          font-size: 12px;
          font-weight: 500;
          line-height: 15px;
          letter-spacing: 0em;
          text-align: center;
          color: var(--dark-40);
          min-width: 65px;
          white-space: nowrap;
        }
        .per_day_container {
          &::before {
            content: "(";
          }
          &::after {
            content: ")";
          }
        }

        ${({ inModal }) =>
          inModal &&
          css`
            gap: 10px;
          `}
      }
    }

    &__main {
      padding: 20px;
      background-color: var(--bg);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      gap: 20px;
    }
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      border-color: var(--primary);

      .catalog__car__row {
        &__nav {
          transform: translateY(0.00001%);
          box-shadow: 0px 2px 10px var(--dark-5);

          &__right {
            width: 315px;
          }
        }
      }
    `}

  ${({ inModal }) =>
    inModal &&
    css`
      .catalog__car__row {
        &__main {
          padding: 10px;
          gap: 10px;
        }
      }
    `}

  ${({ reqCreate }) =>
    reqCreate &&
    css`
      .catalog__car__row {
        &__nav {
          padding: 10px 10px 10px 15px;

          &__left {
            gap: 15px;

            .status__frame {
              width: 6px;
              height: 20px;
              border-radius: 5px;
            }

            .car__image__wrap {
              position: relative;
              line-height: 0;

              p {
                width: auto;
                height: auto;
                padding: 2px 10px;
                border-radius: 13px;
                font-size: 12px;
                font-weight: 600;
                line-height: 15px;
                letter-spacing: 0em;
                text-align: left;
                bottom: -5px;
                right: -3px;
              }
            }

            .car__info__warp {
              h1 {
                font-size: 14px;
                line-height: 17px;
                margin-bottom: 2px;
              }

              p {
                display: flex;
                align-items: baseline;
                font-size: 12px;
                line-height: 15px;
                gap: 5px;
              }
            }
          }

          &__right {
            gap: 5px;
          }
        }

        &__main {
          padding: 10px;
          gap: 10px;
        }
      }
    `}
`;
