import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useMutation } from "react-query";

//
import Input from "../../../../components/input/Input";
import InputPhone from "../../../../components/input-phone/InputPhone";
import Button from "../../../../components/button/Button";
import Loader from "../../../../components/loader/Loader";
import Consts from "../../../../util/const";
import SuccessModal from "./SuccessModal";
import ServerErrorModal from "./ServerErrorModal";
import AuthApi from "../../../../store/auth/api";
import { useAppContext } from "../../../../store/Context";
import { useGetUserInfoMutation } from "../../../../store/user/hook";

export default function Enter({
  onResetPassword,
  searchParams,
  removeSearchParam,
  reset_success,
}) {
  // context
  const {
    fn: { changeIsAuth },
  } = useAppContext();

  // state
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [isServerError] = useState(false);
  const [user, setUser] = useState(null);

  // query
  const mutation = useMutation((body) => AuthApi.getToken(body), {
    onSuccess,
    onError,
  });
  let user_info_mutation = useGetUserInfoMutation({
    onSuccess: userOnSuccess,
  });

  // watcher
  useEffect(() => {
    if (!reset_success) return;
    user_info_mutation.mutate();
    setShowSuccess(true);
    removeSearchParam("success");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset_success]);

  // method
  function onLogin(e) {
    e.preventDefault();
    let ex = true;

    if (!password) {
      setPasswordError("Введите пароль");
      ex = false;
    }
    if (phone.length < 11) {
      setPhoneError(
        "Неверно введен номер: номер должен содержать 10 цифр (формат +7 (xxx) xxx xx xx)"
      );
      ex = false;
    }

    if (ex) {
      mutation.mutate({ login: phone, pass: password });
    }
  }

  function onSuccess(res) {
    let token = res.data.data.token;
    localStorage.setItem(Consts.APP_TOKEN, token);
    localStorage.setItem(Consts.USER_PHONE, phone);
    user_info_mutation.mutate();
  }

  function onError(err) {
    if (err.response.status === 500) {
      setPhoneError("Пользователь с таким номером не найден");
      return;
    }
    if (err.response.status === 401) {
      setPasswordError("Неверно указан пароль");
    }
  }

  function userOnSuccess(res) {
    setUser(res.data.data);
    setShowSuccess(true);
  }

  function changePassword(e) {
    setPassword(e.target.value);
    setPasswordError("");
  }

  function changePhone(e) {
    setPhone(e.target.value);
    setPhoneError("");
  }

  if (showSuccess || searchParams.get("success"))
    return (
      <SuccessModal
        changeIsAuth={changeIsAuth}
        user={user}
        removeSearchParam={removeSearchParam}
      />
    );
  if (isServerError) return <ServerErrorModal />;

  return (
    <StyledEnter>
      <div className="login__top">
        <h1>Вход</h1>
        <p>Кабинет партнера</p>
      </div>
      <div className="inputs__group">
        <InputPhone
          value={phone}
          onChange={changePhone}
          placeholder="+7"
          error={phoneError}
          fill={1}
        />
        <Input
          type="password"
          placeholder="Пароль"
          value={password}
          onChange={changePassword}
          error={passwordError}
          fill={1}
        />
      </div>
      <div className="actions__group">
        <p onClick={() => onResetPassword("reset", true)}>Сбросить пароль</p>
        <Button
          type="submit"
          color="primary"
          p={[16, 30]}
          textUpperCase={1}
          fW={500}
          onClick={onLogin}
        >
          {(mutation.isLoading || user_info_mutation.isLoading) && (
            <Loader
              radius={16}
              bg="primary"
              color="light"
              w={24}
              h={24}
              size={3}
            />
          )}
          Войти
        </Button>
      </div>
    </StyledEnter>
  );
}

const StyledEnter = styled.form`
  .login__top {
    margin-bottom: 60px;

    h1 {
      font-size: 30px;
      font-weight: 500;
      line-height: 37px;
      letter-spacing: 0em;
      text-align: center;
      color: var(--dark);
      text-transform: uppercase;
    }

    p {
      display: none;
    }
  }

  .inputs__group {
    width: 320px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 40px;
  }

  .actions__group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      color: var(--primary);
      text-decoration: underline;
      cursor: pointer;
    }
  }

  @media (max-width: 768px) {
    .login__top {
      margin-bottom: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h1 {
        font-size: 26px;
        line-height: 32px;
      }

      p {
        display: block;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark-50);
      }
    }
  }
`;
