import { useEffect, useState } from "react";

export default function useDebounce(value, delay = 300) {
  const [debounce, setDebounce] = useState(value);

  useEffect(() => {
    const fn = setTimeout(() => setDebounce(value), delay);

    return () => clearTimeout(fn);
  }, [value, delay]);

  return debounce;
}
