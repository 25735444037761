import React from "react";
import { useEffect, useCallback } from "react";

//
import PopUp from "../../../../../components/popup/PopUp";
import Card from "../../../../../components/card/Card";
import IconWrapper from "../../../../../components/icon-wrapper/IconWrapper";
import Button from "../../../../../components/button/Button";
import "./FinanceReportModal.styles.scss";

export default function FinanceReportModal({
  isOpen,
  onClose,
  title,
  text,
  onSubmit,
}) {

  const handleKeyDown = useCallback((evt) => {
    if(evt.key === 'Escape') {
      onClose();
    }
    if(evt.key === 'Enter') {
      onSubmit();
    }
  }, [onClose, onSubmit]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);
  
  return (
    <PopUp isOpen={isOpen} onClose={handleKeyDown}>
      <div className="finance__report__modal__wrap">
        <Card>
          <div className="finance__report__modal__content">
            <nav className="finance__report__modal__nav">
              <h1>{title}</h1>
              <IconWrapper
                icon="close icon-lg"
                w={52}
                h={52}
                rounded={0}
                onClick={onClose}
              />
            </nav>
            <main className="finance__report__modal__main">
              <p>{text}</p>
            </main>
            <footer className="finance__report__modal__footer">
              <Button
                textUpperCase={1}
                color="primary"
                p={[16, 30]}
                fW={500}
                radius={14}
                onClick={onSubmit}
              >
                Скачать
              </Button>
            </footer>
          </div>
        </Card>
      </div>
    </PopUp>
  );
}
