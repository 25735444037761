import React, { useEffect, useState } from "react";
//
import Loader from "../../../../components/loader/Loader";
import ClientRentPeriod from "../../../agent/req-create-v2/components/client-rent-period/ClientRentPeriod";
import RentalAddress from "../../../agent/req-create-v2/components/RentalAddress";
import "./RentInfo.styles.scss";
import TypeBooking from "../type-booking/TypeBooking";
import ExtraFields from "../extra-fields/ExtraFields";

export default function RentInfo({
  localBooking,
  dataBooking,
  isEdit,
  onChangeLocalBooking,
  refRentInfo,
  appeal,
  vidacha,
  changeVidacha,
  vozvrat,
  changeVozvrat,
  options,
  changeOptions,
  rentPeriod,
  carAddress,
  priceVidachaType,
  priceVozvratType,
  tariffIndex,
  points,
  staticDataLoading,
  editContentLoading,
  reqId,
  car,
  defaultPriceList,
  onUpdatePrice,
  approved,
  onChangeApproved
}) {

  const [isShowDeliveryman, setIsShowDeliveryman] = useState(false);
  
  useEffect(() => {
    const type = dataBooking?.booking_type.find((item) => item.booking_type === localBooking?.booking_type);
    setIsShowDeliveryman(type?.opens_delivery);
  }, [dataBooking, localBooking])
  
  const changeShowDeliveryman = (value) => {
    setIsShowDeliveryman(value);
  };
  
  return (
    <div className="rent-info__wrap" ref={refRentInfo}>
      <div className="req_create_left__main">
        {reqId && editContentLoading && <Loader radius={12} zIndex={5} />}
        <TypeBooking
          defaultPriceList={defaultPriceList}
          priceListName={localBooking ? localBooking.price_list_name : ""}
          priceList={dataBooking ? dataBooking.price_list_array : []}
          deposit={localBooking ? localBooking?.deposit : ""}
          isEdit={isEdit}
          bookingType={localBooking ? localBooking?.booking_type : ""}
          bookingTypeList={dataBooking ? dataBooking.booking_type : []}
          onChangeLocalBooking={onChangeLocalBooking}
          car={car}
          changeShowDeliveryman={changeShowDeliveryman}
        />
        <ClientRentPeriod
          appeal={appeal}
          vidacha={vidacha}
          changeVidacha={changeVidacha}
          vozvrat={vozvrat}
          changeVozvrat={changeVozvrat}
          rentPeriod={rentPeriod}
          staticDataLoading={staticDataLoading}
          isEditBooking={isEdit}
        />
        <RentalAddress
          carAddress={carAddress}
          vidacha={vidacha}
          changeVidacha={changeVidacha}
          vozvrat={vozvrat}
          changeVozvrat={changeVozvrat}
          priceVidachaType={priceVidachaType}
          priceVozvratType={priceVozvratType}
          tariffIndex={tariffIndex}
          options={options}
          changeOptions={changeOptions}
          points={points}
          staticDataLoading={staticDataLoading}
          isEditBooking={isEdit}
        />
        <ExtraFields
          isEdit={isEdit}
          car={car}
          isShowDeliveryman={isShowDeliveryman}
          onChangeLocalBooking={onChangeLocalBooking}
          deliveryman={localBooking ? localBooking?.deliveryman : ""}
          deliverymanList={dataBooking ? dataBooking.deliveryman_list : []}
          deliveryPayment={
            localBooking ? localBooking?.delivery_payment_type : ""
          }
          deliveryPaymentList={
            dataBooking ? dataBooking.delivery_payment_type_list : []
          }
          optionsPoint={localBooking ? localBooking?.options_point : ""}
          optionsPointList={dataBooking ? dataBooking.options_point_list : []}
          promotionId={localBooking ? localBooking.promotion_id : ""}
          promotionName={localBooking ? localBooking.promotion_name : ""}
          promotionPercent={localBooking ? localBooking.promotion_percent : ""}
          promotionList={dataBooking ? dataBooking.promotion_list : []}
          onUpdatePrice={onUpdatePrice}
          approved={approved}
          onChangeApproved={onChangeApproved}
          discount={localBooking ? localBooking.discount : ""}
        />
      </div>
    </div>
  );
}
