import { useCallback, useEffect } from 'react';

function useModalKeyDown(props) {
    const handleKeyDown = useCallback((evt) => {
    if (!props.isOpen) return;
      if(evt.key === 'Escape') {
        props.handleClose();
      }
      if(evt.key === 'Enter') {
        props.handleSubmit();
      }
    }, [props.isOpen]);
  
    useEffect(() => {
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, [handleKeyDown]);
}

export default useModalKeyDown;