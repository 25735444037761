import React from "react";
import styled from "styled-components";

export default function AgentReqCard({ count = 0, title = "-" }) {
  return (
    <StyledAgentReqCard>
      <h1>{count}</h1>
      <p>{title}</p>
    </StyledAgentReqCard>
  );
}

const StyledAgentReqCard = styled.div`
  border: 1px solid var(--primary-dark-15);
  border-radius: 16px;
  white-space: nowrap;
  text-align: center;
  padding: 10px;

  & > h1 {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    color: var(--dark);
  }

  & > p {
    color: var(--dark);
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    opacity: 0.6;
  }
`;
