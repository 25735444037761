import React from "react";
import { useNavigate } from "react-router-dom";
//
import IconWrapper from "../icon-wrapper/IconWrapper";
import { formatPhoneNumber } from "../../util/formatters";
import { differentDates } from "../../util/date";
import "./ClientContactCard.styles.scss";
import { useAppContext } from "../../store/Context";

export default function ClientContactCard({id, phone, email, request }) {

  const {
    state: { isMobile },
  } = useAppContext();

  const navigate = useNavigate();
  
  function goToUrl(url) {
    navigate(url);
  }

  return (
    <div className="client-contact-card">
      {
        isMobile &&
        <div className="client-contact-card__name-client">
          <h1>{request?.client_name}</h1>
          <p>
            <i className="icon icon-announcement icon-xs"></i>
            Администраторы СТО
          </p>
        </div>
      }
      <div className="client-contact-card__info">
        {
        isMobile &&
        <div className="info-status">
          <h1>{request?.status}</h1>
          <p>
            {differentDates(request?.status_set_date, Date.now() / 1000)}
          </p>
        </div>
        }
        <div  className="info-phone">
          <h1>{formatPhoneNumber(phone)}</h1>
          <p>{email}</p>
        </div>
      </div>
      <div className="client-contact-card__share">
        <IconWrapper 
          icon="share" 
          w={48} 
          h={48} 
          border={1}
          onClick={() => goToUrl("/client/" + id)} 
          />
      </div>
    </div>
  );
}
