import React from "react";
import styled from "styled-components";
import cl from "classnames";
import { Outlet, useLocation } from "react-router-dom";

//
import VerticalNavbar from "../../components/vertical-navbar/VerticalNavbar";
import MobileNavbar from "../../components/vertical-navbar/mobile-navbar/MobileNavbar";
import { useAppContext } from "../../store/Context";

export default function CabinetLayout() {
  const { pathname } = useLocation();
  
  const {
    state: { isMobile },
  } = useAppContext();

  return (
    <StyledCabinetLayout>
      {
        isMobile ?
        <MobileNavbar />
        :
        <VerticalNavbar />
      }
      <main className="cabinet__layout__main">
        <section className="outlet">
          <div className={cl({ container: !pathname.includes("comparsion") })}>
            <Outlet />
          </div>
        </section>
      </main>
    </StyledCabinetLayout>
  );
}

const StyledCabinetLayout = styled.div`
  display: flex;
  .cabinet__layout__main {
    position: relative;
    margin: 0 auto;
    height: 100vh;

    .outlet {
      padding: 30px 0 100px;
    }
  }

  @media (max-width: 1280px) {
    .cabinet__layout__main {
      .outlet {
        margin-top: 40px;
      }
    }
  }
`;
