import React from "react";
import Button from "../../../../../components/button/Button";

//
import IconWrapper from "../../../../../components/icon-wrapper/IconWrapper";
import { useAppContext } from "../../../../../store/Context";
import "./SelectedCarCard.styles.scss";
import Loader from "../../../../../components/loader/Loader";

export default function SelectedCarCard({ 
  car, 
  changeCar, 
  loading = false,
  isBookingPage,
  isEditBooking
}) {
  const {
    state: {isMobile},
    fn: { changeCarEquipment },
  } = useAppContext();

  if (loading)
    return (
      <div className="selected_car_card" style={{ borderRadius: 20 }}>
        <section className="selected_car_card__top" style={{ height: 200 }}>
          <Loader />
        </section>
      </div>
    );

  return (
    <div className="selected_car_card" style={{ borderRadius: 20 }}>
      <section className="selected_car_card__top">
        <div className="selected_car_card__top__left">
          <img
            src={car?.image_url}
            alt="car-img"
            width={isMobile ? 100 : 204}
          />
          <div className="name_class_address__wrap">
            <h1>
              {car?.brand} {car?.model}
            </h1>
            {(!isMobile || isBookingPage) && <p>Класс {car?.class}</p>}
            {(!isMobile || isBookingPage)  && (
              <div className="address__wrap">
                <IconWrapper
                  icon="home icon-xsm"
                  w={34}
                  h={34}
                  bg="success-20"
                  color="success"
                />
                <div>
                  <h1>Адрес</h1>
                  <p>{car?.point_address || "-"}</p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="selected_car_card__top__right">
          {isBookingPage && 
          <div className="selected_car__gosnomer">
            <p>Гос. номер</p>
            <h1>{car.gosnomer ? car.gosnomer : "-"}</h1>
          </div>}
          {(!isMobile || isBookingPage) && car?.parameters && car?.setup && (
            <div className="info_icons__wrap">
              <IconWrapper
                icon="file icon-sm"
                rounded={12}
                border={1}
                onClick={() => changeCarEquipment({ car, tab: 1 })}
              />
              <IconWrapper
                icon="settings icon-sm"
                rounded={12}
                border={1}
                onClick={() => changeCarEquipment({ car, tab: 2 })}
              />
            </div>
          )}
          {isEditBooking && <Button
            color="success"
            p={[11, 26]}
            size="sm"
            radius={12}
            onClick={() => changeCar(null)}
          >
            Поменять авто
          </Button>}

        </div>
      </section>
      {(!isMobile || isBookingPage) && (
        <section className="selected_car_card__bottom">
          <ul>
            <li>
              <IconWrapper
                icon="calendar icon-sm"
                bg="primary-10"
                color="primary"
                w={35}
                h={35}
              />
              <div>
                <h1>Год выпуска</h1>
                <p>{car?.parameters?.year}</p>
              </div>
            </li>
            <li>
              <IconWrapper
                icon="engine icon-sm"
                bg="primary-10"
                color="primary"
                w={35}
                h={35}
              />
              <div>
                <h1>Тип двигателя</h1>
                <p>{car?.parameters?.engine_type}</p>
              </div>
            </li>
            <li>
              <IconWrapper
                icon="transmission icon-sm"
                bg="primary-10"
                color="primary"
                w={35}
                h={35}
              />
              <div>
                <h1>Коробка передач</h1>
                <p>{car?.is_auto ? "Автомат" : "Механика"}</p>
              </div>
            </li>
            <li>
              <IconWrapper
                icon="drive icon-sm"
                bg="primary-10"
                color="primary"
                w={35}
                h={35}
              />
              <div>
                <h1>Тип привода</h1>
                <p>{car?.parameters?.drive}</p>
              </div>
            </li>
          </ul>
        </section>
      )}
    </div>
  );
}
