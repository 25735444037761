import React, { useMemo } from "react";
import styled from "styled-components";
import cl from "classnames";

//
import Card from "../card/Card";
import IconWrapper from "../icon-wrapper/IconWrapper";
import CarNumber from "../car-number/CarNumber";
import { msToNormTime } from "../../util/date";
import { useAppContext } from "../../store/Context";

export default function CatalogCarInfoCard({ car, carInfo }) {
  // context
  const {
    state: { carComparsionList },
    fn: {
      addCarToCarComparsionList,
      deleteCarFromCarComparsionList,
      changeCarEquipment,
    },
  } = useAppContext();

  function getIcon(status) {
    if (status === "свободна") return "check-circle";
    if (status === "занята") return "clock-circle";
    if (status === "бронь") return "clock-circle-z";
  }

  function getIconColor(status) {
    if (status === "свободна") return "success-dark";
    if (status === "занята") return "danger-dark";
    if (status === "бронь") return "warning-dark";
  }

  function getTitle(status, rent_end_date) {
    if (status === "свободна") return "Автомобиль свободен";
    if (status === "занята") {
      if (!rent_end_date) return "В сервисе";
      return `Автомобиль занят до ${msToNormTime(rent_end_date, 3)}`;
    }
    if (status === "бронь")
      return `Автомобиль освободится ${msToNormTime(rent_end_date, 3)}`;
  }

  const checkComparsion = useMemo(() => {
    return carComparsionList.map((i) => i.gosnomer).includes(carInfo?.gosnomer);
  }, [carComparsionList, carInfo]);

  return (
    <StyledCatalogCarInfoCard>
      <Card radius={20}>
        <main className="catalog__car__info__main">
          <section className="catalog__car__info__top">
            <div className="catalog__car__info__top__left">
              <div
                className={cl("status__wrap", {
                  warning: carInfo.status === "бронь",
                  danger: carInfo.status === "занята",
                })}
              >
                <IconWrapper
                  icon={getIcon(carInfo.status)}
                  w={40}
                  h={40}
                  bg="light"
                  color={getIconColor(carInfo.status)}
                />
                <p>{getTitle(carInfo.status, car.car_list[0].rent_end_date)}</p>
              </div>
            </div>
            <div className="action__wrap">
              <IconWrapper
                icon={checkComparsion ? "scales-minus" : "scales-plus"}
                w={48}
                h={48}
                rounded={14}
                border={!checkComparsion}
                bg={checkComparsion ? "success" : "light"}
                color={checkComparsion ? "light" : "dark"}
                onClick={() =>
                  checkComparsion
                    ? deleteCarFromCarComparsionList({ car, ...carInfo })
                    : addCarToCarComparsionList({ car, ...carInfo })
                }
              />
              <IconWrapper
                icon="file"
                w={48}
                h={48}
                rounded={14}
                border={1}
                onClick={() =>
                  changeCarEquipment({ car: { ...car, ...carInfo }, tab: 1 })
                }
              />
              <IconWrapper
                icon="settings"
                w={48}
                h={48}
                rounded={14}
                border={1}
                onClick={() =>
                  changeCarEquipment({ car: { ...car, ...carInfo }, tab: 2 })
                }
              />
            </div>
          </section>
          <ul className="catalog__car__info__bottom">
            <li>
              <div className="car__color__icon__wrap">
                <IconWrapper
                  icon="palette icon-sm"
                  w={35}
                  h={35}
                  bg="primary-10"
                  color="primary"
                />
                <span
                  style={{
                    backgroundColor: carInfo.parameters.color_code || "#000",
                  }}
                />
              </div>
              <div>
                <h1>Цвет</h1>
                <p>{carInfo.parameters.color}</p>
              </div>
            </li>
            <li>
              <IconWrapper
                icon="calendar icon-sm"
                w={35}
                h={35}
                bg="primary-10"
                color="primary"
              />
              <div>
                <h1>Год выпуска</h1>
                <p>{carInfo.parameters.year}</p>
              </div>
            </li>
            <li>
              <IconWrapper
                icon="transmission icon-sm"
                w={35}
                h={35}
                bg="primary-10"
                color="primary"
              />
              <div>
                <h1>Коробка передач</h1>
                <p>{car.is_auto ? "Автомат" : "Механика"}</p>
              </div>
            </li>
          </ul>
          <ul
            className="catalog__car__info__bottom "
            style={{ marginTop: "30px" }}
          >
            <li>
              <IconWrapper
                icon="state-number icon-sm"
                w={35}
                h={35}
                bg="primary-10"
                color="primary"
              />
              <div>
                <h1>Гос. номер</h1>
                <CarNumber title={carInfo.gosnomer} size="xs" />
              </div>
            </li>
            <li>
              <IconWrapper
                icon="engine icon-sm"
                w={35}
                h={35}
                bg="primary-10"
                color="primary"
              />
              <div>
                <h1>Тип двигателя</h1>
                <p>{carInfo.parameters.engine_type}</p>
              </div>
            </li>
            <li>
              <IconWrapper
                icon="drive icon-sm"
                w={35}
                h={35}
                bg="primary-10"
                color="primary"
              />
              <div>
                <h1>Тип привода</h1>
                <p>{carInfo.parameters.drive}</p>
              </div>
            </li>
          </ul>
        </main>
      </Card>
    </StyledCatalogCarInfoCard>
  );
}

const StyledCatalogCarInfoCard = styled.div`
  .catalog__car__info {
    &__main {
      padding: 30px;


      @media(max-width: 1400px) {
        padding: 10px;
      }
    }

    &__top {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      gap: 30px;
      margin-bottom: 30px;

      &__left {
        display: flex;
        align-items: center;
        gap: 20px;
      }

      .status__wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 20px;
        background-color: var(--success-20);
        border-radius: 24px;
        padding: 4px;
        padding-right: 30px;

        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          color: var(--success-dark);
        }

        &.warning {
          background-color: var(--warning-10);

          p {
            color: var(--warning-dark);
          }
        }

        &.danger {
          background-color: var(--danger-10);

          p {
            color: var(--danger-dark);
          }
        }
      }

      .action__wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 10px;
      }
    }

    &__bottom {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      gap: 10px;
      padding: 0 20px;

      li {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 20px;
        min-width: 165px;

        h1 {
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 0em;
          color: var(--dark-70);
        }

        p {
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0em;
          color: var(--dark);
        }

        .car__color__icon__wrap {
          position: relative;

          span {
            display: inline-block;
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            bottom: 0;
            right: 0;
          }
        }
      }
    }
  }
`;
