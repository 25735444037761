import React, { useState, useEffect } from "react";

import { useAppContext } from "../../../../../store/Context";
import IconWrapper from "../../../../../components/icon-wrapper/IconWrapper";
import Card from "../../../../../components/card/Card";
import Button from "../../../../../components/button/Button";
import Checkbox from "../../../../../components/checkbox/Checkbox";
import NumberFormat from "../../../../../components/number-format/NumberFormat";
import Loader from "../../../../../components/loader/Loader";
import "./TotalPriceRent.styles.scss";
import { useWindowDimensions } from "../../../../../hooks/useWindowDimensions";
import Modal from "../../../../../components/modal/Modal";

export default function TotalPriceRent({
  isLoading,
  onSubmitSend,
  sendBtnIsDisabled,
  totalPrice,
  dailyPrice,
  discount,
  discountSum,
  changeDiscount,
  isBooking,
  changeBooking,
  checkboxBookingDisabled,
  handleSaveToDraft,
  draftBtIsDisabled,
}) {
  const { width } = useWindowDimensions();

  const [isMobile, setIsMobile] = useState(false);
  const [isOpenDiscount, setIsOpenDiscount] = useState(false);

  useEffect(() => {
    width < 1280 ? setIsMobile(true) : setIsMobile(false);
  }, [width]);

  const {
    state: { user: agent },
    fn: { changeIsOpenCalculator },
  } = useAppContext();

  useEffect(() => {
    const discountInput = document.getElementById("discountInput");
  }, [discount]);

  return (
    <>
      <Card radius={20} className="total-price">
        <main className="total-price__main">
          {isMobile ? (
            <div className="mobile-total-price">
              <div
                className="price-block  price-block__text--btn"
                onClick={() => setIsOpenDiscount(true)}
              >
                {discount > 0 ? (
                  <>
                    <p className="price-block__number">
                      {" "}
                      {discount} % (<NumberFormat value={discountSum} />)
                    </p>
                    <p className="price-block__text">Скидка</p>
                  </>
                ) : (
                  <p className="price-block__text">
                    Добавьте <br /> скидку
                  </p>
                )}
              </div>
              <div className="price-block">
                <p className="price-block__number">
                  {" "}
                  <NumberFormat value={Math.floor(dailyPrice)} />
                </p>
                <p className="price-block__text "> В сутки</p>
              </div>
              <div className="price-block">
                <div className="sum-price">
                  {discount > 0 ? (
                    <p className="price-block__number">
                      <NumberFormat value={totalPrice - discountSum} />{" "}
                    </p>
                  ) : (
                    <p className="price-block__number">
                      <NumberFormat value={totalPrice} />
                    </p>
                  )}
                </div>
                <p className="price-block__text">Итого</p>
              </div>
            </div>
          ) : (
            <>
              <div className="total-price__top">
                <div className="total-price__total">
                  <h1>Итого</h1>
                  <div className="price-with-discount">
                    {discount > 0 ? (
                      <span className="price-with-discount__line-through">
                        <NumberFormat value={totalPrice} />
                      </span>
                    ) : (
                      <span></span>
                    )}
                    <div className="sum-price">
                      {discount > 0 ? (
                        <span>
                          <NumberFormat value={totalPrice - discountSum} />{" "}
                        </span>
                      ) : (
                        <span>
                          <NumberFormat value={totalPrice} />
                        </span>
                      )}
                      <p className="price-per-day">
                        {" "}
                        <NumberFormat value={Math.floor(dailyPrice)} /> в сутки
                      </p>
                    </div>
                  </div>
                </div>
                <div className="total-price__list">
                  <div>
                    <h1>Скидка</h1>
                    {discount > 0 ? (
                      <span className="discount">
                        <NumberFormat value={discountSum} />
                      </span>
                    ) : (
                      <span></span>
                    )}
                  </div>
                  <div
                    className="input-discount"
                    onClick={() =>
                      document.getElementById("discountInput").focus()
                    }
                  >
                    <h1>
                      <input
                        value={discount}
                        onChange={(e) => changeDiscount(e.target.value)}
                        id="discountInput"
                      />{" "}
                      <span>%</span>
                    </h1>
                  </div>
                </div>
              </div>
            </>
          )}
          {agent?.has_extended_rights && (
            <div className="total-price__booking">
              <h1>Создать бронирование</h1>
              <Checkbox
                checked={isBooking}
                onClick={changeBooking}
                disabled={checkboxBookingDisabled}
              />
            </div>
          )}
          <div className="total-price__footer">
            {isMobile && (
              <IconWrapper
                icon="calc-1 icon-xl"
                rounded={12}
                border={1}
                w={46}
                h={46}
                onClick={(e) => {
                  changeIsOpenCalculator(true);
                  e.stopPropagation();
                }}
              />
            )}
            <Button
              radius={14}
              color="success"
              fill={1}
              p={[15]}
              textUpperCase={1}
              fW={500}
              onClick={onSubmitSend}
              disabled={sendBtnIsDisabled || isLoading}
            >
              {isLoading && <Loader bg="success" color="light" w={32} h={32} />}
              Отправить
            </Button>
            {isMobile && (
              <IconWrapper
                icon="save icon-lg"
                w={46}
                h={46}
                rounded={12}
                border={1}
                onClick={handleSaveToDraft}
                disabled={draftBtIsDisabled}
                isLoading={isLoading}
              />
            )}
          </div>
        </main>
      </Card>
      {isMobile && (
        <Modal
          isOpen={isOpenDiscount}
          onClose={() => setIsOpenDiscount(false)}
          title={"Добавить скидку"}
          direction="Bottom"
          height="Sm"
        >
          <div
            className="input-discount"
            onClick={() => document.getElementById("discountInput").focus()}
          >
            <h1>
              <input
                value={discount}
                onChange={(e) => changeDiscount(e.target.value)}
                id="discountInput"
              />{" "}
              <span>%</span>
            </h1>
          </div>
          <div
            className="modal-discount__btn"
            onClick={() => setIsOpenDiscount(false)}
          >
            Добавить
          </div>
        </Modal>
      )}
    </>
  );
}
