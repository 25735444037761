import React, { useEffect, useState } from "react";

//
import "./CounterpartyInfo.styles.scss";
import Card from "../../../../components/card/Card";
import Input from "../../../../components/input/Input";
import Checkbox from "../../../../components/checkbox/Checkbox";
import TextArea from "../../../../components/textarea/TextArea";

export default function CounterpartyInfo({
  phone,
	info,
	extraInfo,
	pdtv,
	avtoGotovit,
	infoForSb,
	isEdit,
	onChangeLocalBooking,
}) {

	const [isPdtv, setIsPdtv] = useState(false);
	const [isAvtoGotovit, setIsAvtoGotovit] = useState(false);

	useEffect(() => {
		setIsPdtv(pdtv);
		setIsAvtoGotovit(avtoGotovit);
	}, [avtoGotovit, pdtv])

	const handleChangePdtv = () => {
    setIsPdtv(!isPdtv);
    onChangeLocalBooking("pdtv", !isPdtv);
  };

	const handleChangeAvtoGotovit = () => {
    setIsAvtoGotovit(!isAvtoGotovit);
    onChangeLocalBooking("avto_gotovit", !isAvtoGotovit);
  };

 

  return (
		<Card radius={12}>
			<div className="counterparty-info">
				<div className="counterparty-info__header-wrap">
					<div className="counterparty-info__header">
						<h3>Телефон контрагента</h3>
						<Input 
							placeholder="Введите телефон"
							size={"sm"}
							fill={1}
							defaultValue={phone}
							disabled={!isEdit}
							id="phone"
						/>
					</div>
					<div className="counterparty-info__header">
						<h3>Инфо. по контрагенту</h3>
						<Input 
							placeholder="Введите информацию"
							size={"sm"}
							fill={1}
							defaultValue={info}
							disabled={!isEdit}
							id="info"
						/>
					</div>
					<div className="counterparty-info__checkbox">
						<Checkbox 
							checked={isPdtv}
							onClick={handleChangePdtv}
							disabled={!isEdit}
							size={"sm"}
						/>
						<p>Подтв.</p>
					</div>
					<div className="counterparty-info__checkbox">
						<Checkbox 
							checked={isAvtoGotovit}
							onClick={handleChangeAvtoGotovit}
							disabled={!isEdit}
							size={"sm"}
						/>
						<p>Авто готовить</p>
					</div>
				</div>
				<div className="counterparty-info__header-wrap">
					<div className="counterparty-info__header">
						<h3>Доп. информация</h3>
						<TextArea
							placeholder="До 500 символов"
							rows={2}
							defaultValue={extraInfo}
							name="note"
							disabled={!isEdit}
							id="extra_info"
						/>
					</div>
					<div className="counterparty-info__header">
						<h3>Инфо. для выдачи СБ</h3>
						<TextArea
							fill={1}
							placeholder="Инфо. для выдачи СБ"
							rows={2}
							defaultValue={infoForSb}
							disabled={!isEdit}
							id="info_for_sb"
						/>
					</div>
				</div>
			</div>
		</Card>
  );
}