/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";

//
import Consts from "../util/const";
import { useGetPriceList } from "../store/config/hook";
import { useGetUserInfoQuery } from "../store/user/hook";
import { useGetCarList } from "./catalog/hook";
import { useGetRequests } from "./dashboard/hook";
import { useWindowDimensions } from "../hooks/useWindowDimensions";

//
const AppContext = createContext(null);
export const useAppContext = () => useContext(AppContext);

//
export default function Context({ children }) {
  const { width } = useWindowDimensions();
  // state
  const [isAuth, setIsAuth] = useState(
    !!localStorage.getItem(Consts.APP_TOKEN)
  );
  const [cityList, setCityList] = useState([]);
  const [cityId, setCityId] = useState("");
  const [tempPriceLoading, setTempPriceLoading] = useState(false);
  const [reqCar, setReqCar] = useState(null);
  const [reqClient, setReqClient] = useState(null);
  const [carComparsionList, setCarComparsionList] = useState([]);
  const [carEquipment, setCarEquipment] = useState(null);
  const [userInfoModal, setUserInfoModal] = useState(false);
  const [optionMatrix, setOptionMatrix] = useState([]);
  const [draftsCount, setDraftsCount] = useState(0);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isOpenCalculator, setIsOpenCalculator] = useState(false);
  const [isOpenCatalogCar, setIsOpenCatalogCar] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  
  useEffect(() => {
    width < 1280 ? setIsMobile(true) : setIsMobile(false)
  }, [width]);

  useEffect(() => {
    if (!tempPriceLoading) return;
    setTimeout(() => {
      setTempPriceLoading(false);
    }, 500);
  }, [tempPriceLoading]);

  // query
  let { isLoading: priceLoading, data } = useGetPriceList({
    enabled: isAuth,
  });

  let { data: agent, isLoading: agentInfoLoading } = useGetUserInfoQuery({
    enabled: isAuth,
  });
  agent = agent?.data?.data;

  let { data: requestsList, isLoading: getRequestsLoading } = useGetRequests({
    enabled: isAuth,
  });

  const requestResult = useMemo(() => {
    const countObj = {
      all: 0,
      closed: 0,
      inProcess: 0,
      booked: 0,
      canceled: 0,
      failured: 0,
    };
    const draft = [];
    const list = [];
    const archived = [];
    if (!requestsList) return { statusCount: countObj, draft, list, archived };
    const arr = [...requestsList?.data?.request_list].map((i) => {
      const tempObj = {
        ...i,
        ...i.status_history[i.status_history.length - 1],
      };
      return tempObj;
    });
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].is_archived) {
        archived.push(arr[i]);
        continue;
      }
      countObj.all += 1;
      if (arr[i].status_id === 1) {
        draft.push(arr[i]);
        continue;
      }
      list.push(arr[i]);
      if (arr[i].status_id === 2 || arr[i].status_id === 3) {
        countObj.inProcess += 1;
        continue;
      }
      if (arr[i].status_id === 4 || arr[i].status_id === 5) {
        countObj.booked += 1;
        continue;
      }
      if (arr[i].status_id === 6) {
        countObj.canceled += 1;
        continue;
      }
      if (arr[i].status_id === 7) {
        countObj.failured += 1;
        continue;
      }
      if (arr[i].status_id === 8 || arr[i].status_id === 9) {
        countObj.closed += 1;
        continue;
      }
    }
    changeDraftsCount(draft.length);
    return { statusCount: countObj, draft, list, archived };
  }, [requestsList]);

  const priceId = useMemo(() => {
    if (!cityId) return null;
    return cityList.find((i) => i.id === cityId)?.price_id;
  }, [cityList, cityId]);

  const { data: carList, isLoading: getCarListLoading } = useGetCarList(
    cityId,
    priceId,
    {
      enabled: isAuth && !!priceId,
    }
  );

  useEffect(() => {
    if (!data) return;
    const cityArr = [];

    data?.data?.price_lists.forEach((i) => {
      cityArr.push({
        name: i.city_name,
        id: i.city_id,
        price_id: i.price_list_id,
        offices: i.offices,
      });
    });

    setCityList([...cityArr]);
    if (!cityId) {
      setCityId(cityArr[0].id);
    }
  }, [data]);

  const addOptions = useMemo(() => {
    let tempObj = {
      simple: [],
      delivery: [],
      insurance: [],
      child_seat: [],
      travel: [],
    };
    if (!data || !cityId) return tempObj;

    const item = data?.data?.price_lists.find((i) => i.city_id === cityId);
    setOptionMatrix(item.option_matrix);
    item.option_matrix.forEach((i) => {
      if (i.option_type === "delivery") {
        let listId = tempObj.delivery.map((d) => d.option_id);

        if (listId.includes(i.option_id)) {
          return;
        }
      }
      if (i.option_type) {
        tempObj[i.option_type] = tempObj[i.option_type]
          ? [...tempObj[i.option_type], i]
          : [i];
      }
    });
    return tempObj;
  }, [cityId, data]);

  // method
  function changeIsAuth(v) {
    setIsAuth(v);
  }

  function changeCityId(id) {
    setCityId(id);
    localStorage.setItem("CITY_ID", id);
    setTempPriceLoading(true);
  }

  function changeReqCar(rc) {
    setReqCar(rc);
  }

  function changeReqClient(rc) {
    setReqClient(rc);
  }

  function addCarToCarComparsionList(car) {
    setCarComparsionList((p) => [...p, car]);
  }

  function deleteCarFromCarComparsionList(car) {
    setCarComparsionList((p) =>
      p.filter((i) => !(i.gosnomer === car.gosnomer))
    );
  }

  function resetCarComparsionList(list) {
    setCarComparsionList(list);
  }

  function changeCarEquipment(ce) {
    setCarEquipment(ce);
  }

  function changeUserInfoModal(uim) {
    setUserInfoModal(uim);
  }

  function changeIsOpenMenu(iom) {
    setIsOpenMenu(iom);
  }

  function changeIsOpenCalculator(ioc) {
    setIsOpenCalculator(ioc);
  }

  function changeIsOpenCatalogCar(iocc) {
    setIsOpenCatalogCar(iocc);
  }

  function changeDraftsCount(dc) {
    setDraftsCount(dc);
  }

  return (
    <AppContext.Provider
      value={{
        state: {
          isAuth,
          priceLoading,
          cityList,
          cityId,
          priceId,
          user: agent,
          tempPriceLoading,
          addOptions,
          reqCar,
          reqClient,
          carComparsionList,
          carComparsionListLength: carComparsionList.length,
          carEquipment,
          userInfoModal,
          isOpenMenu,
          isOpenCalculator,
          isOpenCatalogCar,
          agentInfoLoading,
          carList,
          getCarListLoading,
          optionMatrix,
          draftsCount,
          getRequestsLoading,
          requestResult,
          isMobile,
        },
        fn: {
          changeIsAuth,
          changeCityId,
          changeReqCar,
          changeReqClient,
          addCarToCarComparsionList,
          deleteCarFromCarComparsionList,
          resetCarComparsionList,
          changeCarEquipment,
          changeUserInfoModal,
          changeDraftsCount,
          changeIsOpenMenu,
          changeIsOpenCalculator,
          changeIsOpenCatalogCar,
        },
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
