import { v4 } from "uuid";

export function getCarsCount(list) {
  return list.reduce((total, i) => {
    total += i.car_list.length;
    return total;
  }, 0);
}

export function arrDateToUnix(arr) {
  return new Date(arr[2], arr[1], arr[0], arr[3], arr[4]).getTime() / 1000;
}

export function unixDateToArr(unix) {
  const time = new Date(unix * 1000);
  return [
    time.getDate(),
    time.getMonth(),
    time.getFullYear(),
    time.getHours(),
    time.getMinutes(),
  ];
}


export function getUnicCarList(arr) {
  let unicArr = [];
  let result = [];

  arr.forEach((i) => {
    let ex = i.brand + i.model + i.generation;
    if (!unicArr.includes(ex)) {
      result.push(i);
      unicArr.push(ex);
    }
  });

  return result;
}

export function onSortCarList(arr) {
  if (!arr) return [];
  let result = {};

  arr.forEach((i) => {
    let ex = i.brand + i.model + i.generation + i.class;
    if (result[ex]) {
      if (i.status === "свободна") {
        result[ex] = { ...i, car_list: [i, ...result[ex].car_list] };
      } else {
        result[ex].car_list = [...result[ex].car_list, i];
      }
    } else {
      result[ex] = { ...i, car_list: [i] };
    }
  });
  return Object.values(result);
}

export function genMarkList(list) {
  let arr = [];
  list.forEach((i) => {
    let mark = arr.find((j) => j.mark === i.brand);
    if (mark) {
      let model = mark.models.find((k) => k.model === i.model);
      if (!model) {
        mark.models = [
          ...mark.models,
          { id: v4(), model: i.model, checked: false, mark: i.brand },
        ];
      }
    } else {
      mark = {
        id: v4(),
        mark: i.brand,
        checked: false,
        models: [
          {
            id: v4(),
            model: i.model,
            checked: false,
            mark: i.brand,
          },
        ],
      };
      arr.push(mark);
    }
  });
  return arr;
}

export function genLocationList(list) {
  let arr = [];
  list.forEach((i) => {
    i.car_list.forEach((k) => {
      if (k.point_address) {
        if (!arr.find((j) => j.value === k.point_address)) {
          arr.push({
            id: v4(),
            title: k.point_address,
            value: k.point_address,
          });
        }
      }
    });
  });
  return arr;
}

export function hidePhoneNumberGen(p) {
  if (!p) return "-";
  p = filterPhoneNumber(p).split("");

  return `+${p[0]} *** ${p[p.length - 4]}${p[p.length - 3]}-${p[p.length - 2]}${
    p[p.length - 1]
  }`;
}

export function formatPhoneNumber(p) {
  // +7 (999) 087 18-89
  if (!p) return "-";
  p = filterPhoneNumber(p).split("");
  p = ["+", ...p];
  p.splice(2, 0, "(");
  p.splice(2, 0, " ");
  p.splice(7, 0, ")");
  p.splice(8, 0, " ");
  p.splice(12, 0, " ");
  p.splice(15, 0, " ");
  return p.join("");
}

const phoneValues = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

export function filterPhoneNumber(phone) {
  phone = String(phone)
    .split("")
    .filter((i) => phoneValues.includes(i));

  if (phone.length > 11) {
    phone = phone.splice(0, 11);
  }

  return phone.join("");
}

export function filterForOnlyNumbers(value) {
  return String(value)
    .split("")
    .filter((i) => phoneValues.includes(i))
    .join("");
}

export function getCarNumberMain(n) {
  n = n.split("").slice(0, 6);
  n.splice(1, 0, " ");
  n.splice(5, 0, " ");

  return n;
}

export function getCarNumberSup(n) {
  return n.split("").slice(6);
}

export function countMarkModel(arr) {
  let modelList = [];
  let markList = [];

  arr.forEach((i) => {
    if (i.checked) {
      markList.push(i.mark);
    }

    i.models.forEach((k) => {
      if (k.checked) {
        modelList.push(i.mark + " " + k.model);
      }
    });
  });

  return {
    modelList,
    markList,
  };
}

export function formatPrice(price) {
  let tempValue = [];
  price = String(price).split("");
  while (price.length > 3) {
    tempValue.unshift(price.splice(price.length - 3).join(""));
  }
  tempValue.unshift(price.join(""));
  return tempValue.join(" ");
}
