import styled from "styled-components";

//
import IconWrapper from "../icon-wrapper/IconWrapper";
import Loader from "../loader/Loader";

export default function AgentChatTemplate({
  chat = [],
  radius = "0px",
  hideIcon,
  isLoading,
}) {
  return (
    <StyledAgentChatTemplate radius={radius}>
      {isLoading && <Loader w={24} h={24} size={3} bg='warning-10' color="dark-70" radius={0} />}
      {!hideIcon && (
        <IconWrapper
          icon="message-text-circle icon-sm"
          w={32}
          h={32}
          rounded={8}
          bg="warning"
          color="light"
        />
      )}
      <ul>
        {chat.map((i, idx) => (
          <li key={idx} dangerouslySetInnerHTML={{ __html: '— ' + i }} />
        ))}
      </ul>
    </StyledAgentChatTemplate>
  );
}

const StyledAgentChatTemplate = styled.div`
  padding: 10px;
  padding-right: 20px;
  background-color: var(--warning-10);
  border: 1px solid var(--dark-5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  border-radius: ${({ radius }) => radius};
  position: relative;
  min-height: 50px;

  ul {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3px;

    li {
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0em;
      color: var(--dark);

      b {
        font-weight: 600;
      }
    }
  }
`;
