import React from "react";
//
import IconWrapper from "../icon-wrapper/IconWrapper";
import NumberFormat from "../number-format/NumberFormat";
import CarNumber from "../car-number/CarNumber";
import ReqOptionBadge from "../req-option-badge/ReqOptionBadge";
import { msToNormTime } from "../../util/date";
import "./ReqInfoCard.styles.scss";

export default function ReqInfoCard({ request }) {
  function isKeyEmpty() {
    return (
      <div className="empty_display">
        <span className="warning_circle" />—
      </div>
    );
  }

  return (
    <div type={request?.status_id} className="req__info__card">
      <nav className="req__info__card__top">
        <ul>
          <li>
            <IconWrapper
              icon="currency-ruble"
              w={44}
              h={44}
              rounded={12}
              bg="success-20"
              color="success-dark"
            />
            <div>
              <p>Заработок</p>
              <h1>
                <NumberFormat value={request.agent_comission || 0} />
              </h1>
            </div>
          </li>
          <li>
            <IconWrapper
              icon="file"
              w={44}
              h={44}
              rounded={12}
              bg="primary-10"
              color="primary"
            />
            <div>
              <p>Сумма сделки</p>
              <h1>
                <NumberFormat value={request?.price_total || 0} />
              </h1>
            </div>
          </li>
          <li>
            <IconWrapper
              icon="calendar"
              w={44}
              h={44}
              rounded={12}
              bg="dark-5"
              color="dark"
            />
            <div>
              <p>Аренда в сутки</p>
              <h1>
                <NumberFormat value={request?.rent_price_day || 0} />
              </h1>
            </div>
          </li>
        </ul>
      </nav>
      <main className="req__info__card__body">
        <div className="rent__period_display__wrap">
          Срок аренды: <span>{request?.rent_period} дней</span>
        </div>
        <ul>
          <li className="tarif__date">
            <div>
              <h1>Тариф</h1>
              {request?.tariff ? (
                <p>{request?.tariff + "км / сутки"}</p>
              ) : (
                isKeyEmpty()
              )}
            </div>
          </li>
          <li className="car__info">
            <h1>Автомобиль</h1>
            {request.price_unit_id ? (
              <div>
                <p>
                  {request?.brand} {request?.model} {request?.generation}
                </p>
                <CarNumber size="xs" title={request?.gosnomer} />
              </div>
            ) : (
              isKeyEmpty()
            )}
          </li>
          <li className="add__options">
            <h1>Доп. опции</h1>
            <ul>
              {request.options.length > 0
                ? request.options.map((i, index) => (
                    <li key={index}>
                      <ReqOptionBadge
                        title={i.name}
                        count={i.amount}
                        type={i.name.includes("водитель") ? "чел." : "шт."}
                      />
                    </li>
                  ))
                : isKeyEmpty()}
            </ul>
          </li>
          <li className="wish__client">
            <h1>Пожелания клиента</h1>
            {request?.wish ? <p>{request.wish}</p> : isKeyEmpty()}
          </li>
        </ul>
      </main>
      <footer className="req__info__card__bottom">
        <FooterCard
          title="Выдача"
          data={request?.vidacha}
          isKeyEmpty={isKeyEmpty}
        />
        <FooterCard
          title="Возврат"
          data={request?.vozvrat}
          isKeyEmpty={isKeyEmpty}
        />
      </footer>
    </div>
  );
}

function FooterCard({ title, data, isKeyEmpty }) {
  return (
    <div className="footer-card">
      <nav>
        <IconWrapper
          icon={
            title === "Выдача"
              ? "arrow-up-right icon-sm"
              : "arrow-down-left icon-sm"
          }
          w={32}
          h={32}
          bg="primary-10"
          color="primary"
        />
        <h1>{title}</h1>
      </nav>
      <ul>
        <li>
          <h2>Дата</h2>
          {data?.date ? <p>{msToNormTime(data?.date, 7)}</p> : isKeyEmpty()}
        </li>
        <li>
          <h2>{data?.point_name}</h2>
          {data?.address ? <p>{data.address}</p> : isKeyEmpty()}
        </li>
      </ul>
    </div>
  );
}
