import React, { useEffect, useState } from "react";
import cl from "classnames";

//
import Badge from "../badge/Badge";
import Tab from "../tab/Tab";
import IconWrapper from "../icon-wrapper/IconWrapper";
import CarNumber from "../car-number/CarNumber";
import carImg from "../../asset/img/haval.png";
import "./CarEquipment.styles.scss";
import { useAppContext } from "../../store/Context";

export default function CarEquipment({ car, modalTab }) {
  const {
    state: { isMobile },
  } = useAppContext();

  const today = new Date().getTime();
  const carEndDate = car?.car_list[0].rent_end_date * 1000;

  const diffInDays = Math.floor(Math.abs(carEndDate - today) / (1000 * 60 * 60 * 24));

  const [tab, setTab] = useState(1);

  useEffect(() => {
    if (!car) return;
    setTab(modalTab);
  }, [modalTab, car]);

  function changeTab(t) {
    setTab(t);
  }

  function getBadgeBg(status) {
    if (status === "занята") return "danger-10";
    if (status === "бронь") return "warning-20";
    if (status === "свободна") return "success-15";
  }

  function getBadgeColor(status) {
    if (status === "занята") return "danger-dark";
    if (status === "бронь") return "warning-dark";
    if (status === "свободна") return "success-dark";
  }

  const getDaysString = () => {
    if (diffInDays % 10 === 1 & diffInDays !== 11) return ` ${diffInDays} день`;
    if (diffInDays % 10 === 2 || diffInDays % 10 === 3 || diffInDays % 10 === 4) return `${diffInDays} дня`;

    return ` ${diffInDays} дней`;
  }

  if (!car) return null;

  return (
    <>
      <nav className="car__equipment__nav">
        <div>
          <h1 className="car__equipment__nav__h1">
            {car.brand} <CarNumber size="sm" title={car.gosnomer} />
          </h1>
          <p className="car__equipment__nav__p">
            {car.model} {car.generation}
          </p>
          <Badge
            title={car.status}
            bg={getBadgeBg(car.status)}
            color={getBadgeColor(car.status)}
          />
        </div>
        <img src={car.image_url || carImg} alt="carImg" width={250} />
      </nav>
      {car.status === "бронь" && !isMobile && (
        <div className={cl("car__equipment__booking__frame", {})}>
          <IconWrapper
            icon="clock-circle-z icon-xl"
            w={80}
            h={80}
            bg="warning-10"
            color="warning-dark"
          />
          <div>
            <h1>Автомобиль забронирован</h1>
            <p>{`Он освободится через ${getDaysString()}.`}</p>
          </div>
        </div>
      )}

      <div className="car__equipment__tab">
        <Tab
          options={[
            { id: 1, title: "Комплектация", value: 1 },
            { id: 2, title: "Характеристики", value: 2 },
          ]}
          value={tab}
          onChange={changeTab}
        />
      </div>
      <ul className="car__equipment__ul">
        {tab === 1 ? (
          <>
            <li>
              <h1>Партроники</h1>
              <p>{car.setup.p_sensors ? "Есть" : "Нет"}</p>
            </li>
            <li>
              <h1>Кондиционер</h1>
              <p>{car.setup.air_cond ? "Есть" : "Нет"}</p>
            </li>
            <li>
              <h1>Климат контроль</h1>
              <p>{car.setup.climate ? "Есть" : "Нет"}</p>
            </li>
            <li>
              <h1>Круиз контроль</h1>
              <p>{car.setup.cruise ? "Есть" : "Нет"}</p>
            </li>
            <li>
              <h1>Камера заднего вида</h1>
              <p>{car.setup.back_cam ? "Есть" : "Нет"}</p>
            </li>
            <li>
              <h1>Подлокотник</h1>
              <p>{car.setup.armrest ? "Есть" : "Нет"}</p>
            </li>
            <li>
              <h1>Обогрев сидений/стекл</h1>
              <p>{car.setup.seat_heat ? "Есть" : "Нет"}</p>
            </li>
            <li>
              <h1>Обогрев лобового стекла</h1>
              <p>{car.setup.windshield_heat ? "Есть" : "Нет"}</p>
            </li>
          </>
        ) : (
          <>
            <li>
              <h1>Цвет</h1>
              <p>
                <span
                  className="color__square"
                  style={{ backgroundColor: car.parameters.color_code }}
                />
                {car.parameters.color}
              </p>
            </li>
            <li>
              <h1>Вместимость</h1>
              <p>{car.parameters.capacity} чел.</p>
            </li>
            <li>
              <h1>Максимальная скорость</h1>
              <p>{car.parameters.max_speed || "-"} км/ч</p>
            </li>
            <li>
              <h1>Мощность, л.с.</h1>
              <p>{car.parameters.engine_power}</p>
            </li>
            <li>
              <h1>Тип двигателя</h1>
              <p>{car.parameters.engine_type}</p>
            </li>
            <li>
              <h1>Объем двигателя</h1>
              <p>{car.parameters.engine_vol} л</p>
            </li>
            <li>
              <h1>Объем топливного бака</h1>
              <p>{car.parameters.tank_vol} л</p>
            </li>
            <li>
              <h1>Грузоподъемность</h1>
              <p>{car.parameters.load_capacity} кг</p>
            </li>
            <li>
              <h1>Объем багажника</h1>
              <p>{car.parameters.trunk_vol} л</p>
            </li>
            <li>
              <h1>Тип привода</h1>
              <p>{car.parameters.drive}</p>
            </li>
          </>
        )}
      </ul>
    </>
  );
}
