import React from "react";
import styled from "styled-components";
import cl from "classnames";

export default function TabV2({ value, onChange, options }) {
  return (
    <StyledTabV2>
      {options.map((i) => (
        <li
          key={i.id}
          className={cl({
            active: value === i.value,
          })}
          onClick={() => onChange(i.value)}
        >
          {i.title}
        </li>
      ))}
    </StyledTabV2>
  );
}

const StyledTabV2 = styled.ul`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 4px;
  background-color: var(--dark-3);
  border-radius: 8px;
  padding: 4px;
  width: max-content;

  li {
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    padding: 5px 10px;
    background-color: var(--light);
    border: 1px solid var(--dark-5);
    box-shadow: 0px 2px 5px var(--dark-5);
    border-radius: 8px;
    cursor: pointer;

    &.active {
        background-color: var(--primary);
        color: var(--light);
    }
  }
`;
