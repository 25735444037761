import React from "react";
import styled, { css } from "styled-components";

export default function TextArea({ withEffect, rows = 5, ...props }) {
  return (
    <StyledTextArea withEffect={withEffect}>
      <textarea rows={rows} {...props}></textarea>
    </StyledTextArea>
  );
}

const StyledTextArea = styled.div`
  border-radius: 14px;
  border: 1px solid var(--primary-dark-15);
  overflow: hidden;
  padding-right: 2px;

  textarea {
    resize: none;
    width: 100%;
    padding: 14px;
    padding-left: 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    border: none;

    &::placeholder {
      color: var(--dark-50);
    }

    &:focus {
      outline: none;
      ${({ withEffect }) =>
        withEffect &&
        css`
          border-color: var(--primary);
        `}
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--dark-15);
      border-radius: 2px;
    }
  }
`;
