import React, { useMemo } from "react";
import styled, { css } from "styled-components";

//
import IconWrapper from "../icon-wrapper/IconWrapper";

export default function SortBtn({ label, value, onChange, size }) {
  function changeSort() {
    if (value === 0) {
      onChange(1);
      return;
    }
    if (value === 1) {
      onChange(-1);
      return;
    }
    onChange(0);
  }

  const icon = useMemo(() => {
    let icon = "arrow-down";
    if (value === 0) {
      icon = "switch-vertical";
    }
    if (value === 1) {
      icon = "arrow-up";
    }
    if (size === "sm") {
      icon += " icon-xs";
    } else {
      icon += " icon-sm";
    }
    return icon;
  }, [value, size]);

  return (
    <StyledSortBtn onClick={changeSort} size={size}>
      {label && <p className="sort__btn__label">{label}</p>}
      <IconWrapper
        w={size === "sm" ? 28 : 32}
        h={size === "sm" ? 28 : 32}
        icon={icon}
        bg={value === 0 ? "dark-3" : "primary"}
        color={value === 0 ? "dark-30" : "light"}
      />
    </StyledSortBtn>
  );
}

const StyledSortBtn = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;

  .sort__btn__label {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: var(--dark);
  }

  ${({ size }) =>
    size === "sm" &&
    css`
      .sort__btn__label {
        font-size: 12px;
        line-height: 16px;
      }
    `}
`;
