import React, { useState } from "react";
import ROCH from "react-outside-click-handler";
//
import { useAppContext } from "../../../../../store/Context";
import IconWrapper from "../../../../../components/icon-wrapper/IconWrapper";
import Switch from "../../../../../components/switch/Switch";
import SendLeadModal from "../../../../../components/send-lead-modal/SendLeadModal";
import Loader from "../../../../../components/loader/Loader";
import ClientRentPeriod from "../../components/client-rent-period/ClientRentPeriod";
import ClientRentTariff from "../../components/client-rent-tariff/ClientRentTariff";
import ClientTravelLocation from "../../components/client-travel-location/ClientTravelLocation";
import ClientBaseInfo from "../../components/client-base-info/ClientBaseInfo";
import AdditionalOptions from "../../components/additional-options/AdditionalOptions";
import ChildSeat from "../../components/child-seat/ChildSeat";
import RentalAddress from "../../components/RentalAddress";
import "./ReqCreateLeft.styles.scss";
import SelectedCarCard from "../../components/selected-car-card/SelectedCarCard";

export default function ReqCreateLeft({
  addOptions,
  cityList,
  appeal,
  changeClient,
  ageExp,
  changeAgeExp,
  travelRegion,
  changeTravelRegion,
  vidacha,
  changeVidacha,
  vozvrat,
  changeVozvrat,
  tariff,
  changeTariff,
  options,
  changeOptions,
  carFranchise,
  priceMatrixIndex,
  isShowScripts,
  changeIsShowScripts,
  viewCarList,
  changeViewCarList,
  rentPeriod,
  carAddress,
  priceVidachaType,
  priceVozvratType,
  tariffIndex,
  note,
  changeNote,
  points,
  handleSaveToDraft,
  draftBtIsDisabled,
  isLoading,
  staticDataLoading,
  agentChatTemplates,
  clientList,
  editContentLoading,
  reqId,
  car,
  changeCar
}) {

  const {
    state: { isMobile },
    fn: { changeIsOpenCatalogCar },
  } = useAppContext();

  const [isOpenSettings, setIsOpenSettings] = useState(false);
  const [isLeadModalOpen, setIsLeadModalOpen] = useState(false);

  function changeIsOpenSettings(ios) {
    setIsOpenSettings(ios);
  }

  function changeIsLeadModalOpen(ilmo) {
    setIsLeadModalOpen(ilmo);
  }

  function getChatTemplate(list, id) {
    return list.find((i) => i.script_id === id);
  }

  return (
    <div className="req-create-left">
      <div className="req_create_left__top">
        {
          (!reqId) ?
          <h1>Создать заявку</h1>
          :
          <h1>Редактировать заявку</h1>
        }
        <div>
          <ROCH onOutsideClick={() => changeIsOpenSettings(false)}>
            <div className="view_settings__wrap">
              <IconWrapper
                icon="settings icon-sm"
                rounded={12}
                border={1}
                onClick={() => changeIsOpenSettings(!isOpenSettings)}
              />
              {isOpenSettings && (
                <ul>
                  <li>
                    <p>Видимость скриптов</p>
                    <div>
                      <Switch
                        value={isShowScripts}
                        onChange={changeIsShowScripts}
                      />
                    </div>
                  </li>
                  <li>
                    <p>Вид списка в каталоге</p>
                    <div>
                      <IconWrapper
                        icon="rows icon-sm"
                        border={viewCarList === "column"}
                        bg={viewCarList === "row" ? "primary" : "light"}
                        color={viewCarList === "row" ? "light" : "dark"}
                        rounded={12}
                        onClick={() => changeViewCarList("row")}
                      />
                      <IconWrapper
                        icon="columns icon-sm"
                        border={viewCarList === "row"}
                        bg={viewCarList === "column" ? "primary" : "light"}
                        color={viewCarList === "column" ? "light" : "dark"}
                        rounded={12}
                        onClick={() => changeViewCarList("column")}
                      />
                    </div>
                  </li>
                </ul>
              )}
            </div>
          </ROCH>
          {!isMobile && <IconWrapper
            icon="save icon-sm"
            rounded={12}
            border={1}
            onClick={handleSaveToDraft}
            disabled={draftBtIsDisabled}
            isLoading={isLoading}
          />}
        </div>
      </div>
      {isMobile && 
        <>
          {
            !car ?
            <div className="choose-car" onClick={() => changeIsOpenCatalogCar(true)}>
            <p>Выбрать автомобиль</p>
            </div>
            :
            <SelectedCarCard 
              car={car} 
              changeCar={changeCar} 
              isEditBooking={true}
            />
          }
        </>
      }
      <div className="req_create_left__main">
        {reqId && editContentLoading && <Loader radius={12} zIndex={5} />}
        <ClientBaseInfo
          appeal={appeal}
          changeClient={changeClient}
          ageExp={ageExp}
          changeAgeExp={changeAgeExp}
          isShowScripts={isShowScripts}
          staticDataLoading={staticDataLoading}
          agentChatTemplate={getChatTemplate(agentChatTemplates, 1)}
          clientList={clientList}
        />
        <ClientTravelLocation
          appeal={appeal}
          travelRegion={travelRegion}
          changeTravelRegion={changeTravelRegion}
          cityList={cityList}
          travel={addOptions?.travel}
          options={options}
          changeOptions={changeOptions}
          isShowScripts={isShowScripts}
          staticDataLoading={staticDataLoading}
          agentChatTemplate={getChatTemplate(agentChatTemplates, 2)}
        />
        <ClientRentPeriod
          appeal={appeal}
          vidacha={vidacha}
          changeVidacha={changeVidacha}
          vozvrat={vozvrat}
          changeVozvrat={changeVozvrat}
          rentPeriod={rentPeriod}
          isShowScripts={isShowScripts}
          staticDataLoading={staticDataLoading}
          agentChatTemplate={getChatTemplate(agentChatTemplates, 3)}
          isEditBooking={true}
        />
        <ClientRentTariff
          tariff={tariff}
          changeTariff={changeTariff}
          isShowScripts={isShowScripts}
          staticDataLoading={staticDataLoading}
          agentChatTemplate={getChatTemplate(agentChatTemplates, 4)}
        />
        <RentalAddress
          isShowScripts={isShowScripts}
          carAddress={carAddress}
          vidacha={vidacha}
          changeVidacha={changeVidacha}
          vozvrat={vozvrat}
          changeVozvrat={changeVozvrat}
          priceVidachaType={priceVidachaType}
          priceVozvratType={priceVozvratType}
          tariffIndex={tariffIndex}
          options={options}
          changeOptions={changeOptions}
          points={points}
          staticDataLoading={staticDataLoading}
          agentChatTemplate={getChatTemplate(agentChatTemplates, 5)}
          isEditBooking={true}
        />
        <AdditionalOptions
          isEdit={true}
          appeal={appeal}
          options={options}
          changeOptions={changeOptions}
          addOptions={addOptions}
          carFranchise={carFranchise}
          priceMatrixIndex={priceMatrixIndex}
          isShowScripts={isShowScripts}
          staticDataLoading={staticDataLoading}
          agentChatTemplate={getChatTemplate(agentChatTemplates, 6)}
        />
        <ChildSeat
          isEditBooking={true}
          options={options}
          changeOptions={changeOptions}
          childSeat={addOptions.child_seat}
          priceMatrixIndex={priceMatrixIndex}
          note={note}
          changeNote={changeNote}
        />
      </div>
      <SendLeadModal
        isOpen={isLeadModalOpen}
        onClose={() => changeIsLeadModalOpen(false)}
      />
    </div>
  );
}

