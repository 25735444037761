import React, { useMemo } from "react";
import styled from "styled-components";

//
import AgentChatTemplate from "../../../../components/agent-chat-template/AgentChatTemplate";
import AddressPicker from "../../../../components/address-picker/AddressPicker";

export default function RentalAddress({
  isShowScripts,
  carAddress,
  vidacha,
  changeVidacha,
  vozvrat,
  changeVozvrat,
  priceVidachaType,
  priceVozvratType,
  tariffIndex,
  options,
  changeOptions,
  points,
  staticDataLoading,
  agentChatTemplate,
  isEditBooking,
}) {

  const chatListGenerator = useMemo(() => {
    if (!agentChatTemplate) return [];
    const list = [...agentChatTemplate.script_text];
    list[0] = list[0].replace(
      "(АДРЕС)",
      `<b>${carAddress || "Car Address"}</b>`
    );
    return list;
  }, [agentChatTemplate, carAddress]);

  return (
    <StyledRentalAddress>
      {isShowScripts && (
        <AgentChatTemplate
          chat={chatListGenerator}
          radius="12px 12px 0 0"
          isLoading={staticDataLoading}
        />
      )}
      <div className="rental_address__form">
        <AddressPicker
          type="vidacha"
          obj={vidacha}
          onChange={changeVidacha}
          priceType={priceVidachaType}
          tariffIndex={tariffIndex}
          options={options}
          changeOptions={changeOptions}
          points={points}
          isEditBooking={isEditBooking}
        />
        <AddressPicker
          type="vozvrat"
          obj={vozvrat}
          onChange={changeVozvrat}
          priceType={priceVozvratType}
          tariffIndex={tariffIndex}
          options={options}
          changeOptions={changeOptions}
          points={points}
          isEditBooking={isEditBooking}
        />
      </div>
    </StyledRentalAddress>
  );
}

const StyledRentalAddress = styled.div`
  background: var(--light);
  box-shadow: 0px 2px 5px var(--dark-5);
  border-radius: 12px;

  .rental_address {
    &__form {
      padding: 10px 20px;
      display: -ms-grid;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 15px;
      min-height: 95px;
      position: relative;

      @media (max-width: 1600px) {
        grid-template-columns: none;
      }
    }
  }
`;
