import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

//
import IconWrapper from "../icon-wrapper/IconWrapper";
import ClientBlackModal from "../client-black-modal/ClientBlackModal";
import ClientDeleteModal from "../client-delete-modal/ClientDeleteModal";
import { formatPhoneNumber } from "../../util/formatters";
import ToolTipComponent from "../tool-tip-component/ToolTipComponent";
import { useAppContext } from "../../store/Context";
import "./ClientListItem.styles.scss";

export default function ClientListItem({ idx, client }) {
  // helper
  const navigate = useNavigate();

  const {
    state: { isMobile },
  } = useAppContext();

  // state
  const [modal, setModal] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  //   method
  function handleChangeModal(m) {
    setModal(m);
  }

  function handleChangeDeleteModal(m) {
    setDeleteModal(m);
  }

  function onNavigateToUrl(url) {
    navigate(url);
  }

  return (
    <>
      <div
      className="client__list__item"
        idx={idx}
        onClick={(e) => {
          e.stopPropagation();
          onNavigateToUrl("/client/" + client.client_id);
        }}
      >
        <section className="client__list__item__left">
          {client.black_listed && <i className="icon icon-slash-circle" />}
          <div>
            <h1>{client.name.split(" ")[0]}</h1>
            <p>
              {client.name.split(" ")[1]} {client.name.split(" ")[2]}
            </p>
          </div>
        </section>
        <section className="client__list__item__right">
          {!isMobile && <div className="req__wrapper">{client.requests_count} заявок</div>}
          <div 
            className={`phone__wrapper ${client.black_listed && "phone__wrapper--red"}`}>
            {formatPhoneNumber(client.phone)}
          </div>
          {!isMobile && 
          <>
            <div className="action__wrapper">
              {client.black_listed ? (
                <ToolTipComponent text={"вернуть из черного списка"}>
                  <IconWrapper
                    w={48}
                    h={48}
                    bg="light"
                    icon="reverse-left"
                    border={1}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleChangeModal("revert");
                    }}
                  />
                </ToolTipComponent>
              ) : (
                <>
                <ToolTipComponent text={"в черный список"}>
                  <IconWrapper
                    w={48}
                    h={48}
                    bg="danger"
                    color="light"
                    icon="slash-circle"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleChangeModal("send");
                    }}
                  />
                </ToolTipComponent>
                </>
              )}
              <ToolTipComponent text={"удалить"}>
                <IconWrapper
                  w={48}
                  h={48}
                  bg="light"
                  icon="trash"
                  border={1}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleChangeDeleteModal(true);
                  }}
                />
              </ToolTipComponent>
            </div>
            <i className="icon icon-chevron-right" />
          </>
          }
        </section>
      </div>
      {modal && (
        <ClientBlackModal
          isOpen={modal}
          onClose={() => handleChangeModal(false)}
          client={client}
        />
      )}
      {deleteModal && (
        <ClientDeleteModal
          isOpen={deleteModal}
          onClose={() => handleChangeDeleteModal("")}
          clientId={client.client_id}
        />
      )}
    </>
  );
}


