import React, { useState } from "react";
//
import Card from "../../../../../components/card/Card";
import Search from "../../../../../components/search/Search";
import Input from "../../../../../components/input/Input";
import ReqListEmpty from "../../../../../components/req-list-empty/ReqListEmpty";
import Loader from "../../../../../components/loader/Loader";
import ClientListItem from "../../../../../components/client-list-item/ClientListItem";
import "./ClientList.styles.scss";

export default function ClientList({
  clientList,
  isLoading,
  search,
  onChangeSearch,
}) {

  // state
  const [isSearch, setIsSearch] = useState(false);

  // method
  function toggleIsSearch() {
    setIsSearch((p) => !p);
  }

  let notFound = false;
  return (
    <>
      <Card>
        {isLoading && <Loader />}
        <div className="client__list__content">
          <nav className="client__list__nav">
            <section className="client__list__title">
              <h1>{clientList.length}</h1>
              <p>
                Ваших <br /> клиентов
              </p>
            </section>
            <section className="client__list__search">
              {isSearch && (
                <Input
                  icon="search"
                  withValueEffect={1}
                  value={search}
                  onChange={(e) => onChangeSearch(e.target.value)}
                />
              )}
              <Search isOpen={isSearch} changeSearchIsOpen={toggleIsSearch} />
            </section>
          </nav>
          <main className="client__list__main">
            {isSearch && search && notFound ? (
              <ReqListEmpty notFound={1} title="Ничего не найдено" />
            ) : (
              <ul className="client__list__main__ul">
                {clientList.map((i, idx) => (
                  <li key={i.client_id}>
                    <ClientListItem
                      idx={idx}
                      client={i}
                    />
                  </li>
                ))}
              </ul>
            )}
          </main>
        </div>
      </Card>
    </>
  );
}