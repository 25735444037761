import React, { useState, useEffect } from "react";
//
import Button from "../../../../components/button/Button";
import Loader from "../../../../components/loader/Loader";
import Card from "../../../../components/card/Card";
import Input from "../../../../components/input/Input";
import ControlInfo from "../../components/control-info/ControlInfo";
import Auditor from "../../components/auditor/Auditor";
import ClientRentTariff from "../../../agent/req-create-v2/components/client-rent-tariff/ClientRentTariff";
import CounterpartyInfo from "../../components/counterparty-info/CounterpartyInfo";
import "./BookingInfoControl.styles.scss";
import { msToNormTime } from "../../../../util/date";
import Checkbox from "../../../../components/checkbox/Checkbox";
import DriverBooking from "../../components/driver-booking/DriverBooking";
import OptionsBooking from "../../components/options-booking/OptionsBooking";
import OverrunBooking from "../../components/overrun-booking/OverrunBooking";

export default function BookingInfoControl({
  bookingId,
  isEdit,
  onToggleEdit,
  localBooking,
  addOptions,
  tariff,
  changeTariff,
  travel,
  options,
  changeOptions,
  carFranchise,
  priceMatrixIndex,
  isLoading,
  staticDataLoading,
  onChangeLocalBooking,
  onSaveEdit,
  onCancelEdit,
  refBookingInfoControl,
  changeIsOpenAddDriverModal,
}) {

  const [isRework, setIsRework] = useState(false);

  //new Booking
  const {
    date_created: dateCreated,
    counterparty_id: counterpartyId,
    counterparty: counterpartyName,
    signer,
    signer_id: signerId,
    counterparty_fio: counterpartyNickName,
    long_term_rent: isLongTermRent,
    is_legal_entity: isLegalEntity,
    client_status: clientStatus,
    counterparty_check: checkCounerparty,
    driver_id: driverId,
    driver: driverName,
    operates_on_basis: operatesBasis,
    driver_check: checkDriver,
    info,
    info_extra: extraInfo,
    pdtv,
    phone,
    avto_gotovit: avtoGotovit,
    add_drivers_list: addDriversList,
    needsRework,
    info_for_sb: infoForSb,
    order,
  } = localBooking;

  useEffect(() => {
    setIsRework(needsRework);
  }, [needsRework]);

  const isStatusSuccess = localBooking?.booking_status === "success";

  const handleChangeRework = () => {
    setIsRework(!isRework);
    onChangeLocalBooking("needs_rework", !isRework);
  };

  return (
    <div className="booking-information" ref={refBookingInfoControl}>
      <div className="booking-information__top">
        <div className="title">
          <h1>
            {`Бронирование № ${bookingId}`}
            {isStatusSuccess && (
              <span className="title__status">
                <i className=" icon icon-check-circle icon-lg"></i>
              </span>
            )}
          </h1>
          <p>{msToNormTime(dateCreated, 7)}</p>
        </div>
        <div className="title-right">
          {!isEdit ? (
            <Button
              radius={12}
              color="light"
              border={"1"}
              size={"sm"}
              textUpperCase={1}
              fW={500}
              p={[8, 16]}
              onClick={onToggleEdit}
            >
              {isLoading && <Loader bg="success" color="light" w={32} h={32} />}
              Изменить
              <i className="icon icon-sm icon-pencil" />
            </Button>
          ) : (
            <div className="button-wrap">
              <Button
                radius={12}
                color="light"
                border={"1"}
                size={"sm"}
                textUpperCase={1}
                fW={500}
                p={[8, 16]}
                onClick={onCancelEdit}
              >
                {isLoading && (
                  <Loader bg="success" color="light" w={32} h={32} radius={10}/>
                )}
                Отмена
              </Button>
              <Button
                radius={12}
                color="success"
                size={"sm"}
                fill={1}
                textUpperCase={1}
                fW={500}
                p={[8, 16]}
                onClick={onSaveEdit}
              >
                {isLoading && (
                  <Loader bg="success" color="light" w={32} h={32} />
                )}
                Сохранить
                <i className="icon icon-sm icon-save" />
              </Button>
            </div>
          )}
          <div className="rework">
            <p>Требуется доработка</p>
            <Checkbox
              checked={isRework}
              onClick={handleChangeRework}
              disabled={!isEdit}
              size={"sm"}
            />
          </div>
        </div>
      </div>
      <div className="booking-information__main">
        {/* {reqId && editContentLoading && <Loader radius={12} zIndex={5} />} */}
        <Card radius={12}>
          <div className="contractor-name">
            <p>ФИО Контрагента</p>
            <Input
              size={"sm"}
              placeholder="Имя"
              disabled={!isEdit}
              defaultValue={counterpartyNickName}
              id="counterparty_name"
            />
          </div>
        </Card>
        <Card radius={12}>
          <div className="control-wrapper">
            <ControlInfo
              counterparty={{
                counterparty_id: counterpartyId,
                counterparty: counterpartyName,
              }}
              signer={{
                counterparty_id: signerId,
                counterparty: signer,
              }}
              clientStatus={clientStatus}
              isLongTermRent={isLongTermRent}
              isLegalEntity={isLegalEntity}
              onChangeLocalBooking={onChangeLocalBooking}
              isEdit={isEdit}
            />

            <Auditor check={checkCounerparty} />
          </div>
        </Card>
        <DriverBooking
          counterparty={{
            counterparty_id: driverId,
            counterparty: driverName,
          }}
          onChangeLocalBooking={onChangeLocalBooking}
          contract={operatesBasis}
          isEdit={isEdit}
          addDriversList={addDriversList}
          checkDriver={checkDriver}
          changeIsOpenAddDriverModal={changeIsOpenAddDriverModal}
          changeOptions={changeOptions}
          addOptions={addOptions}
        />
        <CounterpartyInfo
          phone={phone}
          info={info}
          extraInfo={extraInfo}
          pdtv={pdtv}
          avtoGotovit={avtoGotovit}
          infoForSb={infoForSb}
          isEdit={isEdit}
          onChangeLocalBooking={onChangeLocalBooking}
        />
        <ClientRentTariff
          tariff={tariff}
          changeTariff={changeTariff}
          staticDataLoading={staticDataLoading}
        />
        <OptionsBooking
          isEdit={isEdit}
          travel={travel}
          options={options}
          changeOptions={changeOptions}
          addOptions={addOptions}
          carFranchise={carFranchise}
          priceMatrixIndex={priceMatrixIndex}
        />
        <OverrunBooking
          isEdit={isEdit}
          order={order}
          options={options}
          changeOptions={changeOptions}
          addOptions={addOptions}
          carFranchise={carFranchise}
        />
      </div>
    </div>
  );
}
