import React, { useEffect, useState } from "react";
//
import Card from "../../../../../components/card/Card";
import IconWrapper from "../../../../../components/icon-wrapper/IconWrapper";
import ReqList from "../../../dashboard/sections/req-list/ReqList";
import { Collapse } from "react-collapse";
import "./FinanceMonthItem.styles.scss";
import Button from "../../../../../components/button/Button";
import NumberFormat from "../../../../../components/number-format/NumberFormat";
import ReqCancelModal from "../../../../../components/req-cancel-modal/ReqCancelModal";
import FinanceSuccessModal from "../finance-success-modal/FinanceSuccessModal";
import FinanceReportModal from "../finance-report-modal/FinanceReportModal";
import { useAppContext } from "../../../../../store/Context";
import ReqCreateApi from "../../../../../store/req-create/api";
import { useMutation } from "react-query";

export default function FinanceMonthItem({ report }) {
  const {
    state: { isMobile },
  } = useAppContext();

  const reportRequest = useMutation(
    "report-request",
    (reportId) => ReqCreateApi.getReportRequestById(reportId),
    { enabled: false }
  );

  useEffect(() => {
    if (reportRequest.isSuccess) {
      let { request_list } = reportRequest.data.data;
      setRequestList(request_list);
    }
  }, [reportRequest.isSuccess, reportRequest.data]);

  // state
  let [requestList, setRequestList] = React.useState([]);
  const [isOpenCollapse, setIsOpenCollapse] = useState(false);
  const [cause, setCause] = useState("");
  const [isOpenReject, setIsOpenReject] = useState(false);
  const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false);
  const [isOpenReportModal, setIsOpenReportModal] = useState(false);

  const isAccepted = report?.is_accepted;
  const sumToPay = report?.sum_to_pay;
  const countInMonth = report?.deals_count;
  const sumInMonth = report?.deals_sum;

  // method
  function getIcon() {
    if (!isAccepted) return "alert-circle";
    if (isAccepted) return "check";
  }

  function getIconColor() {
    if (!isAccepted) return "danger";
    if (isAccepted) return "success";
  }

  function getIconBgColor() {
    if (!isAccepted) return "danger-10";
    if (isAccepted) return "success-10";
  }

  function toggleIsOpen() {
    if (isOpenCollapse === false) {
      reportRequest.mutate(report.report_id);
    }
    setIsOpenCollapse((p) => !p);
  }

  return (
    <div className="month__item__container">
      <Card>
        <section className="month__item__top" onClick={toggleIsOpen}>
          <div className="month__item__top__title">
            <IconWrapper
              icon={getIcon()}
              color={getIconColor()}
              bg={getIconBgColor()}
              w={48}
              h={48}
              rounded={50}
            />
            <div>
              <h1>{report?.report_name}</h1>
              <p>{report?.report_note}</p>
            </div>
          </div>
          <div className={`month__item__top__cards`}>
            <div className={`month__item__top__cards__count`}>
              <h1>{countInMonth}</h1>
              <p>Всего сделок</p>
            </div>
            <div className={`month__item__top__cards__count`}>
              <h1>
                <NumberFormat value={sumInMonth} />
              </h1>
              <p>Сумма всех сделок</p>
            </div>
            <div className={`month__item__top__cards__count count--pay`}>
              <h1 className={`${isAccepted ? "pay-success" : "pay-warning"}`}>
                <NumberFormat value={sumToPay} />
              </h1>
              <p>К выплате</p>
            </div>
          </div>
          {!isMobile && (
            <i
              style={{
                transform: `rotate(${isOpenCollapse ? "-180deg" : "0deg"})`,
              }}
              className="icon icon-chevron-down"
            />
          )}
        </section>
        <Collapse isOpened={isOpenCollapse} borderColor={getIconColor()}>
          <ReqList
            list={requestList}
            finance={1}
            isLoading={reportRequest.isLoading}
          />
        </Collapse>
        <section className="month__item__footer">
          <div
            className="report__button"
            onClick={() => setIsOpenReportModal(true)}
          >
            <IconWrapper
              icon={"paperclip"}
              color={"primary"}
              bg={"primary-10"}
              w={42}
              h={42}
              rounded={12}
            />
            <div>
              <p>Смотреть расчет</p>
              <p>От {report?.date_created}</p>
            </div>
          </div>
          {isAccepted ? (
            <div className="info-report">
              <p>Расчет согласован</p>
              <p>{report?.date_accepted}</p>
            </div>
          ) : (
            <div className="month__item__footer__btn-wrap">
              <Button
                p={[16, 30]}
                color="danger"
                radius={14}
                textUpperCase={1}
                fW={500}
                onClick={() => setIsOpenReject(true)}
              >
                Отклонить
              </Button>
              <Button
                p={[16, 30]}
                color={"primary"}
                radius={14}
                textUpperCase={1}
                fW={500}
                onClick={() => setIsOpenSuccessModal(true)}
              >
                Согласовать
              </Button>
            </div>
          )}
        </section>
      </Card>
      <FinanceReportModal
        isOpen={isOpenReportModal}
        onClose={() => setIsOpenReportModal(false)}
        title="Смотреть расчет"
        text={``}
        // onSubmit={}
      />
      <FinanceSuccessModal
        isOpen={isOpenSuccessModal}
        onClose={() => setIsOpenSuccessModal(false)}
        title="Подтвердите свое действие"
        text={`Я ознакомился(лась) и потверждаю отчет за ${report?.report_name} года`}
        // onSubmit={}
      />
      <ReqCancelModal
        isOpen={isOpenReject}
        onClose={() => setIsOpenReject(false)}
        title="Подтвердите свое действие"
        text="Просим указать причины, по которым вы отклоняете отчет"
        // onSubmit={}
        btnTitle="Отклонить"
        value={cause}
        onChange={(v) => setCause(v)}
      />
    </div>
  );
}
