import React from "react";
import styled, { css } from "styled-components";

export default function Input({
  icon,
  fill,
  error,
  withValueEffect,
  size,
  ...props
}) {
  return (
    <StyledInput
      fill={fill}
      error={error}
      withValueEffect={withValueEffect && props.value}
      size={size}
    >
      <input type="text" {...props} formNoValidate autoComplete="off" />
      {error && <p className="error-text">{error}</p>}
      {icon && <i className={"icon icon-" + icon}></i>}
    </StyledInput>
  );
}

const StyledInput = styled.div`
  position: relative;

  & > input {
    width: 100%;
    background: var(--light);
    border: 1px solid var(--primary-dark-15);
    border-radius: 14px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: var(--dark);
    padding: 12px 20px;
    transition: 0.2s linear;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: var(--dark-40);
      font-weight: 400;
    }

    &:disabled {
      background-color: var(--dark-5);
    }
  }

  i {
    position: absolute;
    top: 50%;
    right: 14px;
    transform: translate(0, -50%);
    background-color: var(--primary-dark-15);
  }

  .error-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    color: var(--danger);
    margin-top: 5px;
    padding-left: 10px;
    width: 300px;
  }

  ${({ error }) =>
    error &&
    css`
      & > input {
        border-color: var(--danger);
      }
    `}

  ${({ withValueEffect }) =>
    withValueEffect &&
    css`
      & > input {
        border-color: var(--primary);
      }

      i {
        background-color: var(--primary);
      }
    `}

    ${({ size }) =>
    size === "sm"
      ? css`
          width: 270px;

          & > input {
            padding: 10px 14px 10px 20px;
            font-size: 14px;
            line-height: 17px;
            border-radius: 12px;
          }
        `
      : size === "xs"
      ? css`
          width: 240px;

          & > input {
            padding: 6px 10px;
            font-size: 12px;
            line-height: 17px;
            border-radius: 10px;
          }
        `
      : null}

  ${({ fill }) =>
    fill &&
    css`
      width: 100%;
    `}
`;
