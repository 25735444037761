import React from "react";
//
import NumberFormat from "../number-format/NumberFormat";
import "./PayCard.styles.scss";

const ColorBlock = {
  warning: "color--warning",
  primary: "color--primary",
}

export default function PayCard({ summa, title, icon, color, outlined }) {

  const classNameColor = ColorBlock[color];

  const border = outlined === 1 ? "border" : "";

  return (
    <div color={color} outlined={outlined} className={`pay__card  ${classNameColor} ${border}`}>
      <section className="pay__card__icon">
        <i className={"icon icon-" + icon}></i>
      </section>
      <section className="pay__card__info">
        <h1>
          <NumberFormat value={summa} decimalScale={0} />
        </h1>
        <p>{title}</p>
      </section>
    </div>
  );
}
