import React, { useState } from "react";
//
import Input from "../input/Input";
import Search from "../search/Search";
import "./BlockFilterMobile.styles.scss";
import SortBtn from "../sort-btn/SortBtn";

export default function BlockFilterMobile({
	isFilterOpen,
  changeIsFilterOpen,
  search,
  onChangeSearch,
	priceSort,
  changePriceSort,
}) {
  //   state
  const [isSearch, setIsSearch] = useState(false);

  //   method
  function changeIsSearch(v) {
    setIsSearch(v);
  }

  return (
    <div className='block-filter-mobile' isSearch={isSearch}>
			{isSearch ? (
        <Input
          fill={1}
          placeholder="Поиск автомобиля"
          icon="search"
          withValueEffect={1}
          value={search}
          onChange={(e) => onChangeSearch(e.target.value)}
        />
      ) : (
        <>
        <div className="block-filter-mobile__sort">
        	<SortBtn
						label="Цена"
						value={priceSort}
						onChange={changePriceSort}
						size="md"
        	/>
      	</div>
				<div className="block-filter-mobile__filter"
					onClick={() => changeIsFilterOpen(!isFilterOpen)}>
					<p>Фильтры</p>
				</div>
        </>
      )}
      <Search isOpen={isSearch} changeSearchIsOpen={changeIsSearch} />
    </div>
  );
}
