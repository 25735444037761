import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
//
import IconWrapper from "../../../../../components/icon-wrapper/IconWrapper";
import ProgressBar from "../../../../../components/preogress-bar/ProgressBar";
import Loader from "../../../../../components/loader/Loader";
import { formatPhoneNumber } from "../../../../../util/formatters";
import { msToNormTime } from "../../../../../util/date";
import "./Drafts.styles.scss";

export default function Drafts({
  list,
  changeSelectedDraftRequest,
  handleDeleteRequest,
  isLoading,
}) {

  const [isOpenModal, setIsOpenModal] = useState(false);

  function changeIsOpenModal(iom) {
    setIsOpenModal(iom);
  }

  return (
    <div className="drafts">
      {isLoading && <Loader />}
      <h1>Черновики</h1>
      <ul>
        {list.map((i) => (
          <ListItem
            key={i.request_id}
            item={i}
            onClick={() => changeSelectedDraftRequest(i)}
            handleDeleteRequest={handleDeleteRequest}
            changeIsOpenModal={changeIsOpenModal}
            isOpenModal={isOpenModal}
          />
        ))}
      </ul>
    </div>
  );
}

function ListItem({ item, isOpenModal, changeIsOpenModal, handleDeleteRequest, ...props }) {
  const navigate = useNavigate();

  const progress = useMemo(() => {
    const total = 7;
    let val = 0;
    if (item.client_id) {
      val += 1;
    }
    if (item.gosnomer) {
      val += 1;
    }
    if (item.tariff) {
      val += 1;
    }
    if (item.vidacha.date) {
      val += 1;
    }
    if (item.vidacha.point_id) {
      val += 1;
    }
    if (item.vozvrat.date) {
      val += 1;
    }
    if (item.vozvrat.point_id) {
      val += 1;
    }
    return (val / total) * 100;
  }, [item]);

  function getProgressBg(v) {
    if (v < 50) {
      return "danger";
    }
    if (v > 90) {
      return "success";
    }
    return "warning";
  }

  function navigateToEdit(id) {
    navigate("/req-create/" + id, {state : {isEdit: 1}});
    localStorage.removeItem("REQUEST_CREATE_OBJ");
    localStorage.removeItem("ADDRESS_PICKER_vidacha");
    localStorage.removeItem("ADDRESS_PICKER_vozvrat");
  }

  return (
    <li {...props}>
      <section>
        <nav>
          <div>
            <h1>{item?.client_name}</h1>
            <p>
              {formatPhoneNumber(item?.phone)}
            </p>
          </div>
        </nav>
        <ProgressBar fill={1} bg={getProgressBg(progress)} value={progress} />
        <p>
          Создано {msToNormTime(item?.date_created, 7)}
        </p>
      </section>
      <section>
        <IconWrapper
          icon="trash icon-sm"
          border={1}
          rounded={12}
          onClick={(e) => handleDeleteRequest(e, item.request_id)}
        />
        <IconWrapper
          icon="pencil icon-sm"
          border={1}
          rounded={12}
          onClick={() => navigateToEdit(item.request_id)}
        />
      </section>
    </li>
  );
}