import React, { useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import cl from "classnames";
//
import defaultCarImg from "../../asset/img/sad.png";
import Button from "../button/Button";
import NumberFormat from "../number-format/NumberFormat";
import CatalogCarList from "../catalog-car-list/CatalogCarList";
import CatalogCarTerm from "../catalog-car-term/CatalogCarTerm";
import CatalogRentTerm from "../catalog-rent-term/CatalogRentTerm";
import { useAppContext } from "../../store/Context";
import "./CatalogCarRowMobile.styles.scss";
import ModalFullMobile from "../modal-full-mobile/ModalFullMobile";
import Card from "../card/Card";
import  Modal  from "../modal/Modal";

export default function CatalogCarRowMobile({
  car,
  inModal,
  selectedCar,
  onChangeSelectedCar,
  reqCreate,
  tariffIndex = 0,
  priceMatrixIndex,
  coef,
  oneTimeOptionsPrice = 0,
  perDayOptionsPrice,
  perDailyPrice,
  changeIsOpenCatalogCar,
}) {
  
  // helper
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isComparsion = pathname.includes("comparsion");

  // context
  const {
    state: { carComparsionList },
    fn: {
      changeReqCar,
      addCarToCarComparsionList,
      deleteCarFromCarComparsionList,
    },
  } = useAppContext();
  // state
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenConditions, setIsOpenConditions] = useState(false);

  const changeIsOpenModal = (iom) => {
    setIsOpen(iom);
  }

  const checkSelected = useMemo(() => {
    if (!selectedCar) return;
    return selectedCar?.price_unit_id === car.price_unit_id;
  }, [selectedCar, car]);

  const checkForComparsion = useMemo(() => {
    return carComparsionList
      .map((i) => i.price_unit_id)
      .includes(car.price_unit_id);
  }, [carComparsionList, car]);

  const carCount = useMemo(() => {
    return car.car_list.length;
  }, [car]);

  const carInfo = useMemo(() => {
    return { ...car.car_list[0] };
  }, [car]);

  const carPrice = useMemo(() => {
    return Math.floor(
      car.price_matrix[tariffIndex ?? 0].prices[priceMatrixIndex ?? 3].price *
        coef +
        perDayOptionsPrice +
        oneTimeOptionsPrice
    );
  }, [
    car,
    tariffIndex,
    priceMatrixIndex,
    coef,
    perDayOptionsPrice,
    oneTimeOptionsPrice,
  ]);

  // price per day without
  const dailyCarPrice = useMemo(() => {
    return Math.floor(
      car.price_matrix[tariffIndex ?? 0].prices[priceMatrixIndex ?? 3].price +
      perDailyPrice
    );
  }, [car, tariffIndex, priceMatrixIndex, perDailyPrice]);

  function navigateReqCreate(e) {
    e.stopPropagation();
    navigate(`/req-create`);
    if (carCount > 1) {
      changeReqCar(car);
    } else {
      changeReqCar({ ...car, ...carInfo });
    }
  }

  const carStatus = useMemo(() => {
    const obj = {
      свободна: 0,
      бронь: 0,
      занята: 0,
    };
    car.car_list.forEach((i) => {
      obj[i.status] += 1;
    });
    if (obj.свободна > 0) return "свободна";
    if (obj.бронь > 0) return "бронь";
    return "занята";
  }, [car]);

  return (
    <div className="catalog-mobile"
      // isOpen={isOpen}
      // inModal={inModal}
      // reqCreate={reqCreate}
    >
      <nav className="catalog-mobile__nav" onClick={() => setIsOpen(true)}>
        <section className="catalog-mobile__nav-left">
					<div className="car-info">
						<div
							className={cl("car-info__status", {
								warning: "бронь" === carStatus,
								danger: "занята" === carStatus,
							})}
						/>
						
						<div className="car-info__name">
							<h1>{car.brand + " " + car.model + " " + car.generation}</h1>
							<p>
								Класс {car.class}{" "}
								{carCount === 1 && carInfo?.point_address ? (
									<>
										<span className="circle" /> {carInfo?.point_address}{" "}
									</>
								) : null}
							</p>
						</div>
					</div>
					<div className="car-price">
						<div
							className={cl("car-isauto-wrap", {
								success: car.is_auto,
							})}
						>
							{car.is_auto ? "A" : "M"}
						</div>
						{reqCreate ? (
            <>
              <div className="car-price__frame">
								<p>
                	<NumberFormat value={carPrice} />
								</p>
                <p className="per_day_container">
                  <NumberFormat value={dailyCarPrice} />
                </p>
              </div>
            </>
						) : (
							<div className="rent__sum__wrap">
								<p>
									От{" "}
									<NumberFormat
										value={car.price_matrix[tariffIndex].prices[3].price}
									/> в сутки
								</p>
							</div>
						)}
					</div>
        </section>
        <section className="catalog-mobile__nav-right">
          <div className="car__image__wrap">
            <img
              src={car.image_url || defaultCarImg}
              alt="carImg"
              width={reqCreate ? "102px" : "108px"}
            />
            {carCount > 1 ? <p>{carCount}</p> : null}
          </div>
        </section>
      </nav>
			<ModalFullMobile
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				title={car.brand + " " + car.model + " " + car.generation}
			>
          <nav className="catalog-mobile__nav catalog-mobile__nav--modal">
            <section className="catalog-mobile__nav-left">
              <div className="car-info">
                <div className="car-info__name">
                  <h1>{car.brand + " " + car.model + " " + car.generation}</h1>
                  <p>
                    Класс {car.class}{" "}
                    {carCount === 1 && carInfo?.point_address ? (
                      <>
                        <span className="circle" /> {carInfo?.point_address}{" "}
                      </>
                    ) : null}
                  </p>
                </div>
              </div>
              <div className="car-info">
                <div
                  className={cl("car-info__isauto", {
                    success: car.is_auto,
                  })}
                >
                  {car.is_auto ? "A" : "M"}
                </div>
                {!(inModal || reqCreate) && (
                  <div className="car-info__conditions" onClick={() => setIsOpenConditions(true)}>
                    <p>Условия аренды</p>
                  </div>
                )}
              </div>
            </section>
            <section className="catalog-mobile__nav-right">
              <div className="car__image__wrap">
                <img
                  src={car.image_url || defaultCarImg}
                  alt="carImg"
                  width={reqCreate ? "120px" : "108px"}
                />
              </div>
            </section>
          </nav>
        <main className="catalog-mobile__main">
          <Card>
              <CatalogRentTerm
                priceMatrix={car.price_matrix[tariffIndex].prices}
                priceMatrixIndex={priceMatrixIndex}
              />
            <div className="catalog-mobile__button">
            {inModal || reqCreate ? (
              <>
                {isComparsion ? (
                  <Button
                    p={[14, 26]}
                    radius={14}
                    fW={500}
                    color={checkForComparsion ? "success" : ""}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (checkForComparsion) {
                        deleteCarFromCarComparsionList({ ...car, ...carInfo });
                      } else {
                        addCarToCarComparsionList({ ...car, ...carInfo });
                      }
                    }}
                  >
                    {checkForComparsion ? "Добавлено" : "Добавить"}
                  </Button>
                ) : (
                  <Button
                    radius={14}
                    border={1}
                    p={reqCreate ? [12, 26] : [14, 26]}
                    fW={reqCreate ? 400 : 500}
                    color={checkSelected ? "success" : "light"}
                    size={reqCreate ? "sm" : ""}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (carCount > 1) {
                        onChangeSelectedCar({
                          ...car,
                        });
                        setIsOpen(false);
                        changeIsOpenCatalogCar(false);
                        return;
                      }
                      onChangeSelectedCar({ ...car, ...carInfo });
                      setIsOpen(false);
                      changeIsOpenCatalogCar(false);
                    }}
                  >
                    {checkSelected ? "Выбрано" : "Выбрать"}
                  </Button>
                )}
              </>
            ) : (
              <Button
                p={[14, 26]}
                color="success"
                fW={500}
                radius={14}
                onClick={navigateReqCreate}
              >
                Создать заявку
              </Button>
            )}
            </div>
          </Card>
          <div className="catalog-mobile__car-list">
            <CatalogCarList
              car={car}
              inModal={inModal}
              selectedCar={selectedCar}
              onChangeSelectedCar={onChangeSelectedCar}
              reqCreate={reqCreate}
              changeIsOpenCatalogCar={changeIsOpenCatalogCar}
              changeIsOpenModal={changeIsOpenModal}
            />
          </div>
          <div className="catalog-mobile__empty-block"></div>
        <Modal
          isOpen={isOpenConditions}
          onClose={() => setIsOpenConditions(false)}
          title={"Условия аренды"}
          direction={"Bottom"}
          height={"Big"}
        >
          <CatalogCarTerm term={car.rental_terms} />
        </Modal>
        </main>
			</ModalFullMobile>
    </div>
  );
}
