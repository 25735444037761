import React from "react";
import styled from "styled-components";

//
import IconWrapper from "../../../../components/icon-wrapper/IconWrapper";

export default function ServerErrorModal() {
  return (
    <StyledServerError>
      <IconWrapper
        icon="server icon-big"
        bg="danger-10"
        color="danger-dark"
        w={80}
        h={80}
      />
      <h1>Сервер временно недоступен</h1>
      <p>Пожалуйста, попробуйте попытку позже</p>
    </StyledServerError>
  );
}

const StyledServerError = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  h1 {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    color: var(--dark);
    margin: 20px 0 5px;
  }

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    color: var(--dark-70);
  }
`;
