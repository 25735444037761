import React from "react";

//
import ReqCollapseLayout from "../../req-collapse-layout/ReqCollapseLayout";
import ReqInfoCard from "../../req-info-card/ReqInfoCard";
import ReqSuccessCard from "../../req-success-card/ReqSuccessCard";
import ReqCancelCard from "../../req-cancel-card/ReqCancelCard";

export default function Successfully({ request, archived }) {

  return (
      <ReqCollapseLayout
      className="success"
        left={
          <ReqSuccessCard
            title="Сделка закрыта"
            text="Вам начислен процент от сделки!"
            request={request}
          />
        }
        right={
          <>
            <ReqCancelCard
              title={"Сделка успешно завершена"}
              type={request.status_id}
              btnText={archived ? "Разархивировать" : "Скрыть в архив"}
              request={request}
              archived={archived}
            />
            <ReqInfoCard
              request={request}
            />
          </>
        }
      />
  );
}