import React, { useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";

//
import CatalogTop from "./sections/catalog-top/CatalogTop";
import CatalogList from "./sections/catalog-list/CatalogList";
import CatalogFilter from "./sections/catalog-filter/CatalogFilter";
import useDebounce from "../../../hooks/useDebounce";
import FilterCarMark from "../../../components/filter-car-mark/FilterCarMark";
import { GET_CAR_LIST } from "../../../store/catalog/type";
import { useAppContext } from "../../../store/Context";
import { staticCarMarkList } from "./sections/static";
import {
  countMarkModel,
  genMarkList,
  genLocationList,
  getCarsCount,
} from "../../../util/formatters";
import "./Catalog.styles.scss";
import Modal from "../../../components/modal/Modal";

export default function Catalog() {
  // context
  const {
    state: {
      cityId,
      priceId,
      priceLoading,
      tempPriceLoading,
      carList,
      getCarListLoading,
      isMobile,
    },
  } = useAppContext();

  // state
  const [carsCount, setCarsCount] = useState(0);
  const [status, setStatus] = useState("");
  const [isAuto, setIsAuto] = useState("");
  const [drive, setDrive] = useState([]);
  const [engine, setEngine] = useState([]);
  const [capacity, setCapacity] = useState([]);
  const [carBody, setCarBody] = useState([]);
  const [marks, setMarks] = useState([...staticCarMarkList]);
  const [category, setCategory] = useState([]);
  const [filterCounter, setFilterCounter] = useState(0);
  const [search, setSearch] = useState("");
  const debounceSearch = useDebounce(search, 500);
  const [location, setLocation] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [ageExp, setAgeExp] = useState(true);
  const [priceSort, setPriceSort] = useState(0);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const seatOptions = useMemo(() => {
    if (!carList) return [];
    let res = carList.data.data.map(
      (car) => car.car_list[0]?.parameters?.capacity
    );
    return [...new Set(res)];
  }, [carList]);


  const categoryOptions = useMemo(() => {
    if (!carList) return [];
    let res = carList.data.data.map((carModel) => carModel.class);
    let uniqueCategories = Array.from(new Set(res));
    let checkList = uniqueCategories.map((cat, index) => ({
      id: index,
      value: cat,
      title: cat,
    }));
    checkList.unshift({ id: 1000, value: "", title: "Любой" });
    return checkList;
  }, [carList]);

  // query
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!carList) return;
    const models = localStorage.getItem("models")?.split(",");
    const marks = localStorage.getItem("marks")?.split(",");

    setMarks(
      [...genMarkList(carList.data.data)].map((i) => {
        return {
          ...i,
          checked: marks?.includes(i.mark) ? true : false,
          models: i.models.map((j) =>
            models?.includes(i.mark + j.model) ? { ...j, checked: true } : j
          ),
        };
      })
        );
    setLocationList([...genLocationList(carList.data.data)]);


    if (localStorage.getItem("drive")) {
      setDrive(localStorage.getItem("drive"));
    }
    if (localStorage.getItem("engine")) {
      setEngine(localStorage.getItem("engine"));
    }
    if (localStorage.getItem("capacity")) {
      setCapacity(localStorage.getItem("capacity"));
    }
    if (localStorage.getItem("carBody")) {
      setCarBody(localStorage.getItem("carBody"));
    }
    if (localStorage.getItem("category")) {
      setCategory(JSON.parse(localStorage.getItem("category")));
    }
    if (localStorage.getItem("filterCounter")) {
      setFilterCounter(localStorage.getItem("filterCounter"));
    }
    if (localStorage.getItem("location")) {
      setLocation(JSON.parse(localStorage.getItem("location")));
    }
    if (localStorage.getItem("search")) {
      setSearch(localStorage.getItem("search"));
    }


  }, [carList]);

  const carListResult = useMemo(() => {
    if (!carList) return [];
    let tempList = [...carList.data.data];
    let filterCount = 0;

    // filter
    if (status) {
      tempList = tempList
        .map((i) => ({
          ...i,
          car_list: i.car_list
            .map((j) => (j.status === status ? j : null))
            .filter((k) => k),
        }))
        .filter((i) => i.car_list.length);
    }

    if (category.length) {
      tempList = tempList.filter((i) => category.includes(i.class));
      filterCount += 1;
    }

    if (isAuto) {
      tempList = tempList.filter((i) => {
        if (isAuto === "auto") return i.is_auto;
        return !i.is_auto;
      });
    }

    if (drive.length) {
      tempList = tempList
        .map((i) => ({
          ...i,
          car_list: i.car_list
            .map((j) => (drive.includes(j.parameters.drive_id) ? j : null))
            .filter((k) => k),
        }))
        .filter((i) => i.car_list.length);
      filterCount += 1;
    }

    if (engine.length) {
      tempList = tempList
        .map((i) => ({
          ...i,
          car_list: i.car_list
            .map((j) => (engine.includes(j.parameters.engine_id) ? j : null))
            .filter((k) => k),
        }))
        .filter((i) => i.car_list.length);
      filterCount += 1;
    }

    if (capacity.length) {
      tempList = tempList
        .map((i) => ({
          ...i,
          car_list: i.car_list
            .map((j) => (capacity.includes(j.parameters.capacity) ? j : null))
            .filter((k) => k),
        }))
        .filter((i) => i.car_list.length);
      filterCount += 1;
    }

    if (carBody.length) {
      tempList = tempList.filter((i) => carBody.includes(i.body_id));
      filterCount += 1;
    }

    let { markList, modelList } = countMarkModel(marks);

    if (modelList.length || markList.length) {
      tempList = tempList.filter(
        (i) =>
          modelList.includes(i.brand + " " + i.model) ||
          markList.includes(i.brand)
      );
      filterCount += 1;
    }

    if (debounceSearch) {
      tempList = tempList
        .map((i) => ({
          ...i,
          car_list: i.car_list
            .map((j) => {
              if (
                i.brand.toLowerCase().includes(debounceSearch.toLowerCase()) ||
                i.model.toLowerCase().includes(debounceSearch.toLowerCase()) ||
                (i.brand + " " + i.model)
                  .toLowerCase()
                  .includes(debounceSearch.toLowerCase()) ||
                j.gosnomer.toLowerCase().includes(debounceSearch.toLowerCase())
              )
                return j;
              else return null;
            })
            .filter((k) => k),
        }))
        .filter((i) => i.car_list.length);
      filterCount += 1;
    }

    if (location.length) {
      tempList = tempList
        .map((i) => ({
          ...i,
          car_list: i.car_list
            .map((j) => (location.includes(j.point_address) ? j : null))
            .filter((k) => k),
        }))
        .filter((i) => i.car_list.length);
      filterCount += 1;
    }

    if (!ageExp) {
      tempList = tempList.filter(
        (i) => !(i.rental_terms.age >= 25 && i.rental_terms.exp >= 5)
      );
    }

    if (priceSort) {
      if (priceSort) {
        tempList = tempList.sort((a, b) =>
          priceSort === -1
            ? a.price_matrix[0].prices[3].price -
              b.price_matrix[0].prices[3].price
            : b.price_matrix[0].prices[3].price -
              a.price_matrix[0].prices[3].price
        );
      }
    }

    setFilterCounter(filterCount);
    localStorage.setItem("filterCounter", filterCount);
    setCarsCount(getCarsCount(tempList));
    // tempList = onSortCarList(tempList);
    // pagination
    return tempList;
  }, [
    carList,
    status,
    category,
    isAuto,
    drive,
    engine,
    capacity,
    carBody,
    marks,
    debounceSearch,
    location,
    ageExp,
    priceSort,
  ]);

  function changeIsFilterOpen(ifo) {
    setIsFilterOpen(ifo);
  }

  function resetFilters() {
    setDrive([]);
    setEngine([]);
    setCapacity([]);
    setCarBody([]);
    setCategory([]);
    setFilterCounter(0);
    setMarks((p) =>
      p.map((i) => ({
        ...i,
        checked: false,
        models: i.models.map((j) => ({ ...j, checked: false })),
      }))
    );
    setLocation([]);
    setSearch("");
    localStorage.removeItem("drive");
    localStorage.removeItem("engine");
    localStorage.removeItem("capacity");
    localStorage.removeItem("carBody");
    localStorage.removeItem("category");
    localStorage.removeItem("filterCounter");
    localStorage.removeItem("marks");
    localStorage.removeItem("models");
    localStorage.removeItem("location");
    localStorage.removeItem("search");
  }

  function changeStatus(s) {
    setStatus(s);
  }

  function changeCategory(c) {
    if (c === "") {
      setCategory([]);
      localStorage.removeItem("category");
      return;
    }
    if (category.includes(c)) {
      setCategory((p) => p.filter((i) => i !== c));
      localStorage.setItem("category", JSON.stringify(category.filter((i) => i !== c)));
      return;
    }
    setCategory((p) => [c, ...p]);
    localStorage.setItem("category", JSON.stringify([c, ...category]));
  }

  function changeIsAuto(a) {
    setIsAuto(a);
  }

  function changeDrive(v) {
    if (v === 0) {
      setDrive([]);
      localStorage.removeItem("drive");
      return;
    }
    if (drive.includes(v)) {
      setDrive((p) => p.filter((i) => i !== v));
      localStorage.setItem("drive", drive.filter((i) => i !== v));
      return;
    }
    setDrive((p) => [...p, v]);
    localStorage.setItem("drive", [...drive, v]);
  }

  function changeEngine(v) {
    if (v === 0) {
      setEngine([]);
      localStorage.removeItem("engine");
      return;
    }
    if (engine.includes(v)) {
      setEngine((p) => p.filter((i) => i !== v));
      localStorage.setItem("engine", engine.filter((i) => i !== v));
      return;
    }
    setEngine((p) => [...p, v]);
    localStorage.setItem("engine", [...engine, v]);
  }

  function changeCapacity(v) {
    if (v === 0) {
      setCapacity([]);
      localStorage.removeItem("capacity");
      return;
    }
    if (capacity.includes(v)) {
      setCapacity((p) => p.filter((i) => i !== v));
      localStorage.setItem("capacity", capacity.filter((i) => i !== v));
      return;
    }
    setCapacity((p) => [...p, v]);
    localStorage.setItem("capacity",[...capacity, v]);
  }

  function changeCarBody(v) {
    if (v === 0) {
      setCarBody([]);
      localStorage.removeItem("carBody");
      return;
    }
    if (carBody.includes(v)) {
      setCarBody((p) => p.filter((i) => i !== v));
      localStorage.setItem("carBody", carBody.filter((i) => i !== v));
      return;
    }
    setCarBody((p) => [...p, v]);
    localStorage.setItem("carBody", [...carBody, v]);
  }

  function changeMarkModel(id, mark) {
    let temp = [];

    setMarks((p) => {
      temp = 
      p.map((i) => {
        return {
          ...i,
          checked: i.mark === mark ? false : i.checked,
          models: i.models.map((j) =>
            j.id === id ? { ...j, checked: !j.checked } : j
          ),
        };
      });
    return temp})

    let selectedModels = [];
    temp.map((i) => 
      i.models.map((j) => j.checked === true ? JSON.stringify(selectedModels.push(i.mark + j.model)) : null));

    localStorage.setItem("models", selectedModels);
  }

  function resetMarks() {
    setMarks((p) =>
      p.map((i) => ({
        ...i,
        checked: false,
        models: i.models.map((j) => ({ ...j, checked: false })),
      }))
    );
    localStorage.removeItem("marks");
    localStorage.removeItem("models");
  }

  function toggleAllMark(id) {
    let temp = [];

    setMarks((p) => {
      temp =
      p.map((i) =>
        i.id === id
          ? {
              ...i,
              checked: !i.checked,
              models: i.models.map((j) => ({ ...j, checked: false })),
            }
          : i
      );
      return temp;
    });
    let selectedMarks = [];
    let models = localStorage.getItem("models").split(",");
    let selectedModels = [];

    temp.map((i) => i.checked === true ? 
    (
      selectedMarks.push(i.mark),
      models.map((j) => j.includes(i.mark) ? null : JSON.stringify(selectedModels.push(j)))
    )
      : null);
    localStorage.setItem("marks", selectedMarks);
    localStorage.setItem("models", selectedModels);
  }

  function changeSearch(s) {
    setSearch(s);
    localStorage.setItem("search", s);
  }

  function resetQueryGetCarList() {
    queryClient.resetQueries([GET_CAR_LIST, cityId, priceId], { exact: true });
  }

  function changeLocation(l) {
    if (l === "") {
      setLocation([]);
      localStorage.removeItem("location");
      return;
    }
    if (location.includes(l)) {
      setLocation((p) => p.filter((i) => i !== l));
      localStorage.setItem("location", JSON.stringify(location.filter((i) => i !== l)));
      return;
    }
    setLocation((p) => [...p, l]);
    localStorage.setItem("location", JSON.stringify([...location, l]));
  }

  function changeAgeExp(ae) {
    setAgeExp(ae);
  }

  function changePriceSort(ps) {
    setPriceSort(ps);
  }

  return (
    <>
    <div className="catalog-wrap">
      {
      !isMobile &&
      <section className="catalog__left">
        <div id="Марка">
          <FilterCarMark
            options={marks}
            onChangeMarkModel={changeMarkModel}
            onToggleAllMark={toggleAllMark}
            onResetCarMarkList={resetMarks}
            inCatalog={1}
          />
        </div>
      </section>
      }
      <section className="catalog__middle">
        <CatalogTop
          status={status}
          onChangeStatus={changeStatus}
          search={search}
          onChangeSearch={changeSearch}
          isAuto={isAuto}
          onChangeIsAuto={changeIsAuto}
          ageExp={ageExp}
          changeAgeExp={changeAgeExp}
          onResetQueryGetCarList={resetQueryGetCarList}
          priceSort={priceSort}
          changePriceSort={changePriceSort}
          isFilterOpen={isFilterOpen}
          changeIsFilterOpen={changeIsFilterOpen}
        />
        <CatalogList
          isLoading={priceLoading || getCarListLoading || tempPriceLoading}
          carList={carListResult}
          carsCount={carsCount}
          filterCounter={filterCounter}
          onResetFilters={resetFilters}
          drive={drive}
          onChangeDrive={changeDrive}
          engine={engine}
          onChangeEngine={changeEngine}
          capacity={capacity}
          onChangeCapacity={changeCapacity}
          carBody={carBody}
          onChangeCarBody={changeCarBody}
          marks={marks}
          onResetMarks={resetMarks}
          category={category}
          onChangeCategory={changeCategory}
          location={location}
          changeLocation={changeLocation}
          search={search}
          changeSearch={changeSearch}
          priceSort={priceSort}
          changePriceSort={changePriceSort}
        />
      </section>
      {
      !isMobile &&
      <section className="catalog__right">
        <CatalogFilter
          drive={drive}
          onChangeDrive={changeDrive}
          engine={engine}
          onChangeEngine={changeEngine}
          capacity={capacity}
          onChangeCapacity={changeCapacity}
          seatOptions={seatOptions}
          location={location}
          onChangeLocation={changeLocation}
          locationOptions={[
            { id: 1, title: "Любой", value: "" },
            ...locationList,
          ]}
          category={category}
          onChangeCategory={changeCategory}
          categoryOptions={categoryOptions}
          carBody={carBody}
          onChangeCarBody={changeCarBody}
          onResetQueryGetCarList={resetQueryGetCarList}
        />
      </section>
      }
    </div>
    {isMobile && 
      <Modal
        isOpen={isFilterOpen}
        onClose={() => changeIsFilterOpen(false)}
        title={"Фильтры"}
        direction="Right"
      >
        <>
        <div className="right__filter">
          <CatalogFilter
            drive={drive}
            onChangeDrive={changeDrive}
            engine={engine}
            onChangeEngine={changeEngine}
            capacity={capacity}
            onChangeCapacity={changeCapacity}
            seatOptions={seatOptions}
            location={location}
            onChangeLocation={changeLocation}
            locationOptions={[
              { id: 1, title: "Любой", value: "" },
                ...locationList,
              ]}
            category={category}
            onChangeCategory={changeCategory}
            categoryOptions={categoryOptions}
            carBody={carBody}
            onChangeCarBody={changeCarBody}
            hideResetBtn={1}
          />
        </div>
        <div className="left__filter">
          <FilterCarMark
            options={marks}
            onChangeMarkModel={changeMarkModel}
            onToggleAllMark={toggleAllMark}
            onResetCarMarkList={resetMarks}
            inCatalog={1}
          />
        </div>
        </>      
      </Modal>}
    </>
  );
}

