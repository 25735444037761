import React, { useMemo } from "react";
//
import NumberFormat from "../../../../../components/number-format/NumberFormat";
import AgentChatTemplate from "../../../../../components/agent-chat-template/AgentChatTemplate";
import { formatPrice } from "../../../../../util/formatters";
import "./FinalPriceCalculator.styles.scss";

export default function FinalPriceCalculator({
  isShowScripts,
  car,
  priceMatrixIndex,
  tariffIndex,
  tariff,
  rentPeriod,
  options,
  addOptions,
  carFranchise,
  totalPrice,
  carPrice,
  dailyPrice,
  priceVidachaType,
  priceVozvratType,
  staticDataLoading,
  agentChatTemplate,
  discountSumBooking,
  discountBooking,
  isBookingPage,
  promotionPercent
}) {
  function getOptionByName(name) {
    return options.find((op) =>
      op.name.toLocaleLowerCase().includes(name.toLocaleLowerCase())
    );
  }

  const addDriver = useMemo(() => {
    const item = options.find(
      (i) => i.option_id === addOptions.simple[6]?.option_id
    );
    if (!item) return null;
    return { ...item, ...addOptions.simple[6] };
  }, [options, addOptions.simple]);

  const insurance = useMemo(() => {
    let inc = null;

    const item = options.find((i) => {
      inc = addOptions.insurance.find((j) => j.option_id === i.option_id);
      return inc;
    });
    if (!item) return null;
    return { ...item, ...inc };
  }, [options, addOptions.insurance]);

  const carWashIndex = useMemo(() => {
    if (carFranchise) {
      let optionIndex = addOptions.simple.findIndex((op) =>
        op.name.includes(String(carFranchise))
      );
      return optionIndex;
    } else
      return addOptions.simple.findIndex((op) => op.name.startsWith("Мойка"));
  }, [addOptions.simple, carFranchise]);

  const carWash = useMemo(() => {
    const item = options.find(
      (i) => i.option_id === addOptions.simple[carWashIndex]?.option_id
    );
    if (!item) return null;
    return { ...item, ...addOptions.simple[carWashIndex] };
  }, [options, addOptions.simple, carWashIndex]);

  const childSeat = useMemo(() => {
    const item = options.find(
      (i) => i.option_id === addOptions.child_seat[0]?.option_id
    );
    if (!item) return null;
    return { ...item, ...addOptions.child_seat[0] };
  }, [options, addOptions.child_seat]);

  const depReg = useMemo(() => {
    const item = options.find(
      (i) => i.option_id === addOptions.travel[0]?.option_id
    );
    if (!item) return null;
    return { ...item, ...addOptions.travel[0] };
  }, [options, addOptions.travel]);

  const depAbroad = useMemo(() => {
    const item = options.find(
      (i) => i.option_id === addOptions.travel[1]?.option_id
    );
    if (!item) return null;
    return { ...item, ...addOptions.travel[1] };
  }, [options, addOptions.travel]);

  const delivery = useMemo(() => {
    const result = [];
    options.forEach((i) => {
      addOptions.delivery.forEach((j) => {
        if (i.option_id === j.option_id) {
          let tempObj = { ...i, ...j };

          if (
            (i.type === "vidacha" && priceVidachaType === "price_night") ||
            (i.type === "vozvrat" && priceVozvratType === "price_night")
          ) {
            tempObj.name += " в нерабочее время";
          }
          result.push(tempObj);
        }
      });
    });
    return result;
  }, [options, addOptions.delivery, priceVidachaType, priceVozvratType]);

  const chatListGenerator = useMemo(() => {
    if (!agentChatTemplate) return [];
    const list = [...agentChatTemplate.script_text];
    list[0] += ` <b>${formatPrice(totalPrice)} руб.</b>`;
    return list;
  }, [agentChatTemplate, totalPrice]);



  return (
    <>
      <main className="calc-price__main">
        <div
          radius={20}
          className={isShowScripts ? "calc-price__show-scripts" : undefined}
        >
          <section className="calc-price__top">
            <h1 className={isBookingPage ? "booking-title" : "req-create-title"}>Калькуляция</h1>
            {isShowScripts && (
              <AgentChatTemplate
                chat={chatListGenerator}
                hideIcon={1}
                radius="16px"
                isLoading={staticDataLoading}
              />
            )}
          </section>
          <ul className="calc-price__list">
            {car && (
              <li className="calc-price__item">
                <div className="item__left">
                  <h1 title={`Услуга по аренде авто (${tariff} км/сутки)`}>
                    Услуга по аренде авто ({tariff} км/сутки)
                  </h1>
                  <p>Кол-во: {rentPeriod.days}</p>
                </div>
                {
                  promotionPercent ?
                  <p className="promo-percent">
                    <span>-{promotionPercent}%</span>
                  </p>
                  : null
                }
                <div className="item__right">
                  <h1>
                    <NumberFormat
                      value={ isBookingPage ? dailyPrice :
                        car?.price_matrix[tariffIndex]?.prices[priceMatrixIndex]
                          ?.price 
                      }
                    />
                  </h1>
                  <p>
                    <NumberFormat value={isBookingPage ? totalPrice : Math.floor(carPrice)} />
                  </p>
                </div>
              </li>
            )}
            {[
              depReg,
              depAbroad,
              ...delivery,
              addDriver,
              insurance,
              carWash,
              getOptionByName("Транспондер"),
              getOptionByName("Держатель"),
              getOptionByName("Провод"),
              childSeat,
              getOptionByName("Перепробег"),
            ].map((i) =>
              i ? (
                <li key={i.option_id} className="calc-price__item">
                  <div className="item__left">
                    <h1 title={i.name}>{i.name}</h1>
                    <p>Кол-во: {i.amount}</p>
                  </div>
                  <div className="item__right">
                    <h1>
                      <NumberFormat value={i.price ? i.price : 0} />
                    </h1>
                    <p>
                      <NumberFormat value={i.sum} />
                    </p>
                  </div>
                </li>
              ) : null
            )}
            {discountBooking && <li className="calc-price__item">
              <div className="item__left">
                    <h1>{discountBooking}</h1>
                  </div>
                  <div className="item__right">
                    <p>
                      - <NumberFormat value={discountSumBooking ? parseInt(discountSumBooking) : 0} />
                    </p>
                  </div>
            </li>}
          </ul>
        </div>
      </main>
    </>
  );
}
