import React from "react";
import styled from "styled-components";

export default function ReqOptionBadge({ title, count, type }) {
  return (
    <StyledReqOptionBadge>
      <h6>{title}</h6>
      {count && <h5>{count + " " + type}</h5>}
    </StyledReqOptionBadge>
  );
}

const StyledReqOptionBadge = styled.div`
  background-color: var(--primary-15);
  border-radius: 20px;
  display: flex;

  h6 {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: var(--primary);
    padding: 10px 15px;
  }

  h5 {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    padding: 10px;
    color: var(--light);
    background-color: var(--primary);
    border-radius: 20px;
  }
`;
