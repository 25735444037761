import React from "react";
//
import FinalPriceCalculator from "../../../agent/req-create-v2/components/final-price-calculator/FinalPriceCalculator";
import "./BookingCalculation.styles.scss";
import TotalPriceBooking from "../../components/total-price-booking/TotalPriceBooking";

export default function BookingCalculation({
  isEdit,
  car,
  priceMatrixIndex,
  tariffIndex,
  tariff,
  rentPeriod,
  options,
  addOptions,
  carFranchise,
  isLoading,
  totalPrice,
  carPrice,
  dailyPrice,
  priceVidachaType,
  priceVozvratType,
  coef,
  vidacha,
  vozvrat,
  discountBooking,
  discountList,
  onChangeLocalBooking,
  discountSumBooking, 
  promotionPercent,
  onChangeApproved
}) {

  return (
    <div className="req-create-right">
        <FinalPriceCalculator
          car={car}
          priceMatrixIndex={priceMatrixIndex}
          tariffIndex={tariffIndex}
          tariff={tariff}
          rentPeriod={rentPeriod}
          options={options}
          addOptions={addOptions}
          carFranchise={carFranchise}
          totalPrice={totalPrice}
          dailyPrice={dailyPrice}
          priceVidachaType={priceVidachaType}
          priceVozvratType={priceVozvratType}
          coef={coef}
          vidacha={vidacha}
          vozvrat={vozvrat}
          discountBooking={discountBooking}
          discountSumBooking={discountSumBooking}
          isBookingPage={true}
          promotionPercent={promotionPercent}
        />
      <TotalPriceBooking 
        isLoading={isLoading}
        totalPrice={car ? totalPrice : 0}
        coef={coef}
        isEdit={isEdit}
        discountBooking={discountBooking}
        discountList={discountList}
        onChangeLocalBooking={onChangeLocalBooking}
        discountSumBooking={discountSumBooking}
        onChangeApproved={onChangeApproved}
      />
    </div>
  );
}
