import React, { useState } from "react";
import { Collapse } from "react-collapse";
import styled from "styled-components";
import cl from "classnames";

//
import Card from "../card/Card";
import Button from "../button/Button";

export default function CarBodyFilter({ title, options, value, onChange }) {
  const [isOpen, setIsOpen] = useState(false);

  function toggleIsOpen() {
    setIsOpen((p) => !p);
  }

  return (
    <StyledCarBodyFilter>
      <Card radius={isOpen ? 20 : 14}>
        <main className="car__body__filter__main">
          <nav className="car__body__filter__nav" onClick={toggleIsOpen}>
            <h1>{title}</h1>
            <i
              className="icon icon-sm icon-chevron-down"
              style={{ transform: isOpen ? "rotate(-180deg)" : "rotate(0deg)" }}
            />
          </nav>
          <div className="car__body__filter__list">
            <Collapse isOpened={isOpen}>
              <ul className="car__body__filter__ul">
                {options?.map((i) => (
                  <li
                    key={i.id}
                    className={cl({
                      active: value?.includes(i.value),
                    })}
                    onClick={() => onChange(i.value)}
                  >
                    <i className={"icon icon-" + i.icon} />
                    <h1>{i.title}</h1>
                  </li>
                ))}
              </ul>
              <footer className="car__body__filter__footer">
                <Button
                  p={[14, 16, 14, 26]}
                  color="light"
                  border={1}
                  fW={500}
                  radius={14}
                  onClick={() => onChange(0)}
                >
                  Сбросить <i className="icon icon-sm icon-refresh" />
                </Button>
              </footer>
            </Collapse>
          </div>
        </main>
      </Card>
    </StyledCarBodyFilter>
  );
}

const StyledCarBodyFilter = styled.div`
  .car__body__filter {
    &__main {
    }

    &__nav {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      gap: 20px;
      padding: 15px;

      h1 {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark);
      }

      i {
        background-color: var(--dark);
      }
    }

    &__ul {
      display: flex;
      gap: 5px;
      padding: 0 10px;
      flex-wrap: wrap;

      li {
        padding: 10px;
        border-radius: 14px;
        text-align: center;
        transition: 0.2s linear;
        cursor: pointer;

        i {
          background-color: var(--dark-70);
          width: 45px;
          height: 26px;
        }

        h1 {
          font-size: 11px;
          font-weight: 400;
          line-height: 13px;
          letter-spacing: 0em;
          color: var(--dark);
        }

        &:hover {
          background-color: var(--dark-5);
        }

        &.active {
          background-color: var(--primary-10);

          i {
            background-color: var(--primary);
          }

          h1 {
            color: var(--primary);
          }

          &:hover {
            background-color: var(--primary-20);
          }
        }
      }
    }

    &__footer {
      padding: 20px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
  }
`;
