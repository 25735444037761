import React, { useState, useEffect } from "react";
//
import PopUp from "../popup/PopUp";
import Button from "../button/Button";
import IconWrapper from "../icon-wrapper/IconWrapper";
import HourPicker from "./hour-picker/HourPicker";
import DatePicker from "./date-picker/DatePicker";
import "./RentPeriodModal.styles.scss";

export default function RentPeriodModal({
  isOpen,
  onChangeIsOpenRent,
  fromTime,
  onChangeVidacha,
  toTime,
  onChangeVozvrat,
  onChangeError,
}) {
  const [fYear, setFYear] = useState(2022);
  const [tYear, setTYear] = useState(2022);
  const [fMonth, setFMonth] = useState(0);
  const [tMonth, setTMonth] = useState(0);
  const [fDate, setFDate] = useState([]);
  const [tDate, setTDate] = useState([]);
  const [curDate, setCurDate] = useState([1, 0, 2022]);
  const [selectedFrom, setSelectedFrom] = useState([]);
  const [selectedTo, setSelectedTo] = useState([]);
  const [fromHour, setFromHour] = useState(["00", "00"]);
  const [toHour, setToHour] = useState(["00", "00"]);
  const [typePicker, setTypePicker] = useState("from");

  useEffect(() => {
    let today = new Date();
    setCurDate([today.getDate(), today.getMonth(), today.getFullYear()]);
    let nextTime = new Date(today.getFullYear(), today.getMonth() + 1);

    getFromDate(today);
    getToDate(nextTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!fromTime.length) return;
    setSelectedFrom(fromTime.slice(0, 3));
    setFromHour(fromTime.slice(3));
  }, [fromTime]);

  useEffect(() => {
    if (!toTime.length) return;
    setSelectedTo(toTime.slice(0, 3));
    setToHour(toTime.slice(3));
  }, [toTime]);

  function changeDate(type) {
    if (type === "-") {
      let curDate = new Date();
      if (curDate.getFullYear() === fYear && curDate.getMonth() === fMonth)
        return;
    }
    type = type === "+" ? 1 : -1;
    let fTime = new Date(fYear, fMonth + type);
    getFromDate(fTime);
    let tTime = new Date(tYear, tMonth + type);
    getToDate(tTime);
  }

  function getFromDate(today) {
    setFYear(today.getFullYear());
    setFMonth(today.getMonth());
    getFromDates(today);
  }

  function getFromDates(today) {
    const curretnMonthDates =
      new Date(today.getFullYear(), today.getMonth() + 1).getTime() - 1000;
    const firstWeekDay = new Date(
      today.getFullYear(),
      today.getMonth(),
      1
    ).getDay();
    setFDate(() => [
      ...new Array((firstWeekDay === 0 ? 7 : firstWeekDay) - 1).fill(""),
      ...new Array(new Date(curretnMonthDates).getDate())
        .fill("")
        .map((i, idx) => idx + 1),
    ]);
  }

  function getToDate(nextTime) {
    setTYear(nextTime.getFullYear());
    setTMonth(nextTime.getMonth());
    getToDates(nextTime);
  }

  function getToDates(next) {
    const nextMonthDates =
      new Date(next.getFullYear(), next.getMonth() + 1).getTime() - 1000;
    const firstWeekDay = new Date(
      next.getFullYear(),
      next.getMonth(),
      1
    ).getDay();
    setTDate(() => [
      ...new Array((firstWeekDay === 0 ? 7 : firstWeekDay) - 1).fill(""),
      ...new Array(new Date(nextMonthDates).getDate())
        .fill("")
        .map((i, idx) => idx + 1),
    ]);
  }

  function changeSelected(date, month, year) {
    const pickedTime = new Date(year, month, date).getTime();
    const toTime = new Date(
      selectedTo[2],
      selectedTo[1],
      selectedTo[0]
    ).getTime();
    const fromTime = new Date(
      selectedFrom[2],
      selectedFrom[1],
      selectedFrom[0]
    ).getTime();
    if (typePicker === "from" || pickedTime < fromTime) {
      if (pickedTime === fromTime) return;
      setSelectedFrom([date, month, year]);
      if (pickedTime >= toTime) {
        const tomorrow = new Date(year, month, date + 1);
        setSelectedTo([
          tomorrow.getDate(),
          tomorrow.getMonth(),
          tomorrow.getFullYear(),
        ]);
      }

      setTypePicker("to");
    } else {
      setSelectedTo([date, month, year]);
      setTypePicker("from");
    }
  }

  function changeFromHour(fh) {
    setFromHour(fh);
  }

  function changeToHour(th) {
    setToHour(th);
  }

  function onReady() {
    if (selectedFrom.length) {
      onChangeVidacha({ date: [...selectedFrom, ...fromHour] });
      onChangeError({ vidachaDate: "" });
    }
    if (selectedTo.length) {
      onChangeVozvrat({ date: [...selectedTo, ...toHour] });
      onChangeError({ vozvratDate: "" });
    }
    onChangeIsOpenRent(false);
  }

 

  return (
    <PopUp isOpen={isOpen} onClose={() => onChangeIsOpenRent(false)}>
      <div className="rent-period-modal">
        <main className="rent__period__body">
          <nav className="rent__period__nav">
            <h1>Период аренды</h1>
            <IconWrapper
              icon="close icon-lg"
              w={52}
              h={52}
              rounded={0}
              onClick={() => onChangeIsOpenRent(false)}
            />
          </nav>
          <section className="rent__period__hour">
            <HourPicker
              title="Выдача"
              hour={fromHour}
              value={[...selectedFrom, ...fromHour]}
              onChange={changeFromHour}
            />
            <i className="icon icon-arrow-right icon-xl"></i>
            <HourPicker
              title="Возврат"
              hour={toHour}
              value={[...selectedTo, ...toHour]}
              onChange={changeToHour}
            />
          </section>
          <section className="rent__period__date">
            <DatePicker
              year={fYear}
              month={fMonth}
              date={fDate}
              curDate={curDate}
              type="from"
              tYear={tYear}
              tMonth={tMonth}
              selectedFrom={selectedFrom}
              selectedTo={selectedTo}
              onChange={changeDate}
              onSelect={changeSelected}
              typePicker={typePicker}
            />
            <div className="vertical__line" />
            <DatePicker
              year={tYear}
              month={tMonth}
              date={tDate}
              curDate={curDate}
              type="to"
              fMonth={fMonth}
              fYear={fYear}
              selectedFrom={selectedFrom}
              selectedTo={selectedTo}
              onChange={changeDate}
              onSelect={changeSelected}
              typePicker={typePicker}
            />
          </section>
        </main>
        <section className="rent__period__bottom">
          <Button
            p={[16, 75]}
            textUpperCase={1}
            fW={500}
            color="primary"
            radius={14}
            onClick={onReady}
          >
            Готово
          </Button>
        </section>
      </div>
    </PopUp>
  );
}
