import React from "react";
import cl from "classnames";

//
import NumberFormat from "../number-format/NumberFormat";
import "./CatalogRentTerm.styles.scss";

export default function CatalogRentTerm ({
  priceMatrix,
  priceMatrixIndex,
}) {

  const isActive = (priceMatrixIndex) ? "true" : "false";

  return (
    <div className={`catalog-rent-term ${isActive && "term-big-size"}`}>
      <ul>
        {priceMatrix.map((i, idx) => (
          <li
            key={i.tariff_day}
            className={isActive && cl({active:  idx === priceMatrixIndex,})}
          >
            <h1>
              {i.tariff_day} {i.tariff_day === "1-3" ? "дня" : "дней"}
            </h1>
            <p>
              <NumberFormat value={i.price} />
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
}
