import React, { useState, useEffect, useMemo } from "react";
import styled, { css } from "styled-components";
import cl from "classnames";
import { useNavigate } from "react-router-dom";
import { Collapse } from "react-collapse";

//
import carImg from "../../asset/img/haval.png";
import Badge from "../badge/Badge";
import IconWrapper from "../icon-wrapper/IconWrapper";
import NumberFormat from "../number-format/NumberFormat";
import Button from "../button/Button";
import CatalogRentTerm from "../catalog-rent-term/CatalogRentTerm";
import CatalogCarInfoCard from "../catalog-car-info-card/CatalogCarInfoCard";
import CatalogCarList from "../catalog-car-list/CatalogCarList";
import CatalogCarTerm from "../catalog-car-term/CatalogCarTerm";
import { useAppContext } from "../../store/Context";

export default function CatalogCarGrid({
  idx,
  car,
  gridIsOpen,
  onChangeGridIsOpen,
  onChangeModal,
}) {
  // helper
  const navigate = useNavigate();

  // context
  const {
    fn: { changeReqCar },
  } = useAppContext();

  // state
  const [carCount, setCarCount] = useState(1);

  useEffect(() => {
    setCarCount(car.car_list.length);
  }, [car]);

  function getBadgeBg(status) {
    if (status === "занята") return "danger-10";
    if (status === "бронь") return "warning-20";
    if (status === "свободна") return "success-15";
  }

  function getBadgeColor(status) {
    if (status === "занята") return "danger-dark";
    if (status === "бронь") return "warning-dark";
    if (status === "свободна") return "success-dark";
  }

  function navigateReqCreate(e) {
    e.stopPropagation();
    navigate(`/req-create`);
    changeReqCar(car);
  }

  const carInfo = useMemo(() => {
    return { ...car.car_list[0] };
  }, [car]);

  return (
    <StyledCatalogCarGrid
      isOpen={gridIsOpen === idx}
      idx={idx}
      mb={carCount > 1 ? 811 : 612}
    >
      <nav
        className="catalog__car__grid__nav"
        onClick={() => onChangeGridIsOpen(idx)}
      >
        <h1>{car.brand + " " + car.model}</h1>
        <p>Класс {car.class}</p>
        <Badge
          title={car.car_list[0].status}
          bg={getBadgeBg(car.car_list[0].status)}
          color={getBadgeColor(car.car_list[0].status)}
        />
        <img src={car.image_url || carImg} alt="carImg" width="238px" />
        {carCount > 1 && (
          <div className="count__wrapper">{carCount} варианта</div>
        )}
        <footer>
          {gridIsOpen === idx ? (
            <>
              <Button
                color="success"
                p={[14, 26]}
                rounded={14}
                fW={500}
                onClick={navigateReqCreate}
                disabled={car.car_list[0].status === "занята" || car.car_list[0].status === "бронь"}
              >
                Создать заявку
              </Button>
            </>
          ) : (
            <div>
              <h1>Аренда в сутки</h1>
              <p>
                <NumberFormat value={car.price_matrix[0].prices[3].price} />
              </p>
            </div>
          )}
          <IconWrapper
            icon="chevron-down"
            rotate={gridIsOpen === idx}
            w={48}
            h={48}
            rounded={14}
            border={1}
          />
        </footer>
      </nav>

      <main className={cl("catalog__car__grid__main", {})}>
        <Collapse isOpened={gridIsOpen === idx}>
          <div className="catalog__car__grid__main__content">
            {carCount > 1 ? (
              <>
                <CatalogRentTerm priceMatrix={car.price_matrix[0].prices} />
                <CatalogCarList
                  count={carCount}
                  grid={1}
                  list={car.car_list}
                  onChangeModal={onChangeModal}
                />
                <CatalogCarTerm term={car?.rental_terms} />
              </>
            ) : (
              <>
                <CatalogCarInfoCard car={car} carInfo={carInfo} onChangeModal={onChangeModal} />
                <CatalogRentTerm
                  priceMatrix={car.price_matrix[0].prices}
                  rentalTerms={car.rental_terms}
                />
                <CatalogCarTerm term={car?.rental_terms} />
              </>
            )}
          </div>
        </Collapse>
      </main>
    </StyledCatalogCarGrid>
  );
}

const StyledCatalogCarGrid = styled.div`
  position: relative;

  .catalog__car__grid {
    &__nav {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      border: 2px solid var(--primary-dark-15);
      border-radius: 20px;
      padding: 20px;
      position: relative;
      transition: 0.2s;

      .count__wrapper {
        position: absolute;
        top: 215px;
        left: 50%;
        transform: translateX(-50%);
        background: var(--light);
        border: 1px solid var(--primary-dark-15);
        border-radius: 14px;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: 0em;
        color: var(--dark);
        padding: 5px 10px;
      }

      & > h1 {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark);
        margin-bottom: 2px;
      }

      & > p {
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        color: var(--dark-70);
        margin-bottom: 10px;
      }

      footer {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        gap: 20px;
        margin-top: 30px;

        h1 {
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 0em;
          color: var(--dark-70);
          margin-bottom: 5px;
        }

        p {
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0em;
          color: var(--dark);
        }
      }
    }

    &__main {
      position: absolute;
      z-index: 2;
      width: 100%;
      transition: 0.2s;
      bottom: -7px;
      transform: translateY(100%);
      width: calc(300% + 40px);
      left: 0;

      &__content {
        border: 2px solid var(--primary);
        border-radius: 0px 20px 20px 20px;
        padding: 20px;
        background-color: var(--bg);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 20px;
      }

      ${({ idx }) => {
        if (idx % 3 === 0)
          return css`
            left: auto;
            right: 0;

            &__content {
              border-radius: 20px 0 20px 20px;
            }
          `;
        if (idx % 3 === 2)
          return css`
            left: 50%;
            transform: translate(-50%, 100%);

            &__content {
              border-radius: 20px;
            }
          `;
      }}
    }
  }

  ${({ isOpen }) =>
    isOpen &&
    css`

      .catalog__car__grid {
        &__nav {
          border-radius: 20px 20px 0 0;
          border: 2px solid var(--primary);
          position: relative;
          margin-bottom: ${({ mb }) => mb + "px"};
          border-bottom: none;

          &::before {
            content: "";
            position: absolute;
            background-color: var(--light);
            height: 9px;
            bottom: -9px;
            border: 2px solid var(--primary);
            border-top: none;
            border-bottom: none;
            opacity: 0;
            visibility: hidden;
            transition: 0.2s;
            width: calc(100% + 4px);
            z-index: 3;

            ${({ isOpen }) =>
              isOpen &&
              css`
                opacity: 1;
                visibility: visible;
              `}
          }
        }

    `}
`;
