import { BrowserRouter } from "react-router-dom";
import { YMaps } from "react-yandex-maps";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

//
import Router from "./router/Router";
import Context from "./store/Context";
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";
import ModalProvider from "./components/modal-provider/ModalProvider";
import "./asset/style/global.scss";
// import { GlobalStyles } from "./asset/style/global";
// import { useEffect } from "react";

let cacheTime = 24 * 60 * 60 * 1000;
const queryClient = new QueryClient(
{
  defaultOptions: {
    queries: {
      cacheTime,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {

  // useEffect(() => {
  //   if (window.innerWidth < 1920) {
  //     const ex = window.innerWidth / 1920;
  //     document.body.style.zoom = ex;
  //   }
  // }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      {/* <GlobalStyles /> */}
      <YMaps>
        <QueryClientProvider client={queryClient}>
          <Context>
            <Router />
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
            <ModalProvider />
          </Context>
        </QueryClientProvider>
      </YMaps>
    </BrowserRouter>
  );
}

export default App;
