import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

//
import Card from "../card/Card";
import Button from "../button/Button";
import IconWrapper from "../icon-wrapper/IconWrapper";
import Loader from "../loader/Loader";
import CreateClientModal from "../create-client-modal/CreateClientModal";
import ClientBlackModal from "../client-black-modal/ClientBlackModal";
import ClientDeleteModal from "../client-delete-modal/ClientDeleteModal";
import { formatPhoneNumber } from "../../util/formatters";
import { msToNormTime } from "../../util/date";
import { useAppContext } from "../../store/Context";
import ToolTipComponent from "../tool-tip-component/ToolTipComponent";
import "./ClientInfoCard.styles.scss";

export default function ClientInfoCard({ clientInfo, isLoading }) {

  // helper
  const navigate = useNavigate();

  // context
  const {
    fn: { changeReqClient },
  } = useAppContext();

  // state
  const [modal, setModal] = useState(false);
  const [blackModal, setBlackModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  function changeModal(m) {
    setModal(m);
  }

  function changeBlackModal(bm) {
    setBlackModal(bm);
  }

  function changeDeleteModal(dm) {
    setDeleteModal(dm);
  }

  function navigateReqCreate() {
    navigate("/req-create");
    changeReqClient({
      client_id: clientInfo.client_id,
      name: clientInfo.name,
      phone: clientInfo.phone,
      email: clientInfo.email,
      birthdate: clientInfo.birthdate,
      source: clientInfo.source,
    });
  }

  return (
    <>
      <Card>
        {isLoading && <Loader />}
        <div className="client__info__card__content">
          <nav className="client__info__card__nav">
            <section className="client__info__card__nav-back">
              <IconWrapper
                icon="arrow-left"
                w={48}
                h={48}
                border={1}
                bg="light"
                onClick={() => navigate(-1)}
              />
            </section>
            <section className="client__info__card__name">
              <h1>{clientInfo?.name.split(" ")[0]}</h1>
              <p>
                {clientInfo?.name.split(" ")[1]} {clientInfo?.name.split(" ")[2]}
              </p>
            </section>
            <section className="client__info__card__action">
              {clientInfo?.black_listed ? (
                <ToolTipComponent text={"вернуть из черного списка"}>
                  <IconWrapper
                    icon="reverse-left"
                    w={48}
                    h={48}
                    border={1}
                    bg="light"
                    onClick={() => changeBlackModal("revert")}
                  />
                </ToolTipComponent>
              ) : (
                <ToolTipComponent text={"в черный список"}>
                  <IconWrapper
                    icon="slash-circle"
                    w={48}
                    h={48}
                    border={1}
                    bg="light"
                    onClick={() => changeBlackModal("send")}
                  />
                </ToolTipComponent>
              )}
              <ToolTipComponent text={"изменить"}>
                <IconWrapper
                  icon="pencil"
                  w={48}
                  h={48}
                  border={1}
                  bg="light"
                  onClick={() => changeModal(true)}
                />
              </ToolTipComponent>
              <ToolTipComponent text={"удалить"}>
                <IconWrapper
                  icon="trash"
                  w={48}
                  h={48}
                  border={1}
                  bg="light"
                  onClick={() => changeDeleteModal(true)}
                />
              </ToolTipComponent>
            </section>
          </nav>
          <main className="client__info__card__main">
            <section className="client__info__card__contact">
              <IconWrapper
                w={35}
                h={35}
                icon="phone icon-sm"
                bg="primary-10"
                color="primary"
              />
              <div>
                <p>Номер телефона</p>
                <h1>{formatPhoneNumber(clientInfo?.phone)}</h1>
              </div>
            </section>
            <section className="client__info__card__contact">
              <IconWrapper
                w={35}
                h={35}
                icon="gmail icon-sm"
                bg="primary-10"
                color="primary"
              />
              <div>
                <p>Эл. почта</p>
                <h1>{clientInfo?.email}</h1>
              </div>
            </section>
            <section className="client__info__card__contact">
              <IconWrapper
                w={35}
                h={35}
                icon="calendar icon-sm"
                bg="primary-10"
                color="primary"
              />
              <div>
                <p>День рождения</p>
                <h1>
                  {msToNormTime(
                    new Date(clientInfo?.birthdate).getTime() / 1000,
                    9
                  )}
                </h1>
              </div>
            </section>
            <section className="client__info__card__contact">
              <IconWrapper
                w={35}
                h={35}
                icon="file icon-sm"
                bg="primary-10"
                color="primary"
              />
              <div>
                <p>Источник</p>
                <h1>{clientInfo?.source}</h1>
              </div>
            </section>
          </main>
          <footer className="client__info__card__footer">
            <section className="client__info__card__note">
              <IconWrapper
                w={35}
                h={35}
                icon="pin icon-sm"
                bg="primary-10"
                color="primary"
              />
              <div>
                <h1>Примечания</h1>
                <p>{clientInfo?.note}</p>
              </div>
            </section>
            {!clientInfo?.black_listed && (
              <Button
                p={[16, 30]}
                color="success"
                radius={14}
                textUpperCase={1}
                fW={500}
                onClick={navigateReqCreate}
              >
                Создать заявку
              </Button>
            )}
          </footer>
        </div>
      </Card>
      {modal && (
        <CreateClientModal
          isOpen={modal}
          title="Изменить клиента"
          clientInfo={clientInfo}
          onClose={() => changeModal(false)}
        />
      )}
      {blackModal && (
        <ClientBlackModal
          isOpen={blackModal}
          client={clientInfo}
          onClose={() => changeBlackModal("")}
        />
      )}
      {deleteModal && (
        <ClientDeleteModal
          isOpen={deleteModal}
          onClose={() => changeDeleteModal(false)}
          clientId={clientInfo.client_id}
        />
      )}
    </>
  );
}
