import React from "react";
import cl from "classnames";
import { Link, useLocation } from "react-router-dom";

//
// import logo from "../../asset/img/logo-icon.svg";
import { navList } from "../../components/vertical-navbar/static";
import { useAppContext } from "../../store/Context";
import "./MainMenuMobile.styles.scss";


export  function  MainMenuMobile() {
  // helper
  const { pathname } = useLocation();

  // context
  const {
    state: { user: agent, carComparsionListLength, draftsCount },
    fn: { changeIsOpenMenu },
  } = useAppContext();
  navList[0].count = draftsCount;
  navList[2].count = carComparsionListLength;

  function checkForActive(to) {
    if (pathname.includes("req-create") && to === "/dashboard") return true;
    if (pathname.includes(to)) return true;
    return false;
  }

  return (
    <div  className="main-menu">
      <ul>
        {navList.map((i) => (
          <li key={i.id}>
            <Link
              to={i.to}
              className={cl("main-menu__link", {
                active: checkForActive(i.to),
              })}
              onClick={(e) => {
								changeIsOpenMenu(false);
                e.stopPropagation();
              }}
            >
							<div className="icon__wrap">
								<i className={"icon icon-sm icon-" + i.icon} />
							</div>
							<p>{i.title}</p>
							{i.count > 0 && <div className="count__circle">{i.count}</div>}
            </Link>
          </li>
        ))}
        {
          agent?.has_extended_rights &&
          <li>
            <Link
              to={"/booking"}
              className={cl("main-menu__link", {
                active: checkForActive("/booking"),
              })}
              onClick={(e) => {
								changeIsOpenMenu(false);
                e.stopPropagation();
              }}
            >
							<div className="icon__wrap">
								<i className={"icon icon-sm icon-booking"} />
							</div>
							<p>Бронирование</p>
            </Link>
          </li>
          }
      </ul>
    </div>
  );
}
