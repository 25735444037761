import React, { useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import cl from "classnames";

import { differentDates, msToNormTime } from "../../util/date";

// type => finalize, denied, expiredConsideration, inWork, prelimBooking, booking, issuanceClient
export default function ReqProgress({ list, archived }) {

  // method
  let currentStatusId = list[list.length - 1].status_id;

  const step = useMemo(() => {
    let lastElementId = list[list.length - 1].status_id;

    if (lastElementId === 7 || lastElementId === 8) {
      lastElementId = list[list.length - 2]?.status_id || 0;
    }

    return lastElementId;
  }, [list]);

  const color = useMemo(() => {
    const lastIttem = list[list.length - 1];
    if (lastIttem.status_id === 2) return "warning";
    if (lastIttem.status_id === 7 || lastIttem.status_id === 8) return "danger";
    if (lastIttem.status_id === 9 ) return "purple";

    return "primary";
  });

  function getTimeStatus(id) {
    const item = [...list].reverse().find((i) => i.status_id === id);
    if (!item) return "";

    if (step === currentStatusId && !archived) {
      return differentDates(item.status_set_date, Date.now() / 1000);
    }
    return msToNormTime(item.status_set_date, 7);
  }

  return (
    <StyledReqProgress type={step} archived={archived} color={color}>
          {currentStatusId === 7 || currentStatusId === 8 ? (
            <li className="req__progress__item">
              <div
                className={cl("req__progress__circle single", {
                  current: step === currentStatusId,
                  active: false,
                })}
              />
              <div
                className={cl("req__progress__context ", {
                  active: true,
                  disabled: false,
                })}
              >
                <i className="icon icon-clock-circle"></i>
                <div>
                  <h1>{currentStatusId === 8 ? "Отказано" : "Отменено"}</h1>
                  <p>{getTimeStatus(currentStatusId)}</p>
                </div>
              </div>
            </li>
          ) : null}

          {(currentStatusId !== 7 || currentStatusId !== 7) && step == 2 ? (
            <li className="req__progress__item">
              <div
                className={cl("req__progress__circle", {
                  current: step === 2,
                  active: step > 2,
                })}
              />
              <div
                className={cl("req__progress__context ", {
                  active: step === 2,
                  disabled: step > 2,
                })}
              >
                <i className="icon icon-clock-circle"></i>
                <div>
                  <h1>Доработать</h1>
                  <p>{getTimeStatus(2)}</p>
                </div>
              </div>
            </li>
          ) : null}

          <li className="req__progress__item">
            <div
              className={cl("req__progress__circle", {
                current: step === 3,
                active: step > 3,
              })}
            />
            <div
              className={cl("req__progress__context ", {
                active: step === currentStatusId ? step === 3 : false,
                disabled: step < 3,
              })}
            >
              <i className="icon icon-clock-circle"></i>
              <div>
                <h1>На расмотрении</h1>
                <p>{getTimeStatus(3)}</p>
              </div>
            </div>
          </li>
          <li className="req__progress__item">
            <div
              className={cl("req__progress__circle", {
                current: step === 4,
                active: step > 4,
              })}
            />
            <div
              className={cl("req__progress__context ", {
                active: step === currentStatusId ? step === 4 : false,
                disabled: step < 4,
              })}
            >
              <i className="icon icon-refresh"></i>
              <div>
                <h1>В работе</h1>
                <p>{getTimeStatus(4)}</p>
              </div>
            </div>
          </li>
          <li className="req__progress__item">
            <div
              className={cl("req__progress__circle", {
                current: step === 5,
                active: step > 5,
              })}
            />
            <div
              className={cl("req__progress__context ", {
                active: step === currentStatusId ? step === 5 : false,
                disabled: step < 5,
              })}
            >
              <i className="icon icon-clock-circle-z"></i>
              <div>
                <h1>Предв. бронь</h1>
                <p>{getTimeStatus(5)}</p>
              </div>
            </div>
          </li>
          <li className="req__progress__item">
            <div
              className={cl("req__progress__circle", {
                current: step === 6,
                active: step > 6,
              })}
            />
            <div
              className={cl("req__progress__context ", {
                active: step === currentStatusId ? step === 6 : false,
                disabled: step < 6,
              })}
            >
              <i className="icon icon-clock-circle-check"></i>
              <div>
                <h1>Бронь</h1>
                <p>{getTimeStatus(6)}</p>
              </div>
            </div>
          </li>
          <li className="req__progress__item">
            <div
              className={cl("req__progress__circle last", {
                current: step === 9,
                active: step > 9,
              })}
            />
            <div
              className={cl("req__progress__context ", {
                active: step === currentStatusId ? step === 9 : false,
                disabled: step < 9,
              })}
            >
              <i className="icon icon-flag"></i>
              <div>
                <h1>Выдача клиенту</h1>
                <p>{getTimeStatus(9)}</p>
              </div>
            </div>
          </li>
    </StyledReqProgress>
  );
}

const StyledReqProgress = styled.ul`
  background: var(--light);
  box-shadow: 0px 2px 10px var(--dark-5);
  border-radius: 20px;
  padding: 25px 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 20px;

  .req__progress {
    &__item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 25px;
    }

    &__circle {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background-color: var(--${(props) => props.color});
      position: relative;

      &.single::before {
        border: 2px dashed var(--${(props) => props.color});
        width: 1px;
      }

      &::before {
        content: "";
        position: absolute;
        background-color: #eaf1fb;
        height: 54px;
        width: 4px;
        bottom: 0px;
        left: 50%;
        transform: translate(-50%, 100%);
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 15px;
        height: 15px;
        background-color: var(--light);
        border-radius: 50%;
      }

      &.last {
        &::before {
          display: none;
        }
      }

      &.current {
        &::after {
          display: none;
        }
      }

      &.active {
        &::after {
          display: none;
        }

        &::before {
          background-color: var(--${(props) => props.color});
          height: 70px;
          bottom: 5px;
        }
      }
    }

    &__context {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 10px;
      border-radius: 14px;
      padding: 10px 15px;
      min-height: 55px;

      h1 {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark);
        white-space: nowrap;
      }

      p {
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        color: var(--dark);
      }

      i {
        display: none;
      }

      &.disabled {
        opacity: 0.5;

        p {
          display: none;
        }
      }

      &.active {
        background-color: var(--${(props) => props.color});
        position: relative;

        i {
          display: inline-block;
        }

        p {
          display: block;
          color: var(--light);
          opacity: 0.7;
        }

        h1 {
          color: var(--light);
        }

        &::before {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          top: 50%;
          transform: translateY(-50%) rotate(45deg);
          left: -5px;
          background-color: var(--${(props) => props.color});
        }
      }
    }
  }

  ${({ type, archived }) =>
    type === "denied" || archived
      ? css`
          opacity: 0.8;
        `
      : null}
`;
