import React, { useMemo, useState, useEffect, useRef } from "react";
import { useMutation, useQueries, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

//
import Modal from "../../../components/modal/Modal";
import SelectedCarCard from "../../agent/req-create-v2/components/selected-car-card/SelectedCarCard";
import ReqCreateMiddle from "../../agent/req-create-v2/sections/req-create-middle/ReqCreateMiddle";
import BookingCalculation from "../sections/booking-calculation/BookingCalculation";
import StatusCheckFailModal from "../../agent/req-create-v2/components/StatusCheckFailModal";
import ConfirmModal from "../../../components/confirm-modal/ConfirmModal";
import RequestApi from "../../../store/request/api";
import { useAppContext } from "../../../store/Context";
import { GET_REQUEST_LIST } from "../../../store/dashboard/type";
import { GET_CLIENT_LIST } from "../../../store/client/type";
import { arrDateToUnix, unixDateToArr } from "../../../util/formatters";
import { useGetCarList } from "../../../store/catalog/hook";
import { useGetPoints } from "../../../store/req-create/hook";
import "./BookingById.styles.scss";
import BookingInfoControl from "../sections/booking-info-control/BookingInfoControl";
import RentInfo from "../components/rent-info/RentInfo";
import BookingApi from "../../../store/booking/api";
import Loader from "../../../components/loader/Loader";
import ModalAddDriver from "../components/modal-add-driver/ModalAddDriver";
import AgentInfo from "../components/agent-info/AgentInfo";
import Alert from "../../../components/alert/Alert";
import useModalKeyDown from "../../../hooks/useModalKeyDown";

const defaultClient = {
  client_id: null,
  client_name: "",
  phone: "",
  source: "",
};

const today = new Date();
const tempTime = new Date(
  today.getFullYear(),
  today.getMonth(),
  today.getDate(),
  today.getHours() + 1
);
const defaultVidacha = {
  point_id: null,
  point_name: "",
  point_type: "office",
  address: "",
  date: [
    tempTime.getDate(),
    tempTime.getMonth(),
    tempTime.getFullYear(),
    tempTime.getHours(),
    "00",
  ],
  comment: "",
};

const tomorrow = new Date(
  tempTime.getFullYear(),
  tempTime.getMonth(),
  tempTime.getDate() + 1,
  tempTime.getHours()
);
const defaultVozvrat = {
  point_id: null,
  point_name: "",
  point_type: "office",
  address: "",
  date: [
    tomorrow.getDate(),
    tomorrow.getMonth(),
    tomorrow.getFullYear(),
    tomorrow.getHours(),
    "00",
  ],
  comment: "",
};

const defaultPriceList = "000000001";

export default function BookingById() {
  const [localBooking, setLocalBooking] = useState();
  const [newBooking, setNewBooking] = useState();
  const [dataBooking, setDataBooking] = useState();
  const { id: bookingId, year } = useParams();
  const [car, setCar] = useState(null);
  const [openAlert, setIsOpenAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [approved, setApproved] = useState("");

  const dataQueries = useQueries([
    {
      queryKey: [`GET_BOOKING_${bookingId}`],
      queryFn: () => BookingApi.getBookingById(year, bookingId),
    },
    {
      queryKey: [`GET_BOOKING_DATA`],
      queryFn: () => BookingApi.getBookingData(),
    },
  ]);

  const updateBookingService = useMutation(
    ["UPDATE_BOOKING"],
    (body) => BookingApi.updateBooking(body),
    {
      onSuccess: (res) => {
        setIsEdit(!isEdit);
        if (res.data.Status) {
          setAlertText(res.data.Status);
          setIsOpenAlert(true);
        }
      },
    }
  );

  const updatePriceService = useMutation(
    ["UPDATE_PRICE"],
    (body) => BookingApi.updatePrice(body),
    {
      cacheTime: 0,
      onSuccess: (res) => {
        handleChangeLocalBooking("order", {
          ...localBooking.order,
          rent_price_day: res.data.rent_price_day,
          rent_price_total: res.data.rent_price_total,
        });
      },
    }
  );


  const isAllSuccess = dataQueries.every((query) => query.isSuccess);
  const isLoadingData = dataQueries.some((query) => query.isLoading);
  useEffect(() => {
    if (isAllSuccess) {
      setLocalBooking(dataQueries[0].data.data);
      changeCityId(dataQueries[0].data.data.city_id === "" ? defaultPriceList : dataQueries[0].data.data.city_id);
      setTariff(dataQueries[0].data.data.order?.tariff || "200");

      setVidacha({
        ...dataQueries[0].data.data.vidacha,
        date: unixDateToArr(dataQueries[0].data.data.vidacha.date),
      });
      setVozvrat({
        ...dataQueries[0].data.data.vozvrat,
        date: unixDateToArr(dataQueries[0].data.data.vozvrat.date),
      });
      setDataBooking(dataQueries[1].data.data);
      setApproved(dataQueries[0].data.data.approved_by);
    }
  }, [isAllSuccess]);

  // helpers
  const navigate = useNavigate();
  const { reqId } = useParams();

  // context
  const {
    state: {
      addOptions,
      cityList,
      cityId,
      reqCar,
      priceLoading,
      // isOpenCatalogCar,
      optionMatrix,
      reqClient,
    },
    fn: { changeReqCar, changeReqClient, changeCityId },
  } = useAppContext();


  //new Booking
  const [isEdit, setIsEdit] = useState(false);
  const [isOpenAddDriverModal, setIsOpenAddDriverModal] = useState(false);
  const [isOpenSaveConfirm, setIsOpenSaveConfirm] = useState(false);
  const refBookingInfoControl = useRef(null);
  const refRentInfo = useRef(null);
  const refAgentInfo = useRef(null);

  const onToggleEdit = () => {
    setIsEdit(!isEdit);
  };

  const changeIsOpenAddDriverModal = () => {
    setIsOpenAddDriverModal(!isOpenAddDriverModal);
  };

  useModalKeyDown({
    isOpen: isOpenSaveConfirm,
    handleClose: () => setIsOpenSaveConfirm(false),
    handleSubmit: () => handleSaveEditBooking(),
  });

  //old from ReqCreateV2
  const requestCacheCount = useRef(0);

  const [viewCarList, setViewCarList] = useState("grid");
  const [ageExp, setAgeExp] = useState(true);
  const [travelRegion, setTravelRegion] = useState("000000001");
  const [tariff, setTariff] = useState("200");
  const [options, setOptions] = useState([]);

  const [client, setClient] = useState({ ...defaultClient });
  const [vidacha, setVidacha] = useState({ ...defaultVidacha });
  const [vozvrat, setVozvrat] = useState({ ...defaultVozvrat });
  const [doc_photos, setDocPhotos] = useState(new Array(6).fill(""));
  const [carStatusFailModal, setCarStatusFailModal] = useState(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [staticLoading, setStaticLoading] = useState(true);
  const [discount, setDiscount] = useState(0);

  useEffect(() => {
    if (!reqCar) return;

    setTravelRegion(cityId);

    setCar(reqCar);
    changeReqCar(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqCar, cityId]);

  useEffect(() => {
    if (!reqClient) return;
    const { client_id, name, phone, source } = reqClient;
    setClient((p) => ({ ...p, client_id, client_name: name, phone, source }));
    changeReqClient(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqClient]);

  // query
  const queryClient = useQueryClient();

  const priceDelivery = useMemo(() => {
    const newOpt = options.map((opt) => {
      if (opt.name === "Доставка в область") {
        let sum = Number(vidacha.sum);
        const maxSum = opt.option_max_price;
        if (sum > maxSum) sum = maxSum;
        return { ...opt, sum: sum, price: sum, max_price: maxSum };
      } else return opt;
    });
    setOptions([...newOpt]);

    return vidacha.sum;
  }, [vidacha.sum]);

  const priceReturn = useMemo(() => {
    const newOpt = options.map((opt) => {
      if (opt.name === "Возврат из области") {
        let sum = Number(vozvrat.sum);
        const maxSum = opt.option_max_price;
        if (sum > maxSum) sum = maxSum;
        return { ...opt, sum: sum, price: sum, max_price: maxSum };
      } else return opt;
    });
    setOptions([...newOpt]);

    return vozvrat.sum;
  }, [vozvrat.sum]);

  const priceId = useMemo(() => {
    if (!travelRegion) return null;

    return cityList.find((i) => i.id === travelRegion)?.price_id;
  }, [travelRegion, cityList]);

  const { data: pointList, isLoading: pointLoading } = useGetPoints(
    localBooking?.city_id || defaultPriceList,
    {
      enabled: !!localBooking,
    }
  );

  const { data: carList, isLoading: carLoading } = useGetCarList(
    localBooking?.city_id || travelRegion,
    localBooking?.price_list_id || defaultPriceList,
    {
      enabled: !!localBooking,
    }
  );

  const postReqMutation = useMutation((body) => RequestApi.postRequest(body));
  const putReqMutation = useMutation((body) => RequestApi.putRequest(body));
  const checkCarStatus = useMutation((gosnomer) =>
    RequestApi.getCarStatus(gosnomer)
  );

  useEffect(() => {
    if (!carList || !localBooking || !localBooking.car) return;

    let cars = carList.data.data;
    let selectedModel = cars.find(
      (c) => c.price_unit_id === localBooking.car.car_id
    );
    if (selectedModel && selectedModel.car_list) {
      let selectedCar = selectedModel.car_list.find(
        (c) => c.gosnomer === localBooking.car.gosnomer
      );
      setCar({ ...selectedModel, ...selectedCar });
    } else {
      setCar(selectedModel);
    }
  }, [carList, localBooking?.car]);

  const priceVidachaType = useMemo(() => {
    const hour = Number(vidacha.date[3]);
    if (hour < 9 || hour > 21) return "price_night";
    return "price_day";
  }, [vidacha.date]);

  const priceVozvratType = useMemo(() => {
    const hour = Number(vozvrat.date[3]);
    if (hour < 9 || hour > 21) return "price_night";
    return "price_day";
  }, [vozvrat.date]);

  const rentPeriod = useMemo(() => {
    let dif =
      new Date(
        vozvrat.date[2],
        vozvrat.date[1],
        vozvrat.date[0],
        vozvrat.date[3],
        vozvrat.date[4]
      ) -
      new Date(
        vidacha.date[2],
        vidacha.date[1],
        vidacha.date[0],
        vidacha.date[3],
        vidacha.date[4]
      );
    const days = Math.floor(dif / 86400000);
    dif -= days * 86400000;
    const hours = Math.floor(dif / 3600000);
    dif -= hours * 3600000;
    const mins = Math.floor(dif / 60000);
    return {
      days,
      hours,
      mins,
    };
  }, [vidacha.date, vozvrat.date]);

  const coef = useMemo(() => {
    const { days, hours, mins } = rentPeriod;

    if (isNaN(rentPeriod.days)) return 1;

    const minutes = hours * 60 + mins;
    if (days === 0 && minutes >= 0) return 1;
    if (minutes <= 75) return Number(days);
    if (minutes > 75 && minutes <= 135) return Number(days) + 0.2;
    if (minutes > 135 && minutes <= 195) return Number(days) + 0.3;
    if (minutes > 195 && minutes <= 255) return Number(days) + 0.4;
    if (minutes > 255 && minutes <= 315) return Number(days) + 0.5;
    if (minutes > 315 && minutes <= 370) return Number(days) + 0.6;
    if (minutes > 370) return Number(days) + 1;
  }, [rentPeriod]);

  const priceMatrixIndex = useMemo(() => {
    if (1 <= coef && coef <= 3) return 0;
    if (4 <= coef && coef <= 6) return 1;
    if (7 <= coef && coef < 15) return 2;
    if (coef >= 15) return 3;
  }, [coef]);

  useEffect(() => {
    if (!options.length || !optionMatrix.length) return;

    const result = options.map((i) => {
      const findedOption = optionMatrix.find(
        (j) => j.option_id === i.option_id
      );

      const priceType = getPriceKey(i.type);
      i.price =
        findedOption?.price_matrix[priceMatrixIndex || 0][priceType] || i.price;
      i.maxSum = findedOption?.option_max_price * i.amount;
      i.sum = findedOption?.per_day
        ? i.price * i.amount * coef
        : i.price * i.amount;
      if (i.sum > i.maxSum) i.sum = i.maxSum;

      return i;
    });
    setOptions([...result]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    priceVidachaType,
    priceVozvratType,
    priceMatrixIndex,
    optionMatrix,
    coef,
  ]);

  const editContentLoading = useMemo(() => {
    if (pointLoading || staticLoading) return true;
    return false;
  }, [pointLoading, staticLoading]);

  const points = useMemo(() => {
    if (!pointList) return null;
    const list = [...pointList.data.data];
    const obj = {
      Delivery: [],
      office: [],
      airport: [],
      metro: [],
    };
    list.forEach((i) => {
      obj[i.type] = obj[i.type] ? [...obj[i.type], i] : [i];
    });
    return obj;
  }, [pointList]);

  const carFranchise = useMemo(() => {
    return car ? car?.rental_terms.franchise || 12000 : null;
  }, [car]);

  const tariffIndex = useMemo(() => {
    if (tariff === "200") return 0;
    if (tariff === "300") return 1;
    if (tariff === "500") return 2;
    return 0;
  }, [tariff]);

  const carPrice = useMemo(() => {
    if (!car) return 0;
    return (
      (car?.price_matrix[tariffIndex]?.prices[priceMatrixIndex]?.price || 0) *
      coef
    );
  }, [car, coef, priceMatrixIndex, tariffIndex]);

  const perDayOptionsPrice = useMemo(() => {
    if (!options.length) return 0;
    return options
      .filter((i) => i.per_day)
      .reduce((total, i) => {
        let sum = i.sum;
        let maxSum = i.option_max_price;
        if (sum > maxSum) {
          sum = maxSum;
        }
        total += sum;
        return total;
      }, 0);
  }, [options]);

  const onceOptionsPrice = useMemo(() => {
    if (!options.length) return 0;

    return options
      .filter((i) => !i.per_day)
      .reduce((total, i) => {
        let sum = i.sum;

        total += sum;
        return total;
      }, 0);
  }, [options]);

  const totalPrice = useMemo(() => {
    let price_total = 0;
    if (localBooking?.order?.rent_price_total) {
      price_total = localBooking?.order?.rent_price_total;
    }

    let sum = price_total + perDayOptionsPrice + onceOptionsPrice;
    sum = Math.floor(sum * 100) / 100;
    sum -= sum * (discount / 100);
    return sum;
  }, [
    discount,
    localBooking?.order?.rent_price_total,
    onceOptionsPrice,
    perDayOptionsPrice,
  ]);

  const discountSum = useMemo(() => {
    let sum = totalPrice * (discount / 100);
    sum = Math.floor(sum * 100) / 100;
    return sum;
  }, [totalPrice, discount]);

  const dailyPrice = useMemo(() => {
    return localBooking?.order?.rent_price_day;
  }, [localBooking?.order?.rent_price_day]);

  const perDailyPrice = useMemo(() => {
    return perDayOptionsPrice / coef;
  }, [perDayOptionsPrice, coef]);

  const requestBody = useMemo(() => {
    return {
      ...client,
      price_list_id: priceId,
      city_id: travelRegion,
      is_archived: false,
      status_id: 1,
      email: "",
      birthdate: Math.floor(new Date().getTime() / 1000),
      doc_photos: doc_photos.filter((i) => i),
      price_unit_id: car?.price_unit_id || null,
      gosnomer: car?.status === "свободна" ? car?.gosnomer : null,
      tariff,
      rent_price_day: carPrice,
      rent_price_total: carPrice,
      discount_percent: discount,
      discount_sum: discountSum,
      vidacha: { ...vidacha, date: arrDateToUnix(vidacha.date) },
      vozvrat: { ...vozvrat, date: arrDateToUnix(vozvrat.date) },
      options: [...options],
    };
  }, [
    client,
    priceId,
    travelRegion,
    doc_photos,
    car?.price_unit_id,
    car?.status,
    car?.gosnomer,
    tariff,
    carPrice,
    discount,
    discountSum,
    vidacha,
    vozvrat,
    options,
  ]);

  const sendBtnIsDisabled = useMemo(() => {
    return client.client_name.length < 3 || client.phone.length < 11;
  }, [client]);

  function changeViewCarList(vcl) {
    setViewCarList(vcl);
  }

  function changeAgeExp(ae) {
    setAgeExp(ae);
    requestCacheCount.current = 1;
  }

  function changeTravelRegion(tr) {
    setTravelRegion(tr);
    setCar(null);
    requestCacheCount.current = 1;
  }

  function changeVozvrat(obj) {
    setVozvrat((p) => {
      return { ...p, ...obj };
    });
    requestCacheCount.current = 1;
  }

  function changeVidacha(obj) {
    setVidacha((p) => {
      return { ...p, ...obj };
    });

    requestCacheCount.current = 1;
  }

  function changeTariff(t) {
    if (!isEdit) return;

    setTariff(t);
    requestCacheCount.current = 1;
  }

  function changeOptions(amount, option, type) {
    if (!option || (options.length === 0 && amount < 1)) return;
    requestCacheCount.current = 1;
    let isExsist = options.find((i) => i.option_id === option.option_id);

    let discountOverrun = 1;

    if (option.name === "Перепробег") {
      discountOverrun = 0.4;
    }

    if (!isExsist) {
      const priceKey = getPriceKey(type);
      setOptions((p) => {
        let price = option.price_matrix[priceMatrixIndex][priceKey];
        let maxSum = option.option_max_price * amount;
        let sum = option.per_day ? price * amount * coef : price * amount;

        if (sum > maxSum) sum = maxSum;

        const result = [
          ...p,
          {
            option_id: option.option_id,
            name: option.name,
            amount,
            price: price,
            sum: Math.ceil(sum * discountOverrun),
            per_day: option.per_day,
            type,
            is_custom: option.is_custom,
            max_price: maxSum,
          },
        ];
        return result;
      });
      return;
    }

    if (amount >= 1) {
      setOptions((p) => {
        const result = p.map((i) => {
          const maxSum = option.option_max_price * amount;
          let sum = Math.ceil(i.price * amount * coef * discountOverrun);
          if (sum > maxSum) sum = maxSum;
          return i.option_id === option.option_id
            ? { ...i, amount, sum: sum, max_price: maxSum }
            : i;
        });
        return result;
      });
      return;
    }

    setOptions((p) => {
      const result = p.filter((i) => i.option_id !== option.option_id);
      return result;
    });
  }

  function getPriceKey(t) {
    if (t === "vidacha") return priceVidachaType;
    if (t === "vozvrat") return priceVozvratType;
    return "price_day";
  }

  function changeCar(c) {
    requestCacheCount.current = 1;
    if (
      car &&
      c &&
      car.price_unit_id === c.price_unit_id &&
      car.gosnomer === c.gosnomer
    ) {
      setCar(null);
      return;
    }
    setCar(c);
  }

  useEffect(() => {
    if (!car) return;
    handleChangeLocalBooking("order", {
      ...localBooking?.order,
      tariff: tariff,
      rent_price_total:
        car?.price_matrix[tariffIndex]?.prices[priceMatrixIndex]?.price * coef,
      rent_price_day:
        car?.price_matrix[tariffIndex]?.prices[priceMatrixIndex]?.price,
    });
  }, [car, coef, priceMatrixIndex, tariff, tariffIndex]);

  function changeClient(obj) {
    setClient((p) => {
      return { ...p, ...obj };
    });
    requestCacheCount.current = 1;
  }

  function clearLclStrg() {
    const tempName = "REQUEST_CREATE_OBJ";
    localStorage.removeItem(tempName);
    localStorage.removeItem("ADDRESS_PICKER_vidacha");
    localStorage.removeItem("ADDRESS_PICKER_vozvrat");
    requestCacheCount.current = 0;
  }

  function resetQueries() {
    queryClient.resetQueries(GET_REQUEST_LIST, { exact: true });
    queryClient.resetQueries(GET_CLIENT_LIST, { exact: true });
  }

  // status_id = 1
  async function handleSaveToDraft() {
    const obj = {
      ...requestBody,
    };

    obj.status_id = 1;

    if (reqId) {
      putReqMutation.mutate(
        { ...obj, request_id: reqId },
        {
          onSuccess: () => {
            changeCarStatusFailModal({
              title: "Заявка",
              text: "Заявка сохранена",
              type: "success",
            });
            resetStates();
            resetQueries();
            clearLclStrg();
          },
        }
      );
      return;
    }
    postReqMutation.mutate(
      { ...obj },
      {
        onSuccess: () => {
          resetQueries();
          changeCarStatusFailModal({
            title: "Заявка",
            text: "Заявка сохранена",
            type: "success",
          });
          resetStates();
          clearLclStrg();
        },
      }
    );
  }

  function handleChangeLocalBooking(key, value) {
    if (key === "counterparty_all") {
      setNewBooking((prevBooking) => {
        return { ...prevBooking, ...value };
      });
      setLocalBooking((prevBooking) => {
        return { ...prevBooking, ...value };
      });
      refBookingInfoControl.current.querySelector("#counterparty_name").value =
        value.counterparty_fio;
      refBookingInfoControl.current.querySelector("#phone").value = value.phone;
      refBookingInfoControl.current.querySelector("#info").value = value.info;
      refBookingInfoControl.current.querySelector("#extra_info").value =
        value.info_extra;
      return;
    }

    setLocalBooking((prevBooking) => {
      return { ...prevBooking, [key]: value };
    });

    if (key === "add_drivers_list") {
      value = value.map((v) => v.counterparty_id);
    }
    setNewBooking((prevBooking) => {
      return { ...prevBooking, [key]: value };
    });
  }

  function changeCarStatusFailModal(csfm) {
    setCarStatusFailModal(csfm);
  }

  function changeDocPhotos(arr) {
    setDocPhotos(arr);
    requestCacheCount.current = 1;
  }

  function changeIsFilterOpen(ifo) {
    setIsFilterOpen(ifo);
  }

  function resetStates() {
    setAgeExp(true);
    setClient({
      client_id: null,
      client_name: "",
      phone: "",
      source: "",
    });
    setTravelRegion("000000001");
    setOptions([]);
    const today = new Date();
    const tempTime = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      today.getHours() + 1
    );
    setVidacha({
      point_id: null,
      point_name: "",
      point_type: "office",
      address: "",
      date: [
        tempTime.getDate(),
        tempTime.getMonth(),
        tempTime.getFullYear(),
        tempTime.getHours(),
        "00",
      ],
      comment: "",
    });
    const tomorrow = new Date(
      tempTime.getFullYear(),
      tempTime.getMonth(),
      tempTime.getDate() + 1,
      tempTime.getHours()
    );
    setVozvrat({
      point_id: null,
      point_name: "",
      point_type: "office",
      address: "",
      date: [
        tomorrow.getDate(),
        tomorrow.getMonth(),
        tomorrow.getFullYear(),
        tomorrow.getHours(),
        "00",
      ],
      comment: "",
    });
    setTariff("200");
    setCar(null);
    setDiscount(0);
  }

  function changeDiscount(d) {
    const nums = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    d = Number(
      d
        .split("")
        .filter((i) => nums.includes(i))
        .join("")
    );
    if (d < 0) {
      d = 0;
    }
    if (d > 99) {
      d = 99;
    }
    setDiscount(d);
  }

  function changeApproved(a) {
    setApproved(a);
  };

  const changeIsOpenSaveConfirm = () => {
    setIsOpenSaveConfirm(!isOpenSaveConfirm);
  };

  const handleSaveEditBooking = () => {
    let counterpartyName = "";
    let phone = "";
    let info = "";
    let extraInfo = "";
    let approved = "";
    let operatesOnBasis = "";
    let infoForSb = "";
    let deposit = "";
    let rejectComment = "";
    let destination = "";

    if (refBookingInfoControl.current) {
      counterpartyName =
        refBookingInfoControl.current.querySelector("#counterparty_name").value;
      phone = refBookingInfoControl.current.querySelector("#phone").value;
      info = refBookingInfoControl.current.querySelector("#info").value;
      extraInfo =
        refBookingInfoControl.current.querySelector("#extra_info").value;
      operatesOnBasis =
        refBookingInfoControl.current.querySelector("#operates_on_basis").value;
      destination =
        refBookingInfoControl.current.querySelector("#destination").value;
      infoForSb =
        refBookingInfoControl.current.querySelector("#info_for_sb").value;
    }
    if (refRentInfo.current) {
      deposit = refRentInfo.current.querySelector("#deposit").value;
      approved = refRentInfo.current.querySelector("#approved").value;
    }

    if (refAgentInfo.current) {
      rejectComment =
        refAgentInfo.current.querySelector("#reject_comment").value;
    }

    setIsOpenSaveConfirm(false);

    const body = {
      year,
      booking_id: bookingId,
      counterparty_id: localBooking.counterparty_id,
      counterparty_fio: counterpartyName,
      operates_on_basis: operatesOnBasis,
      rent_price_day: requestBody.rent_price_day,
      phone,
      info,
      info_extra: extraInfo,
      approved_by: approved,
      destination,

      discount_sum: parseInt(
        newBooking?.discount_sum
          ? newBooking.discount_sum
          : localBooking?.discount_sum ?? 0
      ),

      discount_id: newBooking?.discount_id
        ? newBooking.discount_id
        : localBooking.discount_id ?? 0,

      vidacha: { ...vidacha, date: arrDateToUnix(vidacha.date) },
      vozvrat: { ...vozvrat, date: arrDateToUnix(vozvrat.date) },

      add_drivers_list: localBooking.add_drivers_list,
      options,

      gosnomer: car ? car.gosnomer : localBooking.car.gosnomer,
      car_id: car ? car.price_unit_id : localBooking.car.car_id,
      tariff,
      deposit,
      info_for_sb: infoForSb,
      reject_comment: rejectComment ? rejectComment : undefined,
      ...newBooking,
    };

    updateBookingService.mutate(body);
  };

  const handleCancelEditBooking = () => {
    if (refBookingInfoControl.current) {
      refBookingInfoControl.current.querySelector("#counterparty_name").value =
        dataQueries[0].data.data.counterparty_fio;
      refBookingInfoControl.current.querySelector("#operates_on_basis").value =
        dataQueries[0].data.data.operates_on_basis;
      refBookingInfoControl.current.querySelector("#phone").value =
        dataQueries[0].data.data.phone;
      refBookingInfoControl.current.querySelector("#info").value =
        dataQueries[0].data.data.info;
      refBookingInfoControl.current.querySelector("#extra_info").innerHTML =
        dataQueries[0].data.data.info_extra;
      refBookingInfoControl.current.querySelector("#operates_on_basis").value =
        dataQueries[0].data.data.operates_on_basis;
      refBookingInfoControl.current.querySelector("#destination").value =
        dataQueries[0].data.data.order
          ? dataQueries[0].data.data.order.destination
          : "";
      refBookingInfoControl.current.querySelector("#info_for_sb").value =
        dataQueries[0].data.data.info_for_sb;
    }

    if (refRentInfo.current) {
      refRentInfo.current.querySelector("#deposit").value = dataQueries[0].data
        .data.deposit
        ? dataQueries[0].data.data.deposit
        : "";
      refRentInfo.current.querySelector("#approved").value = 
        dataQueries[0].data.data.approved_by
        ?
        dataQueries[0].data.data.approved_by
        : "";
    }

    if (refAgentInfo.current) {
      refAgentInfo.current.querySelector("#reject_comment").value =
        dataQueries[0].data.data.reject_comment;
    }

    setLocalBooking(dataQueries[0].data.data);

    let selectedModel = carList?.data.data.find(
      (c) => c.price_unit_id === localBooking.car.car_id
    );
    if (selectedModel && selectedModel.car_list) {
      let selectedCar = selectedModel.car_list.find(
        (c) => c.gosnomer === localBooking.car.gosnomer
      );
      setCar({ ...selectedModel, ...selectedCar });
    } else {
      setCar(selectedModel);
    }

    setIsEdit(!isEdit);
  };

  if (isLoadingData) {
    return <Loader w={54} h={54} size={6} />;
  }

  const handleUpdatePrice = (promotionId) => {
    const body = {
      promotion_id: promotionId,
      rent_price_day:
        car?.price_matrix[tariffIndex]?.prices[priceMatrixIndex]?.price,
      vidacha_date: arrDateToUnix(vidacha.date),
      vozvrat_date: arrDateToUnix(vozvrat.date),
    };

    updatePriceService.mutate(body);
  };

  return (
    <>
      <div className="booking-wrap">
        {localBooking && dataBooking ? (
          <BookingInfoControl
            bookingId={bookingId}
            isEdit={isEdit}
            onToggleEdit={onToggleEdit}
            localBooking={localBooking}
            addOptions={addOptions}
            onChangeLocalBooking={handleChangeLocalBooking}
            onSaveEdit={changeIsOpenSaveConfirm}
            onCancelEdit={handleCancelEditBooking}
            handleSaveToDraft={handleSaveToDraft}
            counterpartyList={[]}
            tariff={tariff}
            tariffIndex={tariffIndex}
            changeTariff={changeTariff}
            travel={addOptions?.travel}
            options={options}
            changeOptions={changeOptions}
            carFranchise={carFranchise}
            priceMatrixIndex={priceMatrixIndex}
            viewCarList={viewCarList}
            changeViewCarList={changeViewCarList}
            carAddress={car?.point_address}
            points={points}
            draftBtIsDisabled={sendBtnIsDisabled}
            staticDataLoading={false}
            clientList={[]}
            editContentLoading={editContentLoading}
            isLoading={updateBookingService.isLoading}
            car={car}
            changeCar={changeCar}
            refBookingInfoControl={refBookingInfoControl}
            changeIsOpenAddDriverModal={changeIsOpenAddDriverModal}
          />
        ) : null}
        <div className="middle-block">
          {isEdit && !car ? (
            <ReqCreateMiddle
              cityList={cityList}
              options={options}
              addOptions={addOptions}
              travelRegion={travelRegion}
              changeTravelRegion={changeTravelRegion}
              ageExp={ageExp}
              changeAgeExp={changeAgeExp}
              viewCarList={viewCarList}
              car={car}
              changeCar={changeCar}
              priceMatrixIndex={priceMatrixIndex}
              tariffIndex={tariffIndex}
              priceId={priceId}
              coef={coef}
              perDayOptionsPrice={perDayOptionsPrice}
              perDailyPrice={perDailyPrice}
              isFilterOpen={isFilterOpen}
              changeIsFilterOpen={changeIsFilterOpen}
              carListRes={carList}
              carLoading={carLoading || priceLoading}
              onChangeLocalBooking={handleChangeLocalBooking}
              isBookingPage={true}
            />
          ) : (
            <div>
              {!carLoading && !car ? (
                <div className="emty-selected-car">
                  <h2>Автомобиль не выбран</h2>
                </div>
              ) : (
                <SelectedCarCard
                  car={car}
                  changeCar={changeCar}
                  loading={carLoading}
                  isBookingPage={true}
                  isEditBooking={isEdit}
                />
              )}
            </div>
          )}
          <RentInfo
            defaultPriceList={defaultPriceList}
            localBooking={localBooking}
            dataBooking={dataBooking}
            isEdit={isEdit}
            refRentInfo={refRentInfo}
            editContentLoading={editContentLoading}
            vidacha={vidacha}
            vozvrat={vozvrat}
            changeVidacha={changeVidacha}
            changeVozvrat={changeVozvrat}
            priceVidachaType={priceVidachaType}
            priceVozvratType={priceVozvratType}
            rentPeriod={rentPeriod}
            points={points}
            options={options}
            changeOptions={changeOptions}
            addOptions={addOptions}
            tariffIndex={tariffIndex}
            onChangeLocalBooking={handleChangeLocalBooking}
            car={car}
            onUpdatePrice={handleUpdatePrice}
            approved={approved}
            onChangeApproved={changeApproved}
          />
        </div>
        <div className="right-block">
          <AgentInfo
            isEdit={isEdit}
            agentList={dataBooking ? dataBooking.agent_list : []}
            agent={localBooking?.agent}
            source={localBooking?.booking_source}
            sourceList={dataBooking ? dataBooking.booking_source : []}
            rejectComment={localBooking?.reject_comment}
            bookingStatus={localBooking ? localBooking.booking_status : ""}
            needsRework={localBooking?.needs_rework}
            onChangeLocalBooking={handleChangeLocalBooking}
            refAgentInfo={refAgentInfo}
            cancelReason={localBooking ? localBooking.cancel_reason : ""}
            cancelReasonId={localBooking ? localBooking.cancel_reason_id : ""}
            cancelReasonList={dataBooking ? dataBooking.cancel_reason_list : []}
            detailedCancelReason={
              localBooking ? localBooking.detailed_cancel_reason : ""
            }
          />
          <BookingCalculation
            isEdit={isEdit}
            appeal={client.client_name}
            car={car}
            priceMatrixIndex={priceMatrixIndex}
            tariffIndex={tariffIndex}
            tariff={tariff}
            rentPeriod={rentPeriod}
            options={options}
            addOptions={addOptions}
            carFranchise={carFranchise}
            client={client}
            changeClient={changeClient}
            onSubmitSend={() => {}}
            sendBtnIsDisabled={sendBtnIsDisabled}
            totalPrice={totalPrice}
            carPrice={carPrice}
            dailyPrice={dailyPrice}
            priceVidachaType={priceVidachaType}
            priceVozvratType={priceVozvratType}
            doc_photos={doc_photos}
            changeDocPhotos={changeDocPhotos}
            coef={coef}
            staticDataLoading={false}
            discount={discount}
            discountSum={discountSum}
            changeDiscount={changeDiscount}
            isLoading={
              putReqMutation.isLoading ||
              postReqMutation.isLoading ||
              checkCarStatus.isLoading
            }
            vidacha={vidacha}
            vozvrat={vozvrat}
            handleSaveToDraft={handleSaveToDraft}
            onChangeLocalBooking={handleChangeLocalBooking}
            discountBooking={localBooking ? localBooking.discount : ""}
            discountList={dataBooking ? dataBooking.discount_list : []}
            discountSumBooking={localBooking ? localBooking.discount_sum : ""}
            promotionPercent={localBooking ? localBooking.promotion_percent : ""}
            onChangeApproved={changeApproved}
          />
        </div>
        <StatusCheckFailModal
          isOpen={!!carStatusFailModal}
          data={carStatusFailModal}
          onSubmit={() => navigate("/dashboard")}
          onClose={() => navigate("/dashboard")}
        />
        <ConfirmModal
          title="Внимание!"
          text="Действительно ли вы хотите внести изменения в документ?"
          isOpen={isOpenSaveConfirm}
          onClose={() => changeIsOpenSaveConfirm(false)}
          onSubmit={handleSaveEditBooking}
        />
        <Modal
          isOpen={isOpenAddDriverModal}
          onClose={() => setIsOpenAddDriverModal(false)}
          title={"Водители"}
        >
          <ModalAddDriver
            driverList={localBooking?.add_drivers_list ?? []}
            changeIsOpenAddDriverModal={changeIsOpenAddDriverModal}
            onChangeLocalBooking={handleChangeLocalBooking}
          />
        </Modal>
      </div>
      <Alert
        isOpen={openAlert}
        text={alertText}
        onClose={() => {
          setIsOpenAlert(false);
        }}
      />
    </>
  );
}
