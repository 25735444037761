import React, { useState, useMemo } from "react";
import cl from "classnames";
import { Collapse } from "react-collapse";
import { useLocation, useParams, useNavigate } from "react-router-dom";

//
import { useAppContext } from "../../store/Context";
import IconWrapper from "../icon-wrapper/IconWrapper";
import NumberFormat from "../number-format/NumberFormat";
import Finalize from "./components/Finalize";
import Successfully from "./components/Successfully";
import Denied from "./components/Denied";
import ExpiredConsideration from "./components/ExpiredConsideration";
import InWork from "./components/InWork";
import PrelimBooking from "./components/PrelimBooking";
import Booking from "./components/Booking";
import IssuanceClient from "./components/IssuanceClient";
import ToPayOff from "./components/ToPayOff";
import PaidOut from "./components/PaidOut";
import { useGetClientList } from "../../store/client/hook";
import { differentDates, msToNormTime } from "../../util/date";
import ModalFullMobile from "../modal-full-mobile/ModalFullMobile";
import "./ReqListItem.styles.scss";

// types => finalize, successfully, denied, expiredConsideration, consideration, inWork, prelimBooking, booking, issuanceClient, toPayOff, paidOut
export default function ReqListItem({ idx, request, archived, finance }) {
  const { pathname } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();

  function goToUrl(url) {
    navigate(url, { state: { isEdit: 1 } });
  }

  const {
    state: { user: agent, isMobile },
  } = useAppContext();

  // state
  const [isOpen, setIsOpen] = useState(false);

  // method
  function getIcon() {
    if (finance) return "check-circle icon-lg";
    if (archived) return "archive-outlined";
    if (request.status_id === 1) return "archive-outlined";
    if (request.status_id === 2) return "alert-circle icon-lg";
    if (request.status_id === 3) return "clock-circle icon-lg";
    if (request.status_id === 4) return "refresh icon-lg";
    if (request.status_id === 5) return "clock-circle-z icon-lg";
    if (request.status_id === 6) return "clock-circle-check icon-lg";
    if (request.status_id === 7) return "archive-outlined";
    if (request.status_id === 8) return "archive-outlined";
    if (request.status_id === 9) return "flag";
    if (request.status_id === 10) return "archive-outlined";
  }

  function getIconColor() {
    if (finance) return "success";
    if (archived) return "primary-dark-15";
    if (request.status_id === 1) return "dark-20";
    if (request.status_id === 2) return "warning";
    if (request.status_id === 3) return "danger";
    if (request.status_id === 4) return "primary";
    if (request.status_id === 5) return "orange";
    if (request.status_id === 6) return "purple";
    if (request.status_id === 7) return "danger";
    if (request.status_id === 8) return "danger";
    if (request.status_id === 9) return "purple";
    if (request.status_id === 10) return "success";
  }

  function getIconBg() {
    return "light";
  }

  function toggleIsOpen() {
    setIsOpen((p) => !p);
  }

  const { data } = useGetClientList();

  const client = useMemo(() => {
    if (!data) return null;
    if (pathname.includes("client")) {
      return data.data.client_list.find((i) => i.client_id === id);
    }
    return request.client || request.client_name;
  }, [pathname, id, data, request]);

  const requestTitle = useMemo(() => {
    if (!request.status_id) return "--";
    if (request.status_id === 9) return "Выдача клиенту";
    if (request.status_id === 10) return "Успешно";
    return request.status || "--";
  }, [request]);

  function getBodyComponent() {
    if (finance) { return <PaidOut request={request} />;}

    return {
      1: (
        <Finalize
          archived={archived}
          request={request}
          client={client}
          isDrafted={1}
        />
      ),
      2: <Finalize archived={archived} request={request} client={client} />,
      7: <Denied archived={archived} request={request} client={client} />,
      8: <Denied archived={archived} request={request} client={client} />,
      3: (
        <ExpiredConsideration
          archived={archived}
          request={request}
          client={client}
        />
      ),
      4: <InWork archived={archived} request={request} client={client} />,
      5: (
        <PrelimBooking archived={archived} request={request} client={client} />
      ),
      6: <Booking archived={archived} request={request} client={client} />,
      9: (
        <IssuanceClient archived={archived} request={request} client={client} />
      ),
      10: <Successfully request={request} archived={archived} />,
    }[request.status_id];
  }

  const reqStatusTime = useMemo(() => {
    if ([7, 8, 10].includes(request.status_id))
      return msToNormTime(request.status_set_date, 8);
    return differentDates(request.status_set_date, Date.now() / 1000);
  }, [request]);

  return (
    <div className={`req__list__item ${isOpen ? "collapse" : undefined}`} data-color={request.status_id}
      // idx={idx}
      // type={request.status_id}
      // isOpen={isOpen}
      // archived={archived}
      // finance={finance}
    >
      <section className="req__list__item__header" onClick={toggleIsOpen}>
        <div className="req__list__item__header__left">
          <IconWrapper
            icon={getIcon()}
            color={getIconColor()}
            bg={getIconBg()}
            borderColor={getIconColor()}
            w={45}
            h={45}
            border={!archived && [7, 8, 10].includes(request.status_id)}
            rounded={14}
          />
          <div className="req__infos">
            <h1>{request.client_name}</h1>
            {!["toPayOff", "paidOut"].includes(request.status_id) && (
              <p>
                <i className="icon icon-xs icon-announcement"></i>{" "}
                Администраторы СТО
              </p>
            )}
          </div>
        </div>
        <div className="req__list__item__header__right">
          {finance && (
            <div
              className={cl("req__income__wrap", {
                "text-warning-dark": idx % 2 === 0,
              })}
            >
              <NumberFormat value={1500} prefix="+" />
            </div>
          )}
          {finance && (
            <div className="req__sum__wrap">
              <h1>
                <NumberFormat value={request.price_total} />
              </h1>
              <p>Сумма сделки</p>
            </div>
          )}
          <div className="req__date__wrap">
            <h1>{msToNormTime(request.date_created, 7)}</h1>
            <p>№ {request.request_id}</p>
          </div>
          {agent?.has_extended_rights && !finance && (
            <div className="req__agent__extended">
            <IconWrapper
              icon="pencil"
              w={45}
              h={45}
              border={1}
              rounded={14}
              onClick={() => goToUrl("/req-create/" + request.request_id)}
            />
            </div>
          )}
          {
            !finance &&
            <div className="req__status__wrap">
              <h1>{requestTitle}</h1>
              <p>{reqStatusTime}</p>
              {[7, 8, 10].includes(request.status_id) && (
                <i
                  className={cl("icon", {
                    "icon-check-circle": request.status_id === 10,
                    "icon-slash-circle":
                      request.status_id === 8 || request.status_id === 7,
                  })}
                ></i>
              )}
            </div>

          }
          {
            !isMobile && 
          <i
            style={{ transform: `rotate(${isOpen ? "-180deg" : "0deg"})` }}
            className="icon icon-chevron-down"
          />
          }
        </div>
      </section>
      {
        isMobile 
        ?
        <ModalFullMobile
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          title={"Заявка № " + request.request_id}
        >
          <section className="req__list__item__body">
            {getBodyComponent()}
          </section>
        </ModalFullMobile>
        :
        <Collapse isOpened={isOpen} borderColor={getIconColor()} >
          <section className="req__list__item__body">
            {getBodyComponent()}
          </section>
        </Collapse>
      }
    </div>
  );
}
