import { v4 } from "uuid";

export const navList = [
  {
    id: v4(),
    title: "Дэшборд",
    to: "/dashboard",
    icon: "layout-grid",
    count: 0,
  },
  {
    id: v4(),
    title: "Каталог",
    to: "/catalog",
    icon: "parking-rectangle",
    count: 0,
  },
  {
    id: v4(),
    title: "Сравнения",
    to: "/comparsion",
    icon: "scales",
    count: 0,
  },
  {
    id: v4(),
    title: "Клиенты",
    to: "/client",
    icon: "users",
    count: 0,
  },
  {
    id: v4(),
    title: "Архив",
    to: "/archive",
    icon: "archive-outlined",
    count: 0,
  },

  {
    id: v4(),
    title: "Финансы",
    to: "/finance",
    icon: "line-chart-down",
    count: 0,
  },
//   {
//     id: v4(),
//     title: "Бронирование",
//     to: "/booking",
//     icon: "booking",
//     count: 0,
// },
  
  // {
  //   id: v4(),
  //   title: "Рефералы",
  //   to: "/referal",
  //   icon: "user",
  //   count: 0,
  // },
];
