import React, { useEffect, useState } from "react";

//
import SelectSearch from "../../../../components/select-search/SelectSearch";
import "./DriverBooking.styles.scss";
import Button from "../../../../components/button/Button";
import Auditor from "../auditor/Auditor";
import Card from "../../../../components/card/Card";
import { Collapse } from "react-collapse";
import Input from "../../../../components/input/Input";
import Switch from "../../../../components/switch/Switch";

export default function DriverBooking({
  counterparty,
  onChangeLocalBooking,
  isEdit,
	contract,
  addDriversList,
	checkDriver,
  changeIsOpenAddDriverModal,
	changeOptions,
	addOptions,
}) {
	const simple = addOptions.simple;
  const [driverList, setDriverList] = useState([]);
  const [isOpenDriver, setIsOpenDriver] = useState(false);

  function toggleIsOpenDriver() {
    setIsOpenDriver((p) => !p);
  }

  useEffect(() => {
    setDriverList(addDriversList);
		changeOptions(driverList.length, simple[0])
  }, [addDriversList, driverList.length]);

  const handleChangeDriver = (counterparty) => {
    onChangeLocalBooking("driver_id", counterparty.counterparty_id);
    onChangeLocalBooking("driver", counterparty.counterparty);
  };

  return (
		<Card radius={12}>
      <div className="driver-wrapper">
        <div className="driver-header">
          <h1>Водители</h1>
          {!isOpenDriver && <p>Включите это поле, если основным водителем будет не контрагент,
            на которого оформляется договор</p>}
					<div className="driver-header__add-drivers">
							<Button
								radius={12}
								color="primary"
								border={"1"}
								textUpperCase={1}
								fs={12}
								fW={500}
								p={[8, 10]}
								disabled={!isEdit}
								onClick={changeIsOpenAddDriverModal}
							>
								Доп. водители
								{driverList.length > 0 ? 
								(`( ${driverList.length} )`)
								: null}
							</Button>
          </div>
					<Switch 
						value={isOpenDriver}
						onChange={toggleIsOpenDriver}
						disabled={!isEdit}
					/>
        </div>
      	<Collapse isOpened={isOpenDriver}>
        	<main className="driver__collapse">
						<div className="driver__block">
							<div className="label-block">
								<h3>Основной водитель</h3>
								<SelectSearch
									value={counterparty}
									onChange={handleChangeDriver}
									disabled={!isEdit}
								/>
            	</div>
							<div className="label-block">
								<h3>Действует на основании</h3>
								<Input
									defaultValue={contract}
									disabled={!isEdit}
									size={"sm"}
									fill={1}
									id="operates_on_basis"
								/>
							</div>
						</div>
          	<Auditor check={checkDriver ? checkDriver : ""} />
      		</main>
        </Collapse>
            
      </div>
    </Card>
  );
}
