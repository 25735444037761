import React from "react";
//
import PopUp from "../popup/PopUp";
import Card from "../card/Card";
import IconWrapper from "../icon-wrapper/IconWrapper";
import TextArea from "../textarea/TextArea";
import Button from "../button/Button";
import Loader from "../loader/Loader";
import "./ReqCancelModal.styles.scss";

export default function ReqCancelModal({
  isOpen,
  onClose,
  title,
  text,
  btnTitle,
  onSubmit,
  isLoading,
  value,
  onChange
}) {
  // state

  // method
  function handleChangeMsg(e) {
    onChange(e.target.value);
  }

  return (
    <PopUp isOpen={isOpen} onClose={onClose}>
      <>
        <Card>
          <main className="req__cancel__modal">
            <nav className="req__cancel__modal__top">
              <h1>{title}</h1>
              <IconWrapper
                icon="close icon-lg"
                w={52}
                h={52}
                rounded={0}
                onClick={onClose}
              />
            </nav>
            <div className="req__cancel__modal__body">
              <p>{text}</p>
              <TextArea
                placeholder="Сообщение"
                value={value}
                onChange={handleChangeMsg}
                withEffect={1}
              />
            </div>
            <footer className="req__cancel__modal__bottom">
              <Button
                color="danger"
                textUpperCase={1}
                rounded={14}
                p={[16, 30]}
                disabled={value.length < 5}
                onClick={onSubmit}
              >
                {isLoading && (
                  <Loader bg="danger" color="light" w={24} h={24} size={3} />
                )}
                {btnTitle}
              </Button>
            </footer>
          </main>
        </Card>
      </>
    </PopUp>
  );
}