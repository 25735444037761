import React from "react";
import Card from "../../../../components/card/Card";
import NumberFormat from "../../../../components/number-format/NumberFormat";
import Input from "../../../../components/input/Input";
import "./TotalPriceBooking.styles.scss";
import SelectWithReset from "../../../../components/select-with-reset/SelectWithReset";
import { useAppContext } from "../../../../store/Context";

export default function TotalPriceBooking({
  totalPrice,
  isEdit,
  discountList,
  discountBooking,
  onChangeLocalBooking,
  discountSumBooking = 0,
  coef,
  onChangeApproved,
}) {
  const {
    state: { user },
  } = useAppContext();

  function getDiscountList() {
    return [...discountList?.map((i) => ({ 
		id: i.discount_id, 
		title: i.discount, 
		value: i.discount_id }))];
  }

  function handleChangeDiscount(dis) {
    const discount = discountList.find((item) => item.discount_id === dis);

    onChangeLocalBooking("discount", discount ? discount.discount : "");
    onChangeLocalBooking("discount_id", discount ? discount.discount_id : "");

    if (!discount) {
      onChangeLocalBooking("discount_sum", "");
      onChangeApproved("");
    } else {
      onChangeApproved(user.name);
    }
  }

  const resultTotalPrice = totalPrice - discountSumBooking;
  const dailyPrice = Math.floor(resultTotalPrice / coef);

  return (
    <>
      <Card radius={20} className="total-price">
        <main className="total-price__main">
              <div className="total-price__top">
                <div className="total-price__total">
                  <h1>Итого</h1>
                  <div className="price-with-discount">
                    <div className="sum-price">
                        <span>
                          <NumberFormat value={resultTotalPrice} />
                        </span>
                      <p className="price-per-day">
                        {" "}
                        <NumberFormat value={dailyPrice} /> в сутки
                      </p>
                    </div>
                  </div>
                </div>
              </div>
          <div className="calculation__footer-wrap">
            <div className="calculation__footer">
              <h3>Скидка</h3>
							<div className="calculation__footer__discount">
								<SelectWithReset
									fill={1}
									placeholder="Скидка"
									size="sm"
									options={getDiscountList()}
									disabled={!isEdit}
									defaultValue={discountBooking}
									onChange={handleChangeDiscount}
								/>
								<Input 
                  type="number"
									placeholder="скидка"
									size={"sm"}
									value={discountSumBooking}
									disabled={!isEdit || !discountBooking}
									onChange={(e) => onChangeLocalBooking("discount_sum", e.target.value)}
								/>
							</div>
            </div>
          </div>
        </main>
      </Card>
    </>
  );
}
