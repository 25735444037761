import { useMemo } from "react";

import Card from "../../../../components/card/Card";
import Select from "../../../../components/select/Select";
import Checkbox from "../../../../components/checkbox/Checkbox";
import NumberFormat from "../../../../components/number-format/NumberFormat";
import "./OptionsBooking.styles.scss";
import ChildSeat from "../../../agent/req-create-v2/components/child-seat/ChildSeat";
import SelectWithReset from "../../../../components/select-with-reset/SelectWithReset";

export default function OptionsBooking({
  isEdit,
  travel,
  options,
  changeOptions,
  addOptions,
  carFranchise,
  priceMatrixIndex,
}) {
  const simple = addOptions.simple;

  function getAddOptionByName(name) {
    return addOptions.simple.find((op) =>
      op.name.toLocaleLowerCase().includes(name.toLocaleLowerCase())
    );
  }

  function getAmountOption(optionId) {
    return options.find((i) => i.option_id === optionId)?.amount || 0;
  }

  const insurance = useMemo(() => {
    if (!carFranchise) {
      let insuranceOptionIds = addOptions.insurance.map((i) => i.option_id);
      options.map((o) =>
        insuranceOptionIds.includes(o.option_id) ? changeOptions(0, o) : null
      );
      return [];
    }
    let obj = {
      12000: 1,
      18000: 2,
      24000: 3,
    };
    return addOptions.insurance.filter(
      (i) => i.class === "Ценовая группа №" + obj[carFranchise]
    );
  }, [carFranchise, addOptions.insurance, options, changeOptions]);

  function checkForChecked(list, id) {
    return !!list.find((i) => i.option_id === id);
  }

  function getIncuranceValue() {
    return (
      options.find((i) =>
        insurance.map((j) => j.option_id).includes(i.option_id)
      )?.option_id || 0
    );
  }

  function getIncuranceOptions() {
    return [
      ...insurance.map((i) => ({
        id: i.option_id,
        title: i.name,
        value: i.option_id,
      })),
    ];
  }

  function changeIncurance(optionId) {
    let exsistOption = options.find((i) =>
      insurance
        .map((i) => i.option_id)
        .join("")
        .includes(i.option_id)
    );
    if (exsistOption) {
      changeOptions(0, exsistOption);
    }
    optionId = insurance.find((i) => i.option_id === optionId);
    changeOptions(1, optionId);
  }

  const carWashIndex = useMemo(() => {
    if (carFranchise) {
      let optionIndex = addOptions.simple.findIndex((op) =>
        op.name.includes(String(carFranchise))
      );
      return optionIndex;
    } else
      return addOptions.simple.findIndex((op) => op.name.startsWith("Мойка"));
  }, [carFranchise, addOptions.simple]);

  function checkCarWash() {
    changeOptions(getAmountOption(simple[carWashIndex]?.option_id) === 1 ? 0 : 1, simple[carWashIndex]);
  }

  const transponder = getAddOptionByName("Транспондер");
  const mobileHolder = getAddOptionByName("Держатель");
  const cableUSB = getAddOptionByName("Провод");

  return (
    <>
      <Card radius={12}>
        <main className="additional_options__main">
          <div className="additional_options__form">
            <div className="incurance_wrap">
              <div className="incurance_wrap">
                <p>Страхование</p>
                <div>
                  {insurance.length > 0 ? (
                    <SelectWithReset
                      title="Выберите пункт"
                      value={getIncuranceValue()}
                      options={getIncuranceOptions()}
                      onChange={changeIncurance}
                      disabled={!isEdit}
                    />
                  ) : (
                    <>
                      <Select size="md" title="Выберите авто" />
                    </>
                  )}
                </div>
              </div>
							<ChildSeat
								isEditBooking={isEdit}
								options={options}
								changeOptions={changeOptions}
								childSeat={addOptions.child_seat}
								priceMatrixIndex={priceMatrixIndex}
								isTariff
							/>
            </div>
            <ul className="additional_options__list">
              <li>
                <Checkbox
                  checked={getAmountOption(simple[carWashIndex]?.option_id)}
                  onClick={checkCarWash}
                  disabled={!carFranchise || !isEdit}
                  size={"sm"}
                />
                <div>
                  <h1 style={!carFranchise ? { opacity: "0.5" } : undefined}>
                    Автомойка
                  </h1>
                </div>
              </li>
              <li>
                <Checkbox
                  checked={getAmountOption(transponder?.option_id)}
                  onClick={() =>
                    changeOptions(
                      getAmountOption(transponder?.option_id) === 1 ? 0 : 1,
                      transponder
                    )
                  }
                  disabled={!isEdit}
                  size={"sm"}
                />
                <div>
                  <h1>Транспондер</h1>
                  <p>
                    <NumberFormat
                      value={
                        transponder?.price_matrix[priceMatrixIndex]
                          ?.price_day || 0
                      }
                    />
                    <small>
                      {transponder?.per_day ? "в сутки" : "за услугу"}
                    </small>
                  </p>
                </div>
              </li>
              <li>
                <Checkbox
                  checked={getAmountOption(mobileHolder?.option_id)}
                  onClick={() =>
                    changeOptions(
                      getAmountOption(mobileHolder?.option_id) === 1 ? 0 : 1,
                      mobileHolder
                    )
                  }
                  disabled={!isEdit}
                  size={"sm"}
                />
                <div>
                  <h1>Держатель для телефона</h1>
                  <p>
                    <NumberFormat
                      value={
                        mobileHolder?.price_matrix[priceMatrixIndex]
                          ?.price_day || 0
                      }
                    />
                    <small>
                      {mobileHolder?.per_day ? "в сутки" : "за услугу"}
                    </small>
                  </p>
                </div>
              </li>
              <li>
                <Checkbox
                  checked={getAmountOption(cableUSB?.option_id)}
                  onClick={() =>
                    changeOptions(
                      getAmountOption(cableUSB?.option_id) === 1 ? 0 : 1,
                      cableUSB
                    )
                  }
                  disabled={!isEdit}
                  size={"sm"}
                />
                <div>
                  <h1>USB провод</h1>
                  <p>
                    <NumberFormat
                      value={
                        cableUSB?.price_matrix[priceMatrixIndex]?.price_day || 0
                      }
                    />
                    <small>{cableUSB?.per_day ? "в сутки" : "за услугу"}</small>
                  </p>
                </div>
              </li>
            </ul>
            <div className="additional_options__travel">
              <div className="checkbox">
                <Checkbox
                  checked={checkForChecked(options, travel[0]?.option_id)}
                  onClick={() => {
                    changeOptions(
                      checkForChecked(options, travel[0]?.option_id) ? 0 : 1,
                      travel[0]
                    );
                  }}
                  disabled={!isEdit}
                  size={"sm"}
                />
                <p>Выезд в другие регионы</p>
              </div>
            </div>  
          </div>
        </main>
      </Card>
    </>
  );
}
