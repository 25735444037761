import React from "react";
import styled from "styled-components";

//

export default function ProgressBar({ value = 0, bg }) {
  return (
    <StyledProgressBar bg={bg} value={value}>
      <div className="value"></div>
    </StyledProgressBar>
  );
}

const StyledProgressBar = styled.div`
  display: inline-block;
  width: 100%;
  height: 6px;
  background: var(--primary-dark-5);
  border-radius: 5px;
  position: relative;
  overflow: hidden;

  .value {
    width: ${({ value }) => value + "%"};
    background-color: ${({ bg }) => `var(--${bg})`};
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    font-size: 10px;
  }
`;
