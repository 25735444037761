import React from "react";
import styled, { css } from "styled-components";

export default function Checkbox({
  checked,
  disabled = false,
  outlined,
  size,
  ...p
}) {
  return (
    <StyledCheckbox
      disabled={disabled}
      checked={checked}
      outlined={outlined}
      size={size}
      {...p}
    >
      <i className="icon icon-sm icon-check"></i>
    </StyledCheckbox>
  );
}

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;
  background-color: var(--dark-5);
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s linear;

  i {
    background-color: var(--primary-dark-15);
  }
  @media (min-width: 1280px) {

    &:hover {
      background-color: var(--primary-10);
  
      i {
        background-color: var(--primary);
      }
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}

  ${({ checked }) =>
    checked &&
    css`
      background-color: var(--primary);

      i {
        background-color: var(--light);
      }

      @media (min-width: 1280px) {
        &:hover {
          background-color: var(--primary);
  
          i {
            background-color: var(--light);
          }
        }
      }

    `}

  ${({ outlined, checked }) =>
    outlined &&
    css`
      background-color: transparent;

      &:hover {
        background-color: transparent;

        i {
          background-color: var(--primary);
        }
      }

      ${checked &&
      css`
        i {
          background-color: var(--primary);
        }
      `}
    `}

    ${({ size }) =>
    size === "sm"
      ? css`
          width: 24px;
          height: 24px;
          min-width: 24px;
          min-height: 24px;
          border-radius: 8px;

          i {
            width: 16px;
            height: 16px;
          }
        `
      : null}
    ${({ size }) =>
    size === "lg"
      ? css`
          width: 32px;
          height: 32px;
          min-width: 32px;
          min-height: 32px;
          border-radius: 8px;

          i {
            width: 20px;
            height: 20px;
          }
        `
      : null}
`;
