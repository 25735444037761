import React, { useRef, useState } from 'react';
import styled, { css } from "styled-components";

export default function ToolTipComponent({ children, text }) {
  const refSetTimeout = useRef();
  const [showToolTip, setShowToolTip] = useState(false);

  const onMouseEnterHandler = () => {
    refSetTimeout.current = setTimeout(() => {
      setShowToolTip(true);
    }, 0);
  };

  const onMouseLeaveHandler = () => {
    clearTimeout(refSetTimeout.current);
    setShowToolTip(false);
  };

  return (
    <StyledToolTipComponent>
        <div className={"container-tooltip"} onMouseEnter={onMouseEnterHandler} onMouseLeave={onMouseLeaveHandler}>
        {children}
        {showToolTip && <div className={"open-tooltip"}>{text}</div>}
        </div>

    </StyledToolTipComponent>
  );
};

const StyledToolTipComponent = styled.div`
.container-tooltip {  
    position: relative;
    display: flex; 

}
.open-tooltip {
    position: absolute;
    top: -22px;
    left: 0px;
    width: 300px;
    padding: 4px 12px;
    border-radius: 12px ;
    font-size: 12px;
    text-align: left;
    font-weight: 400 ;
    pointer-events: none;
  }

`;