import React, { useState } from "react";
//
import Card from "../../../../../components/card/Card";
import Button from "../../../../../components/button/Button";
import CreateClinetModal from "../../../../../components/create-client-modal/CreateClientModal";
import "./ClientTop.styles.scss";
import { useAppContext } from "../../../../../store/Context";

export default function ClientTop({ tab, onChangeTab }) {
  const {
    state: { isMobile },
  } = useAppContext();
  // state
  const [modal, setModal] = useState(false);

  //   method
  function changeModal(m) {
    setModal(m);
  }

  return (
    <div className="client__top__wrap">
      <Card>
        <main className="client__top__content">
          <section className="client__top__left">
            <Button
              p={[14, 24]}
              color={tab === "client" ? "primary" : "light"}
              radius={14}
              fW={500}
              onClick={() => onChangeTab("client")}
            >
              Список клиентов
            </Button>
            <Button
              p={[14, 24]}
              color={tab === "blackList" ? "primary" : "light"}
              radius={14}
              fW={500}
              onClick={() => onChangeTab("blackList")}
            >
              Черный список
            </Button>
          </section>
          <section className="client__top__right">
            {isMobile && <h1>Клиенты</h1>}
            <Button
              p={[14, 16, 14, 26]}
              radius={14}
              color={isMobile ? "transparent" : "success"}
              fW={500}
              onClick={() => changeModal(true)}
            >
              Добавить <i className="icon icon-sm icon-plus"></i>
            </Button>
          </section>
        </main>
      </Card>
      {modal && (
        <CreateClinetModal
          isOpen={modal}
          title="Новый клиент"
          onClose={() => changeModal(false)}
        />
      )}
    </div>
  );
}
