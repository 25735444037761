import React from "react";
import { Link, useNavigate } from "react-router-dom";

//
import logo from "../../../asset/img/logo-icon.svg";
import { navList } from "../static";
import { useAppContext } from "../../../store/Context";
import { hidePhoneNumberGen } from "../../../util/formatters";

import "./MobileNavbar.styles.scss";


export default function MobileNavbar() {
  // helper
  const navigate = useNavigate();

  // context
  const {
    state: { carComparsionListLength, user, draftsCount },
    fn: { changeUserInfoModal, changeIsOpenMenu },
  } = useAppContext();
  navList[0].count = draftsCount;
  navList[2].count = carComparsionListLength;

  return (
    <div  className="mobile-navbar">
      <section className="mobile-navbar__top">
				<div className="mobile-navbar__menu-burger" 
					onClick={(e) => {
            changeIsOpenMenu(true);
            e.stopPropagation();
          }}>
          <div className="icon-burger"></div>

				</div>
        <Link
          to="/"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <img src={logo} alt="logo" width={40} height={40}/>
        </Link>
        <button
          className="btn-create"
          onClick={(e) => {
            e.stopPropagation();
            navigate("/req-create");
          }}
        >
          <i className="icon icon-sm icon-plus" />
        </button>
      </section>
      <section className="mobile-navbar__bottom">
        <div
          className="agent__info__wrap"
          onClick={(e) => {
            changeUserInfoModal(true);
            e.stopPropagation();
          }}
        >
          <img
            src={
              user?.avatar ||
              "https://cdn3d.iconscout.com/3d/premium/thumb/man-avatar-6299539-5187871.png"
            }
            alt="user-avatar"
            width={40}
          />
          <div className="agent__info">
            <h1>{user?.name}</h1>
            <p>{hidePhoneNumberGen(user?.phone)}</p>
          </div>
        </div>
      </section>
    </div>
  );
}
