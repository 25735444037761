import React from "react";
import ReactOutsideClickHandler from "react-outside-click-handler";

import "./ReqCreateFilterModal.styles.scss";
import IconWrapper from "../../../../../../components/icon-wrapper/IconWrapper";


export default function ReqCreateFilterModal({ isOpen, title, onClose, children }) {
  
    return (
      <div className={`modal-filter__wrap ${isOpen ? "modal-filter__open" : ""}`} data-draggable='false'>
        <ReactOutsideClickHandler onOutsideClick={onClose}>
					<div>
						<main className="modal-filter__main">
							<nav className="modal-filter__nav">
									<h1>{title}</h1>
									<IconWrapper
									icon="close icon-lg"
									w={52}
									h={52}
									rounded={0}
									onClick={onClose}
									/>
							</nav>
							<div className="modal__child">{children}</div>
						</main>
					</div>
        </ReactOutsideClickHandler>
      </div>
    );
  }
  