import React from "react";
import styled, { css } from "styled-components";

//
import { getCarNumberMain, getCarNumberSup } from "../../util/formatters";

export default function CarNumber({ title, size }) {
  if(!title) return null
  return (
    <StyledCarNumber size={size}>
      {getCarNumberMain(title)} <sup>{getCarNumberSup(title)}</sup>
    </StyledCarNumber>
  );
}

const StyledCarNumber = styled.div`
  display: inline-block;
  border: 1px solid var(--primary-dark-15);
  border-radius: 6px;
  padding: 1px 6px 4px;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  color: var(--dark-70);
  white-space: nowrap;
  text-align: center;

  ${({ size }) =>
    size === "lg"
      ? css`
          font-size: 16px;
          line-height: 20px;
        `
      : size === "sm"
      ? css`
          font-size: 12px;
          line-height: 15px;
        `
      : size === "xs"
      ? css`
          font-size: 10px;
          line-height: 12px;
        `
      : null}
`;
