import { useQuery } from "react-query";
import React, { useEffect, useMemo, useState } from "react";
import { msToNormTime } from "../../util/date";
import { useNavigate } from "react-router-dom";
import BookingApi from "../../store/booking/api";
import Loader from "../../components/loader/Loader";
import SortBtn from "../../components/sort-btn/SortBtn";
import { formatPhoneNumber } from "../../util/formatters";
import "./BookingPage.styles.scss";
import Input from "../../components/input/Input";
import useDebounce from "../../hooks/useDebounce";

function BookingPage() {
  const [bookingList, setBookingList] = useState([]);
  const [search, setSearch] = useState("");
  const debounceSearch = useDebounce(search, 500);
  const [sortBy, setSortBy] = useState({
    key: "date_created",
    value: 0,
  });
  const navigate = useNavigate();

  let {
    isSuccess,
    isLoading,
    data: bookingResponse,
  } = useQuery("GET_BOOKING_LIST", BookingApi.getBookingList);

  useEffect(() => {
    if (isSuccess) {
      setBookingList(bookingResponse.data.booking_list);
    }
  }, [isSuccess, bookingResponse]);

  useEffect(() => {
    if (!isSuccess) return;
    let res = structuredClone(bookingResponse.data.booking_list);
    res.sort(function (a, b) {
      return a[sortBy.key] - b[sortBy.key];
    });
    if (sortBy.value === -1) res.reverse();

    setBookingList(res);
  }, [sortBy, bookingResponse, isSuccess]);

  function changeSearch(s) {
    setSearch(s);
  }

  const resultBookingList = useMemo(()=>{
    if (!bookingList) return [];
    let tempList = [...bookingList];


    if (debounceSearch) {
      tempList = tempList
        .map((i) => {
            if (i.counterparty.toLowerCase().includes(debounceSearch.toLowerCase())) {
              return i;
            } else return null;
        }).filter((k) => k)
    }

    return tempList;
  })

  function renderSortButton(tilte, sortKey) {
    return (
      <div className="BookingPage__flex-header">
        {tilte}
        <SortBtn
          value={sortBy.key === sortKey ? sortBy.value : 0}
          onChange={(val) => {
            setSortBy({ value: val, key: sortKey });
          }}
        />
      </div>
    );
  }

  function handleBookingClick(booking) {
    let url = `${new Date(booking?.date_created * 1000).getFullYear()}/${
      booking.booking_id
    }`;

    navigate(url);
  }

  function getStatus(booking) {
    switch(booking.booking_status) {
      case "canceled":  
        return "Отменa";
      case "progress":  
        return "В работе";
      case "success":
        return "Успех";
      default:
        return "В работе";
    }
  };

  if (isLoading) {
    return (
      <div className="BookingPage__loading">
        <Loader w={54} h={54} size={6} />
      </div>
    );
  }
  return (
    <div className="BookingPage__root">
      <table>
        <thead>
          <tr>
            <th>{renderSortButton("Дата, №", "date_created")}</th>
            <th>Марка модель</th>
            <th>Автомобиль</th>
            <th>{renderSortButton("Дата выдачи", "vidacha_date")}</th>
            <th>
              <div className="client">
                {/* {renderSortButton("Контрагент", "client_name")} */}
                Контрагент
                <Input
                  fill={1}
                  placeholder="Поиск"
                  icon="search"
                  withValueEffect={1}
                  value={search ?? ""}
                  onChange={(e) => changeSearch(e.target.value)}
                />
              </div>
            </th>
            <th style={{ minWidth: 180 }}>Телефон</th>
            <th>Срок аренды (дней)</th>
            <th>Статус</th>
          </tr>
        </thead>
        <tbody>
          {resultBookingList.map((booking) => {
            return (
              <tr
                role="link"
                key={booking.booking_id}
                onClick={() => handleBookingClick(booking)}
              >
                <td>
                  {msToNormTime(booking.date_created, 2)},
                  <span className="gray">{" № " + booking.booking_id}</span>
                </td>
                <td>{booking.model ? booking.model : " - "}</td>
                <td>
                  {booking.brand ? booking.brand : " - "}
                  {booking.gosnomer ? ` - ${booking.gosnomer}` : ""}
                </td>
                <td>{msToNormTime(booking.vidacha_date, 7)}</td>
                <td>{booking.counterparty}</td>
                <td>{formatPhoneNumber(booking.phone)}</td>
                <td>{booking.rent_period + " д."}</td>
                <td>{getStatus(booking)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default BookingPage;
