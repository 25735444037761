import React, { useEffect, useState } from "react";

//
import Card from "../../../../components/card/Card";
import "./ExtraFields.styles.scss";
import SelectWithReset from "../../../../components/select-with-reset/SelectWithReset";
import Input from "../../../../components/input/Input";
import { useAppContext } from "../../../../store/Context";

export default function TypeBooking({
  isEdit,
  car,
  onChangeLocalBooking,
  deliveryman,
  deliverymanList,
  deliveryPayment,
  deliveryPaymentList,
  optionsPoint,
  optionsPointList,
  isShowDeliveryman,
	promotionId,
	promotionName,
	promotionPercent,
	promotionList,
	onUpdatePrice,
	discount,
	approved,
  onChangeApproved
}) {
	const {
    state: { user },
  } = useAppContext();

	function getPromotionList() {
		return [...promotionList.map((i) => ({ 
			id: i.promotion_id, 
			title: `${i.promotion_name} - ${i.promotion_percent}%`, 
			value: i.promotion_id }))];
	}

  function getOptionsPointList() {
    return [...optionsPointList.map((i) => ({ 
		id: i.options_point_id, 
		title: i.options_point, 
		value: i.options_point_id }))];
  }

	function handleChangePromotion(p) {
		const promotion = promotionList.find((item) => item.promotion_id === p);
		onChangeLocalBooking("promotion_name", promotion ? promotion.promotion_name : "");
		onChangeLocalBooking("promotion_percent", promotion ? promotion.promotion_percent : "");
		onChangeLocalBooking("promotion_id", promotion ? promotion.promotion_id : "");
		onUpdatePrice(p);

		if (!promotion && !discount) {
			if(!discount) onChangeApproved("");
		} else {
			onChangeApproved(user.name);
		}
	}

  function handleChangeOptionsPoint(op) {
    const point = optionsPointList.find((item) => item.options_point_id === op);
    onChangeLocalBooking("options_point", point ? point.options_point : "");
    onChangeLocalBooking("options_point_id", point? point.options_point_id : "");
  }

  function getDeliverymanList() {
    return [...deliverymanList?.map((i) => ({ 
		id: i.deliveryman_id, 
		title: i.deliveryman_name, 
		value: i.deliveryman_id }))];
  }

  function handleChangeDeliveryman(dm) {
    const deliveryman = deliverymanList.find((item) => item.deliveryman_id === dm);
    onChangeLocalBooking("deliveryman", deliveryman ? deliveryman.deliveryman_name : "");
    onChangeLocalBooking("deliveryman_id", deliveryman ? deliveryman.deliveryman_id : "");
  }

  function getDeliveryPaymentList() {
    return [...deliveryPaymentList.map((i, index) => ({ id: index, title: i, value: index }))];
  }

  function handleChangeDeliveryPayment(dpIndex) {
    const deliveryPayment = deliveryPaymentList.find((item, index) => index === dpIndex);
    onChangeLocalBooking("delivery_payment_type", deliveryPayment ? deliveryPayment : "");
  }


  return (
    <main className="extra-fields">
			{isShowDeliveryman &&
			<Card radius={20}>
				<div className="extra-fields__card">
					<div className="extra-fields__form">
							<h3>Доставщик</h3>
							<SelectWithReset
								fill={1}
								placeholder="Доставщик"
								size="sm"
								options={getDeliverymanList()}
								disabled={!isEdit}
								defaultValue={deliveryman}
								onChange={handleChangeDeliveryman}
							/>
						</div>
						<div className="extra-fields__form">
							<h3>Способ оплаты доставки</h3>
							<SelectWithReset
								fill={1}
								placeholder="Способ оплаты доставки"
								size="sm"
								options={getDeliveryPaymentList()}
								disabled={!isEdit}
								defaultValue={deliveryPayment}
								onChange={handleChangeDeliveryPayment}
							/>
						</div>

				</div>
			</Card>
			}
			<Card radius={20}>
			<div className="extra-fields__card">
				<div className="extra-fields__form">
					<h3>Точка выдачи доп. услуг</h3>
					<SelectWithReset
						fill={1}
						placeholder="Точка выдачи доп. услуг"
						size="sm"
						options={getOptionsPointList()}
						disabled={!isEdit}
						defaultValue={optionsPoint}
						onChange={handleChangeOptionsPoint}
					/>
				</div>
				<div className="extra-fields__form">
					<h3>Акция</h3>
					<SelectWithReset
						fill={1}
						placeholder="Акция"
						size="sm"
						options={getPromotionList()}
						disabled={!isEdit || !car}
						defaultValue={car && promotionId ? `${promotionName} - ${promotionPercent}%` : ""}
						onChange={handleChangePromotion}
					/>
				</div>
			</div>
			</Card>
			<div className="extra-fields__approved">
			<Card radius={20}>
				<div className="card">
          <h3>Согласовал</h3>
          <Input 
            placeholder="Введите ФИО"
            size={"sm"}
            fill={1}
            defaultValue={approved}
            disabled={!isEdit || (!promotionId && !discount)}
            id="approved"
          />
				</div>
			</Card>
			</div>
    </main>
  );
}