import api from "../../service/axios";

class AuthApi {
  static getToken(body) {
    return api.post(`/getToken`, body);
  }

  static logout(body) {
    return api.post("/logout", body);
  }

  static newPassReq(body) {
    return api.post("/new_pass_request", body);
  }

  static checkCode(body) {
    return api.post("/check_code", body);
  }

  static setNewPassword(body) {
    return api.post("/set_new_pass", body);
  }
}

export default AuthApi;
