import React, { useState, useEffect } from "react";
//
import IconWrapper from "../../../../../components/icon-wrapper/IconWrapper";
import Select from "../../../../../components/select/Select";
import {
  staticCategoryList,
  staticStatusList,
} from "../../../catalog/sections/static";
import "./CarFilterNav.styles.scss";
import { useWindowDimensions } from "../../../../../hooks/useWindowDimensions";

export default function CarFilterNav({
  category,
  changeCategory,
  status,
  changeStatus,
  isAuto,
  changeIsAuto,
  locationList,
  location,
  changeLocation,
  isFilterOpen,
  changeIsFilterOpen,
}) {
  const { width } = useWindowDimensions();

  const [isMobile, setIsMobile] = useState(false);
  
  useEffect(() => {
    width < 1280 ? setIsMobile(true) : setIsMobile(false)
  }, [width]);
  function getRegionList() {
    return [{ id: 0, title: "Любой", value: "" }, ...locationList];
  }

  function getKppOptions() {
    return [
      { id: 1, title: "Любой", value: "" },
      { id: 2, title: "Aвтомат", value: "auto" },
      { id: 3, title: "Mеханика", value: "mechanic" },
    ];
  }


  function getPriceSortOptions() {
    return [
      { id: 0, title: "По умолчанию", value: 0 },
      { id: 1, title: "По возрастания", value: 1 },
      { id: 2, title: "По убывания", value: 2 },
    ];
  }

  return (
    <div className="filter-nav">
      <nav className="filter-nav__top">
        {!isMobile && 
        <IconWrapper
          icon={isFilterOpen ? "x-close icon-sm" : "filter-funnel icon-sm"}
          border={1}
          rounded={12}
          onClick={() => changeIsFilterOpen(!isFilterOpen)}
          bg={isFilterOpen ? "primary" : "light"}
          color={isFilterOpen ? "light" : "dark"}
        />
        }
        <div className="filter-nav__select filter-nav__select--category">
          <Select
            size="sm"
            title="Категория"
            multiple={1}
            options={[...staticCategoryList]}
            value={category}
            onChange={changeCategory}
          />
        </div>
        <div className="filter-nav__select">
          <Select
            size="sm"
            title="Статус"
            options={[...staticStatusList]}
            value={status}
            onChange={changeStatus}
          />
        </div>
        <div className="filter-nav__select filter-nav__select--location">
          <Select
            size="sm"
            title="Локация"
            multiple={1}
            options={getRegionList()}
            value={location}
            onChange={changeLocation}
          />
        </div>

        <div className="filter-nav__select isAuto__wrap" >
        <Select
        size="sm"
        width="120px"
                title="КПП"
                options={getKppOptions()}
                value={isAuto}
                onChange={changeIsAuto}
              />
        </div>
      </nav>
    </div>
  );
}
