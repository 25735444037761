import React, { useEffect } from "react";
import styled from "styled-components";
//
import FinanceTop from "./sections/finance-top/FinanceTop";
import { useQuery } from "react-query";
import FinanceApi from "../../../store/finance/api";
import FinanceMonthItem from "./sections/finance-month-item/FinanceMonthItem";

export default function Finance() {
  let [state, setState] = React.useState({
    reports: [],
    paid: 0,
    toPay: 0,
    total: 0,
  });

  const { data: response, isSuccess } = useQuery(
    "finance-brief",
    FinanceApi.getFinanceBrief
  );

  useEffect(() => {
    if (isSuccess) {
      let { reports, deals_sum, payment_pending, payment_done } =
        response.data.data;
      setState({
        reports: reports,
        paid: payment_done,
        toPay: payment_pending,
        total: deals_sum,
      });
    }
  }, [isSuccess]);

  return (
    <StyledFinance>
      <FinanceTop paid={state.paid} toPay={state.toPay} total={state.total} />
      <ul className="finance__month__ul">
        {state.reports.map((item) => (
          <li key={item.report_id}>
            <FinanceMonthItem report={item} />
          </li>
        ))}
      </ul>
    </StyledFinance>
  );
}

const StyledFinance = styled.div``;
