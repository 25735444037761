import React, { useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import cl from "classnames";

//
import Card from "../../card/Card";
import IconWrapper from "../../icon-wrapper/IconWrapper";
import CarNumber from "../../car-number/CarNumber";
import { msToNormTime } from "../../../util/date";
import { useAppContext } from "../../../store/Context";
import "./CarCardMobile.styles.scss"

export default function CarCardMobile({
  inModal,
  car,
  selectedCar,
  onChangeSelectedCar,
  reqCreate,
  changeIsOpenModal,
  changeIsOpenCatalogCar
}) {
  // helper
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isComparsion = pathname.includes("comparsion");

  // context
  const {
    state: { carComparsionList },
    fn: {
      changeReqCar,
      addCarToCarComparsionList,
      deleteCarFromCarComparsionList,
      changeCarEquipment,
    },
  } = useAppContext();


  function navigateReqCreate() {
    navigate(`/req-create`);
    changeReqCar(car);
  }

  const checkSelected = useMemo(() => {
    return (
      selectedCar?.price_unit_id === car.price_unit_id &&
      selectedCar?.gosnomer === car.gosnomer
    );
  }, [selectedCar, car]);

  const comparsionCheck = useMemo(() => {
    return carComparsionList
      .map((i) => i.price_unit_id + i.gosnomer)
      .includes(car.price_unit_id + car.gosnomer);
  }, [carComparsionList, car]);

  const carAddressInfos = useMemo(() => {
    if (car.status === "свободна") return car.point_address;
    if (car.status === "занята" && !car.rent_end_date) return "В сервисе";
    return `Освободится ${msToNormTime(car.rent_end_date, 3)}`;
  }, [car]);

  return (
    <>
      <Card radius={20}>
        <main className="car-card-mobile">
          <nav className="car-card-mobile__nav">
          <>
            {reqCreate ? 
              <IconWrapper
                icon="check"
                border={1}
                bg={checkSelected ? "success" : "light"}
                color={checkSelected ? "light" : "dark-20"}
                disabled={car.status === "занята" || car.status === "бронь"}
                onClick={() => {
                  onChangeSelectedCar(car); 
                  changeIsOpenModal(false);
                  changeIsOpenCatalogCar(false);
                }}
              /> 
              : (
              <>
              {inModal && !isComparsion ? (
                <IconWrapper
                  icon="check"
                  w={48}
                  h={48}
                  rounded={14}
                  bg={checkSelected ? "success" : "dark-5"}
                  color={selectedCar ? "light" : "dark"}
                  onClick={() => onChangeSelectedCar(car)}
                />
              ) : isComparsion ? (
                  <IconWrapper
                    icon={comparsionCheck ? "check" : "plus"}
                    w={48}
                    h={48}
                    rounded={14}
                    bg={comparsionCheck ? "success" : ""}
                    color={comparsionCheck ? "light" : "dark"}
                    border={!comparsionCheck}
                    onClick={() =>
                    comparsionCheck
                        ? deleteCarFromCarComparsionList(car)
                        : addCarToCarComparsionList(car)
                    }
                    />
                  ) : (
                    <IconWrapper
                      icon="plus"
                      w={48}
                      h={48}
                      rounded={14}
                      bg="success"
                      color="light"
                      onClick={navigateReqCreate}
                      disabled={
                        car.status === "занята" || car.status === "бронь"
                      }
                    />
                  )}
                </>
                )}
                <p
                  className={cl("address__badge", {
                    danger: car.status === "занята",
                    warning: car.status === "бронь",
                  })}
                  title={carAddressInfos}
                >
                  {carAddressInfos}
                </p>
              </>
          </nav>
          <ul className="car-card-mobile__ul">
            <li>
              <IconWrapper
                icon="palette icon-xs"
                w={30}
                h={30}
                bg="primary-10"
                color="primary"
              />
              <p>{car.parameters.color}</p>
              <div
                className="rectangle"
                style={{ backgroundColor: car.parameters.color_code }}
              />
            </li>
            <li>
              <IconWrapper
                icon="state-number icon-xs"
                w={30}
                h={30}
                bg="primary-10"
                color="primary"
              />
              <CarNumber title={car.gosnomer} size="sm" />
            </li>
            <li>
              <IconWrapper
                icon="calendar icon-xs"
                w={30}
                h={30}
                bg="primary-10"
                color="primary"
              />
              <p>{car.parameters.year}</p>
            </li>
            <li>
              <IconWrapper
                icon="engine icon-xs"
                w={30}
                h={30}
                bg="primary-10"
                color="primary"
              />
              <p>{car.parameters.engine_type}</p>
            </li>
            <li>
              <IconWrapper
                icon="transmission icon-xs"
                w={30}
                h={30}
                bg="primary-10"
                color="primary"
              />
              <p>{car.is_auto ? "Автомат" : "Механика"}</p>
            </li>
            <li>
              <IconWrapper
                icon="drive icon-xs"
                w={30}
                h={30}
                bg="primary-10"
                color="primary"
              />
              <p>{car.parameters.drive}</p>
            </li>
          </ul>
          <footer className="car-card-mobile__footer">
            <div className="car-card-mobile__footer__left">
              <IconWrapper
                border={!comparsionCheck}
                bg={comparsionCheck ? "success" : ""}
                color={comparsionCheck ? "light" : "dark"}
                rounded={12}
                icon={
                  comparsionCheck
                    ? "scales-minus icon-sm"
                    : "scales-plus icon-sm"
                  }
                onClick={() =>
                  comparsionCheck
                    ? deleteCarFromCarComparsionList(car)
                    : addCarToCarComparsionList(car)
                }
              />
            </div>
            <div className="car-card-mobile__footer__right">
              <IconWrapper
                icon="file icon-sm"
                border={1}
                rounded={12}
                onClick={() => changeCarEquipment({ car, tab: 1 })}
              />
              <IconWrapper
                icon="settings icon-sm"
                border={1}
                rounded={12}
                onClick={() => changeCarEquipment({ car, tab: 2 })}
              />
            </div>
          </footer>
        </main>
      </Card>
    </>
  );
}
