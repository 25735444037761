import React, { useState } from "react";
import styled, { css } from "styled-components";
import cl from "classnames";
import { Collapse } from "react-collapse";

//
import NumberFormat from "../number-format/NumberFormat";
import CarNumber from "../car-number/CarNumber";
import IconWrapper from "../icon-wrapper/IconWrapper";
import { useAppContext } from "../../store/Context";

export default function ComparsionCarCard({
  car,
  hoverOption,
  equipmentIsOpen,
  featureIsOpen,
  lastElement,
  onDeleteCarFromCarList,
  isOnlyDiff,
  filterOptions,
  idx,
}) {
  const {
    state: {  isMobile },
  } = useAppContext();
  const [isHovered, setIsHovered] = useState(false);

  function changeIsHovered(ih) {
    setIsHovered(ih);
  }

  return (
    <StyledComparsionCarCard lastElement={lastElement} isHovered={isHovered}>
      <nav className="comparsiomn__car__card__nav">
        <span
          className={cl("status__circle", {
            danger: car.status === "занята",
            warning: car.status === "бронь",
          })}
        >
          {" "}
        </span>
        <h1>{car.brand}</h1>
        <h2>{car.model}</h2>
        <CarNumber title={car.gosnomer} size="xs" />
        <div
          className="comparsiomn__car__card__nav__img"
          onMouseOver={() => changeIsHovered(true)}
          onMouseOut={() => changeIsHovered(false)}
        >
          <img
            src={car.image_url}
            alt="carImg"
            style={{ marginBottom: "5px" }}
            draggable={false}
          />
          <div className="delete__wrapper">
            <IconWrapper
              icon="x-close"
              w={40}
              h={40}
              bg="danger-delete"
              color="danger-dark"
              onClick={() => onDeleteCarFromCarList(car)}
            />
          </div>
        </div>
        <p>
          От <NumberFormat value={car.price_matrix[0].prices[3].price} />
        </p>
      </nav>
      <Collapse isOpened={equipmentIsOpen}>
        <ul className="comparsiomn__car__card__list">
          {isOnlyDiff && filterOptions.p_sensors.length <= 1 ? null : (
            <>
            {isMobile &&
            <div className="card__item__title">
              {(idx < 1) ? <p>Партроники</p> : null}
            </div>
            }
            <li
              className={cl({
                hover: hoverOption === "p_sensors",
              })}
            >
              {car.setup.p_sensors ? "Есть" : "Нет"}
            </li>
            </>
          )}
          {isOnlyDiff && filterOptions.air_cond.length <= 1 ? null : (
            <>
            {isMobile &&
            <div className="card__item__title">
              {(idx < 1) ? <p>Кондиционер</p> : null}
            </div>
            }
            <li
              className={cl({
                hover: hoverOption === "air_cond",
              })}
            >
              {car.setup.air_cond ? "Есть" : "Нет"}
            </li>
            </>
          )}
          {isOnlyDiff && filterOptions.climate.length <= 1 ? null : (
            <>
            {isMobile &&
            <div className="card__item__title">
              {(idx < 1) ? <p>Климат контроль</p> : null}
            </div>
            }
            <li
              className={cl({
                hover: hoverOption === "climate",
              })}
            >
              {car.setup.climate ? "Есть" : "Нет"}
            </li>
            </>
          )}
          {isOnlyDiff && filterOptions.cruise.length <= 1 ? null : (
            <>
            {isMobile &&
            <div className="card__item__title">
              {(idx < 1) ? <p>Круиз контроль</p> : null}
            </div>
            }
            <li
              className={cl({
                hover: hoverOption === "cruise",
              })}
            >
              {car.setup.cruise ? "Есть" : "Нет"}
            </li>
            </>
          )}
          {isOnlyDiff && filterOptions.back_cam.length <= 1 ? null : (
            <>
            {isMobile &&
            <div className="card__item__title">
              {(idx < 1) ? <p>Камера заднего вида</p> : null}
            </div>
            }
            <li
              className={cl({
                hover: hoverOption === "back_cam",
              })}
            >
              {car.setup.back_cam ? "Есть" : "Нет"}
            </li>
            </>
          )}
          {isOnlyDiff && filterOptions.armrest.length <= 1 ? null : (
            <>
            {isMobile &&
            <div className="card__item__title">
              {(idx < 1) ? <p>Подлокотник</p> : null}
            </div>
            }
            <li
              className={cl({
                hover: hoverOption === "armrest",
              })}
            >
              {car.setup.armrest ? "Есть" : "Нет"}
            </li>
            </>
          )}
          {isOnlyDiff && filterOptions.seat_heat.length <= 1 ? null : (
            <>
            {isMobile &&
            <div className="card__item__title">
              {(idx < 1) ? <p>Обогрев сидений/стекл</p> : null}
            </div>
            }
            <li
              className={cl({
                hover: hoverOption === "seat_heat",
              })}
            >
              {car.setup.seat_heat ? "Есть" : "Нет"}
            </li>
            </>
          )}
          {isOnlyDiff && filterOptions.windshield_heat.length <= 1 ? null : (
            <>
            {isMobile &&
            <div className="card__item__title">
              {(idx < 1) ? <p>Обогрев лобового стекла</p> : null}
            </div>
            }
            <li
              className={cl({
                hover: hoverOption === "windshield_heat",
              })}
            >
              {car.setup.windshield_heat ? "Есть" : "Нет"}
            </li>
            </>
          )}
        </ul>
      </Collapse>
      <Collapse isOpened={featureIsOpen}>
        <div className="comparsiomn__car__card__more__circles">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <ul className="comparsiomn__car__card__list">
          {isOnlyDiff && filterOptions.color.length <= 1 ? null : (
            <>
            {isMobile &&
            <div className="card__item__title">
              {(idx < 1) ? <p>Цвет</p> : null}
            </div>
            }
            <li
              className={cl({
                hover: hoverOption === "color",
              })}
            >
              {car.parameters.color}{" "}
              <span
                className="car__color__indicator"
                style={{ backgroundColor: car.parameters.color_code }}
              />
            </li>
            </>
          )}
          {isOnlyDiff && filterOptions.capacity.length <= 1 ? null : (
            <>
            {isMobile &&
            <div className="card__item__title">
              {(idx < 1) ? <p>Вместимость</p> : null}
            </div>
            }
            <li
              className={cl({
                hover: hoverOption === "capacity",
              })}
            >
              {car.parameters.capacity} чел.
            </li>
            </>
          )}
          {isOnlyDiff && filterOptions.max_speed.length <= 1 ? null : (
            <>
            {isMobile &&
            <div className="card__item__title">
              {(idx < 1) ? <p>Максимальная скорость</p> : null}
            </div>
            }
            <li
              className={cl({
                hover: hoverOption === "max_speed",
              })}
            >
              {car.parameters.max_speed || "-"} км/ч
            </li>
            </>
          )}
          {isOnlyDiff && filterOptions.engine_power.length <= 1 ? null : (
            <>
            {isMobile &&
            <div className="card__item__title">
              {(idx < 1) ? <p>Мощность, л.с.</p> : null}
            </div>
            }
            <li
              className={cl({
                hover: hoverOption === "engine_power",
              })}
            >
              {car.parameters.engine_power}
            </li>
            </>
          )}
          {isOnlyDiff && filterOptions.engine_vol.length <= 1 ? null : (
            <>
            {isMobile &&
            <div className="card__item__title">
              {(idx < 1) ? <p>Объем двигателя</p> : null}
            </div>
            }
            <li
              className={cl({
                hover: hoverOption === "engine_vol",
              })}
            >
              {car.parameters.engine_vol}
            </li>
            </>
          )}
          {isOnlyDiff && filterOptions.tank_vol.length <= 1 ? null : (
            <>
            {isMobile &&
            <div className="card__item__title">
              {(idx < 1) ? <p>Объем топливного бака</p> : null}
            </div>
            }
            <li
              className={cl({
                hover: hoverOption === "tank_vol",
              })}
            >
              {car.parameters.tank_vol} л
            </li>
            </>
          )}
          {isOnlyDiff && filterOptions.trunk_vol.length <= 1 ? null : (
            <>
            {isMobile &&
            <div className="card__item__title">
              {(idx < 1) ? <p>Объем багажника</p> : null}
            </div>
            }
            <li
              className={cl({
                hover: hoverOption === "trunk_vol",
              })}
            >
              {car.parameters.trunk_vol} л
            </li>
            </>
          )}
          {isOnlyDiff && filterOptions.drive.length <= 1 ? null : (
            <>
            {isMobile &&
            <div className="card__item__title">
              {(idx < 1) ? <p>Тип привода</p> : null}
            </div>
            }
            <li
              className={cl({
                hover: hoverOption === "drive",
              })}
            >
              {car.parameters.drive}
            </li>
            </>
          )}
        </ul>
      </Collapse>
    </StyledComparsionCarCard>
  );
}

const StyledComparsionCarCard = styled.div`
  padding: 10px;
  border-right: 1px solid var(--primary-dark-15);
  height: 100%;
  position: relative;
  min-width: 204px;

  .status__circle {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--success);

    &.danger {
      background-color: var(--danger);
    }

    &.warning {
      background-color: var(--warning);
    }
  }

  .comparsiomn__car__card {
    &__more__circles {
      width: 100px;
      height: 1px;
      background-color: var(--primary-dark-15);
      margin: 35px auto;
    }

    &__nav {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      height: 260px;

      h1 {
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0em;
        color: var(--dark);
        margin-bottom: 5px;
      }

      h2 {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        color: var(--dark);
        opacity: 0.6;
        margin-bottom: 10px;
      }

      &__img {
        position: relative;
        margin-bottom: 12.5px;

        img {
          transition: 0.2s linear;
          mix-blend-mode: multiply;
          width: 180px;
          height: 100px;
          object-fit: contain;
        }

        .delete__wrapper {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 0;
          visibility: hidden;
          transition: 0.2s linear;
        }

        ${({ isHovered }) =>
          isHovered &&
          css`
            img {
              opacity: 0.2;
            }

            .delete__wrapper {
              opacity: 1;
              visibility: visible;
            }
          `}
      }

      p {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--primary);
        padding: 16px;
        width: 100%;
        border: 1px solid var(--primary);
        text-align: center;
        border-radius: 14px;
        margin-bottom: 10px;
      }
    }

    &__list {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      gap: 5px;

      .card__item__title {
        margin-top: 15px;
        min-height: 20px;
        height: 20px;
        font-size: 14px;
      }

      li {
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: center;
        color: var(--dark);
        padding: 16px;
        border-radius: 12px;
        transition: 0.2s linear;
        cursor: default;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        gap: 10px;
        max-height: 49px;

        .car__color__indicator {
          display: inline-block;
          width: 15px;
          height: 15px;
          border-radius: 6px;
        }

        &:nth-child(2n) {
          background-color: var(--dark-3);
        }

        &.hover {
          background-color: var(--primary-5);
          color: var(--primary);

          &:hover {
            background-color: var(--primary-10);
          }
        }
      }
    }
  }

  ${({ lastElement }) =>
    lastElement &&
    css`
      border-right: none;
    `}
`;
