import React from "react";
import "../full-layout/FullLayout.styles.scss";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";

//
import VerticalNavbar from "../../components/vertical-navbar/VerticalNavbar";
import MobileNavbar from "../../components/vertical-navbar/mobile-navbar/MobileNavbar";
import { useAppContext } from "../../store/Context";

export default function FullLayout() {

  const {
    state: { isMobile },
  } = useAppContext();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let isBitrix = false;

  if (searchParams.get("view") === "bitrix" ) isBitrix = true;

  return (
    <div className="nav-bar">
      {
        isMobile ?
        <MobileNavbar />
        :
        <VerticalNavbar />
      }

      <div className="full-layout__children" data-zoom={isBitrix}>
        <Outlet />
      </div>
    </div>
  );
}

