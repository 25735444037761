import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";

//
import PopUp from "../popup/PopUp";
import Button from "../button/Button";
import Input from "../input/Input";
import Select from "../select/Select";
import InputPhone from "../input-phone/InputPhone";
import InputDate from "../input-date/InputDate";
import TextArea from "../textarea/TextArea";
import Loader from "../loader/Loader";
import ClientApi from "../../store/client/api";
import IconWrapper from "../icon-wrapper/IconWrapper";
import { msToNormTime } from "../../util/date";
import { GET_CLIENT_LIST } from "../../store/client/type";
import { useGetStaticData } from "../../store/req-create/hook";
import { useAppContext } from "../../store/Context";
import "./CreateClientModal.styles.scss";

export default function CreateClientModal({
  isOpen,
  title,
  clientInfo,
  onClose,
  appeal,
}) {
  const {
    state: { isMobile },  
  } = useAppContext();

  const handleKeyDown = useCallback((evt) => {
    if(evt.key === 'Escape') {
      onClose();
    }
    if(evt.key === 'Enter') {
      handleSubmit();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

const { data: staticData} = useGetStaticData();
  const [client, setClient] = useState({
    name: "",
    phone: "",
    email: "",
    birthdate: "",
    source: "",
    note: "",
  });
  const [source, setSource] = useState(client.source)
  const [inputErrors, setInputErrors] = useState({
    name: "",
    phone: "",
    email: "",
    birthdate: "",
  });
  const [apiResponse, setApiResponse] = useState({
    type: "",
    text: "",
  });

  const sourceList = useMemo(() => {
    const list = [];
    staticData?.data.source_list.forEach((i) => {
      list.push({ id: i.source_id, title: i.source, value: i.source_id });
    });
    return list;
  }, [staticData?.data.source_list]);

  function changeSource(sr) {
    setSource(sr);
    const nameSource = sourceList.find((item) => item.id === sr);
    setClient((p) => ({...p, source_id: sr, source: nameSource}))
  }

  useEffect(() => {
    if (!clientInfo) return;
    let { name, phone, email, birthdate, source, note } = clientInfo;

    if (birthdate?.includes("T")) {
      birthdate = msToNormTime(new Date(birthdate).getTime() / 1000, 10);
    }

    setClient((p) => ({
      ...p,
      name,
      phone,
      email,
      birthdate,
      source,
      note,
    }));
  }, [clientInfo]);

  const queryClient = useQueryClient();
  const postClientMutation = useMutation((body) => ClientApi.postClient(body));
  const putClienMutation = useMutation((body) => ClientApi.putClient(body));

  function changeClient(e) {
    const { name, value } = e.target;
    if (name === "name") {
      setInputErrors((p) => ({ ...p, name: "" }));
    }
    if (name === "phone") {
      setInputErrors((p) => ({ ...p, phone: "" }));
    }
    if (name === "birthdate") {
      setInputErrors((p) => ({ ...p, birthdate: "" }));
    }
    if (name === "email") {
      setInputErrors((p) => ({ ...p, email: "" }));
    }
    setClient((p) => ({ ...p, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    let ex = true;
    if (!client.name.length) {
      setInputErrors((p) => ({ ...p, name: "Заполните поле" }));
      ex = false;
    }
    if (client.phone.length < 11) {
      setInputErrors((p) => ({ ...p, phone: "Заполните поле" }));
      ex = false;
    }
    // if (!client.birthdate.length) {
    //   setInputErrors((p) => ({ ...p, birthdate: "Укажите дату" }));
    //   ex = false;
    // }
    // if (!validateEmail(client.email)) {
    //   setInputErrors((p) => ({ ...p, email: "Неверный емейл" }));
    //   ex = false;
    // }

    if (ex) {
      let clientObj = {
        ...client,
        birthdate: client.birthdate
          ? new Date(client.birthdate).getTime() / 1000
          : 0,
      };

      if (clientInfo) {
        clientObj = {
          ...clientObj,
          client_id: clientInfo.client_id,
          black_listed: clientInfo.black_listed,
        };
        putClienMutation.mutate(clientObj, {
          onSuccess: (data) => {
            setApiResponse({ type: data.status, text: data.data.Status });
            queryClient.resetQueries(GET_CLIENT_LIST, { exact: true });
          },
          onError: (data) => {
            setApiResponse({
              type: data.response.status,
              text: data.response.data.error || "Server error!",
            });
          },
        });
        return;
      }
      postClientMutation.mutate(clientObj, {
        onSuccess: (data) => {
          setApiResponse({ type: data.status, text: data.data.Status });
          queryClient.resetQueries(GET_CLIENT_LIST, { exact: true });
        },
        onError: (data) => {
          setApiResponse({
            type: data.response.status,
            text: data.response.data.error,
          });
        },
      });
    }
  }

  return (
    <PopUp isOpen={isOpen} onClose={onClose} full={isMobile && "full"}>
      <form onSubmit={handleSubmit} className="create__client__modal__form">
        {apiResponse.type === "" ? (
          <>
            <div className="create__client__modal__header">
              <h1 className="create__client__modal__title">{title}</h1>
              {isMobile && (
                <IconWrapper
                  icon="close icon-lg"
                  w={52}
                  h={52}
                  rounded={0}
                  onClick={onClose}
                />
                )}
            </div>
            <main className="create__client__modal__main">
              <div className="create__client__modal__input">
                <p>ФИО</p>
                <Input
                  fill={1}
                  placeholder="Можно толь имя"
                  value={client.name ?? ""}
                  name="name"
                  onChange={changeClient}
                  error={inputErrors["name"]}
                />
              </div>
              <div className="create__client__modal__input">
                <p>Телефон</p>
                <InputPhone
                  fill={1}
                  placeholder="+7"
                  value={client.phone ?? ""}
                  name="phone"
                  onChange={changeClient}
                  error={inputErrors["phone"]}
                />
              </div>
              {!appeal && (
                <>
                  <div className="create__client__modal__input">
                    <p>Дата рождения</p>
                    <InputDate
                      fill={1}
                      placeholder="ДД.ММ.ГГГГ"
                      icon="calendar icon-sm"
                      value={client.birthdate ?? ""}
                      name="birthdate"
                      onChange={changeClient}
                      error={inputErrors["birthdate"]}
                    />
                  </div>
                  <div className="create__client__modal__input">
                    <p>Эл. Почта</p>
                    <Input
                      fill={1}
                      placeholder="@"
                      value={client.email ?? ""}
                      name="email"
                      onChange={changeClient}
                      error={inputErrors["email"]}
                    />
                  </div>
                </>
              )}
              <div className="create__client__modal__input">
                <p>Источник</p>
                <Select
                    size="sm"
                    title="Выберите пункт"
                    value={source }
                    options={sourceList}
                    onChange={changeSource}
                  />
              </div>
              {!appeal && (
                <div className="create__client__modal__input">
                  <p>Примечания</p>
                  <TextArea
                    placeholder="До 500 символов"
                    value={client.note}
                    name="note"
                    onChange={changeClient}
                  />
                </div>
              )}
            </main>
            <footer className="create__client__modal__footer">
              <Button
                p={[14, 43]}
                color="light"
                border={1}
                fW={500}
                radius={14}
                onClick={onClose}
              >
                Отменить
              </Button>
              <Button
                type="submit"
                p={[14, 40]}
                color="primary"
                border={1}
                fW={500}
                radius={14}
              >
                {(postClientMutation.isLoading ||
                  putClienMutation.isLoading) && (
                  <Loader
                    radius={14}
                    bg="primary"
                    color="light"
                    w={24}
                    h={24}
                    size={3}
                  />
                )}
                Сохранить
              </Button>
            </footer>
          </>
        ) : (
          <>
            <div className="error_in_res__wrap">
              <IconWrapper
                w={110}
                h={110}
                icon={apiResponse.type === 200 ? "check icon-xxl" : apiResponse.type === 400 ? "alert-circle icon-xxl" : "check icon-xxl"}
                bg={
                  apiResponse.type === 200
                    ? "success-20"
                    : apiResponse.type === 400
                    ? "danger-20"
                    : "warning-20"
                }
                color={
                  apiResponse.type === 200
                    ? "success-dark"
                    : apiResponse.type === 400
                    ? "danger-dark"
                    : "warning-dark"
                }
              />
              <p>{apiResponse.text}</p>
              <div className="btns__grp">
                {apiResponse.type === 400 && (
                  <Button
                    p={[14, 35]}
                    radius={12}
                    border={1}
                    color="light"
                    fw={500}
                    onClick={() => {
                      setApiResponse({ type: "", text: "" });
                    }}
                  >
                    Назад
                  </Button>
                )}
                <Button
                  p={[14, 48]}
                  radius={12}
                  color="primary"
                  fw={500}
                  onClick={() => {
                    setApiResponse({ type: "", text: "" });
                    onClose();
                  }}
                >
                  Ок
                </Button>
              </div>
            </div>
          </>
        )}
      </form>
    </PopUp>
  );
}
